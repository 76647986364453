import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import {
  CategoriesAPIActions,
  CategoriesAppActions,
} from '../state/categories/categories.actions';
import {
  AssessmentsAPIActions,
  AssessmentsAppActions,
} from '../state/assessments/assessments.actions';
import { IAssessment } from '../core/models/assessments.model';
import { ICategory } from '../core/models/categories.model';

export interface Entity {
  type: 'individual' | 'organization';
  entityId: string;
  profileId: string;
}
export interface AssessmentCategory {
  assessment: IAssessment;
  category: ICategory;
}

interface AssessmentPageState {
  isLoadingCategories: boolean;
  isLoadingForm: boolean;
  isLoadingEntitySelection: boolean;
  assessmentCategories: any[];
  currentEntity: Entity | null;
  selectedAssessmentCategory: AssessmentCategory | null;
}

@Injectable({
  providedIn: 'root',
})
export class AssessmentPageStore
  extends ComponentStore<AssessmentPageState>
  implements OnDestroy
{
  private subs: Subscription[] = [];

  constructor(private readonly actions$: Actions) {
    super({
      isLoadingCategories: false,
      isLoadingForm: false,
      isLoadingEntitySelection: false,
      assessmentCategories: [],
      currentEntity: null,
      selectedAssessmentCategory: null,
    });

    this.subs.push(
      this.categoryScrollerAppActions,
      this.categoryScrollerApiActions,
      this.entitySelectionAppActions,
      this.entitySelectionApiActions,
    );
  }

  readonly currentEntity$ = this.select((state) => state.currentEntity);
  readonly selectedAssessmentCategory$ = this.select(
    (state) => state.selectedAssessmentCategory,
  );
  readonly assessmentCategories$ = this.select(
    (state) => state.assessmentCategories,
  );
  readonly isLoadingCategories$ = this.select(
    (state) => state.isLoadingCategories,
  );
  readonly isLoadingForm$ = this.select((state) => state.isLoadingForm);

  readonly setCurrentEntity = this.updater((state, entity: Entity | null) => ({
    ...state,
    currentEntity: entity,
  }));
  readonly setSelectedAssessmentCategory = this.updater(
    (state, selectedAssessmentCategory: AssessmentCategory | null) => ({
      ...state,
      selectedAssessmentCategory,
    }),
  );
  readonly clearAllState = this.updater((state) => ({
    ...state,
    currentEntity: null,
    selectedAssessmentCategory: null,
  }));

  readonly setAssessmentCategories = this.updater(
    (state, assessmentCategories: AssessmentCategory[]) => ({
      ...state,
      assessmentCategories,
    }),
  );

  readonly toggleAssessmentFormLoading = this.updater(
    (state, isLoading: boolean) => ({
      ...state,
      isLoadingForm: isLoading,
    }),
  );

  readonly categoryScrollerAppActions = this.actions$
    .pipe(
      ofType(
        CategoriesAppActions.getCategories,
        CategoriesAppActions.getCategoryById,
        AssessmentsAppActions.getAssessmentsByOrganizationId,
        AssessmentsAppActions.getAssessmentsByUserId,
      ),
      tap(() => this.patchState({ isLoadingCategories: true })),
    )
    .subscribe();

  readonly categoryScrollerApiActions = this.actions$
    .pipe(
      ofType(
        CategoriesAPIActions.getCategoriesSuccess,
        CategoriesAPIActions.getCategoriesFailure,
        CategoriesAPIActions.getCategoryByIdSuccess,
        CategoriesAPIActions.getCategoryByIdFailure,
        AssessmentsAPIActions.getAssessmentsByOrganizationIdSuccess,
        AssessmentsAPIActions.getAssessmentsByOrganizationIdFailure,
        AssessmentsAPIActions.getAssessmentsByUserIdSuccess,
        AssessmentsAPIActions.getAssessmentsByUserIdFailure,
      ),
      tap(() => this.patchState({ isLoadingCategories: false })),
    )
    .subscribe();

  readonly entitySelectionAppActions = this.actions$
    .pipe(
      ofType(CategoriesAppActions.getCategories),
      tap(() => this.patchState({ isLoadingEntitySelection: true })),
    )
    .subscribe();

  readonly entitySelectionApiActions = this.actions$
    .pipe(
      ofType(
        CategoriesAPIActions.getCategoriesSuccess,
        CategoriesAPIActions.getCategoriesFailure,
      ),
      tap(() => this.patchState({ isLoadingEntitySelection: false })),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { Injectable, OnDestroy } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Actions, ofType } from "@ngrx/effects";
import { Subscription, tap } from "rxjs";
import { UsersAPIActions, UsersAppActions } from "src/app/state/users/users.actions";



@Injectable()
export class UsersListStore extends ComponentStore<{ loading: boolean }> implements OnDestroy {
    private subs: Subscription[] = [];

    constructor(private readonly actions$: Actions) {
        super({
            loading: false
        })

        this.subs.push(this.appActionsSub, this.apiActionsSub)
    }

    readonly loading$ = this.select((state) => state.loading);



    readonly appActionsSub = this.actions$
        .pipe(
            ofType(UsersAppActions.getUsersAccounts),
            tap(() => this.patchState({ loading: true }))
        ).subscribe();


    readonly apiActionsSub = this.actions$
        .pipe(
            ofType(UsersAPIActions.getUsersAccountsSuccess, UsersAPIActions.getUsersAccountsFailure),
            tap(() => this.patchState({ loading: false }))
        ).subscribe();

    override ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe())
    }
}
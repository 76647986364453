import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

interface IBadgeCellState {
  badges: string[];
}

@Injectable()
export class BadgeCellStore extends ComponentStore<IBadgeCellState> {
  constructor() {
    super({
      badges: [],
    });
  }

  readonly badges$ = this.select((state) => state.badges);

  readonly setBadges = this.updater((state, badges: string[]) => ({
    ...state,
    badges: [...badges],
  }));
}

import { Injectable, Input } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

interface StatusCellState {
  status: boolean;
}

@Injectable()
export class StatusCellStore extends ComponentStore<StatusCellState> {
  constructor() {
    super({
      status: false,
    });
  }

  readonly status$ = this.select((state) => state.status);

  readonly setStatus = this.updater((state, status: boolean) => ({
    ...state,
    status,
  }));
}

<div
  class="space-y-6 text-gray-800 dark:text-gray-50 m-3 p-5 border border-gray-50 max-h-96 overflow-y-scroll"
>
  <div class="border-l-4 border-yellow-400 bg-yellow-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg
          class="h-5 w-5 text-yellow-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3">
        <p class="text-sm text-yellow-700 font-medium">
          TERMS OF SERVICE AND END USER LICENSE AGREEMENT - THIS IS A LEGAL
          AGREEMENT - PLEASE CAREFULLY REVIEW
        </p>
      </div>
    </div>
  </div>

  <h3
    class="font-medium border-b-2 w-fit border-primary-600 dark:border-primary-500"
  >
    CyberWA Cyber Security Services and Cyber Defence Tool
  </h3>

  <p class="font-sans font-normal text-sm leading-relaxed">
    CyberWA, Inc. (“CyberWA”, “we”, “us”, “our”) offers cyber security services
    (“Services”) to organizations, high net worth individuals, celebrities,
    organizations, and others who face significant cyber threats. As part of its
    Services, CyberWA offers use of its proprietary CyberWA Cyber Defence Tool
    (the “Tool”) to CyberWA clients and client affiliates to enable them to
    conduct a high-level assessment of their overall cyber security capabilities
    and preparedness. You must be a CyberWA client (“Client”) or authorized
    affiliate of a CyberWA client (“Client Affiliate”) to use the Tool or
    receive Services. Use of the Tool or Services by any other person or
    organization is prohibited. For purposes of this EULA, a Client Affiliate is
    any entity or individual who is authorized by the agreement between CyberWA
    and a CyberWA Client to use the Tool or Services.
  </p>

  <h5
    class="font-medium border-b-2 w-fit border-primary-600 dark:border-primary-500"
  >
    USE OF THE CYBERWA Cyber Defence Tool IS AT YOUR OWN RISK.
  </h5>

  <p class="font-sans font-normal text-sm leading-relaxed">
    Cyber security is a highly technical field that requires specialized
    technical training and experience to identify, evaluate, and mitigate cyber
    risks facing specific individuals and organizations. The purpose of the
    Cyber Defence Tool is to identify areas where your cyber security posture
    potentially could be improved. We do not guarantee that any specific
    vulnerability will be identified or that following any recommendations
    offered through the Tool will necessarily protect you from any specific
    cyber security threat. CyberWA encourages you to consult with an experienced
    cyber security professional prior to implementing any recommendations
    provided by the Tool’s high-level assessment.
  </p>
  <ol
    class="list-decimal list-outside space-y-2 font-sans font-normal text-sm leading-relaxed"
  >
    <li>
      Binding Agreement. This Terms of Service and End User License Agreement
      (“EULA”) is a legally binding agreement between you and CyberWA. You and
      CyberWA are sometimes referred to herein, individually, as a Party, and,
      collectively, as the “Parties”. Each person or organization (“User” or
      “you”) that receives CyberWA Services must accept this EULA either by
      indicating acceptance electronically through the Tool, by signing a hard
      copy version, or by such other method offered by CyberWA before receiving
      Services. If a person receiving Services is under the age of eighteen (18)
      or the age of majority in their country, this EULA must be accepted by
      that person’s parent, legal guardian, or other authorized individual.
    </li>
    <li>
      Relationship to Other Agreements. You may be a party to or the beneficiary
      of other agreements with CyberWA relating to the Tool or other Services,
      including but not limited to a Professional Services Agreement (“PSA”)
      and/or a Statement of Work (“SOW”) (collectively, “Related Agreements”).
      Where applicable, this EULA supplements those Related Agreements. In the
      event of a conflict between the terms of a Related Agreement and this
      EULA, the agreements shall be read and interpreted in the following order
      of priority: (A) the PSA, (B) an applicable SOW, and (C) this EULA.
    </li>
    <li>
      Age Requirement. The Tool is for use by individuals who are eighteen (18)
      years old or older. If you are under 18, you may not use the Tool.
    </li>
    <li>
      Authority. You represent and warrant that you have all permissions,
      rights, and authority to install the Tool and provide information to
      CyberWA relating to the Tool and other Services.
    </li>
    <li>
      Term and Termination. This EULA commences upon your acceptance of this
      EULA and terminates upon one or more of the following events: (a) the
      termination of the applicable PSA and/or SOWs pursuant to which you are
      receiving Services; or (b) your request that CyberWA stop providing
      Services to you. Upon termination of this EULA, you are required to stop
      using and delete all copies of the Tool from your device(s). If you remove
      the Tool or request that CyberWA stop providing Services, you must accept
      the version of the EULA then in effect before re-installing the Tool or
      receiving new Services.
    </li>
    <li>
      CyberWA Intellectual Property. CyberWA’s trademarks, service marks,
      copyrights, patents, trade secrets, and all other proprietary rights in or
      related to the Tool and other Services are the exclusive property of
      CyberWA and/or its licensors (“CyberWA Intellectual Property”), whether or
      not registered, recognized, or perfected under applicable law.
      <ol type="a" class="list-[lower-alpha] list-outside space-y-2">
        <li>
          Client expressly agrees that all materials and information used,
          developed, or acquired by CyberWA to perform Services, including any
          inventions and discoveries or derivative works of any kind that are
          developed by CyberWA pursuant to this Agreement are CyberWA
          Intellectual Property and shall be owned exclusively by CyberWA.
          Unless otherwise expressly set forth in an SOW, no CyberWA Services or
          materials provided to Client (“Deliverables”) shall be considered a
          “work for hire”.
        </li>
        <li>
          It is understood and agreed that CyberWA sells the same or similar
          services to other clients and CyberWA may modify the services provided
          to other clients based on its experience in performing CyberWA
          Services pursuant to this Agreement.
        </li>
        <li>
          CyberWA Intellectual Property may not be copied, distributed,
          republished, uploaded, posted, or transmitted except in connection
          with the authorized use of the Tool and other Services. You may not
          alter, decompile, disassemble, reverse engineer, or otherwise modify
          any CyberWA Intellectual Property.
        </li>
      </ol>
    </li>
    <li>
      Limited License. In exchange for your agreement to comply with this EULA
      and, where applicable, payment of fees, CyberWA provides you a limited,
      non-transferrable, non- exclusive, revocable license to use the Tool and
      related CyberWA Intellectual Property for the limited purpose of
      conducting a risk assessment for your personal purposes and/or for the
      internal purposes of the organization with which you are affiliated. The
      Tool and CyberWA Intellectual Property may not be used to provide or
      receive third party consulting serviceswithout the express written
      permission of CyberWA. Nothing herein shall be construed as granting to
      you or any other person, whether by estoppel or otherwise, any license or
      other rights to CyberWA Intellectual Property except for those expressly
      granted in this Section
    </li>
    <li>
      Confidential Information. You and CyberWA (each, a “Receiving Party”)
      acknowledge that you and CyberWA may be exposed to or acquire
      “Confidential Information” which is information that is proprietary to or
      confidential to the other Party, to the other Party’s clients, or to third
      parties to which the Party disclosing the information (“Disclosing Party”)
      owes a duty of confidentiality.
      <ol type="a" class="list-[lower-alpha] list-outside space-y-2">
        <li>
          Neither Party shall copy, reproduce, sell, assign, license, market,
          transfer or otherwise dispose of, give, or disclose the other Party’s
          Confidential Information to third parties or use Confidential
          Information for any purposes other than the performance of this EULA.
        </li>
        <li>
          Each Party shall advise its employees, agents, and subcontractors (and
          their employees) who may be exposed to the Confidential Information of
          their obligation to keep such information confidential.
        </li>
        <li>
          If a Party receives a request to disclose Confidential Information
          pursuant to a subpoena, court order, or other legal process, the
          Receiving Party shall, unless otherwise required by law or court
          order:
          <ol type="i" class="list-[lower-roman] list-outside space-y-2">
            <li>
              promptly notify the Disclosing Party of the existence, terms and
              circumstances surrounding such request;
            </li>
            <li>
              consult with the Disclosing Party on the advisability of taking
              legally available steps to resist or narrow such request and
              cooperate, at the Disclosing Party’s sole expense, with the
              Disclosing Party on any such steps the Disclosing Party considers
              advisable or necessary;
            </li>
            <li>
              if disclosure of the Confidential Information is required or
              deemed advisable by the Disclosing Party, exercise commercially
              reasonable efforts to obtain an order, stipulation or other
              reliable assurance acceptable to the Disclosing Party that
              confidential treatment shall be accorded to such Confidential
              Information to be disclosed. Upon the Disclosing Party’s written
              pre-approval, the Receiving Party shall take legally available
              steps to resist or narrow such request, and the Disclosing Party
              shall reimburse the Receiving Party for reasonable legal fees and
              expenses incurred to comply with this Section 8.
            </li>
          </ol>
        </li>
        <li>
          Confidential Information does not include information that is:
          <ol type="i" class="list-[lower-roman] list-outside space-y-2">
            <li>
              in or becomes part of the public domain other than by disclosure
              by the Receiving Party in violation of this Agreement;
            </li>
            <li>
              demonstrably known to the Receiving Party previously, without
              knowledge of a duty of confidentiality applicable to such
              information;
            </li>
          </ol>
        </li>
        <li>
          (i) (ii) (iii) independently developed by the Receiving Party outside
          of this Agreement and without reference to or use of Confidential
          Information; or (iv) rightfully obtained by the Receiving Party from
          third parties without a duty of confidentiality. e. CyberWA will
          maintain the confidentiality of all information received from User in
          perpetuity unless such information ceases to be confidential pursuant
          to Section8(c) above. Each Party is required to maintain the
          confidentiality of trade secrets for so long as the information
          remains a trade secret under applicable law. Each Party is required to
          maintain the confidentiality of all other Confidential Information for
          three (3) years following termination of this Agreement.
        </li>
      </ol>
    </li>
    <li>
      Compliance with Privacy and Data Protection Laws. CyberWA will handle all
      personally identifiable information (“PII”) received from User in
      accordance with all applicable privacy and data protection laws and
      regulations as set forth in CyberWA’s Privacy Policy, which is expressly
      incorporated in this EULA and is available at
      https://www.cyberwa.com/privacy.
    </li>
    <li>
      User Data Security Practices. You are responsible for the safekeeping and
      integrity of your Account. Each Account is for a single user only and
      multiple users may not share login credentials under any circumstances.
      CyberWA assumes no liability or responsibility for your failure to
      maintain the integrity of your Account and the confidentiality of your
      login credentials. You also are responsible for taking commercially
      reasonable measures to protect the security of devices on which you
      install and/or use the Tool.
    </li>
    <li>
      CyberWA Representation and Warranty. CyberWA represents and warrants that
      it will use commercially reasonable physical, administrative, and
      technical controls to protect the confidentiality, integrity, and
      availability of its Services, including Services delivered through the
      Tool. CyberWA does not guarantee that its Services are 100% secure and
      CyberWA’s representation and warranty in this Section 11 does not relieve
      User and Client of their own data security obligations, including those
      set forth in Section 10, above.
    </li>
    <li>
      Disclaimer. EXCEPT FOR THE EXPRESS WARRANTY SET FORTH IN SECTION 11,
      ABOVE, CYBERWA GIVES NO OTHER EXPRESS, IMPLIED OR STATUTORY WARRANTIES,
      INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY,
      FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT OF THIRD-PARTY RIGHTS,
      OR WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR PRACTICE. CYBERWA
      DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE TOOL OR
      OTHER SERVICES, THAT THE TOOL OR OTHER SERVICES WILL MEET YOUR
      REQUIREMENTS, THAT THE OPERATION OF THE TOOL OR OTHER SERVICES WILL BE
      UNINTERRUPTED OR FREE FROM ERRORS, BUGS, CORRUPTION, LOSS OF DATA,
      INTERFERENCE, HACKING OR VIRUSES, OR THAT THE TOOL OR OTHER SERVICES WILL
      INTEROPERATE OR BE COMPATIBLE WITH ANY OTHER SOFTWARE. CYBERWA ALSO DOES
      NOT WARRANT OR GUARANTEE THAT THE TOOL OR OTHER SERVICES WILL IDENTIFY ALL
      VULNERABILITIES OR THREATS, OR THAT FOLLOWING ANY RECOMMENDATION OFFERED
      THROUGH THE TOOL OR OTHER SERVICES WILL PREVENT YOU FROM SUFFERING ANY
      LOSS, DAMAGE, OR INJURY.SOME JURISDICTIONS LIMIT OR PROHIBIT DISCLAIMERS
      OF IMPLIED WARRANTIES, IN WHICH CASE SUCH DISCLAIMER MAY NOT APPLY TO YOU.
    </li>
    <li>
      Limitation of Liability. THE LIABILITY OF CYBERWA, ITS SUBSIDIARIES,
      AFFILIATES AND SUBCONTRACTORS (INCLUDING THEIR RESPECTIVE OFFICERS,
      DIRECTORS, EMPLOYEES, SUCCESSORS AND ASSIGNS) FOR ANY AND ALL CLAIMS AND
      CAUSES OF ACTION (INCLUDING WITHOUT LIMITATION, CLAIMS AND CAUSES OF
      ACTION BASED ON TORT, CONTRACT, STRICT LIABILITY, OR INDEMNITY) ARISING
      OUT OF, RELATED TO, OR IN CONNECTION WITH THIS EULA OR YOUR USE OF THE
      TOOL AND OTHER SERVICES, SHALL NOT EXCEED THE FEE PAID TO CYBERWA FOR THE
      SERVICES PROVIDED TO USER OR ONE THOUSAND U.S. DOLLARS ($1,000.00),
      WHICHEVER IS GREATER. CYBERWA ALSO SHALL NOT BE LIABLE FOR LOSS OF PROFITS
      OR REVENUE, LOSS OF USE OR OTHER OPPORTUNITY, LOSS OF GOODWILL OR OTHER
      CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, OR PUNITIVE DAMAGES. USER
      ACKNOWLEDGES THAT (A) WITHOUT THE INCLUSION OF THIS LIMITATION OF
      LIABILITY PROVISION, CYBERWA WOULD NOT HAVE PROVIDED THE TOOL AND/OR
      SERVICES AND (B) THIS LIMITATION OF LIABILITY PROVISION IS MERELY A
      LIMITATION, AND NOT AN EXCULPATION, OF CYBERWA’S LIABILITY.
    </li>
    <li>
      Miscellaneous.
      <ol type="a" class="list-[lower-alpha] list-outside space-y-2">
        <li>
          Entire Agreement. This EULA together with, where applicable, the PSA
          and any SOW binding on or applicable to you, constitute the entire
          agreement between CyberWA and you relating to the subject matter
          herein and supersedes all prior written or oral agreements and
          understandings between the Parties concerning the same subject matter.
        </li>
        <li>
          Relationship of the Parties. CyberWA and User are independent
          contractors. Nothing contained herein or in any other agreement shall
          be construed as creating any agency or joint venture relationship
          between CyberWA and User. Neither Party shall have any power to create
          any obligation, express or implied, or incur any liability on the
          other Party’s behalf without the express written consent of the other
          Party.
        </li>
        <li>
          Amendments. CyberWA may modify this EULA from time to time. Revisions
          shall become effective thirty (30) days after posting on the CyberWA
          website or in the Tool (“Notice Period”). If you provide us with a
          valid working email address, we also will email you the revised terms
          at least thirty (30) days before they go into effect. Your continued
          use of the Tool or acceptance of Services after this Notice Period
          means you accept the changes.
        </li>
        <li>
          Waiver. A Party’s failure to insist upon strict adherence to any term
          of this EULA on any occasion shall not be considered a waiver thereof
          or deprive that Party of the right thereafter to insist upon strict
          adherence to that term or any other term of this EULA. e. Governing
          Law. This EULA shall be governed by and construed in accordance with
          the laws governing an applicable PSA. If no binding PSA is in effect,
          this EULA shall be governed by and construed in accordance with the
          laws of the State of New York, without regard to its conflict of laws
          provisions.
        </li>
      </ol>
    </li>
    <li>
      Dispute Resolution. Each Party irrevocably agrees that venue and
      jurisdiction for any dispute arising under this EULA shall lie exclusively
      in the venue and jurisdiction identified in an applicable PSA. If no PSA
      applies, venue and jurisdiction for any dispute arising under this EULA
      shall lie exclusively in the New York State Supreme Court, Albany County,
      New York or the United States District Court for the Northern District of
      New York and waives any right to object to jurisdiction within either of
      the foregoing forums. EACH PARTY HEREBY IRREVOCABLY WAIVES TO THE FULLEST
      EXTENT PERMITTED BY APPLICABLE LAW ANY AND ALL RIGHT TO TRIAL BY JURY IN
      ANY LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THIS EULA.
    </li>
  </ol>
</div>

<div class="flex justify-between items-center mx-6">
  <label
    class="ml-3 block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
    for="eula"
  >
    I confirm that I have thoroughly reviewed and agree to be bound by the
    CyberWA Cyber Security Services and Cyber Defence Tool's Terms of Service
    and End User License Agreement.
  </label>
  <input
    class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600 cursor-pointer"
    name="eula"
    type="checkbox"
    [(ngModel)]="isChecked"
    (change)="onCheckChange()"
  />
</div>

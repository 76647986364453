import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

interface DateCellState {
  date: Date | string | null;
}

@Injectable()
export class DateCellStore extends ComponentStore<DateCellState> {
  constructor() {
    super({
      date: null,
    });
  }

  readonly date$ = this.select((state) => state.date);

  readonly setDate = this.updater((state, date: Date) => ({
    ...state,
    date,
  }));
}

import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { DashboardPageHeaderComponent } from './dashboard-page-header/dashboard-page-header.component';
import { DashboardPageStore } from './dashboard-page.store';
import { DashboardPageListComponent } from './dashboardp-page-list/dashboard-page-list.component';
import { Store } from '@ngrx/store';
import { selectUserRole } from '../state/auth/auth.selectors';
import { IRole, UserRole } from '../core/models/user.model';
import { TabViewModule } from 'primeng/tabview';
import { DashbaordPageAdminComponent } from './dashbaord-page-admin/dashbaord-page-admin.component';
import { AssessmentPageStore } from '../assessment-page/assessment-page.store';
import { TabsComponent } from '../shared/tabs/tabs.component';
import { Router, RouterOutlet } from '@angular/router';
import { ITab } from '../core/models/tab.model';
import { SharedAssessmentHeaderComponent } from '../shared/shared-assessment-header/shared-assessment-header.component';
import { DashboardPageAssessmentComponent } from './dashboard-page-assessment/dashboard-page-assessment.component';

@Component({
  selector: 'cap-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    SidebarComponent,
    DashboardPageHeaderComponent,
    DashboardPageListComponent,
    TabViewModule,
    DashbaordPageAdminComponent,
    TabsComponent,
    RouterOutlet,
    SharedAssessmentHeaderComponent,
    DashboardPageAssessmentComponent,
  ],
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss',
})
export class DashboardPageComponent implements OnInit {
  private router = inject(Router);
  private dashboardPageStore = inject(DashboardPageStore);
  private assessmentPageStore = inject(AssessmentPageStore);
  showDashboardPageList: boolean = false;
  entity = this.assessmentPageStore.currentEntity$;
  isSysAdmin = false;
  private store = inject(Store);
  showOrgDash = false;
  currentUserRole: IRole | undefined = undefined;

  ngOnInit(): void {
    this.store.select(selectUserRole).subscribe((role) => {
      this.currentUserRole = role;
      if (role?.name === UserRole.SysAdmin) {
        this.isSysAdmin = true;
      }
      if (
        role?.name === UserRole.SysAdmin ||
        role?.name === UserRole.OrgAdmin
      ) {
        this.showOrgDash = true;
        this.router.navigate([this.tabs[0].route]);
      } else {
        this.showOrgDash = false;
      }
    });
    this.entity.subscribe((entity) => {
      if (entity) {
        this.dashboardPageStore.toggleAssessmentStats(false);
        this.showDashboardPageList = true;
      }
    });
  }

  protected readonly UserRole = UserRole;
  tabs: ITab[] = [
    {
      name: 'Assessments Dashboard',
      route: 'dashboard/assessments',
      current: false,
      icon: 'assessment',
    },
    {
      name: 'Organizations Dashboard',
      route: 'dashboard/organizations',
      current: false,
      icon: 'corporate_fare',
    },
  ];
  onTabChange(route: string) {
    this.router.navigate([route]);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  Observable,
  Subscription,
  combineLatestWith,
  map,
  of,
  tap,
} from 'rxjs';

import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { IAvatar } from 'src/app/core/models/user.model';
import { AvatarService } from 'src/app/core/services/avatar.service';
import { Store } from '@ngrx/store';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { ChangeAvatarModalStore } from './change-avatar-modal.store';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ChangeAvatarComponent } from '../change-avatar/change-avatar.component';
import { FeedbackComponent } from "../feedback/feedback.component";

@Component({
    selector: 'cap-change-avatar-modal',
    standalone: true,
    providers: [ChangeAvatarModalStore],
    templateUrl: './change-avatar-modal.component.html',
    styleUrl: './change-avatar-modal.component.scss',
    imports: [
        CommonModule,
        ModalContainerComponent,
        NgIf,
        SpinnerComponent,
        ChangeAvatarComponent,
        FeedbackComponent
    ]
})
export class ChangeAvatarModalComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  readonly confirmModalData = {
    title: 'Delete Avatar',
    message: 'Are you sure you want to delete this avatar?',
  };

  avatarDropped: IAvatar | null = null;

  @Input() avatars$: Observable<IAvatar[] | null> = of(null);

  @Output() avatarRemoved = new EventEmitter<IAvatar>();
  @Output() avatarUpdated = new EventEmitter<IAvatar>();
  // @Output() avatarSelected = new EventEmitter<IAvatar>();

  private readonly store = inject(Store);
  private readonly componentStore = inject(ChangeAvatarModalStore);
  private avatarService = inject(AvatarService);

  @ViewChild(ModalContainerComponent) modalContainer!: ModalContainerComponent;

  maxDimensions = this.avatarService.maxDimensions;

  readonly selectedAvatar$ = this.componentStore.selectedAvatar$;
  readonly oldAvatars$ = this.componentStore.oldAvatars$;
  readonly currentAvatar$ = this.componentStore.currentAvatar$;
  readonly errorMessage$ = this.componentStore.errorMessage$;
  readonly loading$ = this.componentStore.loading$;

  ngOnInit(): void {
    this.store.dispatch(ModalActions.register({ id: 'avatar' }));

    const sub = this.avatars$
      .pipe(
        tap((avatars) => {
          if (avatars) {
            this.componentStore.setAvatars(avatars);
            const currentAvatar = avatars.find((a) => a.isCurrent);
            if (currentAvatar) {
              this.componentStore.setSelectedAvatar(currentAvatar);
            }
          }
        })
      )
      .subscribe();

    this.subs.push(sub);
  }

  closeModal() {
    this.store.dispatch(ModalActions.close({ id: 'avatar' }));
  }

  setSelectedAvatar(avatar: IAvatar): void {
    this.componentStore.setSelectedAvatar(avatar);
  }

  updateAvatar(): void {
    let avatar: IAvatar | null = null;
    const sub = this.componentStore.selectedAvatar$
      .pipe(
        combineLatestWith(this.currentAvatar$),
        map(([av, ca]) => {
          if (av !== ca) {
            avatar = av;
          }
        })
      )
      .subscribe();
    if (avatar) {
      this.avatarUpdated.emit(avatar);
    }
    this.subs.push(sub);
    this.closeModal();
  }

  removeAvatar(avatar: IAvatar): void {
    if (avatar) this.avatarRemoved.emit(avatar);
  }

  callConfirmationModal(avatar: IAvatar) {
    this.avatarDropped = avatar;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-avatar' }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.store.dispatch(ModalActions.unregister({ id: 'avatar' }));
  }
}

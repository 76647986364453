import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { FeedbackService } from "src/app/core/services/feedback.service";
import { FeedbackApiActions, FeedbackAppActions } from "./feedback.actions";
import { catchError, exhaustMap, map, of, share, take } from "rxjs";
import { handleError } from "src/app/utils/error-handler.util";

export const createFeedback$ = createEffect((
    actions$ = inject(Actions),
    feedbackService = inject(FeedbackService)
) => {
    return actions$.pipe(
        ofType(FeedbackAppActions.submitFeedback),
        exhaustMap(({feedback}) => feedbackService.createFeedback(feedback).pipe(
            take(1),
            map(({feedback}) => FeedbackApiActions.submitFeedbackSuccess({feedback})),
            catchError((error) => of(FeedbackApiActions.submitFeedbackFailure({message: handleError(error)})))
        )),
        share(),
    )
},{functional: true})

export const getFeedbacks$ = createEffect((
    actions$ = inject(Actions),
    feedbackService = inject(FeedbackService)
) => {
    return actions$.pipe(
        ofType(FeedbackAppActions.getFeedbacks),
        exhaustMap(({page, pageSize}) => feedbackService.getFeedbacks(page, pageSize).pipe(
            take(1),
            map(({feedbacks, pagination}) => FeedbackApiActions.getFeedbacksSuccess({feedbacks, pagination})),
            catchError((error) => of(FeedbackApiActions.getFeedbacksFailure({message: handleError(error)})))
        )),
        share(),
    )
},{functional: true})

export const resolveFeedback$ = createEffect((
    actions$ = inject(Actions),
    feedbackService = inject(FeedbackService)
) => {
    return actions$.pipe(
        ofType(FeedbackAppActions.resolveFeedback),
        exhaustMap(({id}) => feedbackService.resolveFeedback(id).pipe(
            take(1),
            map(({feedback}) => FeedbackApiActions.resolveFeedbackSuccess({feedback})),
            catchError((error) => of(FeedbackApiActions.resolveFeedbackFailure({message: handleError(error)})))
        )),
        share(),
    )
},{functional: true})

export const deleteFeedbacks$ = createEffect((
    actions$ = inject(Actions),
    feedbackService = inject(FeedbackService)
) => {
    return actions$.pipe(
        ofType(FeedbackAppActions.deleteFeedbacks),
        exhaustMap(({ids}) => feedbackService.deleteFeedbacks(ids).pipe(
            take(1),
            map(({message}) => FeedbackApiActions.deleteFeedbacksSuccess({ids})),
            catchError((error) => of(FeedbackApiActions.deleteFeedbacksFailure({message: handleError(error)})))
        )),
        share(),
    )
},{functional: true})
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarService } from 'src/app/core/services/avatar.service';
import { AvatarUploadStore } from './avatar-upload.store';
import { IAvatar } from 'src/app/core/models/user.model';
import { Store } from '@ngrx/store';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { ChangeAvatarModalComponent } from '../change-avatar-modal/change-avatar-modal.component';
import { DynamicComponentLoaderService } from 'src/app/core/dynamic-component-loader.service';
import { ModalHostService } from 'src/app/core/services/modal-host.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'cap-avatar-upload',
  standalone: true,
  imports: [CommonModule],
  providers: [AvatarUploadStore],
  templateUrl: './avatar-upload.component.html',
  styleUrl: './avatar-upload.component.scss',
})
export class AvatarUploadComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private readonly avatarService = inject(AvatarService);
  private readonly dynamicComponentLoader = inject(
    DynamicComponentLoaderService,
  );
  private readonly modalHostService = inject(ModalHostService);
  private readonly componentStore = inject(AvatarUploadStore);
  private readonly store = inject(Store);
  private readonly modalHostRef = this.modalHostService.getModalHost();

  maxDimension = this.avatarService.maxDimensions;

  readonly avatars$ = this.componentStore.avatars$;
  readonly currentAvatar$ = this.componentStore.currentAvatar$;

  @Input() set avatars(avatars: IAvatar[] | null) {
    if (avatars) this.componentStore.setAvatars(avatars);
  }

  @Output() avatarUpdated = new EventEmitter<IAvatar>();
  @Output() avatarRemoved = new EventEmitter<IAvatar>();

  ngOnInit() {
    this.dynamicComponentLoader.loadComponent(
      this.modalHostRef,
      ChangeAvatarModalComponent,
      { avatars$: this.avatars$ },
      {
        avatarUpdated: this.onAvatarUpdated.bind(this),
        avatarRemoved: this.onAvatarRemoved.bind(this),
      },
    );
  }

  openModal(id: string) {
    this.store.dispatch(ModalActions.open({ id }));
  }

  onAvatarUpdated(avatar: IAvatar) {
    this.avatarUpdated.emit(avatar);
  }

  onAvatarRemoved(avatar: IAvatar) {
    this.avatarRemoved.emit(avatar);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalHostRef.clear();
  }
}

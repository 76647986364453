import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import { ServiceVendorsListStore } from './service-vendors.store';
import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { EditableTableComponent } from 'src/app/shared/editable-table/editable-table.component';
import { FeedbackComponent } from 'src/app/shared/feedback/feedback.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import {
  selectAll as selectServiceVendors,
  selectTotal as selectServiceVendorsCount,
} from 'src/app/state/services/service-vendors.state';
import {
  IServiceVendorBase,
  IServiceVendor,
} from 'src/app/core/models/services.model';
import { ServicesAppActions } from 'src/app/state/services/services.actions';
import { selectVendorsPagination } from '../../../state/services/services.selectors';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';

@Component({
  selector: 'cap-service-vendors-list',
  standalone: true,
  imports: [
    CommonModule,
    EditableTableComponent,
    ConfirmModalComponent,
    FeedbackComponent,
    SpinnerComponent,
    PaginationComponent,
  ],
  providers: [ServiceVendorsListStore],
  templateUrl: './service-vendors-list.component.html',
  styleUrl: './service-vendors-list.component.scss',
})
export class ServiceVendorsListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private readonly store = inject(Store);
  private componentStore = inject(ServiceVendorsListStore);

  readonly pagination$ = this.store.select(selectVendorsPagination);
  page = 1;
  pageSize = 10;
  totalPages = 0;
  totalCount = 0;

  readonly confirmModalData = {
    title: 'Delete question service vendor',
    message: 'Are you sure you want to delete this service vendor?',
  };

  serviceVendorsTableMetaData: IEditableTableMetadata = {
    title: 'Service Vendors',
    entityName: 'Service Vendor',
    bgColor: 'dark:bg-gray-900',
    accordion: false,
  };

  serviceVendors$: Observable<IServiceVendor[]> =
    this.store.select(selectServiceVendors);

  private serviceVendorDropped: IServiceVendor | null = null;

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;
  listLoading$ = this.componentStore.isListLoading$;

  serviceVendorTableColumns: IEditableColumn[] = [
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: false,
    },
    {
      key: 'email',
      label: 'Email',
      type: 'text',
      sortable: false,
    },
    {
      key: 'url',
      label: 'Link',
      type: 'text',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      type: 'date',
      sortable: false,
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      type: 'date',
      sortable: false,
    },
  ];

  ngOnInit(): void {
    this.loadServiceVendors();
    this.store
      .select(selectServiceVendorsCount)
      .pipe(
        tap((count) => {
          if (!count) {
            this.loadServiceVendors();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.pagination$.subscribe((pagination) => {
      this.page = pagination.page;
      this.pageSize = pagination.pageSize;
      this.totalPages = pagination.totalPages;
      this.totalCount = pagination.totalCount;
    });
  }

  loadServiceVendors() {
    this.store.dispatch(
      ServicesAppActions.getServiceVendors({
        page: this.page,
        pageSize: this.pageSize,
      }),
    );
  }

  onServiceVendorCreated(newVendor: any) {
    this.store.dispatch(
      ServicesAppActions.createServiceVendor({
        serviceVendor: <IServiceVendorBase>newVendor,
      }),
    );
  }

  onServiceVendorUpdated(vendor: any) {
    this.store.dispatch(
      ServicesAppActions.updateServiceVendor({
        serviceVendor: <IServiceVendor>vendor,
      }),
    );
  }

  openConfirmationModal(vendor: any) {
    this.serviceVendorDropped = vendor;
    this.store.dispatch(
      ModalActions.open({ id: 'confirm-removing-service-vendor' }),
    );
  }

  removeServiceVendor() {
    if (this.serviceVendorDropped) {
      this.store.dispatch(
        ServicesAppActions.removeServiceVendor({
          id: this.serviceVendorDropped._id,
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPageChange($event: number) {
    this.page = $event;
    this.loadServiceVendors();
  }
}

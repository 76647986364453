import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { SettingsAppActions } from '../../state/settings/settings.actions';
import {
  selectAll as selectAuditLogs,
  selectPagination as selectAuditLogsPagination,
} from '../../state/audit-logs/audit-logs.state';
import {
  selectAllSettings,
  selectLogoutTime,
} from '../../state/settings/settings.selectors';
import { PaginationComponent } from 'src/app/shared/pagination/pagination.component';
import { TableComponent } from 'src/app/shared/table/table.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { Pagination } from 'src/app/state/categories/categories.state';
import { EntityName } from 'src/app/core/models/entity-name.model';
import { IColumn } from 'src/app/core/models/column.model';
import { AuditLogsAppActions } from 'src/app/state/audit-logs/audit-logs.actions';

@Component({
  selector: 'cap-system-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PaginationComponent,
    TableComponent,
    SpinnerComponent,
  ],
  templateUrl: './system-page.component.html',
  styleUrl: './system-page.component.scss',
})
export class SystemPageComponent implements OnInit {
  private readonly store = inject(Store);

  systemTimeout = 0;
  passwordLength = 12;

  ngOnInit() {
    this.store.select(selectLogoutTime).subscribe((time) => {
      this.systemTimeout = time;
    });
    this.store.select(selectAllSettings).subscribe((settings) => {
      this.passwordLength = settings.minimumPasswordLength;
      this.systemTimeout = settings.logoutTime;
    });
  }

  // changeSystemTimeout($event: Event) {
  //   let newTime = Number((<HTMLInputElement>$event.target).value);
  //   this.store.dispatch(
  //     SettingsAppActions.updateSettings({
  //       settings: {
  //         logoutTime: newTime,
  //       },
  //     }),
  //   );
  // }

  updateSettings() {
    this.store.dispatch(
      SettingsAppActions.updateSettings({
        settings: {
          logoutTime: this.systemTimeout,
          minimumPasswordLength: this.passwordLength,
        },
      }),
    );
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Subscription, tap } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';
import { StepNavigationCommunicationService } from 'src/app/core/services/step-navigation-communication.service';

@Injectable()
export class StepNavigationModalStore
  extends ComponentStore<{
    isLoading: boolean;
    isEula: boolean;
    currentStepIndex: number;
    passwordCredentialsValidity: boolean;
    passwordCredentials: {
      email: string;
      newPassword: string;
      confirmPassword: string;
    };
    errorMessage: string | null;
  }>
  implements OnDestroy
{
  private subs: Subscription[] = [];

  constructor(
    private readonly actions$: Actions,
    private readonly stepCommunicationService: StepNavigationCommunicationService,
  ) {
    super({
      currentStepIndex: 0,
      isLoading: false,
      isEula: false,
      passwordCredentialsValidity: false,
      passwordCredentials: {
        email: '',
        newPassword: '',
        confirmPassword: '',
      },
      errorMessage: null,
    });
    this.subs.push(
      this.appActionsSub,
      this.apiSuccessActionsSub,
      this.apiFailureActionsSub,
      this.setPasswordCredentialsSub,
      this.setIsEulaSub,
    );
  }

  readonly currentStepIndex$ = this.select((state) => state.currentStepIndex);
  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly isEula$ = this.select((state) => state.isEula);
  readonly isPasswordCredentialsValid$ = this.select(
    (state) => state.passwordCredentialsValidity,
  );
  readonly passwordCredentials$ = this.select(
    (state) => state.passwordCredentials,
  );

  readonly errorMessage$ = this.select((state) => state.errorMessage);

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(
        AuthAppActions.setNewPassword,
        AuthAppActions.generate2FAOTP,
        AuthAppActions.verify2FAOTP,
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.setNewPasswordSuccess,
        AuthAPIActions.generate2FAOTPSuccess,
        AuthAPIActions.verify2FAOTPSuccess,
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.setNewPasswordFailure,
        AuthAPIActions.generate2FAOTPFailure,
        AuthAPIActions.verify2FAOTPFailure,
      ),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message }),
      ),
    )
    .subscribe();

  readonly setCurrentStepIndex = this.updater(
    (state, newStepIndex: number) => ({
      ...state,
      currentStepIndex: newStepIndex,
    }),
  );

  readonly setPasswordCredentialsSub =
    this.stepCommunicationService.setNewPasswordFormData$
      .pipe(
        tap((data) =>
          this.patchState({
            passwordCredentials: data.credentials,
            passwordCredentialsValidity: data.formValidity,
          }),
        ),
      )
      .subscribe();

  readonly setIsEulaSub = this.stepCommunicationService.isEula$
    .pipe(tap(({ isEula }) => this.patchState({ isEula })))
    .subscribe();

  override ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectUserRole } from 'src/app/state/auth/auth.selectors';

@Directive({
  selector: '[capRoleCheck]',
  standalone: true,
})
export class RoleCheckDirective implements OnDestroy {
  private subs: Subscription[] = [];

  private currentUserRole: string | null = null;
  private allowedRoles: string[] = [];

  private temlateRef: TemplateRef<any> = inject(TemplateRef);
  private viewContainer: ViewContainerRef = inject(ViewContainerRef);
  private readonly store = inject(Store);

  private sub = this.store.select(selectUserRole).subscribe((role) => {
    this.currentUserRole = role?.name!;
    this.updateView();
  });

  @Input() set capRoleCheck(allowedRoles: string[]) {
    this.allowedRoles = allowedRoles;
    this.updateView();
  }

  private updateView(): void {
    this.viewContainer.clear();

    if (this.allowedRoles.includes(this.currentUserRole!)) {
      this.viewContainer.createEmbeddedView(this.temlateRef);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

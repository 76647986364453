import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { ICategory } from 'src/app/core/models/categories.model';
import { CategoriesAPIActions } from './categories.actions';

export interface Pagination {
  page: number;
  limit: number;
  totalPages: number;
  totalCount: number;
}
export interface CategoriesState extends EntityState<ICategory> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<ICategory> = createEntityAdapter<ICategory>(
  {
    selectId: (category: ICategory) => category._id,
  },
);

export const initialState: CategoriesState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 10,
    totalCount: 0,
  },
});

const categoriesReducer = createReducer(
  initialState,
  on(
    CategoriesAPIActions.getCategoriesSuccess,
    (state, { categories, pagination }) => {
      return adapter.setAll(categories, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(CategoriesAPIActions.getCategoriesFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(
    CategoriesAPIActions.getCategoriesByTypeSuccess,
    (state, { categories, pagination }) => {
      return adapter.setAll(categories, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(CategoriesAPIActions.getCategoriesByTypeFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(CategoriesAPIActions.getCategoryByIdSuccess, (state, { category }) => {
    return adapter.upsertOne(category, state);
  }),
  on(CategoriesAPIActions.getCategoryByIdFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    CategoriesAPIActions.searchCategoriesSuccess,
    (state, { categories, pagination }) => {
      return adapter.setAll(categories, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(CategoriesAPIActions.searchCategoriesFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(CategoriesAPIActions.createCategorySuccess, (state, { category }) => {
    return adapter.addOne(category, state);
  }),
  on(CategoriesAPIActions.createCategoryFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(CategoriesAPIActions.updateCategorySuccess, (state, { category }) => {
    return adapter.upsertOne(category, state);
  }),
  on(CategoriesAPIActions.updateCategoryFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(CategoriesAPIActions.removeCategorySuccess, (state, { categoryId }) => {
    return adapter.removeOne(categoryId, state);
  }),
  on(CategoriesAPIActions.removeCategoryFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    CategoriesAPIActions.updateCategoryAvatarSuccess,
    (state, { categoryId, avatars }) => {
      return adapter.updateOne({ id: categoryId, changes: { avatars } }, state);
    },
  ),
  on(CategoriesAPIActions.updateCategoryAvatarFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    CategoriesAPIActions.removeCategoryAvatarSuccess,
    (state, { categoryId, avatar }) => {
      const category = state.entities[categoryId];
      if (!category) {
        return state;
      }
      const updatedAvatars = category.avatars.filter((av) => av !== avatar);
      return adapter.updateOne(
        { id: categoryId, changes: { avatars: updatedAvatars } },
        state,
      );
    },
  ),
  on(CategoriesAPIActions.removeCategoryAvatarFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    CategoriesAPIActions.toggleCategoryStatusSuccess,
    (state, { categoryId, isActive }) => {
      return adapter.updateOne(
        { id: categoryId, changes: { isActive } },
        state,
      );
    },
  ),
  on(CategoriesAPIActions.toggleCategoryStatusFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
);

export const categoriesFeature = createFeature({
  name: 'categories',
  reducer: categoriesReducer,
  extraSelectors: ({ selectCategoriesState, selectEntities }) => ({
    ...adapter.getSelectors(selectCategoriesState),
    selectCategoryById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id] ?? null),
  }),
});

export const {
  name,
  reducer,
  selectCategoriesState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectCategoryById,
} = categoriesFeature;

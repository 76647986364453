<div class="mt-3 px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1
        class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50"
      >
        Categories
      </h1>
      <p class="mt-2 text-sm text-gray-700 dark:text-gray-400">
        A list of all the categories were you could manage their data
      </p>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <input
        type="text"
        placeholder="Search organization catego..."
        class="w-full border border-gray-300 rounded-md px-3 py-2 dark:bg-slate-700 dark:text-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        [(ngModel)]="searchQuery"
        (input)="searchCategories()"
      />
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <button
        type="button"
        (click)="openCreateCategoryModal()"
        class="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add category
      </button>
    </div>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        @if (!isLoadingCategoryList()) {
          <cap-table
            (onEntityUpdated)="openUpdateCategorynModal($event)"
            (onEntityDeleted)="openConfirmationModal($event)"
            (onSortChanged)="sortCategories($event)"
            [columns]="columns"
            [data]="(categoriesData$ | async) ?? []"
            [isDraggable]="true"
            (OnToggleClicked)="onIndividualCategoryStatusChange($event)"
          ></cap-table>
          <cap-pagination
            [current_page]="current_page"
            [totalItems]="totalItems"
            [totalPages]="total_pages"
            [pageSize]="pageSize"
            (pageChange)="onPageChange($event)"
            [disableNext]="current_page == total_pages"
          ></cap-pagination>
        } @else {
          <cap-spinner class="w-full"></cap-spinner>
        }
      </div>
    </div>
  </div>
</div>

@if (selectModalVisibilityById("add-category") | async) {
  <cap-category-upsert-modal [modalMetadata]="modalMetadata">
  </cap-category-upsert-modal>
}
@if (selectModalVisibilityById("edit-category") | async) {
  <cap-category-upsert-modal [modalMetadata]="modalMetadata">
  </cap-category-upsert-modal>
}

<cap-confirm-modal
  [modalID]="'confirm-removing-category'"
  [data]="confirmModalData"
  (confirmEvent)="removeCategory()"
></cap-confirm-modal>

import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IAssessment, IAssessmentResponse } from '../models/assessments.model';
import { FileWithPreview, IDoc } from '../models/action-items.model';

@Injectable({
  providedIn: 'root',
})
export class AssessmentsService {
  private http = inject(HttpClient);
  assessmentPath = 'assessments';
  constructor() {}

  getAssessments(): Observable<{ assessments: IAssessment[] }> {
    return this.http.get<{ assessments: IAssessment[] }>(
      `${environment.apiUrl}/${this.assessmentPath}`,
    );
  }

  getAssessmentById(id: string): Observable<{ assessment: IAssessment }> {
    return this.http.get<{ assessment: IAssessment }>(
      `${environment.apiUrl}/${this.assessmentPath}/${id}`,
    );
  }

  getAssessmentsByUserId(
    userId: string,
  ): Observable<{ assessments: IAssessment[] }> {
    return this.http.get<{ assessments: IAssessment[] }>(
      `${environment.apiUrl}/${this.assessmentPath}/user/${userId}`,
    );
  }

  getAssessmentsByOrganizationId(
    organizationId: string,
  ): Observable<{ assessments: IAssessment[] }> {
    return this.http.get<{ assessments: IAssessment[] }>(
      `${environment.apiUrl}/${this.assessmentPath}/organization/${organizationId}`,
    );
  }

  updateAssessment(
    assessmentId: string,
    questionsResponses: IAssessmentResponse[],
  ): Observable<{ assessment: IAssessment }> {
    return this.http.put<{ assessment: IAssessment }>(
      `${environment.apiUrl}/${this.assessmentPath}/${assessmentId}`,
      { questionsResponses: questionsResponses },
    );
  }

  removeAssessment(id: string): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(
      `${environment.apiUrl}/${this.assessmentPath}/${id}`,
    );
  }

  uploadAssessmentEvidence(
    assessmentId: string,
    actionItemId: string,
    files: FileWithPreview[],
  ): Observable<{ assessmentId: string; actionItemId: string; docs: IDoc[] }> {
    const formData = new FormData();
    files.forEach((file) => {
      if (file._file) formData.append('files', file._file);
    });
    return this.http.patch<{
      assessmentId: string;
      actionItemId: string;
      docs: IDoc[];
    }>(
      `${environment.apiUrl}/${this.assessmentPath}/${assessmentId}/${actionItemId}/evidence`,
      formData,
    );
  }

  removeAssessmentEvidence(
    assessmentId: string,
    actionItemId: string,
    docId: string,
  ): Observable<{
    assessmentId: string;
    actionItemId: string;
    docId: string;
  }> {
    return this.http.delete<{
      assessmentId: string;
      actionItemId: string;
      docId: string;
    }>(
      `${environment.apiUrl}/${this.assessmentPath}/${assessmentId}/${actionItemId}/evidence/${docId}`,
    );
  }

  deleteAllEvidanceOfActionItem(
    assessmentId: string,
    actionItemId: string,
  ): Observable<{
    assessmentId: string;
    actionItemId: string;
  }> {
    return this.http.delete<{ assessmentId: string; actionItemId: string }>(
      `${environment.apiUrl}/${this.assessmentPath}/${assessmentId}/${actionItemId}/evidence`,
    );
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil, tap } from 'rxjs';

import {
  ServicesAppActions,
  ServicesAPIActions,
} from 'src/app/state/services/services.actions';

@Injectable()
export class ServicesListStore
  extends ComponentStore<{
    isLoading: boolean;
    isListLoading: boolean;
    errorMessage: string | null;
  }>
  implements OnDestroy
{
  private subDestroy$ = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({ isListLoading: false, isLoading: false, errorMessage: null });
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly isListLoading$ = this.select((state) => state.isListLoading);

  private readonly appListingActionSub = this.actions$
    .pipe(
      ofType(ServicesAppActions.getServices),
      tap(() => this.patchState({ isListLoading: true })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly apiListingActionSub = this.actions$
    .pipe(
      ofType(
        ServicesAPIActions.getServicesSuccess,
        ServicesAPIActions.getServicesFailure
      ),
      tap(() => this.patchState({ isListLoading: false })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly appActionSub = this.actions$
    .pipe(
      ofType(
        ServicesAppActions.createService,
        ServicesAppActions.updateService,
        ServicesAppActions.removeService
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly apiSuccessActionSub = this.actions$
    .pipe(
      ofType(
        ServicesAPIActions.createServiceSuccess,
        ServicesAPIActions.updateServiceSuccess,
        ServicesAPIActions.removeServiceSuccess
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly apiFailureSub = this.actions$
    .pipe(
      ofType(
        ServicesAPIActions.createServiceFailure,
        ServicesAPIActions.updateServiceFailure,
        ServicesAPIActions.removeServiceFailure
      ),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message })
      ),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

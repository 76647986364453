import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IStep } from 'src/app/core/models/step.model';

interface StepNavigatorState {
  steps: IStep[];
}

@Injectable()
export class StepNavigatorStore extends ComponentStore<StepNavigatorState> {
  constructor() {
    super({
      steps: [],
    });
  }
  readonly steps$ = this.select((state) => state.steps);

  readonly currentStep$ = this.select(
    this.steps$,
    (steps) => steps.find((step) => step.status === 'current') || null
  );

  readonly setSteps = this.updater(
    (state, steps: Omit<IStep, 'id' | 'status'>[]) => ({
      ...state,
      steps: steps.map((step, index) => ({
        ...step,
        id: index + 1,
        status: index === 0 ? ('current' as const) : ('upcoming' as const),
      })),
    })
  );

  readonly nextStep = this.updater((state: StepNavigatorState) => {
    const updatedSteps = state.steps.map((step, index, array) => {
      if (step.status === 'current') {
        return { ...step, status: 'completed' as const };
      } else if (index === array.findIndex((s) => s.status === 'current') + 1) {
        return { ...step, status: 'current' as const };
      }
      return step;
    });

    return { ...state, steps: updatedSteps };
  });

  readonly previousStep = this.updater((state: StepNavigatorState) => {
    const currentStepIndex = state.steps.findIndex(
      (s) => s.status === 'current'
    );
    if (currentStepIndex < 0) {
      return state;
    }
    const updatedSteps = state.steps.map((step, index) => {
      if (index === currentStepIndex) {
        return { ...step, status: 'upcoming' as const };
      } else if (index === currentStepIndex - 1) {
        return { ...step, status: 'current' as const };
      }
      return step;
    });
    return { ...state, steps: updatedSteps };
  });
}

import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RolesService } from 'src/app/core/services/roles.service';
import { RolesAPIActions, RolesAppActions } from './roles.actions';
import { catchError, map, of, switchMap, take } from 'rxjs';

export const getRoles$ = createEffect(
  (actions$ = inject(Actions), rolesService = inject(RolesService)) => {
    return actions$.pipe(
      ofType(RolesAppActions.getRoles),
      switchMap(() => {
        return rolesService.getRoles().pipe(
          take(1),
          map(({ roles }) => RolesAPIActions.getRolesSuccess({ roles })),
          catchError(({ message }) =>
            of(RolesAPIActions.getRolesFailure({ message }))
          )
        );
      })
    );
  },
  {
    functional: true,
  }
);

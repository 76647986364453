<div class="border-b border-gray-200">
  <nav class="-mb-px flex flex-wrap space-x-8" aria-label="Tabs">
    @for(tab of tabs$ | async; track tab.name) {
    <a
      class="group inline-flex space-x-2 items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
      [ngClass]="{
        'border-primary-500 text-primary-600 dark:text-primary-500':
          tab.current,
        'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-400 dark:hover:border-gray-300 dark:hover:text-gray-300':
          !tab.current
      }"
      (click)="selectTab(tab)"
    >
      <span aria-hidden="true" class="material-symbols-outlined">
        {{ tab.icon }}
      </span>
      <span>{{ tab.name }}</span>
    </a>
    }
  </nav>
</div>

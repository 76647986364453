<div class="max-h-dvh overflow-auto">
  <div class="container mx-auto px-4 py-8">
    @if ((isLoadingAdminData | async) || isLoadingCarousel) {
      <div class="h-dvh flex items-center justify-center">
        <p-progressSpinner class="w-16 h-16"></p-progressSpinner>
      </div>
    } @else {
      <div class="space-y-8">
        <!-- Carousel Section -->
        <section class="mb-8">
          <p-carousel [value]="carouselItems" [numVisible]="6" [numScroll]="3" [circular]="false">
            <ng-template let-item pTemplate="item">
              <div *ngIf="item.category" class="p-2">
                <div
                  class="w-fit h-32 border-2 mr-1 rounded-2xl cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-100 hover:border-white hover:shadow-default relative"
                >
                  <div class="relative">
                    <img
                      [src]="
                        item.category.avatars.length > 0
                          ? item.category.avatars[
                              item.category.avatars.length - 1
                            ].data
                          : 'assets/images/empty-avatar.jpg'
                      "
                      [alt]="item.category.name"
                      class="w-40 h-20 rounded-xl"
                    />
                    <div
                      class="grid grid-cols-2 grid-rows-2 gap-1 absolute inset-0"
                    >
                      <div class="relative w-full h-full">
                        <p-knob
                          (click)="onClickCategory(item.lowEntities)"
                          [ngModel]="item.low"
                          [size]="35"
                          class="absolute top-0 left-0 bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                          readonly="true"
                          [valueColor]="getScoreColor(item.low)"
                          [pTooltip]="'low'"
                        ></p-knob>
                      </div>
                      <div class="relative w-full h-full">
                        <p-knob
                          (click)="onClickCategory(item.mediumEntities)"
                          [ngModel]="item.medium"
                          [size]="35"
                          class="absolute top-0 right-0 bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                          readonly="true"
                          [valueColor]="getScoreColor(item.medium)"
                          [pTooltip]="'medium'"
                        ></p-knob>
                      </div>
                      <div class="relative w-full h-full">
                        <p-knob
                          (click)="onClickCategory(item.highEntities)"
                          [ngModel]="item.high"
                          [size]="35"
                          class="absolute bottom-0 left-0 bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                          readonly="true"
                          [valueColor]="getScoreColor(item.high)"
                          [pTooltip]="'high'"
                        ></p-knob>
                      </div>
                      <div class="relative w-full h-full">
                        <p-knob
                          (click)="onClickCategory(item.unansweredEntities)"
                          [ngModel]="item.unanswered"
                          [size]="35"
                          class="absolute bottom-0 right-0 bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                          readonly="true"
                          [valueColor]="'gray'"
                          [pTooltip]="'unanswered'"
                        ></p-knob>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex justify-center w-full font-light text-xs px-1 mt-2"
                  >
                    <p class="dark:text-white text-center">
                      {{
                        item.category.name.length > 25
                          ? item.category.name.slice(0, 20) + "..."
                          : item.category.name
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </section>

        <!-- Stats Section -->
        <section class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all hover:shadow-lg">
            <h3 class="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-2">Total Entities</h3>
            <p class="text-3xl font-bold text-indigo-600 dark:text-indigo-400">
              {{ isSysAdmin ? 
                 (currentAssessmentStats.totalUsers || 0) +
                 (currentAssessmentStats.totalStaff || 0) +
                 (currentAssessmentStats.totalMangers || 0) +
                 (currentAssessmentStats.totalAdmins || 0) +
                 (currentAssessmentStats.totalSubOrganizations || 0) :
                 currentAssessmentStats.totalUsers + currentAssessmentStats.totalSubOrganizations }}
            </p>
          </div>
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all hover:shadow-lg">
            <h3 class="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-2">Individuals</h3>
            <p class="text-3xl font-bold text-green-600 dark:text-green-400">{{ currentAssessmentStats.totalUsers }}</p>
          </div>
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all hover:shadow-lg">
            <h3 class="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-2">Organizations</h3>
            <p class="text-3xl font-bold text-blue-600 dark:text-blue-400">{{ currentAssessmentStats.totalSubOrganizations }}</p>
          </div>
        </section>

        @if (isSysAdmin) {
          <section class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all hover:shadow-lg">
              <h3 class="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-2">Organization Admins</h3>
              <p class="text-3xl font-bold text-purple-600 dark:text-purple-400">{{ currentAssessmentStats.totalAdmins }}</p>
            </div>
            <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all hover:shadow-lg">
              <h3 class="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-2">Organization Managers</h3>
              <p class="text-3xl font-bold text-yellow-600 dark:text-yellow-400">{{ currentAssessmentStats.totalMangers }}</p>
            </div>
            <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all hover:shadow-lg">
              <h3 class="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-2">Organization Staff</h3>
              <p class="text-3xl font-bold text-red-600 dark:text-red-400">{{ currentAssessmentStats.totalStaff }}</p>
            </div>
          </section>
        }

        <!-- Table Section -->
        <section class="mt-8">
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
            <p-table [value]="filteredTableData" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5, 10, 20]"
                     styleClass="p-datatable-responsive-demo" [tableStyle]="{'min-width': '60rem'}">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="entityName" class="p-3 "><span class="flex">Name <p-sortIcon field="entityName"></p-sortIcon></span></th>
                  <th pSortableColumn="entityType" class="p-3"><span class="flex">Entity Type <p-sortIcon field="entityType"></p-sortIcon></span></th>
                  <th pSortableColumn="score" class="p-3"><span class="flex">Score <p-sortIcon field="score"></p-sortIcon></span></th>
                  <th pSortableColumn="categoryName" class="p-3"><span class="flex">Category <p-sortIcon field="categoryName"></p-sortIcon></span></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-assessment>
                <tr class="p-selectable-row">
                  <td>
                    <span class="p-column-title">Name</span>
                    <div class="flex items-center space-x-3">
                      <img [src]="assessment.avatar ? assessment.avatar.data : 'assets/images/empty-avatar.jpg'"
                           class="w-8 h-8 rounded-full" [alt]="assessment.entityName">
                      <span class="font-medium">{{ assessment.entityName }}</span>
                    </div>
                  </td>
                  <td>
                    <span class="p-column-title">Entity Type</span>
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                      {{ assessment.entityType }}
                    </span>
                  </td>
                  <td>
                    <span class="p-column-title">Score</span>
                    <span [ngClass]="{
                      'text-red-600': assessment.score < 50,
                      'text-yellow-600': assessment.score >= 50 && assessment.score < 75,
                      'text-green-600': assessment.score >= 75
                    }" class="font-semibold">
                      {{ assessment.score.toFixed(0) }}%
                    </span>
                  </td>
                  <td class="flex items-center">
                    <span class="p-column-title">Category</span>
                    <span class="px-2 inline-flex items-center  leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                      <img [src]="assessment.categoryAvatar" [alt]="assessment.categoryName" class="w-8 h-8 rounded-full mr-1">
                      {{ assessment.categoryName }}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </section>
      </div>
    }
  </div>
</div>

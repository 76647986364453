<cap-modal-container [modalID]="'avatar'">
  <p class="text-2xl font-bold text-gray-900 dark:text-gray-50" heading>
    Change Your Avatar / Logo
  </p>
  @if (errorMessage$ | async) {
    <cap-feedback type="error" [message]="errorMessage$ | async"></cap-feedback>
  }
  <cap-change-avatar
    [selectedAvatar]="selectedAvatar$ | async"
    [oldAvatars]="oldAvatars$ | async"
    [currentAvatar]="currentAvatar$ | async"
    (avatarRemoved)="removeAvatar($event)"
    (avatarSelected)="setSelectedAvatar($event)"
  ></cap-change-avatar>
  <button
    type="button"
    (click)="updateAvatar()"
    class="block w-full rounded-md bg-red-500 hover:bg-red-400 text-gray-50 px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
  >
    save
  </button>
</cap-modal-container>

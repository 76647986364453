export interface IAddress {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  addressType: 'Home' | 'Office' | 'Others';
  isPrimary: boolean;
}

export interface IPhoneNumber {
  phoneCode: string;
  number: string;
  phoneType: 'Mobile' | 'Home' | 'Office' | 'Others';
  isPrimary: boolean;
}
export interface IAvatar {
  url: string;
  data?: string; // URL to the avatar image
  contentType: string; // MIME type of the avatar
  isCurrent: boolean; // Flag indicating if this is the current avatar
}

export interface IRole {
  _id: string;
  name: UserRole;
}

export interface IUserOrganization {
  _id: string;
  name: string;
}

export enum UserRole {
  SuperAdmin = 'SA',
  SysAdmin = 'SSA',
  OrgAdmin = 'OA',
  OrgManager = 'OM',
  Individual = 'I',
  OrgStaff = 'OS',
}

export interface IUserBase {
  email: string;
  firstName: string;
  lastName: string;
  role: IRole;
  organization?: string;
  avatars: IAvatar[];
  addresses: IAddress[];
  phoneNumbers: IPhoneNumber[];
  paymentCompleted?: boolean;
  paymentIntentId?: string | null;
  discountCode?: string | null;
  profile: string;
  createdAt: Date;
  updatedAt: Date;
  isActive: boolean;
  isEula: boolean;
  lastLogin: Date;
  otpEnabled: boolean;
  optVerified: boolean;
  otpBase32: string | null;
  otpAuthUrl: string | null;
  otpHex: string | null;
  otpAscii: string | null;
  refferel?: string;
  subscriptionId?: string | null;
  subscriptionStatus?: string | null;
  subscriptionStartTime?: number | null;
  subscriptionEndTime?: number | null;
  lastAssessmentTime?: Date;
  __v: number;
}

export interface IUser extends IUserBase {
  _id: string;
}

export interface IUserTable {
  _id: string;
  info: {
    fullName: string;
    email: string;
  };
  role: string; // role name
  avatar: IAvatar | null; // current avatar
  addresse: string; // primary address composed of address details
  phoneNumber: string; // primary phone number;
  createdAt: Date;
  updatedAt: Date;
  organization?: string;
  profile?: string;
  status: boolean;
  lastLogin: Date;
  otpEnabled: boolean;
  optVerified: boolean;
  otpBase32: string | null;
  otpAuthUrl: string | null;
  otpHex: string | null;
  otpAscii: string | null;
  lastAssessmentTime?: Date;
}

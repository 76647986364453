import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  inject,
  OnDestroy,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AlertContainerComponent } from './shared/alert-container/alert-container.component';
import { ModalHostService } from './core/services/modal-host.service';
import { AuthService } from './core/services/auth.service';
import { Store } from '@ngrx/store';
import { SettingsAppActions } from './state/settings/settings.actions';
import { selectLogoutTime } from './state/settings/settings.selectors';
import { selectSettingsState } from './state/settings/settings.state';
import { slideInAnimation } from './browser-animation';


@Component({
  selector: 'cap-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, AlertContainerComponent],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  title = 'CAP 2.0';

  private modalHostService = inject(ModalHostService);

  @ViewChild('modalHost', { read: ViewContainerRef, static: true })
  modalHost!: ViewContainerRef;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.store.dispatch(SettingsAppActions.getSettings());
    this.authService.initializeListeners();
    this.store.select(selectLogoutTime).subscribe((time) => {
      if (time) {
        this.authService.setLogoutTime = time;
      }
    });
    this.modalHostService.setModalHost(this.modalHost);
  }


  ngOnDestroy() {}

  getRouteAnimation(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
}

<div class="mt-8 flex flex-col">
  <div class="flex justify-between">
    <p class="text-lg font-bold text-gray-900 dark:text-gray-50" heading>
      {{ metadata.title }}
    </p>

    <div class="flex gap-2">
      @if (entityAllowCSVUpload.includes(metadata.entityName)) {
        
      }
      <button
      type="button"
      (click)="addRow()"
      class="block rounded-md  bg-primary-600 px-3 py-2 mb-4 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Add {{ metadata.entityName }}
    </button>
    </div>
  </div>
  <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
    <table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
      <thead class="bg-gray-50 dark:bg-gray-800">
        <tr>
          @if (metadata.accordion) {
            <th scope="col" class="w-16 border-r border-gray-300 dark:border-gray-700"></th>
          }
          @for (col of columns$ | async; track col.key; let isFirst = $first; let isLast = $last) {
            <th
              scope="col"
              class="py-3.5 px-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 dark:text-gray-400 relative border-r border-gray-300 dark:border-gray-700"
              [ngClass]="{ 'sm:pl-0': isFirst }"
              [style.width]="columnWidths[col.key] || 'auto'"
            >
              {{ col.label }}
              @if (!isLast) {
                <div
                  class="absolute top-0 right-0 bottom-0 w-1 cursor-col-resize hover:bg-indigo-500 transition-colors duration-200"
                  (mousedown)="startResize($event, col.key)"
                ></div>
              }
            </th>
          }
          <th scope="col" class="relative py-3.5 px-3 sm:pr-6 border-r border-gray-300 dark:border-gray-700">
            <span class="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      @if (isDraggable) {
        <tbody
          cdkDropList
          (cdkDropListDropped)="drop($event)"
          class="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900"
        >
          @if ((data$ | async)?.length === 0) {
            <tr>
              <td
                [attr.colspan]="(columns$ | async)!.length + 2"
                class="py-6 text-center text-sm text-gray-500 dark:text-gray-400"
              >
                No {{ metadata.entityName }} found
              </td>
            </tr>
          }
          @for (item of data$ | async; track item.id) {
            <tr
              [attr.data-row-id]="item._id"
              cdkDrag
              [class.opacity-50]="isDragging"
              [class.shadow-md]="isDragging"
              (cdkDragStarted)="onDragStarted($event)"
              (cdkDragReleased)="onDragReleased($event)"
              class="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200 ease-in-out cursor-move active:cursor-grabbing"
            >
              <div
                class="animate-pulse bg-gray-300 dark:bg-gray-700 h-12"
                *cdkDragPlaceholder
              ></div>

              @if (metadata.accordion) {
                <td class="w-16 border-r border-gray-300 dark:border-gray-700">
                  <button
                    class="disabled:text-gray-200 dark:text-gray-300 dark:disabled:text-gray-700"
                    [disabled]="editRowId === item._id"
                    type="button"
                    (click)="toggleEmbeddedRows(item._id)"
                  >
                    <span class="material-symbols-outlined">{{
                      expandedRowId === item._id ? "expand_less" : "expand_more"
                    }}</span>
                  </button>
                </td>
              }

              @for (
                col of columns$ | async;
                track col.key;
                let isFirst = $first
              ) {
                <td
                  class="py-4 px-3 text-sm text-gray-500 dark:text-gray-400 border-r border-gray-300 dark:border-gray-700 max-w-xs overflow-hidden"
                  [ngClass]="{ 'sm:pl-0': isFirst }"
                >
                  <div class="truncate">
                    <!-- Read Mode cells -->
                    @if (editRowId !== item._id) {
                      @switch (col.type) {
                        @case ("text") {
                          @if (metadata.title === "Questions") {
                            <cap-text-cell
                              [text]="item[col.key]"
                              [isQuestion]="true"
                            ></cap-text-cell>
                          } @else {
                            <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                          }
                        }
                        @case ("description") {
                          <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                        }
                        @case ("radio") {
                          <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                        }
                        @case ("list") {
                          <cap-text-cell
                            [text]="
                              col.key === 'weight'
                                ? convertWeight(item[col.key])
                                : col.key === 'standardId' ||
                                    col.key === 'sourceId' ||
                                    col.key === 'vendorId'
                                  ? (getEntityRowNameById(col.key, item[col.key])
                                    | async)
                                  : item[col.key]
                            "
                          ></cap-text-cell>
                        }
                        @case ("button") {
                          <cap-edit-button-cell
                            (buttonClicked)="onButtonClicked(item._id, col.key)"
                            [label]="col.label"
                          ></cap-edit-button-cell>
                        }
                        @case ("status") {
                          <cap-status-cell
                            [status]="item[col.key]"
                            [props]="col.options!"
                          ></cap-status-cell>
                        }
                        @case ("checkbox") {
                          <cap-status-cell
                            [status]="item[col.key]"
                            [props]="
                              col.options ?? [
                                { label: 'Positive', value: true },
                                { label: 'Negative', value: false }
                              ]
                            "
                          ></cap-status-cell>
                        }
                        @case ("badge") {
                          <cap-badge-cell
                            [badges]="item[col.key]"
                          ></cap-badge-cell>
                        }
                        @case ("date") {
                          <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                        }
                        @case ("cost") {
                          <cap-cost-cell [value]="item[col.key]"></cap-cost-cell>
                        }
                        @case ("toggle") {
                          <cap-edit-toggle-button-cell
                            (checkChange)="
                              onToggelClicked($event, item._id, col.key)
                            "
                            [button]="item[col.key]"
                          ></cap-edit-toggle-button-cell>
                        }
                        <!-- Other column types -->
                        @default {
                          <p></p>
                        }
                      }
                    }
                    <!-- Edit Mode cells -->
                    @if (editRowId === item._id) {
                      @switch (col.type) {
                        @case ("text") {
                          <cap-edit-text-cell
                            [text]="item[col.key]"
                            (textChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-text-cell>
                        }
                        @case ("description") {
                          <cap-edit-text-area-cell
                            [description]="item[col.key]"
                            (descriptionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-text-area-cell>
                        }
                        @case ("radio") {
                          <cap-edit-radio-cell
                            [options]="col.options!"
                            [selectedOption]="item[col.key]"
                            [groupName]="col.key"
                            (optionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-radio-cell>
                        }
                        @case ("list") {
                          <cap-edit-list-cell
                            [options]="col.options!"
                            [selectedOption]="item[col.key]"
                            (optionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-list-cell>
                        }
                        @case ("button") {
                          <cap-edit-button-cell
                            (buttonClicked)="onButtonClicked(item._id, col.key)"
                            [label]="col.label"
                            [disabled]="false"
                          ></cap-edit-button-cell>
                        }
                        @case ("status") {
                          <cap-edit-status-cell
                            [options]="col.options!"
                            [groupName]="col.key"
                            [selectedOption]="item[col.key]"
                            (optionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-status-cell>
                        }
                        @case ("checkbox") {
                          <cap-edit-checkbox-cell
                            [isChecked]="item[col.key]"
                            [groupName]="item._id + '-' + col.key"
                            [label]="'Is Positive'"
                            (checkChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-checkbox-cell>
                        }
                        @case ("badge") {
                          <cap-edit-badge-cell
                            [suggestedBadges]="
                              (suggestedStandardGroupings | async)!
                            "
                            [badges]="item[col.key] ?? []"
                            (badgesChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-badge-cell>
                        }
                        @case ("date") {
                          <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                        }
                        @case ("cost") {
                          <cap-edit-status-cell
                            [options]="col.options!"
                            [groupName]="col.key"
                            [selectedOption]="item[col.key]"
                            (optionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-status-cell>
                        }
                        @case ("toggle") {
                          <cap-edit-toggle-button-cell
                            (checkChange)="
                              onToggelClicked($event, item._id, col.key)
                            "
                            [button]="item[col.key]"
                          ></cap-edit-toggle-button-cell>
                        }
                        <!-- Other column types -->
                        @default {
                          <p></p>
                        }
                      }
                    }
                  </div>
                </td>
              }
              <td
                class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 border-r border-gray-300 dark:border-gray-700"
              >
                <!--Action Buttons -->
                @if (editRowId !== item._id) {
                  <button
                    type="button"
                    (click)="onUpdateClicked(item._id)"
                    class="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 mr-4"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    (click)="onDeleteClicked(item)"
                    class="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                  >
                    Remove
                  </button>
                }

                <!-- Edit Mode Actions -->
                @if (editRowId === item._id) {
                  <button
                    type="button"
                    (click)="confirmRowEdit()"
                    class="rounded-full p-1 flex justify-center items-center bg-gray-100 hover:bg-green-500 dark:bg-gray-700 dark:hover:bg-green-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    <span class="material-symbols-outlined"> check </span>
                  </button>
                  <button
                    type="button"
                    (click)="cancelRowEdit()"
                    class="rounded-full p-1 flex justify-center bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  >
                    <span class="material-symbols-outlined"> close </span>
                  </button>
                }
              </td>
            </tr>
            @if (item._id === expandedRowId) {
              <tr>
                <td [attr.colspan]="(columns$ | async)!.length + 2">
                  <cap-edit-accordion-section
                    [metadata]="accordionMetadata"
                    [columns]="accordionColumns"
                    [data]="(accordionData$ | async)!"
                    (buttonClicked)="onAccordionButtonsClicked($event)"
                    (onEntityCreated)="onAccordionEntityCreated.emit($event)"
                    (onEntityUpdated)="onAccordionEntityUpdated.emit($event)"
                    (onEntityDeleted)="onAccordionEntityDeleted.emit($event)"
                    (onAccordionSortChanged)="onAccordionSortChanged.emit($event)"
                  ></cap-edit-accordion-section>
                </td>
              </tr>
            }
          }
        </tbody>
      } @else {
        <tbody class="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900">
          @if ((data$ | async)?.length === 0) {
            <tr>
              <td
                [attr.colspan]="(columns$ | async)!.length + 2"
                class="py-6 text-center text-sm text-gray-500 dark:text-gray-400"
              >
                No {{ metadata.entityName }} found
              </td>
            </tr>
          }
          @for (item of data$ | async; track item.id) {
            <tr
              [attr.data-row-id]="item._id"
              class="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200 ease-in-out"
            >
              @if (metadata.accordion) {
                <td class="w-16 border-r border-gray-300 dark:border-gray-700">
                  <button
                    class="disabled:text-gray-200 dark:text-gray-300 dark:disabled:text-gray-700"
                    [disabled]="editRowId === item._id"
                    type="button"
                    (click)="toggleEmbeddedRows(item._id)"
                  >
                    <span class="material-symbols-outlined">{{
                      expandedRowId === item._id ? "expand_less" : "expand_more"
                    }}</span>
                  </button>
                </td>
              }

              @for (
                col of columns$ | async;
                track col.key;
                let isFirst = $first
              ) {
                <td
                  class="py-4 px-3 text-sm text-gray-500 dark:text-gray-400 border-r border-gray-300 dark:border-gray-700 max-w-xs overflow-hidden"
                  [ngClass]="{ 'sm:pl-0': isFirst }"
                >
                  <div class="truncate">
                    <!-- Read Mode cells -->
                    @if (editRowId !== item._id) {
                      @switch (col.type) {
                        @case ("text") {
                          @if (metadata.title === "Questions") {
                            <cap-text-cell
                              [text]="item[col.key]"
                              [isQuestion]="true"
                            ></cap-text-cell>
                          } @else {
                            <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                          }
                        }
                        @case ("description") {
                          <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                        }
                        @case ("radio") {
                          <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                        }
                        @case ("list") {
                          <cap-text-cell
                            [text]="
                              col.key === 'weight'
                                ? convertWeight(item[col.key])
                                : col.key === 'standardId' ||
                                    col.key === 'sourceId' ||
                                    col.key === 'vendorId'
                                  ? (getEntityRowNameById(col.key, item[col.key])
                                    | async)
                                  : item[col.key]
                            "
                          ></cap-text-cell>
                        }
                        @case ("button") {
                          <cap-edit-button-cell
                            (buttonClicked)="onButtonClicked(item._id, col.key)"
                            [label]="col.label"
                          ></cap-edit-button-cell>
                        }
                        @case ("status") {
                          <cap-status-cell
                            [status]="item[col.key]"
                            [props]="col.options!"
                          ></cap-status-cell>
                        }
                        @case ("checkbox") {
                          <cap-status-cell
                            [status]="item[col.key]"
                            [props]="
                              col.options ?? [
                                { label: 'Positive', value: true },
                                { label: 'Negative', value: false }
                              ]
                            "
                          ></cap-status-cell>
                        }
                        @case ("badge") {
                          <cap-badge-cell
                            [badges]="item[col.key]"
                          ></cap-badge-cell>
                        }
                        @case ("date") {
                          <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                        }
                        @case ("cost") {
                          <cap-cost-cell [value]="item[col.key]"></cap-cost-cell>
                        }
                        @case ("toggle") {
                          <cap-edit-toggle-button-cell
                            (checkChange)="
                              onToggelClicked($event, item._id, col.key)
                            "
                            [button]="item[col.key]"
                          ></cap-edit-toggle-button-cell>
                        }
                        <!-- Other column types -->
                        @default {
                          <p></p>
                        }
                      }
                    }
                    <!-- Edit Mode cells -->
                    @if (editRowId === item._id) {
                      @switch (col.type) {
                        @case ("text") {
                          <cap-edit-text-cell
                            [text]="item[col.key]"
                            (textChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-text-cell>
                        }
                        @case ("description") {
                          <cap-edit-text-area-cell
                            [description]="item[col.key]"
                            (descriptionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-text-area-cell>
                        }
                        @case ("radio") {
                          <cap-edit-radio-cell
                            [options]="col.options!"
                            [selectedOption]="item[col.key]"
                            [groupName]="col.key"
                            (optionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-radio-cell>
                        }
                        @case ("list") {
                          <cap-edit-list-cell
                            [options]="col.options!"
                            [selectedOption]="item[col.key]"
                            (optionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-list-cell>
                        }
                        @case ("button") {
                          <cap-edit-button-cell
                            (buttonClicked)="onButtonClicked(item._id, col.key)"
                            [label]="col.label"
                            [disabled]="false"
                          ></cap-edit-button-cell>
                        }
                        @case ("status") {
                          <cap-edit-status-cell
                            [options]="col.options!"
                            [groupName]="col.key"
                            [selectedOption]="item[col.key]"
                            (optionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-status-cell>
                        }
                        @case ("checkbox") {
                          <cap-edit-checkbox-cell
                            [isChecked]="item[col.key]"
                            [groupName]="item._id + '-' + col.key"
                            [label]="'Is Positive'"
                            (checkChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-checkbox-cell>
                        }
                        @case ("badge") {
                          <cap-edit-badge-cell
                            [suggestedBadges]="
                              (suggestedStandardGroupings | async)!
                            "
                            [badges]="item[col.key] ?? []"
                            (badgesChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-badge-cell>
                        }
                        @case ("date") {
                          <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                        }
                        @case ("cost") {
                          <cap-edit-status-cell
                            [options]="col.options!"
                            [groupName]="col.key"
                            [selectedOption]="item[col.key]"
                            (optionChange)="
                              onCellValueChange($event, item._id, col.key)
                            "
                          ></cap-edit-status-cell>
                        }
                        @case ("toggle") {
                          <cap-edit-toggle-button-cell
                            (checkChange)="
                              onToggelClicked($event, item._id, col.key)
                            "
                            [button]="item[col.key]"
                          ></cap-edit-toggle-button-cell>
                        }
                        <!-- Other column types -->
                        @default {
                          <p></p>
                        }
                      }
                    }
                  </div>
                </td>
              }
              <td
                class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 border-r border-gray-300 dark:border-gray-700"
              >
                <!--Action Buttons -->
                @if (editRowId !== item._id) {
                  <button
                    type="button"
                    (click)="onUpdateClicked(item._id)"
                    class="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 mr-4"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    (click)="onDeleteClicked(item)"
                    class="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                  >
                    Remove
                  </button>
                }

                <!-- Edit Mode Actions -->
                @if (editRowId === item._id) {
                  <button
                    type="button"
                    (click)="confirmRowEdit()"
                    class="rounded-full p-1 flex justify-center items-center bg-gray-100 hover:bg-green-500 dark:bg-gray-700 dark:hover:bg-green-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    <span class="material-symbols-outlined"> check </span>
                  </button>
                  <button
                    type="button"
                    (click)="cancelRowEdit()"
                    class="rounded-full p-1 flex justify-center bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  >
                    <span class="material-symbols-outlined"> close </span>
                  </button>
                }
              </td>
            </tr>
            @if (item._id === expandedRowId) {
              <tr>
                <td [attr.colspan]="(columns$ | async)!.length + 2">
                  <cap-edit-accordion-section
                    [metadata]="accordionMetadata"
                    [columns]="accordionColumns"
                    [data]="(accordionData$ | async)!"
                    (onEntityCreated)="onAccordionEntityCreated.emit($event)"
                    (onEntityUpdated)="onAccordionEntityUpdated.emit($event)"
                    (onEntityDeleted)="onAccordionEntityDeleted.emit($event)"
                    (onAccordionSortChanged)="onAccordionSortChanged.emit($event)"
                  ></cap-edit-accordion-section>
                </td>
              </tr>
            }
          }
        </tbody>
      }
    </table>
  </div>
</div>

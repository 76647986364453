import { createFeature, createReducer, on } from '@ngrx/store';

import { AlertActions } from './alert.actions';
import { IAlert } from 'src/app/core/models/alert.model';

interface State {
  alerts: IAlert[];
  alertCounter: number;
}

const initialState: State = {
  alerts: [],
  alertCounter: 0,
};

export const alertsFeature = createFeature({
  name: 'alerts',
  reducer: createReducer(
    initialState,
    on(AlertActions.addAlert, (state, { alert }) => ({
      ...state,
      alerts: [...state.alerts, { ...alert, id: state.alertCounter + 1 }],
      alertCounter: state.alertCounter + 1,
    })),
    on(AlertActions.removeAlert, (state, { id }) => ({
      ...state,
      alerts: state.alerts.filter((alert) => alert.id !== id),
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectAlertsState, // feature selector
  selectAlerts, // selector for `alerts` property
  selectAlertCounter, // selector for `alertCounter` property
} = alertsFeature;

<div
  class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
>
  <div>
    <h2
      class="text-base font-semibold leading-7 text-gray-800 dark:text-gray-50"
    >
      Change password
    </h2>
    <p class="mt-1 text-sm leading-6 text-gray-400">
      Update your password associated with your account.
    </p>
  </div>

  <form
    class="md:col-span-2"
    [formGroup]="passwordForm"
    (ngSubmit)="onPasswordFormSubmit()"
  >
    @if (errorMessage$ | async) {
      <cap-feedback
        type="error"
        [message]="errorMessage$ | async"
      ></cap-feedback>
    }
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
      <div class="col-span-full">
        <label
          for="current-password"
          class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
          >Current password</label
        >
        <div class="relative mt-2">
          <input
            id="current-password"
            name="current_password"
            formControlName="currentPassword"
            [type]="(isCpVisible$ | async) ? 'text' : 'password'"
            autocomplete="current-password"
            class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
          />
          <!-- Icon to toggle password visibility -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            [title]="(isCpVisible$ | async) ? 'Hide password' : 'Show password'"
            (click)="togglePasswordVisibility('cp')"
          >
            <span class="material-symbols-outlined text-gray-400">
              {{ (isCpVisible$ | async) ? "visibility_off" : "visibility" }}
            </span>
          </button>
        </div>
        <!-- Current Password error message -->
        <!--        <cap-password-control-message-container-->
        <!--          [form]="passwordForm"-->
        <!--          [control]="'currentPassword'"-->
        <!--        ></cap-password-control-message-container>-->
      </div>

      <div class="col-span-full">
        <label
          for="new-password"
          class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
          >New password</label
        >
        <div class="relative mt-2">
          <input
            id="new-password"
            name="new_password"
            formControlName="newPassword"
            [type]="(isNpVisible$ | async) ? 'text' : 'password'"
            autocomplete="new-password"
            class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
          />
          <!-- Icon to toggle password visibility -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            [title]="(isNpVisible$ | async) ? 'Hide password' : 'Show password'"
            (click)="togglePasswordVisibility('np')"
          >
            <span class="material-symbols-outlined text-gray-400">
              {{ (isNpVisible$ | async) ? "visibility_off" : "visibility" }}
            </span>
          </button>
        </div>
        <!-- New Password error message -->
        <cap-password-control-message-container
          [form]="passwordForm"
          [control]="'newPassword'"
        ></cap-password-control-message-container>
      </div>

      <div class="col-span-full">
        <label
          for="confirm-new-password"
          class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
          >Confirm password</label
        >
        <div class="relative mt-2">
          <input
            id="confirm-new-password"
            name="confirm_new_password"
            formControlName="confirmNewPassword"
            [type]="(isCnpVisible$ | async) ? 'text' : 'password'"
            autocomplete="confirm-new-password"
            class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
          />
          <!-- Icon to toggle password visibility -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            [title]="
              (isCnpVisible$ | async) ? 'Hide password' : 'Show password'
            "
            (click)="togglePasswordVisibility('cnp')"
          >
            <span class="material-symbols-outlined text-gray-400">
              {{ (isCnpVisible$ | async) ? "visibility_off" : "visibility" }}
            </span>
          </button>
        </div>
        <!-- Confirm Password error message -->
        <cap-password-control-message-container
          [form]="passwordForm"
          [control]="'confirmNewPassword'"
        ></cap-password-control-message-container>
      </div>
    </div>

    <div class="mt-8 flex">
      <button
        type="submit"
        [disabled]="!passwordForm.valid"
        class="flex justify-center items-center rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 dark:disabled:bg-primary-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        @if (loading$ | async) {
          <svg
            aria-hidden="true"
            role="status"
            class="inline w-4 h-4 mr-3 text-white animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
        }
        Change Password
      </button>
    </div>
  </form>
</div>

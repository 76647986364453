import { createActionGroup, props } from '@ngrx/store';

export const ModalActions = createActionGroup({
  source: 'Modal',
  events: {
    Register: props<{ id: string }>(),
    Unregister: props<{ id: string }>(),
    Open: props<{ id: string }>(),
    Close: props<{ id: string }>(),
  },
});

import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  inject,
  Inject,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { trigger, style, transition, animate } from '@angular/animations';

import { ThemeStore, ThemeMode } from './theme.store';

@Component({
  selector: 'cap-theme',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="relative inline-block text-left" #dropdownContainer>
      <div>
        <button
          (click)="toggleDropdown()"
          type="button"
          class="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          @switch (themeMode$ | async) {
            @case ('system') {
              <span class="material-symbols-outlined"> routine </span>
            }
            @case ('dark') {
              <span class="material-symbols-outlined"> dark_mode </span>
            }
            @case ('light') {
              <span class="material-symbols-outlined"> light_mode </span>
            }
            @default {
              <span class="material-symbols-outlined"> routine </span>
            }
          }
        </button>
      </div>
      @if ((isDropDownOpen$ | async) === true) {
        <div
          [@dropdownAnimation]="
            (isDropDownOpen$ | async) === true ? 'enter' : 'leave'
          "
          class="absolute -right-4 z-10 mt-2 w-fit rounded-md bg-white dark:bg-gray-800 shadow-lg"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <button
            (click)="setTheme('system')"
            class="w-full text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 group flex items-center flex-col px-4 py-2 font-medium text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
          >
            <span class="material-symbols-outlined"> routine </span>
            <p>System</p>
          </button>

          <button
            (click)="setTheme('dark')"
            class="w-full text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 group flex items-center flex-col px-4 py-2 font-medium text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
          >
            <span class="material-symbols-outlined"> dark_mode </span>
            <p>Dark</p>
          </button>

          <button
            (click)="setTheme('light')"
            class="w-full text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 group flex items-center flex-col px-4 py-2 font-medium text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-2"
          >
            <span class="material-symbols-outlined"> light_mode </span>
            <p>Light</p>
          </button>
        </div>
      }
    </div>
  `,
  styles: ``,
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(0.9)' })),
      ]),
    ]),
  ],
})
export class ThemeComponent {
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  private readonly themeStore = inject(ThemeStore);

  isDropDownOpen$ = this.themeStore.isDropdownOpen$;
  themeMode$ = this.themeStore.themeMode$;
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setTheme(themeMode: ThemeMode) {
    this.themeStore.updateThemeMode(themeMode);
  }

  toggleDropdown() {
    this.themeStore.toggleDropdown();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.dropdownContainer.nativeElement.contains(event.target)) {
      this.themeStore.closeDropdown();
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil, tap } from 'rxjs';
import { IService } from 'src/app/core/models/services.model';

import {
  ActionItemsAPIActions,
  ActionItemsAppActions,
} from 'src/app/state/action-items/action-items.actions';
import {
  ServicesAPIActions,
  ServicesAppActions,
} from 'src/app/state/services/services.actions';

@Injectable()
export class ActionItemServicesModalStore
  extends ComponentStore<{
    isLoading: boolean;
    errorMessage: string | null;
    services: IService[];
  }>
  implements OnDestroy
{
  subDestroy$ = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({ isLoading: false, errorMessage: null, services: [] });
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly services$ = this.select((state) => state.services);

  readonly setServices = this.updater((state, services: IService[]) => ({
    ...state,
    services,
  }));

  private readonly appActionSub = this.actions$
    .pipe(
      ofType(
        ActionItemsAppActions.updateActionItemServices,
        ServicesAppActions.getServices,
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private readonly apiSuccessActionSub = this.actions$
    .pipe(
      ofType(
        ActionItemsAPIActions.updateActionItemServicesSuccess,
        ServicesAPIActions.getServicesSuccess,
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private readonly apiFailureActionSub = this.actions$
    .pipe(
      ofType(
        ActionItemsAPIActions.updateActionItemServicesFailure,
        ServicesAPIActions.getServicesFailure,
      ),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message }),
      ),
      takeUntil(this.destroy$),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

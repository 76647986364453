import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadingZeros',
  standalone: true,
})
export class LeadingZerosPipe implements PipeTransform {
  transform(value: number, length: number): string {
    let numberAsString = value.toString();
    while (numberAsString.length < length) {
      numberAsString = '0' + numberAsString;
    }
    return numberAsString;
  }
}

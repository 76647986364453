import { CommonModule, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cap-toggle-button',
  standalone: true,
  imports: [CommonModule, NgClass],
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss'
})
export class ToggleButtonComponent {
  @Input() data: any = {};
  @Input() button: boolean = false;
  @Output() checkChange = new EventEmitter();

  onButtonClick() {
    this.button = !this.button;
    this.checkChange.emit(this.button);
  }
}

<button
  type="button"
  class="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
  (click)="onClick($event)"
  [disabled]="disabled"
>
  @if (label === "Options") {
    <span class="material-symbols-outlined"> settings </span>
  } @else if (label === "Services") {
    <span class="material-symbols-outlined"> service_toolbox </span>
  } @else if (label === "Controls") {
    <span class="material-symbols-outlined"> control_point_duplicate </span>
  } @else if (label === "Documents") {
    <span class="material-symbols-outlined"> tv_options_edit_channels </span>
  } @else {
    {{ label }}
    <span class="material-symbols-outlined"> tv_options_edit_channels </span>
  }
</button>

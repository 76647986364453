import { Component, inject, Input, OnInit } from '@angular/core';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { DashboardPageHeaderComponent } from '../dashboard-page/dashboard-page-header/dashboard-page-header.component';
import { ReportPageAssessmentSectionComponent } from './report-page-assessment-section/report-page-assessment-section.component';
import { Store } from '@ngrx/store';
import { SharedAssessmentHeaderComponent } from '../shared/shared-assessment-header/shared-assessment-header.component';
import { SharedAssessmentScrollerComponent } from '../shared/shared-assessment-scroller/shared-assessment-scroller.component';

@Component({
  selector: 'cap-reports-page',
  standalone: true,
  imports: [
    SidebarComponent,
    DashboardPageHeaderComponent,
    ReportPageAssessmentSectionComponent,
    SharedAssessmentHeaderComponent,
    SharedAssessmentScrollerComponent,
  ],
  templateUrl: './reports-page.component.html',
  styleUrl: './reports-page.component.scss',
})
export class ReportsPageComponent implements OnInit {
  private store = inject(Store);
  constructor() {}

  ngOnInit(): void {}
}

<cap-modal-container
  [modalWidthClass]="userConfig.modalWidth"
  [modalID]="userConfig.modalID"
>
  <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
    {{ userConfig.title }}
  </p>
  <div class="w-full space-y-3 mt-2">
    @if (errorMessage$ | async) {
      <cap-feedback type="error" [message]="errorMessage$ | async"></cap-feedback>
    } @if(loading$ | async) {
    <div
      class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
    >
      <cap-spinner class="z-50" [size]="16"></cap-spinner>
    </div>
    }

    <cap-user-profile-form
      [formInput]="userConfig"
      (userProfileFormChanges)="handleProfileChange($event)"
    ></cap-user-profile-form>

    <div class="flex justify-between flex-row-reverse">
      <button
        [disabled]="!(userProfileFormValidity$ | async)"
        type="button"
        (click)="submitUserForm()"
        class="rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        {{ userConfig.title }}
      </button>

      <button
        type="button"
        (click)="closeModal()"
        class="rounded-md bg-gray-100 hover:bg-gray-50 dark:bg-white/10 dark:hover:bg-white/20 px-3 py-2 text-sm font-semibold text-gray-800 dark:text-gray-50 shadow-sm"
      >
        Cancel
      </button>
    </div>
  </div>
</cap-modal-container>

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, firstValueFrom, Observable, tap, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private http = inject(HttpClient);
  private stripe: Promise<Stripe>;
  private paymentIntentId: string | null = null;
  private subscriptionId: string | null = null;
  private subscriptionStatus: string | null = null;
  private subscriptionStartTime: Date | null = null;
  private subscriptionEndTime: Date | null = null;
  
  constructor() {
    this.stripe = loadStripe(environment.stripePublicKey) as Promise<Stripe>;
  }

  async getStripe() {
    return await this.stripe;
  }

  createPaymentIntent(
    featureType: string, 
    discountCode?: string, 
    quantity?: number,
    customerEmail?: string,
    customerDetails?: {
      name: string;
      phone: string;
      country: string;
      description: string;
    }
  ): Observable<{ 
    clientSecret: string | null;
    subscriptionId?: string;
    customerId?: string;
    originalPrice: number;
    finalPrice: number;
    discount: number;
    subscriptionStatus?: string;
    subscriptionStartTime?: Date;
    subscriptionEndTime?: Date;
    fullyDiscounted?: boolean;
  }> {
    return this.http.post<any>(
      `${environment.apiUrl}/payment/create-payment-session`,
      { featureType, discountCode, quantity, customerEmail, customerDetails }
    ).pipe(
      tap(response => {
        if (!response.fullyDiscounted) {
          this.paymentIntentId = response.clientSecret?.split('_secret')[0] || null;
          this.subscriptionId = response.subscriptionId || null;
          this.subscriptionStatus = response.subscriptionStatus || null;
          this.subscriptionStartTime = response.subscriptionStartTime || null;
          this.subscriptionEndTime = response.subscriptionEndTime || null;
        }
      })
    );
  }

  getPaymentIntentId(): string | null {
    return this.paymentIntentId;
  }

  getSubscriptionId(): string | null {
    return this.subscriptionId;
  }

  getPaymentStatus(paymentIntentId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/payment/${paymentIntentId}/status`
    ).pipe(
      catchError(error => {
        console.error('Payment status check failed:', error);
        throw new Error(error.error?.message || 'Failed to check payment status');
      })
    );
  }

  getSubscriptionStatus(subscriptionId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/payment/subscription/${subscriptionId}/status`
    ).pipe(
      catchError(error => {
        console.error('Subscription status check failed:', error);
        throw new Error(error.error?.message || 'Failed to check subscription status');
      })
    );
  }

  cancelSubscription(subscriptionId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/payment/subscription/${subscriptionId}/cancel`,
      {}
    ).pipe(
      catchError(error => {
        console.error('Subscription cancellation failed:', error);
        throw new Error(error.error?.message || 'Failed to cancel subscription');
      })
    );
  }

  clearPaymentIntentId(): void {
    this.paymentIntentId = null;
  }

  clearSubscriptionId(): void {
    this.subscriptionId = null;
  }

  disableUserSubscriptionRenewal(userId: string): Observable<{
    success: boolean;
    userId: string;
    status: string;
    newQuantity: number;
  }> {
    return this.http.post<any>(
      `${environment.apiUrl}/payment/users/${userId}/disable-renewal`,
      {}
    );
  }

  enableUserSubscriptionRenewal(userId: string, subscriptionId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/payment/users/${userId}/enable-renewal`,
      { subscriptionId }
    );
  }

  disableSubscriptionRenewal(organizationId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/payment/organizations/${organizationId}/disable-renewal`,
      {}
    );
  }

  enableSubscriptionRenewal(organizationId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/payment/organizations/${organizationId}/enable-renewal`,
      {}
    );
  }
}

import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IAlert } from 'src/app/core/models/alert.model';
import { AlertComponent } from '../alert/alert.component';
import { AlertActions } from 'src/app/state/alert/alert.actions';
import { selectAlerts } from 'src/app/state/alert/alert.state';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cap-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.scss'],
  standalone: true,
  imports: [CommonModule, AlertComponent],
})
export class AlertContainerComponent {
  private readonly store = inject(Store);
  alerts$: Observable<IAlert[]> = this.store.select(selectAlerts);

  onDismiss(id: number): void {
    this.store.dispatch(AlertActions.removeAlert({ id }));
  }
}

import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { IFeedback } from "src/app/core/models/feedback.model";
import { Pagination } from "../categories/categories.state";

export const FeedbackAppActions = createActionGroup({
    source: 'Feedback App',
    events: {
        submitFeedback: props<{ feedback: {title: string, description: string} }>(),
        getFeedbacks: props<{ page: number, pageSize: number }>(),
        resolveFeedback: props<{ id: string }>(),
        deleteFeedbacks: props<{ ids: string[] }>(),
    }
});

export const FeedbackApiActions = createActionGroup({
    source: 'Feedback API',
    events: {
        submitFeedbackSuccess: props<{ feedback: IFeedback }>(),
        submitFeedbackFailure: props<{ message: string }>(),
        getFeedbacksSuccess: props<{ feedbacks: IFeedback[], pagination: Pagination }>(),
        getFeedbacksFailure: props<{ message: string }>(),
        resolveFeedbackSuccess: props<{ feedback: IFeedback }>(),
        resolveFeedbackFailure: props<{ message: string }>(),
        deleteFeedbacksSuccess: props<{ ids: string[] }>(),
        deleteFeedbacksFailure: props<{ message: string }>(),
    }
});
import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  inject,
  Output,
  EventEmitter,
  ViewChildren,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subscription, tap } from 'rxjs';
import { TwoFactorAuthVerificationStore } from './two-factor-auth-verificaiton.store';
import { Store } from '@ngrx/store';
import { selectModalVisibility } from '../../state/modal/modal.state';

@Component({
  selector: 'cap-two-factor-auth-verificaiton',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  providers: [TwoFactorAuthVerificationStore],
  templateUrl: './two-factor-auth-verificaiton.component.html',
  styleUrls: ['./two-factor-auth-verificaiton.component.scss'],
})
export class TwoFactorAuthVerificaitonComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private store = inject(Store);
  private cdf = inject(ChangeDetectorRef);
  @ViewChildren('digitInput') digitInputs: any;
  @Output() sendTotpToken = new EventEmitter<string>();

  otpForm!: FormGroup;
  digitControls: FormControl[] = [];
  subs: Subscription[] = [];
  firstElementFocused = false;

  private readonly componentStore = inject(TwoFactorAuthVerificationStore);
  error$ = this.componentStore.error$;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
    this.subscribeToFormChanges();
    const sub = this.error$
      .pipe(
        tap((error) => {
          if (error) this.otpForm.reset();
        }),
      )
      .subscribe();

    this.subs.push(sub);
  }

  ngAfterViewInit() {
    const modalSub = this.store
      .select(selectModalVisibility('validate-otp'))
      .subscribe((isVisible) => {
        if (isVisible) {
          setTimeout(() => {
            this.digitInputs.first.nativeElement.focus();
            this.cdf.detectChanges();
          }, 0);
        }
      });

    this.subs.push(modalSub);
  }

  private initializeForm() {
    // Initialize 6 FormControl instances for each digit
    this.digitControls = Array.from(
      { length: 6 },
      () =>
        new FormControl('', [Validators.required, Validators.pattern(/^\d$/)]),
    );

    this.otpForm = this.fb.group({
      digits: this.fb.array(this.digitControls),
    });
  }

  private subscribeToFormChanges() {
    const formSub = this.otpForm.valueChanges.subscribe((value) => {
      const token = value.digits.join('');
      const isFormValid = this.otpForm.valid;

      if (isFormValid) {
        this.emitValidationRequest(token);
      }
    });
    this.subs.push(formSub);
  }

  private emitValidationRequest(token: string) {
    this.sendTotpToken.emit(token);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  focusNext(previousIndex: number, event: any) {
    let nextInput: HTMLInputElement | null = null;
    if (event.code === 'Backspace' && !event.target.value) {
      nextInput = document.querySelector(`#digit-${previousIndex - 1}`);
    } else if (event.target.value) {
      nextInput = document.querySelector(`#digit-${previousIndex + 1}`);
    }
    nextInput?.focus();
  }

  handlePaste(event: ClipboardEvent) {
    const pastedValue = event.clipboardData?.getData('text');
    if (pastedValue) {
      const digitsArray = pastedValue.split('').slice(0, 6);
      digitsArray.forEach((char, index) => {
        if (this.digitControls[index]) {
          this.digitControls[index].setValue(char);
        }
      });
    }
    event.preventDefault();
  }
}

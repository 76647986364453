import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleName',
  standalone: true,
})
export class RoleNamePipe implements PipeTransform {
  private rolesMap = new Map<string, string>([
    ['SA', 'Super Admin'],
    ['SSA', 'System Admin'],
    ['OA', 'Organization Admin'],
    ['OM', 'Organization Manager'],
    ['I', 'Individual'],
    ['OS', 'Organization Staff'],
  ]);
  transform(value: string): string {
    return this.rolesMap.get(value) || 'Unknown';
  }
}

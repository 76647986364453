<cap-modal-container
  [modalWidthClass]="modalMetadata.modalWidth"
  [modalID]="modalMetadata.modalID"
>
  <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
    {{ modalMetadata.title }}
  </p>

  <main class="mx-auto max-w-screen-lg h-full">
    <!-- file upload modal -->
    <article
      aria-label="File Upload Modal"
      class="relative h-full flex flex-col"
      (drop)="dropHandler($event)"
      (dragover)="dragOverHandler($event)"
      (dragleave)="dragLeaveHandler($event)"
      (dragenter)="dragEnterHandler($event)"
    >
      <!-- overlay -->
      <div
        id="overlay"
        class="w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md"
      >
        <i>
          <svg
            class="fill-current w-12 h-12 mb-3 text-primary-700"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z"
            />
          </svg>
        </i>
        <p class="text-lg text-primary-700">Drop files to upload</p>
      </div>

      <!-- scroll area -->
      <section class="h-full overflow-auto p-8 w-full flex flex-col">
        <header
          class="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center"
        >
          <p
            class="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center"
          >
            <span>Drag and drop your</span>&nbsp;<span>files anywhere or</span>
          </p>
          <input
            #hiddenInput
            id="hidden-input"
            type="file"
            multiple
            class="hidden"
            (change)="handleFileInput($event)"
          />
          <button
            id="button"
            class="mt-2 rounded-lg px-3 py-1 font-semibold bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
            (click)="nativeClick()"
          >
            Upload a file
          </button>
        </header>

        <h1 class="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
          To Upload
        </h1>

        <ul id="gallery" class="flex flex-1 flex-wrap -m-1">
          <li
            id="empty"
            class="h-full w-full text-center flex flex-col justify-center items-center"
          >
            <img
              class="mx-auto w-32"
              src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
              alt="no data"
            />
            <span class="text-small text-gray-500">No files selected</span>
          </li>
          <!-- Render files dynamically -->
          <li
            *ngFor="let file of files; let i = index"
            [id]="i"
            class="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24"
          >
            
              <article
                tabindex="0"
                class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline relative bg-gray-100 cursor-pointer shadow-sm"
              >
                <img
                  *ngIf="(file.src || file.url) && file.type.match('image/*')"
                  [src]="file.src ?? file.url"
                  alt="upload preview"
                  class="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
                />
                <section
                  class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3"
                >
                <a target="_blank" [href]="file.url" class="w-full h-full">
                  <h1 class="flex-1 group-hover:text-primary-800">
                    {{ file.name }}
                  </h1>
                  </a>
                  <div class="flex">
                    <span class="p-1 text-primary-800">
                      <i>
                        <svg
                          class="fill-current w-4 h-4 ml-auto pt-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z"
                          />
                        </svg>
                      </i>
                    </span>
                    <p class="p-1 size text-xs text-gray-700">
                      {{ file.size }}
                    </p>
                    <button
                      class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800"
                      (click)="callConfirmationModal(file._id ? file._id : i)"
                    >
                      <svg
                        class="pointer-events-none fill-current w-4 h-4 ml-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          class="pointer-events-none"
                          d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                        />
                      </svg>
                    </button>
                  </div>
                </section>
              </article>
          </li>
        </ul>
      </section>

      <!-- sticky footer -->
      <footer class="flex justify-end gap-x-5 px-8 pb-8 pt-4">
        <button
          id="submit"
          class="rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          (click)="submitFiles()"
        >
          Upload now
        </button>
        <button
          id="cancel"
          class="rounded-md bg-gray-100 hover:bg-gray-50 dark:bg-white/10 dark:hover:bg-white/20 px-3 py-2 text-sm font-semibold text-gray-800 dark:text-gray-50 shadow-sm"
          (click)="cancelUpload()"
        >
          Cancel
        </button>
      </footer>
    </article>
  </main>
</cap-modal-container>

<cap-confirm-modal
  [modalID]="'confirm-removing-document'"
  [data]="confirmModalData"
  (confirmEvent)="deleteFile()"
></cap-confirm-modal>

<div>
  <div class="relative flex flex-wrap gap-2">
    <span
      *ngFor="let badge of badges"
      class="inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
    >
      {{ badge }}
      <button
        type="button"
        (click)="removeBadge(badge)"
        class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-indigo-600/20"
      >
        <span class="sr-only">Remove</span>
        <svg
          viewBox="0 0 14 14"
          class="h-3.5 w-3.5 stroke-indigo-600/50 group-hover:stroke-indigo-600/75"
        >
          <path d="M4 4l6 6m0-6l-6 6"></path>
        </svg>
      </button>
    </span>
    <div class="relative">
      <input
        #badgeInput
        [formControl]="badgeInputControl"
        type="text"
        (input)="filterBadges($event)"
        (keydown)="handleInputKeydown($event)"
        class="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-10 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
        role="combobox"
        aria-expanded="false"
        autocomplete="off"
      />
      @if (isSuggesteGroupingListOpen()) {
        <ul
          #suggestGroupingList
          class="absolute z-10 top-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black dark:ring-white/5 ring-opacity-5 focus:outline-none sm:text-sm"
        >
          @for (badge of filteredBadges; track $index) {
            <li
              (click)="checkAddBadge(badge)"
              class="cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-gray-100 dark:text-gray-50 dark:hover:bg-gray-700"
            >
              <span class="block truncate">{{ badge }}</span>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</div>

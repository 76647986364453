import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StepNavigationCommunicationService } from 'src/app/core/services/step-navigation-communication.service';

@Component({
  selector: 'cap-agreement',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './agreement.component.html',
  styleUrl: './agreement.component.scss',
})
export class AgreementComponent {
  isChecked = false;

  private stepCommunicationService = inject(StepNavigationCommunicationService);

  onCheckChange() {
    this.stepCommunicationService.emitIsEulaData({ isEula: this.isChecked });
  }
}

<cap-modal-container
  [modalWidthClass]="modalMetadata.modalWidth"
  [modalID]="modalMetadata.modalID"
>
  <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
    {{ modalMetadata.title }}
  </p>

  <main class="mx-auto max-w-screen-lg h-full">
    <!-- FORM ERROR HANDLER END-->
    @if (errorMessage$ | async) {
      <cap-feedback
        type="error"
        [message]="errorMessage$ | async"
      ></cap-feedback>
    }

    @if (loading$ | async) {
      <div
        class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
      >
        <cap-spinner class="z-50" [size]="16"></cap-spinner>
      </div>
      <div class="h-56"></div>
    } @else {
      <article>
        <!-- scroll area -->
        <section class="h-full overflow-auto p-8 w-full flex flex-col">
          @if (files.length === 0) {
            <h1 class="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
              No Documents available yet
            </h1>
          } @else {
            <ul id="gallery" class="flex flex-1 flex-wrap -m-1">
              <!-- Render files dynamically -->
              <li
                *ngFor="let file of files; let i = index"
                [id]="i"
                class="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24"
              >
                <a [href]="file.url" target="_blank">
                  <article
                    tabindex="0"
                    class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline relative bg-gray-100 cursor-pointer shadow-sm"
                  >
                    <img
                      *ngIf="file.url && file.type.match('image/*')"
                      [src]="file.src ?? file.url"
                      alt="download preview"
                      class="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
                    />

                    <section
                      class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3"
                    >
                      <h1 class="flex-1 group-hover:text-primary-800">
                        {{ file.name }}
                      </h1>
                      <div class="flex">
                        <span class="p-1 text-primary-800">
                          <i>
                            <svg
                              class="fill-current w-4 h-4 ml-auto pt-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z"
                              />
                            </svg>
                          </i>
                        </span>
                        <p class="p-1 size text-xs text-gray-700">
                          {{ file.size }}
                        </p>
                      </div>
                    </section>
                  </article>
                </a>
              </li>
            </ul>
          }
        </section>

        <!-- sticky footer -->
        <footer class="flex justify-end gap-x-5 px-8 pb-8 pt-4">
          <button
            id="close"
            class="rounded-md bg-gray-100 hover:bg-gray-50 dark:bg-white/10 dark:hover:bg-white/20 px-3 py-2 text-sm font-semibold text-gray-800 dark:text-gray-50 shadow-sm"
            (click)="closeModal()"
          >
            Close
          </button>
        </footer>
      </article>
    }
  </main>
</cap-modal-container>

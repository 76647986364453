import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateCellStore } from './date-cell.store';


@Component({
  selector: 'cap-date-cell',
  standalone: true,
  imports: [CommonModule],
  providers: [DateCellStore],
  templateUrl: './date-cell.component.html',
  styleUrl: './date-cell.component.scss',
})
export class DateCellComponent {
  private readonly componentStore = inject(DateCellStore);

  @Input() set date(value: Date) {
    this.componentStore.setDate(value);
  }

  readonly date$ = this.componentStore.date$;
}

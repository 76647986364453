<div class="card">
  <p-pickList
    [source]="sourceItems"
    [target]="targetItems"
    sourceHeader="Available"
    targetHeader="Selected"
    [dragdrop]="true"
    [responsive]="true"
    [sourceStyle]="{ height: '15rem' }"
    [targetStyle]="{ height: '15rem' }"
    filterBy="name"
    sourceFilterPlaceholder="Search by name"
    targetFilterPlaceholder="Search by name"
    breakpoint="700px"
    (onMoveToTarget)="onTransfer($event)"
    (onMoveToSource)="onTransfer($event)"
  >
    <ng-template let-item pTemplate="item">
      <div class="flex flex-wrap p-2 align-items-center gap-3">
        <div class="flex-1 flex flex-column gap-2">
          <span class="font-bold">
            {{ item.name }}
          </span>
        </div>
      </div>
    </ng-template>
  </p-pickList>
</div>

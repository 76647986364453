import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IColumn } from 'src/app/core/models/column.model';

interface TableState {
  columns: IColumn[];
  data: any[];
  totalItems: number;
}

@Injectable()
export class TableStore extends ComponentStore<TableState> {
  constructor() {
    super({
      columns: [],
      data: [],
      totalItems: 0,
    });
  }

  readonly columns$ = this.select((state) => state.columns);
  readonly data$ = this.select((state) => state.data);
  readonly totalItems$ = this.select((state) => state.totalItems);

  readonly setColumns = this.updater((state, columns: IColumn[]) => ({
    ...state,
    columns,
  }));

  readonly setData$ = this.updater((state, data: any[]) => ({
    ...state,
    data,
  }));

  readonly setTotalItems$ = this.updater((state, totalItems: number) => ({
    ...state,
    totalItems,
  }));
}

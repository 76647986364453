import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IRegister } from '../models/register.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IUser, IUserBase } from '../models/user.model';
import { Pagination } from 'src/app/state/users/users.state';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  registerNewUser(credentials: IUserBase): Observable<{ user: IUser }> {
    return this.http.post<{ user: IUser }>(
      `${environment.apiUrl}/users`,
      credentials,
    );
  }

  getAllUsers(
    page: number,
    limit: number,
  ): Observable<{ users: IUser[]; pagination: Pagination }> {
    return this.http.get<{ users: IUser[]; pagination: Pagination }>(
      `${environment.apiUrl}/users?page=${page}&limit=${limit}`,
    );
  }
  getUsersAccountsNoPagination(): Observable<{ users: IUser[] }> {
    return this.http.get<{ users: IUser[] }>(
      `${environment.apiUrl}/users?all=true`,
    );
  }
  searchUsers(
    search: string,
    page: string,
    limit: string,
  ): Observable<{ users: IUser[]; pagination: Pagination }> {
    return this.http.get<{ users: IUser[]; pagination: Pagination }>(
      `${environment.apiUrl}/users/search?search=${search}&page=${page}&limit=${limit}`,
    );
  }

  getUsersByOgranizationId(orgId: string): Observable<{ users: IUser[] }> {
    return this.http.get<{ users: IUser[] }>(
      `${environment.apiUrl}/users/${orgId}`,
    );
  }

  updateUser(user: IUser): Observable<{ user: IUser }> {
    const {
      avatars,
      isActive,
      otpEnabled,
      optVerified,
      otpAscii,
      otpHex,
      otpBase32,
      otpAuthUrl,
      createdAt,
      __v,
      updatedAt,
      ...userUpdateObject
    } = user;

    return this.http.patch<{ user: IUser }>(
      `${environment.apiUrl}/users/${user._id}`,
      userUpdateObject,
    );
  }

  updateUserOrganization(obj: { userId: string; orgId: string }) {
    return this.http.patch<{ user: IUser }>(
      `${environment.apiUrl}/users/${obj.userId}/organization`,
      {
        organizationId: obj.orgId,
      },
    );
  }

  removeUser(id: string): Observable<{ userId: string; message: string }> {
    return this.http.delete<{ userId: string; message: string }>(
      `${environment.apiUrl}/users/${id}`,
    );
  }

  updateUserStatus(obj: { userId: string; isActive: boolean }) {
    return this.http.patch<{ user: IUser }>(
      `${environment.apiUrl}/users/${obj.userId}/status`,
      {
        isActive: obj.isActive,
      },
    );
  }
}

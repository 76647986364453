import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeedbackAppActions } from 'src/app/state/feedback/feedback.actions';
import { selectAllFeedbacks, selectFeedbackPagination } from 'src/app/state/feedback/feedback.selectors';
import { PaginationComponent } from "../../../shared/pagination/pagination.component";
import { AsyncPipe, DatePipe } from '@angular/common';

import { PanelModule } from 'primeng/panel';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { CardModule } from 'primeng/card';
import { IUserBase } from 'src/app/core/models/user.model';
import { IFeedback } from 'src/app/core/models/feedback.model';
import { take } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-feedback-page',
  standalone: true,
  imports: [PaginationComponent, AsyncPipe, PanelModule, AvatarModule, ButtonModule, MenuModule,DatePipe, CardModule ,FormsModule],
  templateUrl: './feedback-page.component.html',
  styleUrl: './feedback-page.component.scss'
})
export class FeedbackPageComponent implements OnInit{
  private store = inject(Store);
  feedbacks$ = this.store.select(selectAllFeedbacks);
  pagination$ = this.store.select(selectFeedbackPagination);
  selectedIds = new Set<string>();
  page = 1;
  pageSize = 10;
  totalPages = 1;
  totalCount = 1;
  activeMenuId: string | null = null;
  selectAllCheckbox = false;

  // Add this new property to store menu items
  menuItemsMap = new Map<string, any[]>();

  ngOnInit(): void {
    this.store.dispatch(FeedbackAppActions.getFeedbacks({page: 1, pageSize: 10}));
    this.pagination$.subscribe((pagination) => {
      console.log('pagination', pagination);
      this.page = pagination.page;
      this.pageSize = pagination.limit;
      this.totalPages = pagination.totalPages;
      this.totalCount = pagination.totalCount;
    });

    // Pre-populate menu items when feedbacks change
    this.feedbacks$.subscribe(feedbacks => {
      feedbacks.forEach(feedback => {
        this.menuItemsMap.set(feedback._id, [
          {
            label: feedback.isResolved ? 'Mark as Unresolved' : 'Mark as Resolved',
            icon: 'check',
            command: () => this.resolveFeedback(feedback._id)
          },
          {
            label: 'Delete',
            icon: 'delete',
            command: () => this.deleteFeedbacks([feedback._id])
          }
        ]);
      });
    });
  }

  getUserAvatar(user: IUserBase) {
    return user.avatars[user.avatars.length - 1]?.url ?? 'assets/default-avatar.png';
  }

  // Update the getMenuItems method to use the map
  getMenuItems(feedback: IFeedback) {
    return this.menuItemsMap.get(feedback._id) || [];
  }

  resolveFeedback(id: string) {
    this.store.dispatch(FeedbackAppActions.resolveFeedback({id}));
    this.toggleMenu(id);
  }

  onPageChange(event: any) {
    this.page = event;
    this.store.dispatch(FeedbackAppActions.getFeedbacks({page: this.page, pageSize: this.pageSize}));
  }

  toggleMenu(feedbackId: string) {
    this.activeMenuId = this.activeMenuId === feedbackId ? null : feedbackId;
  }

  deleteFeedbacks(ids: string[]) {
    this.store.dispatch(FeedbackAppActions.deleteFeedbacks({ids}));
  }

  handleMenuAction(command: Function) {
    console.log('command', command);
    if (command) {
      command();
    }
    this.activeMenuId = null;
  }

  isAllSelected() {
    return this.feedbacks$.subscribe(feedbacks => {
      return feedbacks.every(feedback => this.selectedIds.has(feedback._id));
    });
  }

  toggleSelectAll() {
    this.feedbacks$.pipe(take(1)).subscribe((feedbacks: IFeedback[]) => {
      if (this.selectedIds.size === feedbacks.length) {
        // If all are selected, clear the selection
        this.selectedIds.clear();
        this.selectAllCheckbox = false;
      } else {
        // Otherwise select all
        this.selectedIds = new Set(feedbacks.map(feedback => feedback._id));
        this.selectAllCheckbox = true;
      }
    });
  }

  deleteSelectedFeedbacks() {
    this.store.dispatch(FeedbackAppActions.deleteFeedbacks({ids: Array.from(this.selectedIds)}));
    this.selectedIds.clear();
  }

  toggleSelection(id: string) {
    this.selectedIds.has(id) ? this.selectedIds.delete(id) : this.selectedIds.add(id);
  }
}

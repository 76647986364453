import { ICategory, ICategoryTable } from '../core/models/categories.model';
import {
  IOrganization,
  IOrganizationTable,
} from '../core/models/organization.model';
import {
  IAddress,
  IPhoneNumber,
  IUser,
  IUserTable,
} from '../core/models/user.model';
import { IProfile, IProfileTable } from '../core/models/profiles.model';

function formatPrimaryAddress(addresses: IAddress[]): string {
  const primaryAddress = addresses.find((address) => address.isPrimary);
  return primaryAddress
    ? `${primaryAddress.street1}, ${primaryAddress.city}, ${primaryAddress.state}, ${primaryAddress.country}, ${primaryAddress.zip}`
    : 'No primary address';
}

function formatPrimaryPhoneNumber(phoneNumbers: IPhoneNumber[]): string {
  const primaryPhone = phoneNumbers.find((phone) => phone.isPrimary);
  return primaryPhone
    ? `(+${primaryPhone.phoneCode}) ${primaryPhone.number}`
    : 'No primary phone';
}

export const convertUserRoleName = (role: string) => {
  const rolesMap = new Map<string, string>([
    ['SA', 'Super Admin'],
    ['SSA', 'System Admin'],
    ['OA', 'Organization Admin'],
    ['OM', 'Organization Manager'],
    ['OS', 'Organization Staff'],
    ['I', 'Individual'],
  ]);

  return rolesMap.get(role) || 'Unknown';
};

export const convertWeight = (weight: number) => {
  const weightMap = new Map<number, string>([
    [0, 'None'],
    [1, 'Low'],
    [2, 'Medium-Low'],
    [3, 'Medium'],
    [4, 'Medium-High'],
    [5, 'High'],
  ]);

  return weightMap.get(weight) || 'Unkown';
};

export function convertUserToUserTable(
  user: IUser,
  profiles: IProfile[],
): IUserTable {
  const profile = profiles.find((profile) => profile._id === user.profile);
  const userProfile = profile ? profile.name : 'No Profile';
  return {
    _id: user._id,
    info: {
      fullName: `${user.firstName} ${user.lastName}`,
      email: user.email,
    },
    role: convertUserRoleName(user.role.name),
    organization: user.organization,
    avatar: user.avatars.find((avatar) => avatar.isCurrent) || null,
    addresse: formatPrimaryAddress(user.addresses),
    phoneNumber: formatPrimaryPhoneNumber(user.phoneNumbers),
    createdAt: user.createdAt,
    updatedAt: user.updatedAt,
    profile: userProfile,
    status: user.isActive,
    lastLogin: user.lastLogin,
    otpEnabled: user.otpEnabled,
    optVerified: user.optVerified,
    otpBase32: user.otpBase32,
    otpAuthUrl: user.otpAuthUrl,
    otpHex: user.otpHex,
    otpAscii: user.otpAscii,
  };
}

export const convertOrganizationToOrganizationTable = (
  organization: IOrganization,
  profiles: IProfile[],
  organizationsList: IOrganization[],
): IOrganizationTable => {
  const profile = profiles.find(
    (profile) => profile._id === organization.profile,
  );
  const ParentOrganization = organizationsList.find(
    (org) => org._id === organization.parentOrg,
  );
  const OrganizationProfile = profile ? profile.name : 'No Profile';
  return {
    _id: organization._id,
    name: organization.name,
    profile: OrganizationProfile,
    avatar: organization.avatars.find((avatar) => avatar.isCurrent) || null,
    addresse: formatPrimaryAddress(organization.addresses),
    phoneNumber: formatPrimaryPhoneNumber(organization.phoneNumbers),
    parentOrg: ParentOrganization ? ParentOrganization.name : 'None',
    createdAt: organization.createdAt,
    updatedAt: organization.updatedAt,
    isActive: organization.isActive,
  };
};

export const convertCategoryToCategoryTable = (
  category: ICategory,
): ICategoryTable => ({
  _id: category._id,
  name: category.name,
  weight: convertWeight(category.weight),
  isActive: category.isActive,
  categoryType: category.categoryType,
  avatar: category.avatar,
  createdAt: category.createdAt,
  updatedAt: category.updatedAt,
});

export const convertProfileToProfileTable = (
  profile: IProfile,
): IProfileTable => ({
  _id: profile._id,
  name: profile.name,
  type: profile.type,
  categories: profile.categories.length,
  associatedIndividuals: profile.associatedIndividuals.length,
  associatedOrganizations: profile.associatedOrganizations.length,
  isEnabled: profile.isEnabled,
  isPrivate: profile.isPrivate,
  createdAt: profile.createdAt,
});

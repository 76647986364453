import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  BehaviorSubject,
  Subscription,
  map,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

import {
  IOrganizationProfileFormInput,
  ProfileContext,
} from 'src/app/core/models/profile-form-context.model';
import { OrganizationPageStore } from './organization-page.store';
import { FormGroup } from '@angular/forms';
import {
  OrganizationsAPIActions,
  OrganizationsAppActions,
} from 'src/app/state/organizations/organizations.actions';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { selectCurrentUserOrganization } from 'src/app/state/auth/auth.selectors';
import { OrganizationProfileFromComponent } from 'src/app/shared/organization-profile-from/organization-profile-from.component';
import { FeedbackComponent } from "../../shared/feedback/feedback.component";

@Component({
    selector: 'cap-organization',
    standalone: true,
    providers: [OrganizationPageStore],
    templateUrl: './organization-page.component.html',
    styleUrl: './organization-page.component.scss',
    imports: [CommonModule, OrganizationProfileFromComponent, SpinnerComponent, FeedbackComponent]
})
export class OrganizationPageComponent implements OnInit, OnDestroy {
  page: number = 1;
  limit: number = 10;
  totalPages: number = 0;
  totalItems: number = 0;
  organizationConfigSubject: BehaviorSubject<IOrganizationProfileFormInput> =
    new BehaviorSubject<IOrganizationProfileFormInput>({
      context: ProfileContext.OrgUpdate,
      data: null,
    });
  organizationConfig$ = this.organizationConfigSubject.asObservable();

  private store = inject(Store);
  private componentStore = inject(OrganizationPageStore);
  private actions$ = inject(Actions);

  currentUserOrganization$ = this.store.select(selectCurrentUserOrganization);

  private subs: Subscription[] = [];

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;
  organizationProfileValue$ = this.componentStore.organization$;
  organizationProfileFormValidity$ =
    this.componentStore.organizationFormValidity$;

  ngOnInit(): void {
    this.store.dispatch(
      OrganizationsAppActions.getOrganizations({ page: 1, limit: 10 })
    );

    const sub = this.actions$
      .pipe(
        ofType(OrganizationsAPIActions.getOrganizationsSuccess),
        withLatestFrom(this.currentUserOrganization$),
        tap(([_, organization]) => {
          const newConfig = {
            context: ProfileContext.OrgUpdate,
            data: organization!,
          };

          this.organizationConfigSubject.next(newConfig);
          this.componentStore.setOrganization(organization!);
        })
      )
      .subscribe();

    this.subs.push(sub);
  }

  handleProfileChange(profile: FormGroup) {
    this.componentStore.setOrganization(profile.value);
    this.componentStore.setOrganizationFormValidity(profile.valid);
  }

  submitOrganizationForm() {
    const sub = this.organizationProfileValue$
      .pipe(
        map((organiztionProfileValue) => {
          if (organiztionProfileValue) {
            this.store.dispatch(
              OrganizationsAppActions.updateOrganization({
                organization: organiztionProfileValue,
              })
            );
          }
          this.waitForResponse();
        })
      )
      .subscribe();

    this.subs.push(sub);
  }

  private waitForResponse() {
    const sub = this.actions$
      .pipe(ofType(OrganizationsAPIActions.updateOrganizationSuccess), take(1))
      .subscribe();

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusCellStore } from './status-cell.store';

@Component({
  selector: 'cap-status-cell',
  standalone: true,
  imports: [CommonModule],
  providers: [StatusCellStore],
  templateUrl: './status-cell.component.html',
  styleUrl: './status-cell.component.scss',
})
export class StatusCellComponent {
  private readonly componentStore = inject(StatusCellStore);
  @Input() props = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];
  @Input() set status(value: boolean) {
    this.componentStore.setStatus(value);
  }

  readonly status$ = this.componentStore.status$;
}

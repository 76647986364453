import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ICategoryBase, ICategory } from '../models/categories.model';
import { IAvatar } from '../models/user.model';
import { Pagination } from 'src/app/state/categories/categories.state';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private readonly http = inject(HttpClient);
  private categoriesPath = 'categories';

  getCategories(
    page: number,
    pageSize: number,
  ): Observable<{ categories: ICategory[]; pagination: Pagination }> {
    return this.http.get<{ categories: ICategory[]; pagination: Pagination }>(
      `${environment.apiUrl}/${this.categoriesPath}?page=${page}&limit=${pageSize}`,
    );
  }

  searchCategories(
    page: number,
    pageSize: number,
    categoryType: string,
    search: string,
  ): Observable<{ categories: ICategory[]; pagination: Pagination }> {
    return this.http.get<{ categories: ICategory[]; pagination: Pagination }>(
      `${environment.apiUrl}/${this.categoriesPath}/search?page=${page}&limit=${pageSize}&categoryType=${categoryType}&search=${search}`,
    );
  }

  getCategoriesByType(
    categoryType: string,
    page?: number,
    limit?: number,
  ): Observable<{
    categories: ICategory[];
    pagination: Pagination;
  }> {
    if (page && limit) {
      return this.http.get<{ categories: ICategory[]; pagination: Pagination }>(
        `${environment.apiUrl}/${this.categoriesPath}/type?categoryType=${categoryType}&page=${page}&limit=${limit}`,
      );
    }
    return this.http.get<{ categories: ICategory[]; pagination: Pagination }>(
      `${environment.apiUrl}/${this.categoriesPath}/type?categoryType=${categoryType}`,
    );
  }

  getCategoryById(categoryId: string): Observable<{
    category: ICategory;
  }> {
    return this.http.get<{ category: ICategory }>(
      `${environment.apiUrl}/${this.categoriesPath}/${categoryId}`,
    );
  }
  createCategory(category: ICategoryBase): Observable<{ category: ICategory }> {
    return this.http.post<{ category: ICategory }>(
      `${environment.apiUrl}/${this.categoriesPath}`,
      category,
    );
  }

  updateCategory(category: ICategory): Observable<{ category: ICategory }> {
    const { _id, ...categoryData } = category;

    return this.http.put<{ category: ICategory }>(
      `${environment.apiUrl}/${this.categoriesPath}/${_id}`,
      categoryData,
    );
  }

  removeCategory(
    categoryId: string,
  ): Observable<{ categoryId: string; message: string }> {
    return this.http.delete<{ categoryId: string; message: string }>(
      `${environment.apiUrl}/${this.categoriesPath}/${categoryId}`,
    );
  }

  updateCategoryAvatar(
    categoryId: string,
    avatar: IAvatar,
  ): Observable<{ categoryId: string; avatars: IAvatar[] }> {
    return this.http.put<{ categoryId: string; avatars: IAvatar[] }>(
      `${environment.apiUrl}/${this.categoriesPath}/${categoryId}/avatars`,
      { avatar },
    );
  }

  removeCategoryAvatar(
    categoryId: string,
    avatar: IAvatar,
  ): Observable<{ categoryId: string; avatar: IAvatar }> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { avatar },
    };

    return this.http.delete<{ categoryId: string; avatar: IAvatar }>(
      `${environment.apiUrl}/${this.categoriesPath}/${categoryId}/avatars`,
      options,
    );
  }

  toggleCategoryStatus(
    categoryId: string,
    isActive: boolean,
  ): Observable<{ categoryId: string; isActive: boolean }> {
    return this.http.patch<{ categoryId: string; isActive: boolean }>(
      `${environment.apiUrl}/${this.categoriesPath}/${categoryId}/status`,
      { isActive },
    );
  }

  updateCategoriesSortOrder(
    categoryId: string,
    previousIndex: number,
    currentIndex: number,
  ): Observable<{ message: string }> {
    return this.http.patch<{ message: string }>(
      `${environment.apiUrl}/${this.categoriesPath}/${categoryId}/sort`,
      { previousIndex, currentIndex },
    );
  }
}

import { emptyProps, props } from "@ngrx/store";

import { createActionGroup } from "@ngrx/store";
import { ICreateDiscountCode, IDiscount } from "src/app/core/models/discount.model";

export const DiscountAppActions = createActionGroup({
    source: 'Discount',
    events: {
        'Create Discount Code': props<{discountCode: ICreateDiscountCode}>(),
        'Get All Discount Codes': emptyProps(),
        'Delete Discount Code': props<{id: string}>(),
    }
})

export const DiscountApiActions = createActionGroup({
    source: 'Discount API',
    events: {
        'Create Discount Code Success': props<{discountCode: IDiscount}>(),
        'Create Discount Code Failure': props<{error: string}>(),
        'Get All Discount Codes Success': props<{discountCodes: IDiscount[]}>(),
        'Get All Discount Codes Failure': props<{error: string}>(),
        'Delete Discount Code Success': props<{id: string}>(),
        'Delete Discount Code Failure': props<{error: string}>(),
    }
})
import { Component, Input, inject } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { InfoCellStore } from './info-cell.store';

interface IInfoCell {
  fullName: string;
  email: string;
}

@Component({
  selector: 'cap-name-cell',
  standalone: true,
  imports: [CommonModule],
  providers: [InfoCellStore],
  templateUrl: './info-cell.component.html',
  styleUrl: './info-cell.component.scss',
})
export class InfoCellComponent {
  private readonly componentStore = inject(InfoCellStore);

  @Input() set infoCell(value: IInfoCell) {
    this.componentStore.setFullName(value.fullName);
    this.componentStore.setEmail(value.email);
  }

  readonly fullName$ = this.componentStore.fullName$;
  readonly email$ = this.componentStore.email$;
}

import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { Store } from '@ngrx/store';

import { ModalActions } from 'src/app/state/modal/modal.actions';
import { selectModalVisibility } from 'src/app/state/modal/modal.state';
import { Observable } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'cap-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
  standalone: true,
  imports: [CommonModule, NgClass],
  styles: ``,
  animations: [
    trigger('modalAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(4px) scale(0.95)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0) scale(1)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(4px) scale(0.95)' })
        ),
      ]),
    ]),
  ],
})
export class ModalContainerComponent implements OnInit {
  @Input() modalID: string = '';
  @Input() modalWidthClass: string = 'sm:max-w-xl';

  private readonly store = inject(Store);
  isModalVisible$!: Observable<boolean | undefined>;
  ngOnInit(): void {
    this.isModalVisible$ = this.store.select(
      selectModalVisibility(this.modalID)
    );
  }

  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.modalID }));
  }
}

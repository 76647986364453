import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  combineLatestWith,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ListboxModule } from 'primeng/listbox';

import { ActionItemControlsModalStore } from './action-item-controls-modal.store';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { DualPickListComponent } from 'src/app/shared/dual-pick-list/dual-pick-list.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { FeedbackComponent } from 'src/app/shared/feedback/feedback.component';
import {
  ISecurityControl,
  IStandard,
} from 'src/app/core/models/security-controls.model';
import { selectAll as selectAllStandards } from 'src/app/state/security-controls/standards.state';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { ActionItemsAppActions } from 'src/app/state/action-items/action-items.actions';
import {
  selectSecurityControlsByStandardId,
  selectSecurityControlsByIds,
} from 'src/app/state/security-controls/security-controls.selectors';
import { SecurityControlsAppActions } from 'src/app/state/security-controls/security-controls.actions';
import { selectActionItemById } from 'src/app/state/action-items/action-items.state';

@Component({
  selector: 'cap-action-item-controls-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ModalContainerComponent,
    DualPickListComponent,
    SpinnerComponent,
    FeedbackComponent,
    ListboxModule,
  ],
  providers: [ActionItemControlsModalStore],
  templateUrl: './action-item-controls-modal.component.html',
  styleUrl: './action-item-controls-modal.component.scss',
})
export class ActionItemControlsModalComponent implements OnInit, OnDestroy {
  @Input() actionItemId = '';

  selectedStandard: WritableSignal<IStandard | null> = signal<IStandard | null>(
    null,
  );

  destroy$ = new Subject<void>();

  modalMetadata = {
    modalID: 'action-item-controls',
    modalWidth: 'sm:max-w-6xl',
    title: 'Add Security Controls To Action Item',
  };

  private store = inject(Store);
  private componentStore = inject(ActionItemControlsModalStore);

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;

  standardsList$: Observable<IStandard[]> =
    this.store.select(selectAllStandards);
  actionItemControlIdsList$: Observable<string[]> = of([]);
  sourceControls$: Observable<ISecurityControl[]> = of([]);
  targetControls$: Observable<ISecurityControl[]> = of([]);

  ngOnInit(): void {
    this.store.dispatch(SecurityControlsAppActions.getStandards());
    this.standardsList$
      .pipe(
        take(1),
        tap((standards) => {
          if (standards.length > 0) {
            this.selectedStandard.set(standards[0]);
            this.fetchControls(standards[0]._id);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.actionItemControlIdsList$ = this.store
      .select(selectActionItemById(this.actionItemId))
      .pipe(map((actionItem) => actionItem?.controls ?? []));

    this.actionItemControlIdsList$
      .pipe(
        switchMap((controlIds) =>
          this.store.select(selectSecurityControlsByIds(controlIds)),
        ),
        map((controls) => this.componentStore.setControls(controls)),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.targetControls$ = this.componentStore.controls$;
  }

  onStandardChange(standard: any) {
    // Preserve the currently selected contorls
    const currentControls$ = this.componentStore.controls$.pipe(take(1));

    currentControls$
      .pipe(
        map((controls) => {
          //Reassign the previously selected controls to the targetControls$
          // this.targetControls$ = of(controls);

          this.selectedStandard.set(standard);
          this.fetchControls(standard._id);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  fetchControls(standardId: string) {
    this.store.dispatch(
      SecurityControlsAppActions.getSecurityControlsByStandardId({
        standardId,
      }),
    );
    this.sourceControls$ = this.store
      .select(selectSecurityControlsByStandardId(standardId))
      .pipe(
        combineLatestWith(this.componentStore.controls$),
        map(([allControls, targetControls]) =>
          allControls.filter(
            (control) => !targetControls.some((tc) => tc._id === control._id),
          ),
        ),
      );
  }

  updateActionItemControls() {
    this.componentStore.controls$
      .pipe(
        take(1),
        map((controls) => controls.map((control) => control._id)),
        tap((controls) => {
          this.store.dispatch(
            ActionItemsAppActions.updateActionItemControls({
              id: this.actionItemId,
              controls,
            }),
          );
          this.closeModal();
        }),
      )
      .subscribe();
  }

  onControlsChange(changeObj: {
    source: ISecurityControl[];
    target: ISecurityControl[];
  }) {
    this.componentStore.setControls(changeObj.target);
  }

  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.modalMetadata.modalID }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { IAuditLog } from 'src/app/core/models/audit-logs.model';
import { Pagination } from '../categories/categories.state';
import { AuditLogsAPIActions } from './audit-logs.actions';
import { state } from '@angular/animations';

export interface AuditLogsState extends EntityState<IAuditLog> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<IAuditLog> = createEntityAdapter<IAuditLog>(
  {
    selectId: (auditLog: IAuditLog) => auditLog._id,
  },
);

export const initialState: AuditLogsState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 10,
    totalCount: 0,
  },
});

const auditLogsReducer = createReducer(
  initialState,
  on(
    AuditLogsAPIActions.getAuditLogsSuccess,
    (state, { auditLogs, pagination }) => {
      return adapter.setAll(auditLogs, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(AuditLogsAPIActions.getAuditLogsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(
    AuditLogsAPIActions.searchAuditLogsSuccess,
    (state, { auditLogs, pagination }) => {
      return adapter.setAll(auditLogs, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(AuditLogsAPIActions.searchAuditLogsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
);

export const auditLogsFeature = createFeature({
  name: 'auditLogs',
  reducer: auditLogsReducer,
  extraSelectors: ({ selectAuditLogsState, selectEntities }) => ({
    ...adapter.getSelectors(selectAuditLogsState),
    selectServiceById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
  }),
});

export const {
  name,
  reducer,
  selectAuditLogsState,
  selectIds,
  selectEntities,
  selectErrorMessage,
  selectPagination,
  selectAll,
  selectServiceById,
  selectTotal,
} = auditLogsFeature;

<!--@if(status$ | async) {-->
<!--<span-->
<!--  class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"-->
<!--  >{{ props[0].label }}</span-->
<!--&gt;-->
<!--}@else {-->
<!--<span-->
<!--  class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"-->
<!--  >{{ props[1].label }}</span-->
<!--&gt;-->
<!--}-->
<div class="flex items-center">
  <!-- Enabled: "bg-red-600", Not Enabled: "bg-gray-200" -->
  <button
    type="button"
    class="cursor-default relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
    [ngClass]="{
      'bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2':
        (status$ | async),
      'bg-gray-200 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2':
        !(status$ | async)
    }"
    role="switch"
    aria-checked="false"
  >
    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
    <span
      aria-hidden="true"
      class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
      [ngClass]="{
        'translate-x-5': (status$ | async),
        'translate-x-0': !(status$ | async)
      }"
    ></span>
  </button>
</div>

<div class="mt-3 px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1
        class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50"
      >
        Audit Logs
      </h1>
      <p class="mt-2 text-sm text-gray-700 dark:text-gray-400">
        Audit logs are records of events and activities that occur within our
        application
      </p>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <input
        type="text"
        placeholder="Search logs..."
        class="w-full border border-gray-300 rounded-md px-3 py-2 dark:bg-slate-700 dark:text-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        [(ngModel)]="searchQuery"
        (input)="searchAuditLogs()"
      />
    </div>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <cap-table
          [isDraggable]="false"
          [displayActions]="false"
          [columns]="columns"
          [data]="(auditLogs$ | async) ?? []"
        >
        </cap-table>
        <cap-pagination
          [current_page]="current_page"
          [totalItems]="totalItems"
          [totalPages]="total_pages"
          [pageSize]="pageSize"
          (pageChange)="onPageChange($event)"
          [disableNext]="current_page == total_pages"
        ></cap-pagination>
      </div>
    </div>
  </div>
</div>

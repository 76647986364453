import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, tap } from 'rxjs';
import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import {
  IQuestion,
  IQuestionBase,
  IQuestionOption,
} from 'src/app/core/models/questions.model';

import { EditableTableComponent } from 'src/app/shared/editable-table/editable-table.component';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { selectQuestionOptions } from 'src/app/state/questions/questions.state';
import { QuestionOptionsModalStore } from './question-options-modal.store';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { QuestionsAppActions } from 'src/app/state/questions/questions.actions';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { FeedbackComponent } from '../../../../shared/feedback/feedback.component';

@Component({
  selector: 'cap-question-options-modal',
  standalone: true,
  providers: [QuestionOptionsModalStore],
  templateUrl: './question-options-modal.component.html',
  styleUrl: './question-options-modal.component.scss',
  imports: [
    CommonModule,
    EditableTableComponent,
    ModalContainerComponent,
    SpinnerComponent,
    ConfirmModalComponent,
    FeedbackComponent,
  ],
})
export class QuestionOptionsModalComponent implements OnInit {
  modalMetadata = {
    modalID: 'question-options',
    modalWidth: 'sm:max-w-6xl',
    title: 'Question Options',
  };

  readonly confirmModalData = {
    title: 'Delete question option',
    message: 'Are you sure you want to delete this option?',
  };

  optionsTableMetaData: IEditableTableMetadata = {
    title: 'Question Options',
    entityName: 'option',
    accordion: false,
  };

  @Input() questionId = '';

  private readonly store = inject(Store);
  private readonly componentStore = inject(QuestionOptionsModalStore);

  options$: Observable<IQuestionOption[]> = of([]);
  private optionDropped: IQuestionOption | null = null;

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;

  questionOptionsTableColumns: IEditableColumn[] = [
    {
      key: 'isPositive',
      label: 'Positive',
      type: 'checkbox',
      sortable: false,
    },
    {
      key: 'value',
      label: 'Value',
      type: 'text',
      sortable: false,
    },
    {
      key: 'weight',
      label: 'Weight',
      sortable: false,
      type: 'list',
      options: [
        {
          label: 'None',
          value: 0,
        },
        {
          label: 'Low',
          value: 1,
        },
        {
          label: 'Medium-Low',
          value: 2,
        },
        {
          label: 'Medium',
          value: 3,
        },
        {
          label: 'Medium-High',
          value: 4,
        },
        {
          label: 'High',
          value: 5,
        },
      ],
    },
    {
      key: 'isActive',
      label: 'Status',
      sortable: false,
      type: 'status',
      options: [
        {label: 'Positive', value : true},
        {label: 'Negative', value: false}
      ]
    },
  ];

  ngOnInit(): void {
    this.options$ = this.store.select(selectQuestionOptions(this.questionId));
  }

  onQuestionOptionCreated(newOption: any) {
    this.store.dispatch(
      QuestionsAppActions.addOptionToQuestion({
        questionId: this.questionId,
        option: <IQuestionOption>newOption,
      })
    );
  }

  onQuestionOptionUpdated(option: any) {
    this.store.dispatch(
      QuestionsAppActions.updateQuestionOption({
        questionId: this.questionId,
        option: <IQuestionOption>option,
      })
    );
  }

  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.modalMetadata.modalID }));
  }

  openConfirmationModal(option: any) {
    this.optionDropped = option;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-option' }));
  }

  removeQuestionOption() {
    if (this.optionDropped) {
      this.store.dispatch(
        QuestionsAppActions.removeQuestionOption({
          questionId: this.questionId,
          optionId: this.optionDropped._id,
        })
      );
    }
  }
}

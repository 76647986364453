<div class="mt-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-sm">
  <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100 mb-3">Subscription Information</h3>
  
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
    <div class="flex flex-col">
      <span class="text-sm font-medium text-gray-500 dark:text-gray-400">Start Date</span>
      <span class="text-base font-semibold text-gray-900 dark:text-gray-100">
        {{ formatDate(subscriptionStartTime) }}
      </span>
    </div>
    
    <div class="flex flex-col">
      <span class="text-sm font-medium text-gray-500 dark:text-gray-400">End Date</span>
      <span class="text-base font-semibold text-gray-900 dark:text-gray-100">
        {{ formatDate(subscriptionEndTime) }}
      </span>
    </div>
    
    <div class="flex flex-col">
      <span class="text-sm font-medium text-gray-500 dark:text-gray-400">Status</span>
      <span class="text-base font-semibold" [ngClass]="statusClass">
        {{ subscriptionStatus | titlecase }}
      </span>
    </div>
  </div>
  
  <div class="mt-4 flex items-center justify-between">
    <div class="flex items-center">
      <span class="text-sm mr-2 text-gray-700 dark:text-gray-300">
        <span [ngClass]="statusClass">
          {{ statusMessage }}
        </span>
      </span>
      
      <!-- Status indicator dot -->
      <span *ngIf="isActive" class="inline-flex h-2 w-2 rounded-full bg-green-500"></span>
      <span *ngIf="isPendingCancellation" class="inline-flex h-2 w-2 rounded-full bg-yellow-500"></span>
      <span *ngIf="isCanceled || isDisabled" class="inline-flex h-2 w-2 rounded-full bg-gray-400"></span>
    </div>
    
    <!-- Action buttons based on subscription state -->
    <div class="flex gap-2">
      <!-- Show disable button only for active subscriptions -->
      <button 
        *ngIf="isActive"
        (click)="disableSubscriptionRenewal()"
        type="button"
        class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50 focus:outline-red-600 dark:bg-gray-700 dark:text-red-400 dark:ring-red-600 dark:hover:bg-gray-600"
      >
        <svg class="h-4 w-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
        Disable Auto-renewal
      </button>
      
      <!-- Show enable button for pending cancellation, canceled or disabled subscriptions -->
      <button 
        *ngIf="isPendingCancellation || isCanceled || isDisabled"
        (click)="enableSubscriptionRenewal()"
        type="button"
        class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-green-50 focus:outline-green-600 dark:bg-gray-700 dark:text-green-400 dark:ring-green-600 dark:hover:bg-gray-600"
      >
        <svg class="h-4 w-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
        </svg>
        Enable Auto-renewal
      </button>
    </div>
  </div>
  
  <!-- Additional information about subscription end date -->
  <div *ngIf="isPendingCancellation || isCanceled" class="mt-3 text-sm text-gray-500 dark:text-gray-400">
    Your subscription will expire on {{ formatDate(subscriptionEndTime) }}
  </div>
</div>

<div *ngIf="operation">
    <div class="mt-6 p-4  dark:bg-gray-800 rounded-lg shadow-sm flex justify-center items-center bg-green-500 text-white dark:text-gray-100 dark:bg-green-600">
        <p>
            Your subscription is updated successfully.
        </p>
    </div>
</div>

<cap-modal-container
  [modalWidthClass]="modalMetadata.modalWidth"
  [modalID]="modalMetadata.modalID"
>
  <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
    {{ modalMetadata.title }}
  </p>

  <div class="w-full space-y-5">
    @if (errorMessage$ | async) {
      <cap-feedback
        type="error"
        [message]="errorMessage$ | async"
      ></cap-feedback>
    }
    @if (loading$ | async) {
      <div
        class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
      >
        <cap-spinner class="z-50" [size]="16"></cap-spinner>
      </div>
    }
    <cap-editable-table
      [isDraggable]="true"
      [metadata]="optionsTableMetaData"
      [columns]="questionOptionsTableColumns"
      [data]="(options$ | async)!"
      (onEntityDeleted)="openConfirmationModal($event)"
      (onEntityUpdated)="onQuestionOptionUpdated($event)"
      (onEntityCreated)="onQuestionOptionCreated($event)"
    ></cap-editable-table>

    <div class="flex justify-end mt-5">
      <button
        type="button"
        (click)="closeModal()"
        class="rounded-md bg-gray-100 hover:bg-gray-50 dark:bg-white/10 dark:hover:bg-white/20 px-3 py-2 text-sm font-semibold text-gray-800 dark:text-gray-50 shadow-sm"
      >
        Close
      </button>
    </div>
  </div>
</cap-modal-container>

<cap-confirm-modal
  [modalID]="'confirm-removing-option'"
  [data]="confirmModalData"
  (confirmEvent)="removeQuestionOption()"
></cap-confirm-modal>

import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { exhaustMap, take, map, catchError, of, switchMap, share } from 'rxjs';

import { CategoriesService } from 'src/app/core/services/categories.service';
import {
  CategoriesAppActions,
  CategoriesAPIActions,
} from './categories.actions';
import { handleError } from 'src/app/utils/error-handler.util';

export const getCategories$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.getCategories),
      exhaustMap(({ page, pageSize }) =>
        categoriesService.getCategories(page, pageSize).pipe(
          take(1),
          map(({ categories, pagination }) => {
            return CategoriesAPIActions.getCategoriesSuccess({
              categories,
              pagination,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              CategoriesAPIActions.getCategoriesFailure({
                message,
              }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const searchCategories$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.searchCategories),
      exhaustMap(({ page, pageSize, categoryType, search }) =>
        categoriesService
          .searchCategories(page, pageSize, categoryType, search)
          .pipe(
            take(1),
            map(({ categories, pagination }) => {
              return CategoriesAPIActions.searchCategoriesSuccess({
                categories,
                pagination,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                CategoriesAPIActions.searchCategoriesFailure({
                  message,
                }),
              );
            }),
          ),
      ),
      share(),
    );
  },
  { functional: true },
);

// export const getOrganizationCategories$ = createEffect(
//   (
//     actions$ = inject(Actions),
//     categoriesService = inject(CategoriesService),
//   ) => {
//     return actions$.pipe(
//       ofType(CategoriesAppActions.getOrganizationsCategories),
//       exhaustMap(({ page, pageSize }) =>
//         categoriesService.getOrganizationsCategories(page, pageSize).pipe(
//           take(1),
//           map(({ categories, pagination }) => {
//             return CategoriesAPIActions.getOrganizationsCategoriesSuccess({
//               categories,
//               pagination,
//             });
//           }),
//           catchError((error) => {
//             const message = handleError(error);
//             return of(
//               CategoriesAPIActions.getOrganizationsCategoriesFailure({
//                 message,
//               }),
//             );
//           }),
//         ),
//       ),
//       share(),
//     );
//   },
//   { functional: true },
// );

// export const getIndividualCategories$ = createEffect(
//   (
//     actions$ = inject(Actions),
//     categoriesService = inject(CategoriesService),
//   ) => {
//     return actions$.pipe(
//       ofType(CategoriesAppActions.getIndividualsCategories),
//       exhaustMap(({ page, pageSize }) =>
//         categoriesService.getIndividualsCategories(page, pageSize).pipe(
//           take(1),
//           map(({ categories, pagination }) => {
//             return CategoriesAPIActions.getIndividualsCategoriesSuccess({
//               categories,
//               pagination,
//             });
//           }),
//           catchError((error) => {
//             const message = handleError(error);
//             return of(
//               CategoriesAPIActions.getIndividualsCategoriesFailure({
//                 message,
//               }),
//             );
//           }),
//         ),
//       ),
//       share(),
//     );
//   },
//   { functional: true },
// );

export const getCategoriesByType$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.getCategoriesByType),
      exhaustMap(({ categoryType, page, limit }) =>
        categoriesService.getCategoriesByType(categoryType, page, limit).pipe(
          take(1),
          map(({ categories, pagination }) => {
            return CategoriesAPIActions.getCategoriesByTypeSuccess({
              categories,
              pagination,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              CategoriesAPIActions.getCategoriesByTypeFailure({
                message,
              }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const getCategoryById$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesServices = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.getCategoryById),
      exhaustMap(({ id }) =>
        categoriesServices.getCategoryById(id).pipe(
          take(1),
          map(({ category }) => {
            return CategoriesAPIActions.getCategoryByIdSuccess({ category });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(CategoriesAPIActions.getCategoryByIdFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const createCategory$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.createCategory),
      exhaustMap(({ category }) =>
        categoriesService.createCategory(category).pipe(
          take(1),
          map(({ category }) => {
            return CategoriesAPIActions.createCategorySuccess({
              category,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(CategoriesAPIActions.createCategoryFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const updateCategory$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.updateCategory),
      exhaustMap(({ category }) =>
        categoriesService.updateCategory(category).pipe(
          take(1),
          map(({ category }) => {
            return CategoriesAPIActions.updateCategorySuccess({ category });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(CategoriesAPIActions.updateCategoryFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const removeCategory$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.removeCategory),
      exhaustMap(({ id }) =>
        categoriesService.removeCategory(id).pipe(
          take(1),
          map(({ categoryId, message }) => {
            return CategoriesAPIActions.removeCategorySuccess({
              categoryId,
              message,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(CategoriesAPIActions.removeCategoryFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const updateCategoryAvatar$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.updateCategoryAvatar),
      exhaustMap(({ categoryId, avatar }) =>
        categoriesService.updateCategoryAvatar(categoryId, avatar).pipe(
          take(1),
          map(({ categoryId, avatars }) => {
            return CategoriesAPIActions.updateCategoryAvatarSuccess({
              categoryId,
              avatars,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              CategoriesAPIActions.updateCategoryAvatarFailure({ message }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const removeCategoryAvatar$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.removeCategoryAvatar),
      exhaustMap(({ categoryId, avatar }) =>
        categoriesService.removeCategoryAvatar(categoryId, avatar).pipe(
          take(1),
          map(({ categoryId, avatar }) => {
            return CategoriesAPIActions.removeCategoryAvatarSuccess({
              categoryId,
              avatar,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              CategoriesAPIActions.removeCategoryAvatarFailure({ message }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const updateCategoriesSortOrder = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.updateCategorySortOrder),
      switchMap(({ categoryId, previousIndex, currentIndex }) =>
        categoriesService
          .updateCategoriesSortOrder(categoryId, previousIndex, currentIndex)
          .pipe(
            take(1),
            map(({ message }) => {
              return CategoriesAPIActions.updateCategorySortOrderSuccess({
                message,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                CategoriesAPIActions.updateCategorySortOrderFailure({
                  message,
                }),
              );
            }),
          ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const toggleCategoryStatus$ = createEffect(
  (
    actions$ = inject(Actions),
    categoriesService = inject(CategoriesService),
  ) => {
    return actions$.pipe(
      ofType(CategoriesAppActions.toggleCategoryStatus),
      switchMap(({ categoryId, isActive }) =>
        categoriesService.toggleCategoryStatus(categoryId, isActive).pipe(
          take(1),
          map(({ categoryId, isActive }) => {
            return CategoriesAPIActions.toggleCategoryStatusSuccess({
              categoryId,
              isActive,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              CategoriesAPIActions.toggleCategoryStatusFailure({ message }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

import { inject, Injectable } from '@angular/core';
import { Entity } from '../../shared/shared-assessment-header/shared-assessment-header.component';
import { Observable } from 'rxjs';
import { IAssessment } from '../models/assessments.model';
import { ICategory } from '../models/categories.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private http = inject(HttpClient);

  constructor() {}

  getReport(entity: Entity): Observable<{
    assessments: IAssessment[];
    categories: ICategory[];
    questions: any;
  }> {
    return this.http.post<{
      assessments: IAssessment[];
      categories: ICategory[];
      questions: any;
    }>(`${environment.apiUrl}/reports`, { entity });
  }

  generateReport(
    entity: Entity,
    categoryFilter: string | null,
    weightFilter: number | null,
    includeServices: boolean,
    includeControls: boolean,
  ) {
    if (categoryFilter) {
      return this.http.post(
        `${environment.apiUrl}/reports/generate`,
        {
          entity,
          categoryFilter,
          includeServices,
          includeControls,
        },
        {
          responseType: 'blob',
          observe: 'response',
        },
      );
    } else if (weightFilter) {
      return this.http.post(
        `${environment.apiUrl}/reports/generate`,
        {
          entity,
          weightFilter,
          includeServices,
          includeControls,
        },
        {
          responseType: 'blob',
          observe: 'response',
        },
      );
    } else if (categoryFilter && weightFilter) {
      return this.http.post(
        `${environment.apiUrl}/reports/generate`,
        {
          entity,
          categoryFilter,
          weightFilter,
          includeServices,
          includeControls,
        },
        {
          responseType: 'blob',
          observe: 'response',
        },
      );
    }
    return this.http.post(
      `${environment.apiUrl}/reports/generate`,
      {
        entity,
        categoryFilter,
        weightFilter,
        includeServices,
        includeControls,
      },
      {
        responseType: 'blob',
        observe: 'response',
      },
    );
  }
}

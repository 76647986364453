import { Component, EventEmitter, inject, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { PaymentService } from 'src/app/core/services/payment.service';
import { FeedbackComponent } from '../feedback/feedback.component';
import { firstValueFrom } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'cap-payment-form',
  standalone: true,
  imports: [CommonModule, FeedbackComponent, ReactiveFormsModule],
  templateUrl: './payment-form.component.html',
  styles: [`
    .payment-form {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
  `]
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  @Input() featureType!: string;
  @Input() quantity: number = 1;
  @Input() customerEmail?: string;
  @Input() customerDetails?: {
    name: string;
    phone: string;
    country: string;
    description: string;
  };
  @Output() paymentCompleted = new EventEmitter<{
    success: boolean;
    paymentIntentId: string | null;
    subscriptionId: string | undefined;
    discountCode?: string;
    fullyDiscounted?: boolean;
  }>();

  private paymentService = inject(PaymentService);
  private elements: StripeElements | null = null;
  discountForm = new FormGroup({
    discountCode: new FormControl('', { nonNullable: true })
  });

  protected discountApplied = false;
  protected originalPrice = 0;
  protected finalPrice = 0;
  protected isLoading = false;
  protected error: string | null = null;
  protected paymentSucceeded = false;
  protected subscriptionId: string | null = null;
  protected subscriptionStatus: string | null = null;
  protected subscriptionStartTime: Date | null = null;
  protected subscriptionEndTime: Date | null = null;
  discount: number = 0;

  async ngOnInit() {
    try {
      // Get initialized Stripe instance
      const stripe = await this.paymentService.getStripe();
      
      // Log customer details for debugging
      
      // Get client secret
      const response = await firstValueFrom(
        this.paymentService.createPaymentIntent(
          this.featureType, 
          undefined, 
          this.quantity,
          this.customerEmail,
          this.customerDetails
        )
      );
      
      const { clientSecret, originalPrice, finalPrice, subscriptionId, subscriptionStatus, subscriptionStartTime, subscriptionEndTime } = response;
      
      if (!clientSecret && !response.fullyDiscounted) {
        throw new Error('Failed to retrieve client secret');
      }
      
      // Handle fully discounted case
      if (response.fullyDiscounted) {
        this.paymentSucceeded = true;
        this.paymentCompleted.emit({
          success: true,
          paymentIntentId: null,
          subscriptionId: undefined,
          discountCode: undefined,
          fullyDiscounted: true,
        });
        this.originalPrice = originalPrice;
        this.finalPrice = 0;
        return;
      }
      
      // Store initial prices and subscription ID
      this.originalPrice = originalPrice;
      this.finalPrice = finalPrice;
      this.subscriptionId = subscriptionId || null;
      this.subscriptionStatus = subscriptionStatus || null;
      this.subscriptionStartTime = subscriptionStartTime || null;
      this.subscriptionEndTime = subscriptionEndTime || null;
  
      // Create elements
      this.elements = stripe.elements({ clientSecret: clientSecret || undefined });
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');
  
    } catch (err) {
      this.error = err instanceof Error ? err.message : 'Payment system initialization failed';
      console.error('Payment initialization error:', err);
    }
  }

  async applyDiscount(event: Event) {
    event.preventDefault();
    this.isLoading = true;
    this.error = null;

    try {
      
      const response = await firstValueFrom(
        this.paymentService.createPaymentIntent(
          this.featureType,
          this.discountForm.value.discountCode,
          this.quantity,
          this.customerEmail,
          this.customerDetails
        )
      );

      // Handle fully discounted case
      if (response.fullyDiscounted) {
        this.paymentSucceeded = true;
        this.paymentCompleted.emit({
          success: true,
          paymentIntentId: null,
          subscriptionId: undefined,
          discountCode: this.discountForm.value.discountCode,
          fullyDiscounted: response.fullyDiscounted,
        });
        this.discountApplied = true;
        this.originalPrice = response.originalPrice;
        this.finalPrice = 0;
        this.discount = response.discount;
        return;
      }

      // Only create Stripe elements if we have a client secret
      if (response.clientSecret) {
        const stripe = await this.paymentService.getStripe();
        this.elements = stripe.elements({ clientSecret: response.clientSecret });
        const paymentElement = this.elements.create('payment');
        paymentElement.mount('#payment-element');
      }

      this.discountApplied = true;
      this.originalPrice = response.originalPrice;
      this.finalPrice = response.finalPrice;
      this.discount = response.discount;
      this.subscriptionId = response.subscriptionId || null;
      this.subscriptionStatus = response.subscriptionStatus || null;
      this.subscriptionStartTime = response.subscriptionStartTime || null;
      this.subscriptionEndTime = response.subscriptionEndTime || null;

    } catch (err) {
      this.error = err instanceof Error ? err.message : 'Failed to apply discount code';
      console.error('Discount application error:', err);
    } finally {
      this.isLoading = false;
    }
  }
  

  async handleSubmit(event: Event) {
    event.preventDefault();
    this.isLoading = true;
    this.error = null;
  
    try {
      const stripe = await this.paymentService.getStripe();
  
      // Confirm the payment directly using elements and clientSecret
      const { error: submitError, paymentIntent } = await stripe.confirmPayment({
        elements: this.elements!,
        redirect: 'if_required',
      });
  
      if (submitError) {
        throw new Error(submitError.message ?? 'Payment failed');
      }
  
      // Check payment status
      if (paymentIntent?.status === 'succeeded') {
        this.paymentSucceeded = true;
        this.paymentCompleted.emit({ 
          success: true, 
          paymentIntentId: paymentIntent.id || null,
          subscriptionId: this.subscriptionId || undefined,
          discountCode: this.discountForm.value.discountCode || undefined,
        });
      } else {
        throw new Error(`Payment status: ${paymentIntent?.status}`);
      }
  
    } catch (err) {
      this.error = err instanceof Error ? err.message : 'Payment failed';
      this.paymentCompleted.emit({ 
        success: false, 
        paymentIntentId: null,
        subscriptionId: undefined
      });
      console.error('Payment error:', err);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    // Clean up if needed
  }
}

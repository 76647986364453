import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { exhaustMap, take, map, catchError, of, switchMap, share } from 'rxjs';

import { QuestionsService } from 'src/app/core/services/questions.service';
import { QuestionsAppActions, QuestionsAPIActions } from './questions.actions';
import { handleError } from 'src/app/utils/error-handler.util';
import { Store } from '@ngrx/store';
import { AlertActions } from '../alert/alert.actions';

export const getQuestions$ = createEffect(
  (actions$ = inject(Actions), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.getQuestions),
      exhaustMap(({}) =>
        questionsService.getQuestions().pipe(
          take(1),
          map(({ questions, pagination }) => {
            return QuestionsAPIActions.getQuestionsSuccess({
              questions,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(QuestionsAPIActions.getQuestionsFailure({ message }));
          })
        )
      )
    );
  },
  { functional: true }
);

export const getQuestionById$ = createEffect(
  (actions$ = inject(Actions), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.getQuestionById),
      exhaustMap(({ id }) =>
        questionsService.getQuestionsById(id).pipe(
          take(1),
          map(({ question }) => {
            return QuestionsAPIActions.getQuestionsByIdSuccess({
              question,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(QuestionsAPIActions.getQuestionsByIdFailure({ message }));
          })
        )
      )
    );
  },
  { functional: true }
);

export const createQuestion$ = createEffect(
  (actions$ = inject(Actions), qestionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.createQuestion),
      exhaustMap(({ question }) =>
        qestionsService.createQuestion(question).pipe(
          take(1),
          map(({ question }) => {
            return QuestionsAPIActions.createQuestionSuccess({
              question,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              QuestionsAPIActions.createQuestionFailure({
                message,
              })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

export const updateQuestion$ = createEffect(
  (actions$ = inject(Actions), qestionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.updateQuestion),
      exhaustMap(({ question }) =>
        qestionsService.updateQuestion(question).pipe(
          take(1),
          map(({ question }) => {
            return QuestionsAPIActions.updateQuestionSuccess({
              question,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              QuestionsAPIActions.updateQuestionFailure({
                message,
              })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

export const removeOrganization$ = createEffect(
  (actions$ = inject(Actions), qestionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.removeQuestion),
      exhaustMap(({ id }) =>
        qestionsService.removeQuestion(id).pipe(
          take(1),
          map(({ questionId, message }) => {
            return QuestionsAPIActions.removeQuestionSuccess({
              questionId,
              message,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              QuestionsAPIActions.removeQuestionFailure({
                message,
              })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

export const getQuestionsByCategoryId$ = createEffect(
  (actions$ = inject(Actions), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.getQuestionsByCategoryId),
      exhaustMap(({ categoryId, page, pageSize }) =>
        questionsService
          .getQuestionsByCategoryId(categoryId, page, pageSize)
          .pipe(
            take(1),
            map(({ questions, pagination }) => {
              return QuestionsAPIActions.getQuestionsByCategoryIdSuccess({
                questions,
                pagination,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                QuestionsAPIActions.getQuestionsByCategoryIdFailure({ message })
              );
            })
          )
      )
    );
  },
  { functional: true }
);

export const updateQuestionsSortOrder$ = createEffect(
  (actions$ = inject(Actions), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.updateQuestionSortOrder),
      switchMap(({ questionId, previousIndex, currentIndex }) =>
        questionsService
          .updateQuestionSortOrder(questionId, previousIndex, currentIndex)
          .pipe(
            take(1),
            map(({ message }) => {
              return QuestionsAPIActions.updateQuestionSortOrderSuccess({
                message,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                QuestionsAPIActions.updateQuestionSortOrderFailure({ message })
              );
            })
          )
      ),
      share()
    );
  },
  {
    functional: true,
  }
);

export const addOptionToQuestion$ = createEffect(
  (actions$ = inject(Actions), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.addOptionToQuestion),
      exhaustMap(({ questionId, option }) =>
        questionsService.addOptionToQuestion(questionId, option).pipe(
          take(1),
          map(({ question }) => {
            return QuestionsAPIActions.addOptionToQuestionSuccess({ question });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              QuestionsAPIActions.addOptionToQuestionFailure({ message })
            );
          }),
          share()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const updateQuestionOption$ = createEffect(
  (actions$ = inject(Actions), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.updateQuestionOption),
      exhaustMap(({ questionId, option }) =>
        questionsService.updateQuestionOption(questionId, option).pipe(
          take(1),
          map(({ question }) => {
            return QuestionsAPIActions.updateQuestionOptionSuccess({
              question,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              QuestionsAPIActions.updateQuestionOptionFailure({ message })
            );
          }),
          share()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeQuestionOption$ = createEffect(
  (actions$ = inject(Actions), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.removeQuestionOption),
      exhaustMap(({ questionId, optionId }) =>
        questionsService.removeQuestionOption(questionId, optionId).pipe(
          take(1),
          map(({ questionId, optionId, message }) => {
            return QuestionsAPIActions.removeQuestionOptionSuccess({
              questionId,
              optionId,
              message,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              QuestionsAPIActions.removeQuestionOptionFailure({ message })
            );
          }),
          share()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const uploadQuestionsCSV$ = createEffect(
  (actions$ = inject(Actions),store$= inject(Store), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.uploadQuestionsCSV),
      exhaustMap(({ categoryId, jsonData }) =>
        questionsService.uploadQuestionsCSV(categoryId, jsonData).pipe(
          take(1),
          map(({ questions }) => {
            store$.dispatch(AlertActions.addAlert({alert: {message: 'questions uploaded successfully', type: 'success'}}))
            return QuestionsAPIActions.uploadQuestionsCSVSuccess({ questions });
          }),
          catchError((error) => {
            const message = handleError(error);
            store$.dispatch(AlertActions.addAlert({alert: {message: `Operation Cancelled: ${message}`, type: 'error'}}))
            return of(
              QuestionsAPIActions.uploadQuestionsCSVFailure({ message })
            );
          }),
          share()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const downloadCategoryQuestionsCSV$ = createEffect(
  (actions$ = inject(Actions), store$= inject(Store), questionsService = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(QuestionsAppActions.downloadCategoryQuestionsCSV),
      exhaustMap(({ categoryId }) =>
        questionsService.downloadCategoryQuestionsCSV(categoryId).pipe(
          take(1),
          map((csvData) => {
            store$.dispatch(AlertActions.addAlert({alert: {message: 'questions downloaded successfully', type: 'success'}}))
            return QuestionsAPIActions.downloadCategoryQuestionsCSVSuccess();
          }),
          catchError((error) => {
            const message = handleError(error);
            store$.dispatch(AlertActions.addAlert({alert: {message: `Operation Cancelled: ${message}`, type: 'error'}}))
            return of(
              QuestionsAPIActions.downloadCategoryQuestionsCSVFailure({ message })
            );
          })
        )
      )
    );
  },
  {
    functional: true,
  }
);


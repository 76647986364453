import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, Subscription, takeUntil, tap } from 'rxjs';

import {
  OrganizationsAppActions,
  OrganizationsAPIActions,
} from 'src/app/state/organizations/organizations.actions';

@Injectable()
export class BrandingPageStore
  extends ComponentStore<{ isLoading: boolean }>
  implements OnDestroy
{
  private subDestroy$ = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({ isLoading: false });
  }

  isLoading$ = this.select((state) => state.isLoading);

  private readonly appActionsSub = this.actions$
    .pipe(
      takeUntil(this.subDestroy$),
      ofType(OrganizationsAppActions.updateOrganizationBrandingLink),
      tap(() => this.patchState({ isLoading: true })),
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      takeUntil(this.subDestroy$),
      ofType(OrganizationsAPIActions.updateOrganizationBrandingLinkSuccess),
      tap(() => this.patchState({ isLoading: false })),
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      takeUntil(this.subDestroy$),
      ofType(OrganizationsAPIActions.updateOrganizationBrandingLinkFailure),
      tap(({ message }) => this.patchState({ isLoading: false })),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take, takeUntil } from 'rxjs';

import { selectUserRole } from 'src/app/state/auth/auth.selectors';

export const roleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const store = inject(Store);
  const router = inject(Router);

  const requiredRoles: string[] = route.data['roles'];

  return store.select(selectUserRole).pipe(
    filter((currentUserRole) => currentUserRole !== undefined),
    take(1),
    map((currentUserRole) => {
      if (!requiredRoles || requiredRoles.length === 0) {
        return true;
      }

      if (requiredRoles.includes(currentUserRole?.name!)) return true;

      router.navigate(['/not-found']);
      return false;
    })
  );
};

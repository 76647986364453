import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'cap-edit-toggle-button-cell',
  standalone: true,
  imports: [NgClass],
  templateUrl: './edit-toggle-button-cell.component.html',
  styleUrl: './edit-toggle-button-cell.component.scss',
})
export class EditToggleButtonCellComponent {
  @Input() data: any = {};
  @Input() button: boolean = false;
  @Output() checkChange = new EventEmitter();

  onButtonClick() {
    this.button = !this.button;
    this.checkChange.emit(this.button);
  }
}

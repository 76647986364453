import { Component, inject, OnInit } from '@angular/core';
import { DashboardPageHeaderComponent } from '../dashboard-page-header/dashboard-page-header.component';
import { DashboardPageListComponent } from '../dashboardp-page-list/dashboard-page-list.component';
import { DashboardPageStore } from '../dashboard-page.store';
import { AssessmentPageStore } from '../../assessment-page/assessment-page.store';
import { AsyncPipe } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedAssessmentHeaderComponent } from '../../shared/shared-assessment-header/shared-assessment-header.component';
import { SharedAssessmentScrollerComponent } from '../../shared/shared-assessment-scroller/shared-assessment-scroller.component';
import { SharedHeaderDataService } from '../../core/services/shared-header-data.service';

@Component({
  selector: 'cap-dashboard-page-assessment',
  standalone: true,
  imports: [
    DashboardPageHeaderComponent,
    DashboardPageListComponent,
    AsyncPipe,
    ProgressSpinnerModule,
    SharedAssessmentHeaderComponent,
    SharedAssessmentScrollerComponent,
  ],
  templateUrl: './dashboard-page-assessment.component.html',
  styleUrl: './dashboard-page-assessment.component.scss',
})
export class DashboardPageAssessmentComponent implements OnInit {
  private dashboardPageStore = inject(DashboardPageStore);
  private assessmentPageStore = inject(AssessmentPageStore);
  private sharedHeaderDataService = inject(SharedHeaderDataService);
  private currentSelectedCategory =
    this.sharedHeaderDataService.selectedCategory$;

  entity = this.assessmentPageStore.currentEntity$;

  ngOnInit(): void {}
}

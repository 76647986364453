import { createSelector } from "@ngrx/store";
import { selectAll, selectFeedbackState } from "./feedback.state";

export const selectAllFeedbacks = createSelector(selectAll, (feedbacks) => feedbacks);

export const selectFeedbackById = (id: string) => createSelector(
  selectAll,
  (feedbacks) => feedbacks.find(feedback => feedback._id === id)
);

export const selectFeedbackPagination = createSelector(
  selectFeedbackState,
  (state) => state.pagination
);

import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  exhaustMap,
  take,
  map,
  catchError,
  of,
  share,
  debounceTime,
  switchMap,
  mergeMap,
} from 'rxjs';

import { OrganizationsService } from 'src/app/core/services/organizations.service';
import {
  OrganizationsAppActions,
  OrganizationsAPIActions,
} from './organizations.actions';
import { handleError } from 'src/app/utils/error-handler.util';

export const getOrganizations$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.getOrganizations),
      exhaustMap(({ page, limit }) =>
        organizationsService.getOrganizations(page, limit).pipe(
          take(1),
          map(({ organizations, pagination }) => {
            return OrganizationsAPIActions.getOrganizationsSuccess({
              organizations,
              pagination,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              OrganizationsAPIActions.getOrganizationsFailure({
                message,
              }),
            );
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const getOrganizationById$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.getOrganizationById),
      exhaustMap(({ id }) =>
        organizationsService.getOrganizationById(id).pipe(
          take(1),
          map(({ organization }) => {
            return OrganizationsAPIActions.getOrganizationByIdSuccess({
              organization,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              OrganizationsAPIActions.getOrganizationByIdFailure({
                message,
              }),
            );
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const searchOrganizations$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.searchOrganizations),
      debounceTime(300),
      mergeMap(({ search, page, limit }) =>
        organizationsService.searchOrganizations(search, page, limit).pipe(
          take(1),
          map(({ organizations, pagination }) => {
            return OrganizationsAPIActions.searchOrganizationsSuccess({
              organizations,
              pagination,
            });
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);
export const createOrganization$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.createOrganization),
      exhaustMap(({ organization }) =>
        organizationsService.createOrganization(organization).pipe(
          take(1),
          map(({ organization }) => {
            return OrganizationsAPIActions.createOrganizationSuccess({
              organization,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              OrganizationsAPIActions.createOrganizationFailure({
                message,
              }),
            );
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateOrganization$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.updateOrganization),
      exhaustMap(({ organization }) =>
        organizationsService.updateOrganization(organization).pipe(
          take(1),
          map(({ organization }) => {
            return OrganizationsAPIActions.updateOrganizationSuccess({
              organization,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              OrganizationsAPIActions.updateOrganizationFailure({
                message,
              }),
            );
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const removeOrganization$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.removeOrganization),
      exhaustMap(({ id }) =>
        organizationsService.removeOrganization(id).pipe(
          take(1),
          map(({ organizationId, message }) => {
            return OrganizationsAPIActions.removeOrganizationSuccess({
              organizationId,
              message,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              OrganizationsAPIActions.removeOrganizationFailure({
                message,
              }),
            );
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateOrganizationsAvatars$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.updateOrganizationAvatar),
      exhaustMap(({ organizationId, avatar }) =>
        organizationsService
          .updateOrganizationsAvatars(organizationId, avatar)
          .pipe(
            take(1),
            map(({ organizationId, avatars }) => {
              return OrganizationsAPIActions.updateOrganizationAvatarSuccess({
                organizationId,
                avatars,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                OrganizationsAPIActions.updateOrganizationAvatarFailure({
                  message,
                }),
              );
            }),
          ),
      ),
    );
  },
  { functional: true },
);

export const removeOrganizationAvatar$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.removeOrganizationAvatar),
      exhaustMap(({ organizationId, avatar }) =>
        organizationsService
          .removeOrganizationAvatar(organizationId, avatar)
          .pipe(
            take(1),
            map(({ organizationId, avatar }) => {
              return OrganizationsAPIActions.removeOrganizationAvatarSuccess({
                organizationId,
                avatar,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                OrganizationsAPIActions.removeOrganizationAvatarFailure({
                  message,
                }),
              );
            }),
          ),
      ),
    );
  },
  { functional: true },
);

export const updateOrganizationStatus$ = createEffect(
  (
    actions$ = inject(Actions),
    organizationsService = inject(OrganizationsService),
  ) => {
    return actions$.pipe(
      ofType(OrganizationsAppActions.updateOrganizationStatus),
      exhaustMap(({ organizationId, isActive }) =>
        organizationsService
          .updateOrganizationStatus({ organizationId, isActive })
          .pipe(
            take(1),
            map(({ organization }) =>
              OrganizationsAPIActions.updateOrganizationStatusSuccess({
                organization,
              }),
            ),
            catchError((error) => {
              const message = handleError(error);
              return of(
                OrganizationsAPIActions.updateOrganizationStatusFailure({
                  message,
                }),
              );
            }),
            share(),
          ),
      ),
    );
  },
  {
    functional: true,
  },
);

import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { PickListModule } from 'primeng/picklist';

@Component({
  selector: 'cap-dual-pick-list',
  standalone: true,
  imports: [PickListModule],
  templateUrl: './dual-pick-list.component.html',
  styleUrl: './dual-pick-list.component.scss',
})
export class DualPickListComponent {
  @Input() sourceItems!: any[];

  @Input() targetItems!: any[];

  @Output() change: EventEmitter<{ source: any[]; target: any[] }> =
    new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  onTransfer(event: any) {
    this.change.emit({
      source: this.sourceItems,
      target: this.targetItems,
    });
    this.cdr.markForCheck();
  }
}

import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { TabsComponent } from '../shared/tabs/tabs.component';
import { ITab } from '../core/models/tab.model';

@Component({
  selector: 'cap-administration',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    SidebarComponent,
    TabsComponent,
  ],
  templateUrl: './administration-page.component.html',
  styleUrl: './administration-page.component.scss',
})
export class AdministrationPageComponent implements OnInit {
  private readonly router = inject(Router);

  tabs: ITab[] = [
    {
      name: 'Profiles',
      route: '/administration/profiles',
      current: true,
      icon: 'subheader',
    },
    {
      name: 'Categories',
      route: '/administration/categories',
      current: false,
      icon: 'category',
    },
    {
      name: 'Services',
      route: '/administration/security-services',
      current: false,
      icon: 'health_and_safety',
    },
    {
      name: 'Security Controls',
      route: '/administration/controls',
      current: false,
      icon: 'security',
    },
    {
      name: 'Settings',
      route: '/administration/settings',
      current: false,
      icon: 'settings',
    },
  ];
  ngOnInit() {
    this.router.navigate([this.tabs[0].route]);
  }

  onTabChange(route: string) {
    this.router.navigate([route]);
  }
}

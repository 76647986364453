import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-edit-checkbox-cell',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './edit-checkbox-cell.component.html',
  styleUrl: './edit-checkbox-cell.component.scss',
})
export class EditCheckboxCellComponent {
  @Input() isChecked: boolean = false;
  @Input() groupName: string = '';
  @Input() label: string = '';
  @Output() checkChange = new EventEmitter<boolean>();

  onCheckChange() {
    this.checkChange.emit(this.isChecked);
  }
}

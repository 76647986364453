<div class="flex items-center">
  <input
    class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
    [name]="groupName"
    type="checkbox"
    [(ngModel)]="isChecked"
    (change)="onCheckChange()"
  />
  <label
    class="ml-3 block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
    [for]="groupName"
    >{{ label }}</label
  >
</div>

import { createSelector } from '@ngrx/store';

import { CategoriesState, selectAll as selectAllCategories, selectCategoriesState } from './categories.state';
import { IAvatar } from 'src/app/core/models/user.model';
import { ICategory } from 'src/app/core/models/categories.model';

function selectCurrentAvatar(category: ICategory): IAvatar | undefined {
  return category.avatars.find((avatar) => avatar.isCurrent);
}

export const selectPagination = createSelector(
  selectCategoriesState,
  (state: CategoriesState) => state.pagination
)

export const selectCategoriesWithCurrentAvatar = createSelector(
  selectAllCategories,
  (categories) =>
    categories.map((cat) => ({
      ...cat,
      avatar: selectCurrentAvatar(cat) || null,
    }))
);

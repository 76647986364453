import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, tap } from 'rxjs';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';

@Injectable()
export class AccountProfileFormStore
  extends ComponentStore<{
    isLoading: boolean;
    errorMessage: string | null;
  }>
  implements OnDestroy
{
  private actionsSubs: Subscription[] = [];

  constructor(private readonly actions$: Actions) {
    super({ isLoading: false, errorMessage: null });

    this.actionsSubs.push(
      this.appActionsSub,
      this.apiSuccessActionsSub,
      this.apiFailureActionsSub
    );
  }

  isLoading$ = this.select((state) => state.isLoading);
  errorMessage$ = this.select((state) => state.errorMessage);

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(AuthAppActions.updateUserAccount),
      tap(() => this.patchState({ isLoading: true }))
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.updateUserAccountSuccess),
      tap(() => this.patchState({ isLoading: false, errorMessage: null }))
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.updateUserAccountFailure),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message })
      )
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.actionsSubs.forEach((sub) => sub.unsubscribe());
  }
}

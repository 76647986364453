import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { IServiceSource } from 'src/app/core/models/services.model';
import { ServicesAPIActions } from './services.actions';
import { Pagination } from './services.state';

export interface ServiceSourcesState extends EntityState<IServiceSource> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<IServiceSource> =
  createEntityAdapter<IServiceSource>({
    selectId: (serviceSource: IServiceSource) => serviceSource._id,
  });

export const initialState: ServiceSourcesState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  },
});

const serviceSourcesReducer = createReducer(
  initialState,
  on(
    ServicesAPIActions.getServiceSourcesSuccess,
    (state, { serviceSources, pagination }) =>
      adapter.setAll(serviceSources, {
        ...state,
        errorMessage: null,
        pagination,
      }),
  ),
  on(ServicesAPIActions.getServiceSourcesFailure, (state, { message }) => ({
    ...adapter.removeAll(state),
    errorMessage: message,
  })),
  on(
    ServicesAPIActions.getServiceSourceByIdSuccess,
    (state, { serviceSource }) => adapter.upsertOne(serviceSource, state),
  ),
  on(ServicesAPIActions.getServiceSourceByIdFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(
    ServicesAPIActions.createServiceSourceSuccess,
    (state, { serviceSource }) => adapter.addOne(serviceSource, state),
  ),
  on(ServicesAPIActions.createServiceSourceFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(
    ServicesAPIActions.updateServiceSourceSuccess,
    (state, { serviceSource }) => adapter.upsertOne(serviceSource, state),
  ),
  on(ServicesAPIActions.updateServiceSourceFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(
    ServicesAPIActions.removeServiceSourceSuccess,
    (state, { serviceSourceId, message }) =>
      adapter.removeOne(serviceSourceId, state),
  ),
  on(ServicesAPIActions.removeServiceSourceFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
);

export const serviceSourcesFeature = createFeature({
  name: 'serviceSources',
  reducer: serviceSourcesReducer,
  extraSelectors: ({ selectServiceSourcesState, selectEntities }) => ({
    ...adapter.getSelectors(selectServiceSourcesState),
    selectServiceSourceById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
  }),
});

export const {
  name,
  reducer,
  selectServiceSourcesState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectServiceSourceById,
} = serviceSourcesFeature;

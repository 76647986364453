import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { SettingsApiActions, SettingsAppActions } from './settings.actions';
import { catchError, map, of, share, switchMap } from 'rxjs';
import { SettingsService } from '../../core/services/settings.service';

export const getSettings = createEffect(
  (actions$ = inject(Actions), settingsService = inject(SettingsService)) => {
    return actions$.pipe(
      ofType(SettingsAppActions.getSettings),
      switchMap(() => {
        return settingsService.getSettings().pipe(
          map(({ settings }) =>
            SettingsApiActions.getSettingsSuccess({ settings }),
          ),
          catchError((error) =>
            of(
              SettingsApiActions.getSettingsFailure({ message: error.message }),
            ),
          ),
        );
      }),
      share(),
    );
  },
  { functional: true },
);

export const updateSettings = createEffect(
  (actions$ = inject(Actions), settingsService = inject(SettingsService)) => {
    return actions$.pipe(
      ofType(SettingsAppActions.updateSettings),
      switchMap(({ settings }) => {
        return settingsService.updateSettings(settings).pipe(
          map(() => SettingsApiActions.updateSettingsSuccess({ settings })),
          catchError((error) =>
            of(
              SettingsApiActions.updateSettingsFailure({
                message: error.message,
              }),
            ),
          ),
        );
      }),
      share(),
    );
  },
  { functional: true },
);

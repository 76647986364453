import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { IPricingConfig, IUpdatePricingConfigPrice } from "src/app/core/models/pricing-config.model";

export const PricingConfigAppActions = createActionGroup({
    source: 'Pricing Config',
    events: {
        'Get All Pricing Configs': emptyProps(),
        'Update Pricing Config Price': props<{ prices: IUpdatePricingConfigPrice[] }>(),
    }
})

export const PricingConfigAPIActions = createActionGroup({
    source: 'Pricing Config API',
    events: {
        'Get All Pricing Configs Success': props<{ pricingConfigs: IPricingConfig[] }>(),
        'Get All Pricing Configs Failure': props<{ message: string }>(),
        'Update Pricing Config Price Success': props<{ pricingConfigs: IPricingConfig[] }>(),
        'Update Pricing Config Price Failure': props<{ message: string }>(),
    }
})
import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil, tap } from 'rxjs';
import { ISecurityControl } from 'src/app/core/models/security-controls.model';
import {
  ActionItemsAPIActions,
  ActionItemsAppActions,
} from 'src/app/state/action-items/action-items.actions';
import {
  SecurityControlsAPIActions,
  SecurityControlsAppActions,
} from 'src/app/state/security-controls/security-controls.actions';

@Injectable()
export class ActionItemControlsModalStore
  extends ComponentStore<{
    isLoading: boolean;
    errorMessage: string | null;
    controls: ISecurityControl[];
  }>
  implements OnDestroy
{
  subDestroy$ = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({ isLoading: false, errorMessage: null, controls: [] });
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly controls$ = this.select((state) => state.controls);

  readonly setControls = this.updater(
    (state, controls: ISecurityControl[]) => ({
      ...state,
      controls,
    }),
  );

  readonly resetControls = this.updater((state) => ({
    ...state,
    controls: [],
  }));

  private readonly appActionSub = this.actions$
    .pipe(
      ofType(
        ActionItemsAppActions.updateActionItemControls,
        SecurityControlsAppActions.getSecurityControlsByStandardId,
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private readonly apiSuccessActionSub = this.actions$
    .pipe(
      ofType(
        ActionItemsAPIActions.updateActionItemControlsSuccess,
        SecurityControlsAPIActions.getSecurityControlsByStandardIdSuccess,
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private readonly apiFailureActionSub = this.actions$.pipe(
    ofType(
      ActionItemsAPIActions.updateActionItemControlsFailure,
      SecurityControlsAPIActions.getSecurityControlsByStandardIdFailure,
    ),
  );
}

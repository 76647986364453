<cap-sidebar>
  <ng-container class="h-dvh">
    <div class="flex flex-col h-full">
      @if (!showOrgDash) {
        <div class="flex flex-col h-full">
          <cap-dashboard-page-assessment></cap-dashboard-page-assessment>
        </div>
      } @else {
        <cap-tabs [tabs]="tabs" (tabChange)="onTabChange($event)"></cap-tabs>
        <ng-container class="h-full">
          <ng-container class="h-full flex flex-col mb-3">
            <router-outlet></router-outlet>
          </ng-container>
        </ng-container>
      }
    </div>
  </ng-container>
</cap-sidebar>

import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControlName,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { PasswordControlMessageComponent } from '../password-control-message/password-control-message.component';
import { Store } from '@ngrx/store';
import { selectAllSettings } from '../../state/settings/settings.selectors';
import { map } from 'rxjs';

@Component({
  selector: 'cap-password-control-message-container',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PasswordControlMessageComponent],
  templateUrl: './password-control-message-container.component.html',
  styleUrl: './password-control-message-container.component.scss',
})
export class PasswordControlMessageContainerComponent {
  @Input() form!: FormGroup;
  @Input() control!: string;
  private store = inject(Store);
  protected minPasswordLength$ = this.store
    .select(selectAllSettings)
    .pipe(map((settings) => settings.minimumPasswordLength));
}

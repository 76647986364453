// Angular imports
import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';

// Third-party library imports
import { Store } from '@ngrx/store';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  catchError,
  combineLatest,
  debounceTime,
  filter,
  finalize,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

// PrimeNG imports
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { KnobModule } from 'primeng/knob';
import { CarouselModule } from 'primeng/carousel';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { OverlayModule } from 'primeng/overlay';
import { DropdownModule } from 'primeng/dropdown';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

// Application-specific imports
import { IAssessment } from '../../core/models/assessments.model';
import { ICategory } from '../../core/models/categories.model';
import { AssessmentsAppActions } from '../../state/assessments/assessments.actions';
import { QuestionsService } from '../../core/services/questions.service';
import { DashboardPageStore } from '../dashboard-page.store';
import {
  convertUserRoleName,
  convertWeight,
} from '../../utils/entity-formatting.util';
import { IQuestion } from '../../core/models/questions.model';
import { AssessmentPageStore } from '../../assessment-page/assessment-page.store';
import { IUser } from '../../core/models/user.model';
import { selectUser } from '../../state/auth/auth.state';
import { SharedAssessmentScrollerComponent } from '../../shared/shared-assessment-scroller/shared-assessment-scroller.component';
import { SharedHeaderDataService } from '../../core/services/shared-header-data.service';
import { ActionItemsAppActions } from '../../state/action-items/action-items.actions';

// Other imports
import 'chartjs-adapter-date-fns';
@Component({
  selector: 'cap-dashboardp-page-list',
  standalone: true,
  imports: [
    DataViewModule,
    NgClass,
    TagModule,
    DividerModule,
    ScrollPanelModule,
    KnobModule,
    FormsModule,
    ChartModule,
    TableModule,
    CarouselModule,
    NgIf,
    TabViewModule,
    CheckboxModule,
    DialogModule,
    ProgressSpinnerModule,
    SharedAssessmentScrollerComponent,
    OverlayModule,
    DropdownModule,
  ],
  templateUrl: './dashboard-page-list.component.html',
  styleUrl: './dashboard-page-list.component.scss',
})
export class DashboardPageListComponent
  implements OnInit, OnChanges, OnDestroy
{
  private store = inject(Store);
  private questionsService = inject(QuestionsService);
  private destroy$ = new Subject<void>();
  private dashboardPageStore = inject(DashboardPageStore);
  private assessmentPageStore = inject(AssessmentPageStore);
  private cdRef = inject(ChangeDetectorRef);
  private currentUser: IUser | undefined;
  private sharedHeaderDataService = inject(SharedHeaderDataService);
  protected showStats = false;
  private fullScoreHistory: any[] = [{}];
  private categoryChange$ = new Subject<{
    assessment: IAssessment;
    category: ICategory;
  }>();
  protected showGraphs = signal(true);
  @Input() entity: {
    type: 'individual' | 'organization';
    entityId: string;
    profileId: string;
  } | null = null;

  currentSelectedCategory: any;
  displayConfirmation: boolean = false;
  selectedProduct: any;
  showUncompletedItems: boolean = false;
  activeIndexTab: number = 1;
  selectedActionItems: any[] = [];
  numberOfDataPoints = signal(-20);
  dataPointOptions: any[] = [
    {
      name: '20',
      value: 20,
    },
    {
      name: '50',
      value: 50,
    },
    {
      name: '100',
      value: 100,
    },
    {
      name: 'All',
      value: 0,
    },
  ];

  currentAssessmentStats: {
    totalQuestions: number;
    totalActionItems: number;
    totalCompletedActionItems: number;
    actionItemsGraphData: any;
    questionsGraphData: any;
    unCompletedItemsList: any;
    completedItemsList: any;
    scoreProgressionData: any;
    scoreHistory: any;
    questionsWithId: any;
  } = {} as any;

  options: any = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--text-color',
          ),
          font: {
            size: 16
          }
        },
      },
      datalabels: {
        color: 'white',
        display: true,
        font: {
          size: 16
        },
        formatter: (value: any, context: any) => {
          return value;
        },
      },
    },
  };
  scoreProgressionChartOptions: any = {
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 99,
        title: {
          display: true,
          text: 'Score (%)',
          font: {
            size: 16
          }
        },
        ticks: {
          font: {
            size: 16
          }
        }
      },
      x: {
        ticks: {
          font: {
            size: 16
          }
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 16
          }
        }
      },
      datalabels: {
        color: 'black',
        display: true,
        font: {
          size: 16
        },
        formatter: (value: any, context: any) => {
          return '';
        },
      },
      tooltip: {
        titleFont: {
          size: 16
        },
        bodyFont: {
          size: 16
        },
        callbacks: {
          label: (context: { dataIndex: any }) => {
            const dataIndex = context.dataIndex;
            const scoreHistoryEntry =
              this.currentAssessmentStats.scoreHistory[dataIndex];
            return `${scoreHistoryEntry.userName}`;
          },
        },
      },
    },
  };
  selectedLabel: 'Completed' | 'Remaining' = 'Remaining';
  hoveredActionItem: any = null;
  hoveredQuestion: string = '';
  protected isTableLoading: boolean = false;

  ngOnInit(): void {
    this.store
      .select(selectUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) this.currentUser = user;
      });
    
    combineLatest([
      this.sharedHeaderDataService.selectedCategory$,
      this.sharedHeaderDataService.selectedEntity$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([category, entity]) => !!category && !!entity),
        map(([category, entity]) => ({
          assessment: category.assessment,
          category: category.category,
        })),
        tap((assessmentCategory) => {
          if (this.currentSelectedCategory?.category._id !== assessmentCategory.category._id) {
            this.showStats = false;
          }
        }),
      )
      .subscribe((assessmentCategory) =>
        this.categoryChange$.next(assessmentCategory),
      );

    this.categoryChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        switchMap((assessmentCategory) => {
          return this.extractAssessmentCategoryInfo(assessmentCategory).pipe(
            finalize(() => {}),
          );
        }),
      )
      .subscribe(() => {
        this.showStats = true;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entity'] && !changes['entity'].isFirstChange()) {
    }
  }

  onHoverActionItem(actionItem: any) {
    this.hoveredActionItem = actionItem;
    this.hoveredQuestion = this.currentAssessmentStats.questionsWithId.filter(
      (question: any) => question[actionItem.questionId],
    )[0][actionItem.questionId].questionText;
  }

  onLeaveActionItem() {
    this.hoveredActionItem = null;
    this.hoveredQuestion = '';
  }

  extractAssessmentCategoryInfo(assessmentCategory: {
    assessment: IAssessment;
    category: ICategory;
  }): Observable<void> {
    this.dashboardPageStore.toggleAssessmentStats(false);
    this.assessmentPageStore.setSelectedAssessmentCategory(assessmentCategory);
    this.currentSelectedCategory = assessmentCategory;

    return this.questionsService
      .getQuestionsWithOptionsAndActions(assessmentCategory.category._id)
      .pipe(
        debounceTime(500),
        tap((questions: any) => {
          this.showUncompletedItems = false;
          let totalQuestions = 0;
          let totalActionItems = 0;
          let totalCompletedActionItems = 0;
          let questionsWithId: { [key: string]: IQuestion }[] = [];
          let unCompletedItemsList: any[] = [];
          let completedItemsList: any[] = [];

          questionsWithId = questions.questions.map((question: any) => ({
            [question._id]: question,
          }));
          totalQuestions = questions.questions.length;
          totalActionItems = questions.questions.reduce(
            (total: number, question: any) =>
              total + question.actionItems.length,
            0,
          );
          totalCompletedActionItems =
            assessmentCategory.assessment.questionsResponses.reduce(
              (total: number, response: any) =>
                total + response.actionItemsIds.length,
              0,
            );

          // Extracting uncompleted and completed action items
          questions.questions.forEach((question: any) => {
            question.actionItems.forEach((actionItem: any) => {
              if (
                assessmentCategory.assessment.questionsResponses.some(
                  (response: any) =>
                    response.actionItemsIds.includes(actionItem._id),
                )
              ) {
                completedItemsList.push(actionItem);
              } else {
                unCompletedItemsList.push(actionItem);
              }
            });
          });
          this.fullScoreHistory = assessmentCategory.assessment.scoreHistory;
          const scoreHistory =
            assessmentCategory.assessment.scoreHistory.slice(
              this.numberOfDataPoints(),
            ) || [];
          const scoreColors = scoreHistory.map((entry) =>
            this.getScoreColor(entry.score),
          );
          const scoreProgressionData = {
            labels: scoreHistory.map((entry: any) =>
              new Date(entry.timestamp).toLocaleDateString(),
            ),
            datasets: [
              {
                label: 'History',
                data: scoreHistory.map((entry: any) => entry.score),
                fill: false,
                borderColor: '#4bc0c0',
                pointBackgroundColor: scoreColors,
                pointBorderColor: scoreColors,
              },
            ],
          };

          this.currentAssessmentStats = {
            totalQuestions: totalQuestions,
            totalActionItems: totalActionItems,
            totalCompletedActionItems: totalCompletedActionItems,
            actionItemsGraphData: {
              labels: ['Completed', 'Remaining'],
              datasets: [
                {
                  data: [
                    totalCompletedActionItems,
                    totalActionItems - totalCompletedActionItems,
                  ],
                  backgroundColor: ['#7300ff', '#a2a19f'],
                },
              ],
            },
            questionsGraphData: {
              labels: ['Answered', 'Remaining'],
              datasets: [
                {
                  data: [
                    assessmentCategory.assessment.questionsResponses.filter(
                      (response: any) => response.optionId !== null,
                    ).length,
                    totalQuestions -
                      assessmentCategory.assessment.questionsResponses.filter(
                        (response: any) => response.optionId !== null,
                      ).length,
                  ],
                  backgroundColor: ['#0a1688', '#939191'],
                },
              ],
            },
            scoreProgressionData: scoreProgressionData,
            scoreHistory: scoreHistory,
            unCompletedItemsList: unCompletedItemsList,
            completedItemsList: completedItemsList,
            questionsWithId: questionsWithId,
          };
          this.showUncompletedItems = unCompletedItemsList.length > 0;
          if (this.activeIndexTab === 0) {
            this.selectedLabel = 'Completed';
            this.selectedActionItems = completedItemsList;
          } else {
            this.selectedLabel = 'Remaining';
            this.selectedActionItems = unCompletedItemsList;
          }
          this.cdRef.detectChanges(); // Ensure UI refresh
        }),
        map(() => void 0),
        catchError((error) => {
          console.error('Error extracting assessment category info:', error);
          this.dashboardPageStore.toggleAssessmentStats(false);
          return of(void 0);
        }),
      );
  }

  showConfirmationDialog(product: any) {
    this.selectedProduct = product;
    this.displayConfirmation = true;
  }

  confirmChangeStatus() {
    this.updateAssessmentStatus(this.selectedProduct);
    this.displayConfirmation = false;
  }

  cancelChangeStatus() {
    this.selectedProduct = null;
    this.displayConfirmation = false;
  }

  updateAssessmentStatus(product: any) {
    this.isTableLoading = true;

    // Get questions and calculate new score
    this.questionsService
      .getQuestionsWithOptionsAndActions(this.currentSelectedCategory.category._id)
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        switchMap((data: any) => {
          const questions = data.questions;
          
          // Update responses
          const updatedResponses = this.currentSelectedCategory.assessment.questionsResponses.map(
            (response: any) => ({
              questionId: response.questionId,
              optionId: response.optionId,
              textResponse: response.textResponse || '',
              actionItemsIds: response.questionId === product.questionId
                ? (response.actionItemsIds.includes(product._id)
                  ? response.actionItemsIds.filter((id: any) => id !== product._id)
                  : [...response.actionItemsIds, product._id])
                : response.actionItemsIds
            })
          );

          const newScore = this.calculateCategoryScore(questions, updatedResponses);
          
          // Create new score history entry
          const newScoreHistoryEntry = {
            timestamp: new Date(),
            score: newScore,
            userName: `${this.currentUser?.firstName} ${this.currentUser?.lastName}`,
            role: this.currentUser?.role.name,
          };

          // Update local state first
          this.currentSelectedCategory.assessment = {
            ...this.currentSelectedCategory.assessment,
            questionsResponses: updatedResponses,
            scoreHistory: [...this.currentSelectedCategory.assessment.scoreHistory, newScoreHistoryEntry],
            score: newScore
          };

          // Update action items lists
          if (this.selectedLabel === 'Completed') {
            this.currentAssessmentStats.completedItemsList = this.currentAssessmentStats.completedItemsList.filter(
              (item: any) => item._id !== product._id
            );
            this.currentAssessmentStats.unCompletedItemsList.push(product);
            this.selectedActionItems = this.currentAssessmentStats.completedItemsList;
          } else {
            this.currentAssessmentStats.unCompletedItemsList = this.currentAssessmentStats.unCompletedItemsList.filter(
              (item: any) => item._id !== product._id
            );
            this.currentAssessmentStats.completedItemsList.push(product);
            this.selectedActionItems = this.currentAssessmentStats.unCompletedItemsList;
          }

          // Update action items graph data
          this.currentAssessmentStats.actionItemsGraphData = {
            labels: ['Completed', 'Remaining'],
            datasets: [
              {
                data: [
                  this.currentAssessmentStats.completedItemsList.length,
                  this.currentAssessmentStats.unCompletedItemsList.length,
                ],
                backgroundColor: ['#7300ff', '#a2a19f'],
              },
            ],
          };

          // Update score progression data
          const scoreHistory = this.currentSelectedCategory.assessment.scoreHistory.slice(this.numberOfDataPoints());
          const scoreColors = scoreHistory.map((entry: any) => this.getScoreColor(entry.score));
          
          this.currentAssessmentStats.scoreProgressionData = {
            labels: scoreHistory.map((entry: any) => new Date(entry.timestamp).toLocaleDateString()),
            datasets: [
              {
                label: 'History',
                data: scoreHistory.map((entry: any) => entry.score),
                fill: false,
                borderColor: '#4bc0c0',
                pointBackgroundColor: scoreColors,
                pointBorderColor: scoreColors,
              },
            ],
          };

          // Dispatch update to store
          return of(this.store.dispatch(
            AssessmentsAppActions.updateAssessment({
              assessmentId: this.currentSelectedCategory.assessment._id,
              questionsResponses: updatedResponses,
            })
          )).pipe(
            map(() => ({ success: true }))
          );
        }),
        catchError(error => {
          this.isTableLoading = false;
          return throwError(() => error);
        })
      )
      .subscribe({
        next: () => {
          this.isTableLoading = false;
          this.cdRef.detectChanges();
        },
        error: (error: any) => {
          console.error('Error updating assessment:', error);
          this.isTableLoading = false;
        }
      });
  }
  private calculateCategoryScore(
    questions: any[],
    questionsResponses: any,
  ): number {
    // Create a map for O(1) question lookups
    const questionMap = new Map(questions.map(q => [q._id, q]));
    
    // Create a map of completed action items by question ID
    const completedActionsByQuestion = new Map();
    questionsResponses.forEach((response: any) => {
      completedActionsByQuestion.set(response.questionId, new Set(response.actionItemsIds));
    });
    
    let totalQuestionScores = 0;
    let maxPossibleScore = 0;
    
    // Single pass through questions
    for (const question of questions) {
      const totalActionWeightSum = question.actionItems.reduce(
        (sum: number, item: any) => sum + item.weight, 0
      );
      maxPossibleScore += question.weight * totalActionWeightSum;
      
      // Calculate completed action weight sum
      const completedActions = completedActionsByQuestion.get(question._id);
      if (completedActions) {
        const completedActionWeightSum = question.actionItems
          .filter((item: any) => completedActions.has(item._id))
          .reduce((sum: number, item: any) => sum + item.weight, 0);
        
        totalQuestionScores += question.weight * completedActionWeightSum;
      }
    }
    
    const categoryScore = maxPossibleScore > 0 ? (totalQuestionScores / maxPossibleScore) * 99 : 0;
    return Math.max(0, categoryScore);
  }

  private updateSelectedActionItems() {
    if (this.selectedLabel === 'Completed') {
      this.selectedActionItems = this.currentAssessmentStats.completedItemsList;
    } else {
      this.selectedActionItems =
        this.currentAssessmentStats.unCompletedItemsList;
    }
  }

  getScoreColor(assessmentScore: number) {
    if (assessmentScore === 0) {
      return '#8c908d';
    } else if (assessmentScore < 33) {
      return '#DC3444';
    } else if (assessmentScore < 66) {
      return '#FFC007';
    } else {
      return '#28A745';
    }
  }

  onDataSelectActionItem(event: any) {
    this.activeIndexTab = 1;
    const selectedIndex = event.element.index;
    this.selectedLabel =
      this.currentAssessmentStats.actionItemsGraphData.labels[selectedIndex];
    if (this.selectedLabel === 'Completed') {
      this.selectedActionItems = this.currentAssessmentStats.completedItemsList;
    } else {
      this.selectedActionItems =
        this.currentAssessmentStats.unCompletedItemsList;
    }
  }

  onSelectDataPoints(value: any) {
    this.numberOfDataPoints.set(value.value);
    this.updateChartData();
  }

  updateChartData() {
    if (!this.currentAssessmentStats || !this.fullScoreHistory || this.fullScoreHistory.length === 0) {
      return;
    }
    
    const limit = this.numberOfDataPoints();
    const scoreHistory = limit === 0
      ? this.fullScoreHistory
      : this.fullScoreHistory.slice(-limit);
    
    if (scoreHistory.length === 0) {
      return;
    }
    
    // Calculate score colors once
    const scoreColors = scoreHistory.map((entry: { score: number }) => 
      this.getScoreColor(entry.score)
    );
    
    // Create new chart data object
    this.currentAssessmentStats.scoreProgressionData = {
      labels: scoreHistory.map((entry: any) => 
        new Date(entry.timestamp).toLocaleDateString()
      ),
      datasets: [{
        label: 'History',
        data: scoreHistory.map((entry: any) => entry.score),
        fill: false,
        borderColor: '#4bc0c0',
        pointBackgroundColor: scoreColors,
        pointBorderColor: scoreColors,
      }],
    };
    
    // Use requestAnimationFrame to defer change detection to next frame
    requestAnimationFrame(() => this.cdRef.detectChanges());
  }

  toggleGraphs() {
    this.showGraphs.set(!this.showGraphs())
  }
  protected readonly convertUserRoleName = convertUserRoleName;

  protected readonly convertWeight = convertWeight;
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.categoryChange$.complete();
  }

  private calculateQuestionStats(questions: any[], assessmentCategory: any) {
    const totalQuestions = questions.length;
    const questionsWithId = questions.map((question: any) => ({
      [question._id]: question,
    }));
    
    // Create a Set of completed action item IDs for O(1) lookups
    const completedActionItemIds = new Set();
    assessmentCategory.assessment.questionsResponses.forEach((response: any) => {
      response.actionItemsIds.forEach((id: string) => completedActionItemIds.add(id));
    });
    
    // Process all action items in a single pass
    let totalActionItems = 0;
    const unCompletedItemsList: any[] = [];
    const completedItemsList: any[] = [];
    
    questions.forEach((question: any) => {
      totalActionItems += question.actionItems.length;
      
      question.actionItems.forEach((actionItem: any) => {
        if (completedActionItemIds.has(actionItem._id)) {
          completedItemsList.push(actionItem);
        } else {
          unCompletedItemsList.push(actionItem);
        }
      });
    });
    
    return {
      totalQuestions,
      totalActionItems,
      questionsWithId,
      completedActionItemIds,
      unCompletedItemsList,
      completedItemsList
    };
  }

  private processScoreHistory(assessmentCategory: any) {
    this.fullScoreHistory = assessmentCategory.assessment.scoreHistory || [];
    return this.numberOfDataPoints() === 0
      ? this.fullScoreHistory
      : this.fullScoreHistory.slice(this.numberOfDataPoints());
  }

  private updateAssessmentStats(
    totalQuestions: number,
    totalActionItems: number,
    totalCompletedActionItems: number,
    assessmentCategory: any,
    questionsWithId: any[],
    unCompletedItemsList: any[],
    completedItemsList: any[],
    scoreHistory: any[]
  ) {
    // Calculate score colors once
    const scoreColors = scoreHistory.map((entry) => this.getScoreColor(entry.score));
    
    // Create score progression data
    const scoreProgressionData = {
      labels: scoreHistory.map((entry: any) => new Date(entry.timestamp).toLocaleDateString()),
      datasets: [{
        label: 'History',
        data: scoreHistory.map((entry: any) => entry.score),
        fill: false,
        borderColor: '#4bc0c0',
        pointBackgroundColor: scoreColors,
        pointBorderColor: scoreColors,
      }],
    };
    
    // Update the stats object in a single operation
    this.currentAssessmentStats = {
      totalQuestions,
      totalActionItems,
      totalCompletedActionItems,
      actionItemsGraphData: {
        labels: ['Completed', 'Remaining'],
        datasets: [{
          data: [totalCompletedActionItems, totalActionItems - totalCompletedActionItems],
          backgroundColor: ['#7300ff', '#a2a19f'],
        }],
      },
      questionsGraphData: {
        labels: ['Answered', 'Remaining'],
        datasets: [{
          data: [
            assessmentCategory.assessment.questionsResponses.filter(
              (response: any) => response.optionId !== null
            ).length,
            totalQuestions - assessmentCategory.assessment.questionsResponses.filter(
              (response: any) => response.optionId !== null
            ).length,
          ],
          backgroundColor: ['#0a1688', '#939191'],
        }],
      },
      scoreProgressionData,
      scoreHistory,
      unCompletedItemsList,
      completedItemsList,
      questionsWithId,
    };
  }
}

Chart.register(ChartDataLabels);

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, tap } from 'rxjs';
import {
  IOrganization,
  IOrganizationBase,
} from 'src/app/core/models/organization.model';
import { IAvatar, IUser, IUserBase } from 'src/app/core/models/user.model';
import {
  OrganizationsAPIActions,
  OrganizationsAppActions,
} from 'src/app/state/organizations/organizations.actions';

interface IVerticalStepNavigationModalState {
  isLoading: boolean;
  currentStepIndex: number;
  errorMessage: string | null;
  organization: IOrganizationBase | IOrganization | null;
  organizationFormValidity: boolean;
  selectedAvatar: IAvatar | null;
  paymentCompleted: boolean;
  paymentIntentId: string | null;
  discountCode: string | null;
  fullyDiscounted: boolean;
  isAdminUser: boolean;
}

@Injectable()
export class VerticalStepNavigationModalStore
  extends ComponentStore<IVerticalStepNavigationModalState>
  implements OnDestroy
{
  private subs: Subscription[] = [];

  constructor(private readonly actions$: Actions) {
    super({
      isLoading: false,
      currentStepIndex: 0,
      errorMessage: null,
      organization: null,
      organizationFormValidity: false,
      selectedAvatar: null,
      paymentCompleted: false,
      paymentIntentId: null,
      discountCode: null,
      fullyDiscounted: false,
      isAdminUser: false,
    });

    this.subs.push(
      this.appActionsSub,
      this.apiSuccessActionsSub,
      this.apiFailureActionsSub,
    );
  }

  readonly currentStepIndex$ = this.select((state) => state.currentStepIndex);
  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly organization$ = this.select((state) => state.organization);
  readonly organizationFormValidity$ = this.select(
    (state) => state.organizationFormValidity,
  );
  readonly discountCode$ = this.select((state) => state.discountCode);
  readonly isAdminUser$ = this.select((state) => state.isAdminUser);
  readonly selectedAvatar$ = this.select((state) => state.selectedAvatar);

  readonly selectCurrentOrganizationAvatar$ = this.select(
    (state) => state.organization?.avatars?.find((a) => a.isCurrent) ?? null,
  );
  readonly selectOrganizationOldAvatars$ = this.select(
    (state) => state.organization?.avatars?.filter((a) => !a.isCurrent) ?? [],
  );

  readonly setCurrentStepIndex = this.updater(
    (state, newStepIndex: number) => ({
      ...state,
      currentStepIndex: newStepIndex,
    }),
  );

  readonly setErrorMessage = this.updater(
    (state, newErrorMessage: string | null) => ({
      ...state,
      errorMessage: newErrorMessage,
    }),
  );

  readonly setOrganization = this.updater(
    (state, newOrganization: IOrganizationBase | IOrganization) => ({
      ...state,
      organization: {
        ...state.organization,
        ...newOrganization,
      },
    }),
  );

  readonly setSelectedAvatar = this.updater(
    (
      state: IVerticalStepNavigationModalState,
      newSelectedAvatar: IAvatar | null,
    ) => {
      if (!newSelectedAvatar || !state.organization) {
        return state;
      }

      const avatars = state.organization.avatars || [];
      const updatedAvatars = avatars.map((avatar) => ({
        ...avatar,
        isCurrent: false,
      }));

      const existingAvatarIndex = avatars.findIndex(
        (a) => a.data === newSelectedAvatar.data,
      );

      if (existingAvatarIndex !== -1) {
        updatedAvatars[existingAvatarIndex] = {
          ...updatedAvatars[existingAvatarIndex],
          isCurrent: true,
        };
      } else {
        updatedAvatars.push({ ...newSelectedAvatar, isCurrent: true });
      }

      const newOrganization = {
        ...state.organization,
        avatars: updatedAvatars,
        // Ensure _id is explicitly included. If _id is somehow not present in state.organization, handle accordingly.
      };

      return {
        ...state,
        organization: newOrganization,
        selectedAvatar: newSelectedAvatar,
      };
    },
  );

  setCurrentAvatar = this.updater((state, newCurrentAvatar: IAvatar | null) => {
    if (!state.organization) {
      // Handle the case where organization is null or undefined
      return { ...state };
    }

    if (!newCurrentAvatar) {
      return {
        ...state,
        organization: {
          ...state.organization,
          avatars: state.organization.avatars.map((a) => ({
            ...a,
            isCurrent: false,
          })),
        },
      };
    }

    const avatarExists = state.organization.avatars.some(
      (a) => a.data === newCurrentAvatar.data,
    );
    let newAvatars;

    if (avatarExists) {
      newAvatars = state.organization.avatars.map((a) => ({
        ...a,
        isCurrent: a.data === newCurrentAvatar.data,
      }));
    } else {
      newAvatars = [
        ...state.organization.avatars,
        { ...newCurrentAvatar, isCurrent: true },
      ];
    }

    return {
      ...state,
      organization: {
        ...state.organization,
        avatars: newAvatars,
      },
    };
  });

  removeOrganizationAvatar = this.updater((state, avatarToRemove: IAvatar) => {
    if (!state.organization || !state.organization.avatars) {
      // If there is no organization or avatars, return the current state
      return state;
    }

    const filteredAvatars = state.organization.avatars.filter(
      (avatar) => avatar.data !== avatarToRemove.data,
    );

    return {
      ...state,
      organization: {
        ...state.organization,
        avatars: filteredAvatars,
      },
    };
  });

  readonly setOrganizationFormValidity = this.updater(
    (state, newOrganizationFormValidity: boolean) => ({
      ...state,
      organizationFormValidity: newOrganizationFormValidity,
    }),
  );

  readonly setParentOrganization = this.updater(
    (state, parentOrganizationId: string) => ({
      ...state,
      organization: {
        ...state.organization!,
        parentOrg: parentOrganizationId,
      },
    }),
  );

  readonly paymentCompleted$ = this.select((state) => state.paymentCompleted);
  readonly paymentIntentId$ = this.select((state) => state.paymentIntentId);

  readonly setPaymentCompleted = this.updater(
    (state, paymentCompleted: boolean) => ({
      ...state,
      paymentCompleted,
    })
  );

  readonly fullyDiscounted$ = this.select((state) => state.fullyDiscounted);

  readonly setFullyDiscounted = this.updater(
    (state, fullyDiscounted: boolean) => ({
      ...state,
      fullyDiscounted,
    })
  );

  readonly setPaymentIntentId = this.updater(
    (state, paymentIntentId: string | null) => ({
      ...state,
      paymentIntentId,
    })
  );

  readonly setDiscountCode = this.updater(
    (state, discountCode: string | null) => ({
      ...state,
      discountCode,
    })
  );

  readonly setIsAdminUser = this.updater((state, isAdmin: boolean) => ({
    ...state,
    isAdminUser: isAdmin
  }));

  readonly canFinalize$ = this.select(
    this.organization$,
    this.paymentCompleted$,
    this.fullyDiscounted$,
    this.select(state => state.isAdminUser),
    (organization, paymentCompleted, fullyDiscounted, isAdminUser) => {
      return organization && (paymentCompleted || fullyDiscounted || isAdminUser);
    }
  );

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(
        OrganizationsAppActions.createOrganization,
        OrganizationsAppActions.updateOrganization,
        OrganizationsAppActions.updateOrganizationAvatar,
        OrganizationsAppActions.removeOrganizationAvatar,
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(
        OrganizationsAPIActions.createOrganizationSuccess,
        OrganizationsAPIActions.updateOrganizationSuccess,
        OrganizationsAPIActions.updateOrganizationAvatarSuccess,
        OrganizationsAPIActions.removeOrganizationAvatarSuccess,
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(
        OrganizationsAPIActions.createOrganizationFailure,
        OrganizationsAPIActions.updateOrganizationFailure,
        OrganizationsAPIActions.updateOrganizationAvatarFailure,
        OrganizationsAPIActions.removeOrganizationAvatarFailure,
      ),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message }),
      ),
    )
    .subscribe();

  readonly resetState = this.updater((state) => ({
    ...state,
    isLoading: false,
    currentStepIndex: 0,
    errorMessage: null,
    organization: null,
    user: null,
    organizationFormValidity: false,
    userFormValidity: false,
    selectedAvatar: null,
  }));

  override ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, pipe } from 'rxjs';

import { environment } from 'src/environments/environment';
import {
  IQuestionBase,
  IQuestion,
  IQuestionOption,
} from '../models/questions.model';
import { Pagination } from 'src/app/state/categories/categories.state';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  private readonly http = inject(HttpClient);
  private questionsPath = 'questions';
  private optionsPath = 'options';

  getQuestions(): Observable<{
    questions: IQuestion[];
    pagination: Pagination;
  }> {
    return this.http.get<{ questions: IQuestion[]; pagination: Pagination }>(
      `${environment.apiUrl}/${this.questionsPath}`,
    );
  }

  getQuestionsById(questionId: string): Observable<{ question: IQuestion }> {
    return this.http.get<{ question: IQuestion }>(
      `${environment.apiUrl}/${this.questionsPath}/${questionId}`,
    );
  }

  createQuestion(question: IQuestionBase): Observable<{ question: IQuestion }> {
    return this.http.post<{ question: IQuestion }>(
      `${environment.apiUrl}/${this.questionsPath}`,
      question,
    );
  }

  updateQuestion(question: IQuestion): Observable<{ question: IQuestion }> {
    return this.http.put<{ question: IQuestion }>(
      `${environment.apiUrl}/${this.questionsPath}/${question._id}`,
      question,
    );
  }

  removeQuestion(
    questionId: string,
  ): Observable<{ questionId: string; message: string }> {
    return this.http.delete<{ questionId: string; message: string }>(
      `${environment.apiUrl}/${this.questionsPath}/${questionId}`,
    );
  }

  getQuestionsByCategoryId(
    categoryId: string,
    page: number,
    pageSize: number,
  ): Observable<{ questions: IQuestion[]; pagination: Pagination }> {
    return this.http.get<{ questions: IQuestion[]; pagination: Pagination }>(
      `${environment.apiUrl}/${this.questionsPath}/categories/${categoryId}?page=${page}&pageSize=${pageSize}`,
    );
  }

  updateQuestionSortOrder(
    questionId: string,
    previousIndex: number,
    currentIndex: number,
  ): Observable<{ message: string }> {
    return this.http.patch<{ message: string }>(
      `${environment.apiUrl}/${this.questionsPath}/${questionId}/sort`,
      { previousIndex, currentIndex },
    );
  }

  addOptionToQuestion(
    questionId: string,
    option: IQuestionOption,
  ): Observable<{ question: IQuestion }> {
    return this.http.post<{ question: IQuestion }>(
      `${environment.apiUrl}/${this.questionsPath}/${questionId}/${this.optionsPath}`,
      option,
    );
  }

  updateQuestionOption(
    questionId: string,
    option: IQuestionOption,
  ): Observable<{ question: IQuestion }> {
    return this.http.put<{ question: IQuestion }>(
      `${environment.apiUrl}/${this.questionsPath}/${questionId}/${this.optionsPath}/${option._id}`,
      option,
    );
  }

  removeQuestionOption(
    questionId: string,
    optionId: string,
  ): Observable<{ questionId: string; optionId: string; message: string }> {
    return this.http.delete<{
      questionId: string;
      optionId: string;
      message: string;
    }>(
      `${environment.apiUrl}/${this.questionsPath}/${questionId}/${this.optionsPath}/${optionId}`,
    );
  }

  getQuestionsWithOptionsAndActions(categoryId: string): any {
    return this.http.get(
      `${environment.apiUrl}/${this.questionsPath}/actions/${categoryId}`,
    );
  }
  uploadQuestionsCSV(categoryId: string, jsonData: any): Observable<{ questions: IQuestion[] }> {
    return this.http.post<{ questions: IQuestion[] }>(
      `${environment.apiUrl}/${this.questionsPath}/populate-questions-with-csv/${categoryId}`,
      {csvData: jsonData},
    );
  }
  
  downloadCategoryQuestionsCSV(categoryId: string): Observable<void> {
    return this.http.get(`${environment.apiUrl}/${this.questionsPath}/download-questions-csv/${categoryId}`, { responseType: 'blob' })
      .pipe(
        map((blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `questions_import.csv`;
          link.click();
          window.URL.revokeObjectURL(url);
        })
      );
  }
}

<nav aria-label="Progress">
  <ol role="list" class="overflow-hidden">
    @for(step of (steps$ | async); track step.id) {
    <li
      [@stepTransition]
      class="relative"
      [ngStyle]="{ 'padding-bottom': paddingClass }"
    >
      @if(step.status == 'completed') { @if((steps$ | async)!.indexOf(step) !==
      (steps$ | async)!.length - 1) {
      <div
        class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-600"
        aria-hidden="true"
      ></div>
      }

      <!-- Complete Step -->
      <button class="group relative flex items-start" disabled>
        <span class="flex h-9 items-center">
          <span
            class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 group-hover:bg-primary-800"
          >
            <svg
              class="h-5 w-5 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </span>
        <span class="ml-4 flex min-w-0 flex-col">
          <span class="text-sm font-medium">{{ step.title }}</span>
          <span class="text-sm text-gray-500">{{ step.description }}</span>
        </span>
      </button>
      }@else if (step.status == 'current') { @if((steps$ | async)!.indexOf(step)
      !== (steps$ | async)!.length - 1) {
      <div
        class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
        aria-hidden="true"
      ></div>
      }
      <!-- Current Step -->
      <button
        disabled
        class="group relative flex items-start"
        aria-current="step"
      >
        <span class="flex h-9 items-center" aria-hidden="true">
          <span
            class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-600 bg-white"
          >
            <span class="h-2.5 w-2.5 rounded-full bg-primary-600"></span>
          </span>
        </span>
        <span class="ml-4 flex min-w-0 flex-col">
          <span class="text-sm font-medium text-primary-600">{{
            step.title
          }}</span>
          <span class="text-sm text-gray-500">{{ step.description }}</span>
        </span>
      </button>
      } @else if (step.status == 'upcoming') { @if((steps$ |
      async)!.indexOf(step) !== (steps$ | async)!.length - 1) {
      <div
        class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
        aria-hidden="true"
      ></div>
      }

      <!-- Upcoming Step -->
      <button disabled class="group relative flex items-start">
        <span class="flex h-9 items-center" aria-hidden="true">
          <span
            class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400"
          >
            <span
              class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
            ></span>
          </span>
        </span>
        <span class="ml-4 flex min-w-0 flex-col">
          <span class="text-sm font-medium text-gray-500">{{
            step.title
          }}</span>
          <span class="text-sm text-gray-500">{{ step.description }}</span>
        </span>
      </button>
      }
    </li>
    }
  </ol>
</nav>

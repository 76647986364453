import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, map, tap } from 'rxjs';
import { AuthAPIActions, AuthAppActions } from '../state/auth/auth.actions';

@Injectable()
export class ResetPasswordPageStore
  extends ComponentStore<{
    formSubmissionSuccess: boolean;
    passwordFormVisibility: {
      np: boolean;
      cnp: boolean;
    };
    isLoading: boolean;
  }>
  implements OnDestroy
{
  private actionsSubscription: Subscription[] = [];

  constructor(private readonly actions$: Actions) {
    super({
      formSubmissionSuccess: false,
      passwordFormVisibility: { np: false, cnp: false },
      isLoading: false,
    });

    this.handleFormSubmissionSuccess();
    this.actionsSubscription.push(this.appActionsSub, this.apiActionSub);
  }

  readonly formSubmissionSuccess$ = this.select(
    (state) => state.formSubmissionSuccess
  );
  readonly npVisibility$ = this.select(
    (state) => state.passwordFormVisibility.np
  );
  readonly cnpVisibility$ = this.select(
    (state) => state.passwordFormVisibility.cnp
  );
  readonly isLoading$ = this.select((state) => state.isLoading);

  readonly setFormSubmissionSuccess = this.updater(
    (state, formSubmissionSuccess: boolean) => ({
      ...state,
      formSubmissionSuccess,
    })
  );

  readonly togglePasswordVisibility = this.updater(
    (state, type: 'np' | 'cnp') => ({
      ...state,
      passwordFormVisibility: {
        ...state.passwordFormVisibility,
        [type]: !state.passwordFormVisibility[type],
      },
    })
  );

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(AuthAppActions.resetPassword),
      tap(() => this.patchState({ isLoading: true }))
    )
    .subscribe();

  private readonly apiActionSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.resetPasswordSuccess,
        AuthAPIActions.resetPasswordFailure
      ),
      tap(() => this.patchState({ isLoading: false }))
    )
    .subscribe();

  handleFormSubmissionSuccess() {
    const subscription = this.actions$
      .pipe(
        ofType(AuthAPIActions.forgotPasswordSuccess),
        map(() => true)
      )
      .subscribe((formSubmissionSuccess) => {
        this.setFormSubmissionSuccess(formSubmissionSuccess);
      });
    this.actionsSubscription.push(subscription);
  }

  override ngOnDestroy(): void {
    if (this.actionsSubscription) {
      this.actionsSubscription.forEach((sub) => sub.unsubscribe());
    }
  }
}

import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import {
  ISecurityControlBase,
  ISecurityControl,
  IStandardBase,
  IStandard,
} from '../models/security-controls.model';
@Injectable({
  providedIn: 'root',
})
export class SecurityControlsService {
  private readonly http = inject(HttpClient);
  private securityControlsPath = 'security-controls';
  private standardsPath = 'standards';

  getSecurityControls(): Observable<{ securityControls: ISecurityControl[] }> {
    return this.http.get<{ securityControls: ISecurityControl[] }>(
      `${environment.apiUrl}/${this.securityControlsPath}`,
    );
  }

  getPaginatedSecurityControls(
    page: number,
    limit: number,
  ): Observable<{ securityControls: ISecurityControl[]; pagination: any }> {
    return this.http.get<{
      securityControls: ISecurityControl[];
      pagination: any;
    }>(
      `${environment.apiUrl}/${this.securityControlsPath}?page=${page}&limit=${limit}`,
    );
  }

  getSecurityControlById(
    securityControlId: string,
  ): Observable<{ securityControl: ISecurityControl }> {
    return this.http.get<{ securityControl: ISecurityControl }>(
      `${environment.apiUrl}/${this.securityControlsPath}/${securityControlId}`,
    );
  }

  createSecurityControl(
    securityControl: ISecurityControlBase,
  ): Observable<{ securityControl: ISecurityControl }> {
    return this.http.post<{ securityControl: ISecurityControl }>(
      `${environment.apiUrl}/${this.securityControlsPath}`,
      securityControl,
    );
  }

  updateSecurityControl(
    securityControl: any,
  ): Observable<{ securityControl: ISecurityControl }> {
    const { _id, ...securityControlData } = securityControl;
    return this.http.put<{ securityControl: ISecurityControl }>(
      `${environment.apiUrl}/${this.securityControlsPath}/${_id}`,
      securityControlData,
    );
  }

  removeSecurityContro(
    securityControlId: string,
  ): Observable<{ securityControlId: string; message: string }> {
    return this.http.delete<{ securityControlId: string; message: string }>(
      `${environment.apiUrl}/${this.securityControlsPath}/${securityControlId}`,
    );
  }

  getStandards(): Observable<{ standards: IStandard[] }> {
    return this.http.get<{ standards: IStandard[] }>(
      `${environment.apiUrl}/${this.standardsPath}`,
    );
  }

  getStandardsById(standardId: string): Observable<{ standard: IStandard }> {
    return this.http.get<{ standard: IStandard }>(
      `${environment.apiUrl}/${this.standardsPath}/${standardId}`,
    );
  }

  createStandard(standard: IStandardBase): Observable<{ standard: IStandard }> {
    return this.http.post<{ standard: IStandard }>(
      `${environment.apiUrl}/${this.standardsPath}`,
      standard,
    );
  }

  updateStandard(standard: IStandard): Observable<{ standard: IStandard }> {
    const { _id, ...standardData } = standard;
    return this.http.put<{ standard: IStandard }>(
      `${environment.apiUrl}/${this.standardsPath}/${_id}`,
      standardData,
    );
  }

  removeStandard(
    standardId: string,
  ): Observable<{ standardId: string; message: string }> {
    return this.http.delete<{ standardId: string; message: string }>(
      `${environment.apiUrl}/${this.standardsPath}/${standardId}`,
    );
  }

  getPaginatedStandards(
    page: number,
    limit: number,
  ): Observable<{ standards: IStandard[]; pagination: any }> {
    return this.http.get<{ standards: IStandard[]; pagination: any }>(
      `${environment.apiUrl}/${this.standardsPath}?page=${page}&limit=${limit}`,
    );
  }

  getSecurityControlsByStandardId(
    standardId: string,
  ): Observable<{ securityControls: ISecurityControl[] }> {
    return this.http.get<{ securityControls: ISecurityControl[] }>(
      `${environment.apiUrl}/${this.securityControlsPath}/${this.standardsPath}/${standardId}`,
    );
  }
}

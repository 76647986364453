<div class="feedback-list">
  <!-- Select all and delete controls -->
  <div class="flex justify-between mb-3">
    <div class="flex items-center gap-2">
      <input 
        type="checkbox"
        [(ngModel)]="selectAllCheckbox"
        (change)="toggleSelectAll()"
        class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500 cursor-pointer"
      >
      <label class="text-sm font-medium text-gray-700 dark:text-gray-200 cursor-pointer">Select All</label>
    </div>
    @if(selectedIds.size > 0) {
      <button 
        type="button"
        class="inline-flex items-center gap-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 transition-colors duration-200"
        (click)="deleteSelectedFeedbacks()"
      >
        <span class="pi pi-trash"></span>
        Delete Selected ({{selectedIds.size}})
      </button>
    }
  </div>

  @if((feedbacks$ | async)!.length <= 0) {
    <div class="flex justify-center items-center h-full">
      <p class="text-2xl font-bold text-gray-500">No feedbacks found</p>
    </div>
  } @else {
  @for (feedback of (feedbacks$ | async); track feedback._id) {
    <p-panel 
      [toggleable]="true"
      [collapsed]="true"
      toggler="header"
      [styleClass]="'mb-3 ' + (!feedback.isResolved ? 'unresolved-feedback' : '')"
    >
      <ng-template pTemplate="header">
        <div class="flex w-full align-items-center justify-content-between">
          <div class="flex items-center" (click)="$event.stopPropagation()">
            <input 
              type="checkbox"
              [checked]="selectedIds.has(feedback._id)"
              (change)="toggleSelection(feedback._id)"
              class="w-4 h-4 cursor-pointer"
            >
          </div>
          <div class="flex w-full justify-between gap-2">
            <div class="flex items-center gap-2">
                <p-avatar 
                [image]="getUserAvatar(feedback.user) || 'assets/default-avatar.png'" 
                size="large" 
                shape="circle" />
                <div class="flex flex-col">
                    <span class="font-bold">
                        {{ (feedback.user.firstName && feedback.user.lastName) ? (feedback.user.firstName + ' ' + feedback.user.lastName) : 'Anonymous' }}
                      </span>
                      <span>
                        {{ feedback.title}}
                      </span>
                </div>
            </div>
            <div class="flex items-center gap-2">
              <small class="text-500">{{ feedback.updatedAt | date:'short' }}</small>
              <div class="relative">
                <button 
                  (click)="toggleMenu(feedback._id)"
                  class="p-2 rounded-full hover:bg-gray-100"
                >
                  <span class="material-symbols-outlined">more_vert</span>
                </button>
                @if(activeMenuId === feedback._id) {
                <div 
                  class="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                >
                  <div class="py-1">
                    @for (item of getMenuItems(feedback); track item) {
                    <button
                      (click)="handleMenuAction(item.command); toggleMenu(feedback._id)"
                      class="w-full text-left px-4 py-2 text-sm hover:bg-gray-100 flex items-center gap-2"
                    >
                      <span class="material-symbols-outlined">{{ item.icon }}</span>
                      {{ item.label }}
                    </button>
                    }
                  </div>
                </div>
              }
              </div>
            </div>
          </div>
          <!-- <p-tag [value]="feedback.category" severity="info"></p-tag> -->
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <p class="m-0 line-height-3">
          {{ feedback.description }}
        </p>
      </ng-template>
    </p-panel>
  }
}
</div>

@if(totalPages) {
  <cap-pagination [current_page]="page" [totalPages]="totalPages" [totalItems]="totalCount" [pageSize]="pageSize" (pageChange)="onPageChange($event)"></cap-pagination>
}

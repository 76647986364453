<cap-sidebar>
  <div class="h-full">
    <div class="flex flex-col h-dvh">
      <cap-shared-assessment-header></cap-shared-assessment-header>
      @if (showCategoryScroller) {
        <cap-shared-assessment-scroller></cap-shared-assessment-scroller>
      }
      <div>
        @if (showAssessmentForm) {
          <cap-assessment-form
            [selectedAssessmentCategory]="selectedAssessmentCategory | async"
          ></cap-assessment-form>
        }
      </div>
    </div>
  </div>
</cap-sidebar>

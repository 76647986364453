import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';

import { ITab } from 'src/app/core/models/tab.model';
import { TabsComponent } from 'src/app/shared/tabs/tabs.component';

@Component({
  selector: 'cap-services-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    TabsComponent,
  ],
  templateUrl: './services-page.component.html',
  styleUrl: './services-page.component.scss',
})
export class ServicesPageComponent implements OnInit {
  private readonly router = inject(Router);

  tabs: ITab[] = [
    {
      name: 'Services',
      route: '/administration/security-services/services',
      current: true,
      icon: 'add_moderator',
    },
    {
      name: 'Service Sources',
      route: '/administration/security-services/sources',
      current: false,
      icon: 'policy',
    },
    {
      name: 'Service Vendors',
      route: '/administration/security-services/vendors',
      current: false,
      icon: 'local_police',
    },
  ];

  ngOnInit(): void {
    this.router.navigate([this.tabs[0].route]);
  }

  onTabChange(route: string) {
    this.router.navigate([route]);
  }
}

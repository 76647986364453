import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from '../../shared/table/table.component';
import { PaginationComponent } from '../../shared/pagination/pagination.component';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { TabsComponent } from '../../shared/tabs/tabs.component';
import { ITab } from '../../core/models/tab.model';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'cap-profiles-list',
  standalone: true,
  imports: [
    CommonModule,
    TableComponent,
    PaginationComponent,
    SpinnerComponent,
    TabsComponent,
    RouterOutlet,
  ],
  templateUrl: './profiles-list.component.html',
  styleUrl: './profiles-list.component.scss',
})
export class ProfilesListComponent implements OnInit {
  private readonly router = inject(Router);
  constructor() {}
  tabs: ITab[] = [
    {
      name: 'Individuals',
      route: 'administration/profiles/individuals',
      current: true,
      icon: 'person',
    },
    {
      name: 'Organizational',
      route: 'administration/profiles/organizational',
      current: false,
      icon: 'corporate_fare',
    },
  ];
  ngOnInit(): void {
    this.router.navigate([this.tabs[0].route]);
  }

  onTabChange(route: string) {
    this.router.navigate([route]);
  }
}

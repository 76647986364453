import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, of, take } from 'rxjs';
import { UsersAppActions } from '../../state/users/users.actions';
import { OrganizationsAppActions } from '../../state/organizations/organizations.actions';
import { CategoriesAppActions } from '../../state/categories/categories.actions';

@Injectable({
  providedIn: 'root',
})
export class SharedAssessmentHeaderService {
  private dataLoaded = false;

  constructor(private store: Store) {}
  loadDataIfNeeded(): Observable<boolean> {
    if (this.dataLoaded) {
      return of(true);
    }

    return new Observable<boolean>((observer) => {
      this.store.dispatch(UsersAppActions.getAllUsersAccounts());
      this.store.dispatch(OrganizationsAppActions.getOrganizations({}));
      this.store.dispatch(
        CategoriesAppActions.getCategories({ page: 1, pageSize: 10000 }),
      );

      // You might want to add some logic here to determine when all the data has been loaded
      // For simplicity, we're just setting it to true immediately
      this.dataLoaded = true;
      observer.next(true);
      observer.complete();
    }).pipe(
      take(1),
      catchError((error) => {
        console.error('Error loading data:', error);
        return of(false);
      }),
    );
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'cap-feedback',
  standalone: true,
  imports: [],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss'
})
export class FeedbackComponent {
  @Input() type: 'error' | 'success' | 'warning' | 'info' = 'info';
  @Input() message: string | null = '';
  @Input()description?: string = '';
  @Input() items?: string[] = [];
}

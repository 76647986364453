import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { ITab } from 'src/app/core/models/tab.model';

interface TabsState {
  tabs: ITab[];
}

@Injectable()
export class TabsStore extends ComponentStore<TabsState> {
  constructor() {
    super({
      tabs: [],
    });
  }

  readonly tabs$ = this.select((state) => state.tabs);

  readonly currentTab$ = this.select(
    this.tabs$,
    (tabs) => tabs.find((tab) => tab.current) || null
  );

  readonly setTabs = this.updater((state, tabs: ITab[]) => ({
    ...state,
    tabs,
  }));

  readonly setCurrentTab = this.updater((state, tab: ITab) => ({
    ...state,
    tabs: state.tabs.map((t) => ({
      ...t,
      current: t.name === tab.name ? true : false,
    })),
  }));

  // Method to update current tab based on URL
  setCurrentTabByUrl(routeUrl: string) {
    this.patchState((state) => {
      const currentUrl = routeUrl;
      return {
        ...state,
        tabs: state.tabs.map((tab) => ({
          ...tab,
          current: currentUrl.includes(tab.route),
        })),
      };
    });
  }
}

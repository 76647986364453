import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap, withLatestFrom } from 'rxjs';

import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { TwoFactorAuthVerificaitonComponent } from '../two-factor-auth-verificaiton/two-factor-auth-verificaiton.component';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { ValidateOtpModalStore } from './validate-otp-modal.store';
import { selectUserEmail } from 'src/app/state/auth/auth.selectors';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';
import { ModalLoaderComponent } from 'src/app/shared/modal-loader/modal-loader.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { FeedbackComponent } from '../../shared/feedback/feedback.component';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'cap-validate-otp-modal',
  standalone: true,
  providers: [ValidateOtpModalStore],
  templateUrl: './validate-otp-modal.component.html',
  styleUrl: './validate-otp-modal.component.scss',
  imports: [
    CommonModule,
    ModalContainerComponent,
    TwoFactorAuthVerificaitonComponent,
    ModalLoaderComponent,
    SpinnerComponent,
    FeedbackComponent,
  ],
})
export class ValidateOtpModalComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly componentStore = inject(ValidateOtpModalStore);

  private destroy$ = new Subject<void>();

  public readonly modalId = 'validate-otp';

  private readonly userEmail$ = this.store.select(selectUserEmail);

  public readonly loading$ = this.componentStore.isLoading$;
  public readonly isFormValid$ = this.componentStore.isFormValid$;
  public readonly formErrorMessage$ = this.componentStore.errorMessage$;

  ngOnInit(): void {
    this.store.dispatch(ModalActions.register({ id: this.modalId }));

    // Subscribe to form validation changes
    this.componentStore.token$
      .pipe(
        withLatestFrom(this.userEmail$),
        tap(([token, email]) => {
          if (token && email) {
            this.store.dispatch(
              AuthAppActions.validate2FAOTP({ email: email, token }),
            );
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.actions$.pipe(
      ofType(
        AuthAPIActions.reset2FAOTPSuccess,
        AuthAPIActions.reset2FAOTPFailure,
      ),
      tap(() => this.store.dispatch(ModalActions.close({ id: this.modalId }))),
    );
  }

  setTotpToken(token: string) {
    this.componentStore.setTwoAuthFormToken(token);
  }

  onReset2FAOTPClicked() {
    this.store.dispatch(ModalActions.close({ id: this.modalId }));
    this.store.dispatch(ModalActions.open({ id: 'reset-2fa-otp' }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.store.dispatch(ModalActions.unregister({ id: this.modalId }));
  }
}

import { inject } from '@angular/core';
import {
  HttpRequest,
  HttpEvent,
  HttpInterceptorFn,
  HttpHandlerFn,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';

import { selectAccessToken } from 'src/app/state/auth/auth.state';
import { AuthAppActions } from 'src/app/state/auth/auth.actions';
import { Router } from '@angular/router';

export const authTokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const store = inject(Store);

  const excludedPaths = [
    '/auth/login',
    '/auth/forgot-password',
    '/auth/reset-password',
    '/auth/set-password',
    '/auth/refresh-token',
    '/auth/otp/generate',
    '/auth/otp/verify',
    '/auth/otp/validate',
    '/auth/otp/reset',
    '/auth/otp/verify-reset',
  ];

  // Using switchMap to handle the asynchronous nature of the store
  return store.select(selectAccessToken).pipe(
    switchMap((token) => {
      // Check if the URL is not one of the excluded paths
      const shouldAttachToken = !excludedPaths.some((path) =>
        request.url.includes(path),
      );
      // console.debug('INTERCEPTOR WAS CALLED');
      // Only add the token to the headers if it exists and the request is not for login
      if (token && shouldAttachToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      return next(request);
    }),
    catchError((error: HttpErrorResponse) => {
      if (request.responseType === 'blob') {
        return throwError(() => error);
      }
      // Handle 401 Unauthorized errors by dispatching a logout action
      if (error.status === 401 && !request.url.endsWith('/auth/logout')) {
        // router.navigate(['auth/login']);
      }
      console.error('Error by interceptor:', error);
      return throwError(() => error);
    }),
  );
};

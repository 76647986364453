import { FormControl } from '@angular/forms';

export function passwordStrengthValidator(
  control: FormControl
): { [key: string]: any } | null {
  const value = control.value || '';
  let error: { [key: string]: any } = {}; // Initialize error as an empty object
  if (!value.match(/[A-Z]/)) {
    error = { ...error, uppercaseRequired: true };
  }
  if (!value.match(/[0-9]/)) {
    error = { ...error, digitRequired: true };
  }
  if (!value.match(/[\W_]/)) {
    error = { ...error, symbolRequired: true };
  }
  return error;
}

import { Component, OnInit, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PasswordControlMessageContainerComponent } from 'src/app/shared/password-control-message-container/password-control-message-container.component';
import { Store } from '@ngrx/store';
import { ChangePasswordStore } from './change-password.store';
import { passwordMatchValidator } from 'src/app/shared/validators/password-match.validator';
import { passwordStrengthValidator } from 'src/app/shared/validators/password-strength.validator';
import { AlertActions } from 'src/app/state/alert/alert.actions';
import { AuthAppActions } from 'src/app/state/auth/auth.actions';
import { FeedbackComponent } from '../../../shared/feedback/feedback.component';
import { selectAllSettings } from '../../../state/settings/settings.selectors';
import { Subject, takeUntil } from 'rxjs';

function differentFromCurrentPasswordValidator(
  currentPasswordControlName: string,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }

    const currentPasswordControl = control.parent.get(
      currentPasswordControlName,
    );

    if (!currentPasswordControl) {
      return { controlNotFound: true };
    }

    // Return null if current password control hasn't been touched yet
    if (!currentPasswordControl.value) {
      return null;
    }

    return control.value !== currentPasswordControl.value
      ? null
      : { sameAsCurrentPassword: true };
  };
}

@Component({
  selector: 'cap-change-password',
  standalone: true,
  providers: [ChangePasswordStore],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PasswordControlMessageContainerComponent,
    FeedbackComponent,
  ],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  passwordForm!: FormGroup;

  private fb = inject(FormBuilder);
  private store = inject(Store);
  private componentStore = inject(ChangePasswordStore);
  private minPasswordLength = 12;
  private destroy$ = new Subject<void>();
  public readonly loading$ = this.componentStore.isLoading$;
  public readonly errorMessage$ = this.componentStore.errorMessage$;
  isNpVisible$ = this.componentStore.npVisibility$;
  isCpVisible$ = this.componentStore.cpVisibility$;
  isCnpVisible$ = this.componentStore.cnpVisibility$;

  ngOnInit(): void {
    this.store
      .select(selectAllSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings) => {
        this.minPasswordLength = settings.minimumPasswordLength;
      });
    this.passwordForm = this.fb.group(
      {
        currentPassword: ['', [Validators.required, passwordStrengthValidator]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(this.minPasswordLength),
            passwordStrengthValidator,
            differentFromCurrentPasswordValidator('currentPassword'),
          ],
        ],
        confirmNewPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(this.minPasswordLength),
            passwordStrengthValidator,
          ],
        ],
      },
      {
        validators: passwordMatchValidator('newPassword', 'confirmNewPassword'),
      },
    );
  }

  togglePasswordVisibility(type: 'np' | 'cp' | 'cnp') {
    this.componentStore.togglePasswordVidibility(type);
  }

  onPasswordFormSubmit() {
    if (this.passwordForm.invalid) {
      this.store.dispatch(
        AlertActions.addAlert({
          alert: {
            type: 'warning',
            message: 'Please fill in all required fields',
          },
        }),
      );
      return;
    }

    this.store.dispatch(
      AuthAppActions.changePassword({
        ...this.passwordForm.value,
      }),
    );
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

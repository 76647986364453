<select
  [ngModel]="selectedOption"
  (ngModelChange)="onOptionChange($event)"
  class="block w-full pl-10 rounded-md border-0 bg-white/5 dark:bg-gray-800 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
>
<option value="" disabled selected>Select an option</option>
  @for (option of options; track $index) {
  <option [ngValue]="option.value">
    {{ option.label }}
  </option>
  }
</select>

import { inject, Injectable } from '@angular/core';
import { IDiscount } from '../models/discount.model';
import { ICreateDiscountCode } from '../models/discount.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  private http = inject(HttpClient);

  constructor() { }

  createDiscountCode(discountCode: ICreateDiscountCode): Observable<{discountCode: IDiscount}> {
    return this.http.post<{discountCode: IDiscount}>(`${environment.apiUrl}/pricing-config/discount-code`, discountCode);
  }

  getAllDiscountCodes(): Observable<{discountCodes: IDiscount[]}> {
    return this.http.get<{discountCodes: IDiscount[]}>(`${environment.apiUrl}/pricing-config/discount-code`);
  }

  deleteDiscountCode(id: string): Observable<{id: string}> {
    return this.http.delete<{id: string}>(`${environment.apiUrl}/pricing-config/discount-code/${id}`);
  }
}

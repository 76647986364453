<div class="w-full flex justify-between py-2 px-4 rounded-2xl bg-white dark:bg-slate-800 border-2 border-gray-200 dark:border-gray-700">
  <div class="flex items-center">
      <div class="flex items-center mb-2">
        <div class="w-12 h-12 rounded-full overflow-hidden mr-3 border-2 border-gray-300">
          <img
            [ngSrc]="currentAssessment?.data?.avatars?.slice(-1)[0]?.url || 'assets/images/default-avatar.jpeg'"
            width="48"
            height="48"
            alt="Entity Avatar"
            class="w-full h-full object-cover"
          >
        </div>
      </div>
    <h1 class="text-2xl font-bold dark:text-white">
      {{ currentAssessment === undefined ? "" : currentAssessment.label }}
    </h1>
  </div>
  @if (isAllowedToSelectOrgs) {
    <div>
      <div class="flex gap-3">
        <div
          class="relative"
          capClickOutsideDirective
          (clickOutside)="closeOrgSelection()"
        >
          <button
            type="button"
            (click)="toggleOrgSelection()"
            class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white bg-red-600 px-5 py-2 rounded-xl"
            aria-expanded="false"
          >
            <span>Select an Entity</span>
            <svg
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          @if (OrgSelection) {
            @if (islandingEntitySelector | async) {
              <div
                class="absolute z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"
              >
                <div
                  class="w-screen flex justify-center max-w-md flex-auto overflow-hidden rounded-3xl bg-white dark:bg-slate-800 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
                >
                  <p-progressSpinner class="w-20" animationDuration="1s">
                  </p-progressSpinner>
                </div>
              </div>
            } @else {
              <div
                class="absolute z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"
              >
                <div
                  class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white dark:bg-slate-800 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
                >
                  <div class="p-4">
                    <!--            organization-->
                    <p-tree
                      [value]="treeNodes"
                      class="w-full md:w-30rem"
                      [filter]="true"
                      filterPlaceholder="Search Entities"
                      scrollHeight="250px"
                      (onNodeSelect)="onNodeSelected($event)"
                      [selectionMode]="'single'"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="46"
                      [style]="{ 'max-height': '250px', overflow: 'auto' }"
                    >
                      <ng-template let-node pTemplate="individual">
                        <div class="flex gap-2">
                          <span class="material-symbols-outlined">
                            person
                          </span>
                          <span>{{ node.label }}</span>
                        </div>
                      </ng-template>
                      <ng-template let-node pTemplate="organization">
                        <div class="flex gap-2">
                          <span class="material-symbols-outlined">
                            apartment
                          </span>
                          <span>{{ node.label }}</span>
                        </div>
                      </ng-template>
                    </p-tree>
                  </div>
                </div>
              </div>
            }
          }
        </div>
        <p-button
          class="bg-red-600 py-2 px-4 border-white rounded-full text-white"
          (click)="showDialog()"
        >
          <span class="material-symbols-outlined"> account_tree </span>
        </p-button>
      </div>

      <p-dialog
        header="Entity Hierachy"
        [(visible)]="visible"
        [modal]="true"
        [style]="{ width: '80%' }"
      >
        @if (islandingEntitySelector | async) {
          <div class="w-full h-full flex justify-center items-center">
            <p-progressSpinner
              class="w-20"
              animationDuration="1s"
            ></p-progressSpinner>
          </div>
        } @else {
          <ng-template pTemplate="header">
            <div class="inline-flex align-items-center justify-content-center">
              <span class="font-bold white-space-nowrap"> Select Entity </span>
            </div>
          </ng-template>
          <div class="flex w-full h-full">
            <cap-entity-tree-visualization
              [data]="treeNodes"
              [isSysadmin]="isSysAdmin"
              (nodeSelected)="onNodeSelected($event)"
            ></cap-entity-tree-visualization>
          </div>
        }
      </p-dialog>
    </div>
  }
</div>

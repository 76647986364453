import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IAvatar } from 'src/app/core/models/user.model';

interface AvatarCellState {
  avatar: IAvatar | null;
}

@Injectable()
export class AvatarCellStore extends ComponentStore<AvatarCellState> {
  constructor() {
    super({
      avatar: null,
    });
  }

  readonly avatar$ = this.select((state) => state.avatar);

  readonly setAvatar = this.updater((state, avatar: IAvatar) => ({
    ...state,
    avatar,
  }));
}

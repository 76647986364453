import { createSelector } from '@ngrx/store';
import { selectSettingsState } from './settings.state';

export const selectLogoutTime = createSelector(
  selectSettingsState,
  (state) => state.logoutTime,
);

export const selectAllSettings = createSelector(
  selectSettingsState,
  (state) => ({
    logoutTime: state.logoutTime,
    minimumPasswordLength: state.minimumPasswordLength,
    maxLoginAttempts: state.maxLoginAttempts,
    windowMs: state.windowMs,
    blockDuration: state.blockDuration,
  }),
);

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IAuthResponse } from 'src/app/core/models/auth-response.model';
import { ILogin } from 'src/app/core/models/login.model';
import {
  IChangePassword,
  IForgotPassword,
  IResetPassword,
  ISetNewPassword,
} from 'src/app/core/models/password.modal';
import { IAvatar, IUser } from 'src/app/core/models/user.model';

export const AuthAppActions = createActionGroup({
  source: 'Auth App',
  events: {
    Login: props<ILogin>(),
    Logout: emptyProps(),

    'First Login Detected': props<{ user: IUser }>(),
    'Init Auth': emptyProps(),
    'Refresh Token': props<{ caller: 'initialize' | 'update' }>(),
    'Token Expired': emptyProps(),
    'Change Password': props<IChangePassword>(),
    'Set New Password': props<ISetNewPassword>(),
    'Forgot Password': props<IForgotPassword>(),
    'Reset Password': props<IResetPassword>(),
    'Handle Storage Event': props<{ event: StorageEvent }>(),
    'Update User Avatar': props<{ avatar: IAvatar }>(),
    'Remove User Avatar': props<{ avatar: IAvatar }>(),
    'Update User Account': props<{ user: IUser }>(),
    'Resend Email OTP': props<{ email: string }>(),
    'Generate 2FA OTP': props<{ email: string }>(),
    'Verify 2FA OTP': props<{ email: string; token: string }>(),
    'Validate 2FA OTP': props<{ email: string; token: string }>(),
    'Reset 2FA OTP': props<{ email: string }>(),
    'Verify Resetting 2FA OTP': props<{ token: string }>(),
    // 'Disable 2FA OTP': props<{ email: string }>(),
  },
});

export const AuthAPIActions = createActionGroup({
  source: 'Auth API',
  events: {
    'Login Success': props<{ user: IUser }>(),
    'Login Failure': props<{ message: string; remainingTime?: number }>(),
    'Logout Success': emptyProps(),
    'Logout Failure': props<{ message: string }>(),
    'Refresh Token Success': props<{
      access_token: string;
      token_expiration: number;
      user: IUser;
    }>(),
    'Refresh Token Failure': emptyProps(),
    'Change Password Success': props<{ message: string }>(),
    'Change Password Failure': props<{ message: string }>(),
    'Set New Password Success': props<{ user: IUser }>(),
    'Set New Password Failure': props<{ message: string }>(),
    'Forgot Password Success': emptyProps(),
    'Forgot Password Failure': props<{ message: string }>(),
    'Reset Password Success': emptyProps(),
    'Reset Password Failure': props<{ message: string }>(),
    'Update User Account Success': props<IUser>(),
    'Update User Account Failure': props<{ message: string }>(),
    'Update User Avatar Success': props<{ avatars: IAvatar[] }>(),
    'Update User Avatar Failure': props<{ message: string }>(),
    'Remove User Avatar Success': props<{ avatar: IAvatar }>(),
    'Remove User Avatar Failure': props<{ message: string }>(),
    'Resend Email OTP Success': emptyProps(),
    'Resend Email OTP Failure': props<{ message: string }>(),

    'Generate 2FA OTP Success': props<{
      otpAuthUrl: string;
      otpBase32: string;
    }>(),
    'Generate 2FA OTP Failure': props<{ message: string }>(),
    'Verify 2FA OTP Success': props<IAuthResponse>(),
    'Verify 2FA OTP Failure': props<{ message: string }>(),
    'Validate 2FA OTP Success': props<IAuthResponse>(),
    'Validate 2FA OTP Failure': props<{ message: string }>(),
    'Reset 2FA OTP Success': props<{ message: string }>(),
    'Reset 2FA OTP Failure': props<{ message: string }>(),
    'Verify Resetting 2FA OTP Success': props<{
      message: string;
      email: string;
      otpAuthUrl: string;
      otpBase32: string;
    }>(),
    'Verify Resetting 2FA OTP Failure': props<{ message: string }>(),
    // 'Disable 2FA OTP Success': emptyProps(),
    // 'Disable 2FA OTP Failure': props<{ message: string }>(),
  },
});

import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router, RouterLink } from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Observable, Subscription } from 'rxjs';

import { PasswordControlMessageContainerComponent } from '../shared/password-control-message-container/password-control-message-container.component';
import { passwordStrengthValidator } from '../shared/validators/password-strength.validator';
import { passwordMatchValidator } from '../shared/validators/password-match.validator';
import { selectTokenParam } from '../state/router/router.selectors';
import { ResetPasswordPageStore } from './reset-password-page.store';
import { selectIsAuthenticated } from '../state/auth/auth.state';
import { AlertActions } from '../state/alert/alert.actions';
import { AuthAppActions } from '../state/auth/auth.actions';

@Component({
  selector: 'cap-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    PasswordControlMessageContainerComponent,
  ],
  providers: [ResetPasswordPageStore],
  templateUrl: './reset-password-page.component.html',
  styleUrl: './reset-password-page.component.scss',
  animations: [
    trigger('fadeSlideInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('300ms ease-out'),
      ]),
      transition(':leave', [
        animate(
          '300ms ease-in',
          style({ opacity: 0, transform: 'translateY(100%)' })
        ),
      ]),
    ]),
    trigger('growAndCenterLogo', [
      state('normal', style({ transform: 'scale(1)', top: 'initial' })),
      state('centered', style({ transform: 'scale(2)', top: '50%' })),
      transition('normal => centered', animate('500ms ease-in')),
    ]),
  ],
})
export class ResetPasswordPageComponent implements OnInit, OnDestroy {
  public formError: string = '';
  public form!: FormGroup;
  private subs: Subscription[] = [];
  private token$!: Observable<string>;

  private fb = inject(FormBuilder);
  private store = inject(Store);
  private componentStore = inject(ResetPasswordPageStore);

  loading$ = this.componentStore.isLoading$;
  formSubmissionSuccess$ = this.componentStore.formSubmissionSuccess$;
  isNpVisible$ = this.componentStore.npVisibility$;
  isCnpVisible$ = this.componentStore.cnpVisibility$;

  ngOnInit(): void {
    this.token$ = this.store.select(selectTokenParam);
    this.form = this.fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            passwordStrengthValidator,
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            passwordStrengthValidator,
          ],
        ],
      },
      { validators: passwordMatchValidator('newPassword', 'confirmPassword') }
    );
  }

  onFormSubmit() {
    if (!this.form.valid) {
      this.store.dispatch(
        AlertActions.addAlert({
          alert: {
            type: 'warning',
            message: 'All fields are required',
          },
        })
      );
      return;
    }
    const sub = this.token$.subscribe((token) => {
      this.store.dispatch(
        AuthAppActions.resetPassword({ token, ...this.form.value })
      );
    });
    this.subs.push(sub);
  }

  togglePasswordVisibility(type: 'np' | 'cnp'): void {
    this.componentStore.togglePasswordVisibility(type);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

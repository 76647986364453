import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { IAvatar, IUser } from 'src/app/core/models/user.model';
import { IChangePassword } from 'src/app/core/models/password.modal';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService {
  private http: HttpClient = inject(HttpClient);

  updateAccount(user: IUser): Observable<IUser> {
    return this.http.put<IUser>(`${environment.apiUrl}/account/update`, user);
  }

  updateUserAvatars(avatar: IAvatar): Observable<{ avatars: IAvatar[] }> {
    return this.http.put<{ avatars: IAvatar[] }>(
      `${environment.apiUrl}/account/avatars`,
      { avatar },
    );
  }
  removeUserAvatar(avatar: IAvatar): Observable<{ avatar: IAvatar }> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { avatar },
    };

    return this.http.delete<{ avatar: IAvatar }>(
      `${environment.apiUrl}/account/avatars`,
      options,
    );
  }

  changePassword(
    passwordObj: IChangePassword,
  ): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(
      `${environment.apiUrl}/account/change-password`,
      passwordObj,
    );
  }
}

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter, map, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from 'src/app/state/auth/auth.state';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectIsAuthenticated).pipe(
    filter((isAuthenticated) => isAuthenticated !== null),
    take(1),
    map((isAuthenticated) => {
      const isLoginRoute = state.url.startsWith('/auth/login');
      if (isLoginRoute && isAuthenticated) {
        router.navigate(['/']);
        return false;
      } else if (!isAuthenticated && !isLoginRoute) {
        router.navigate(['/auth/login'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      }
      return true;
    })
  );
};

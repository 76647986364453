import { createSelector } from '@ngrx/store';

import {
  AuditLogsState,
  selectAll as selectAllAuditLogs,
} from './audit-logs.state';

export const selectAuditLogsWithUserEmail = createSelector(
  selectAllAuditLogs,
  (auditLogs) =>
    auditLogs.map((audit) => ({
      ...audit,
      userId: audit.userId.email,
    })),
);

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import { IAlert } from 'src/app/core/models/alert.model';
import { NgClass } from '@angular/common';

@Component({
    selector: 'cap-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('100ms ease-out', style({ opacity: 1 })),
            ]),
            transition(':leave', [animate('300ms ease-in', style({ opacity: 0 }))]),
        ]),
    ],
    standalone: true,
    imports: [NgClass],
})
export class AlertComponent implements OnInit {
  @Input() alert!: IAlert;
  @Output() dismissed = new EventEmitter<number>();

  ngOnInit(): void {}

  onDismiss(): void {
    this.dismissed.emit(this.alert.id);
  }

  private icons = {
    success: {
      path: 'M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z',
    },
    info: {
      path: 'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z',
    },
    warning: {
      path: 'M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z',
    },
    error: {
      path: 'M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z',
    },
  };

  getIcon(): { path: string } {
    return this.icons[this.alert.type];
  }

  getClass(type: string, degree: number): string {
    switch (this.alert.type) {
      case 'success':
        return `${type}-green-${degree}`;
      case 'error':
        return `${type}-red-${degree}`;
      case 'warning':
        return `${type}-yellow-${degree}`;
      case 'info':
        return `${type}-blue-${degree}`;
      default:
        return '';
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgClass } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { formatSubscriptionDate } from '../../utils/entity-formatting.util';
import { Store } from '@ngrx/store';
import { selectOrganizationById } from 'src/app/state/organizations/organizations.state';

@Component({
  selector: 'cap-disable-subscription-renewal',
  standalone: true,
  imports: [NgClass, CommonModule],
  templateUrl: './disable-subscription-renewal.component.html',
  styleUrl: './disable-subscription-renewal.component.scss'
})
export class DisableSubscriptionRenewalComponent {
  private store = inject(Store)
  @Input() organizationId!: string;
  @Input() subscriptionStatus!: string;
  @Input() subscriptionStartTime!: number;
  @Input() subscriptionEndTime!: number;
  @Output() subscriptionRenewalDisabled = new EventEmitter<string>();
  @Output() subscriptionRenewalEnabled = new EventEmitter<string>();
  currentOrganization$ = this.store.select(selectOrganizationById(this.organizationId));
  operation: boolean = false;
  autoRenewal: boolean = true;

  // Use these methods to check subscription states more clearly
  get isActive(): boolean {
    return this.subscriptionStatus === 'active';
  }

  get isPendingCancellation(): boolean {
    return this.subscriptionStatus === 'pending_cancellation';
  }

  get isCanceled(): boolean {
    return this.subscriptionStatus === 'canceled';
  }

  get isDisabled(): boolean {
    return this.subscriptionStatus === 'disabled';
  }

  // Get appropriate status message based on subscription state
  get statusMessage(): string {
    if (this.isActive) return 'Auto-renewal is enabled';
    if (this.isPendingCancellation) return 'Auto-renewal will be disabled at the end of the billing period';
    if (this.isCanceled) return 'Auto-renewal is disabled';
    if (this.isDisabled) return 'Subscription is disabled';
    return 'Unknown subscription status';
  }

  // Get appropriate status color
  get statusClass(): string {
    if (this.isActive) return 'text-green-600 dark:text-green-400';
    if (this.isPendingCancellation) return 'text-yellow-600 dark:text-yellow-400';
    return 'text-gray-500 dark:text-gray-400';
  }

  formatDate(timestamp: string | number | null | undefined): string {
    return formatSubscriptionDate(timestamp);
  }

  disableSubscriptionRenewal() {
    this.operation = true;
    this.subscriptionStatus = 'pending_cancellation';
    this.subscriptionRenewalDisabled.emit(this.organizationId);
  }

  enableSubscriptionRenewal() {
    this.operation = true;
    this.subscriptionStatus = 'active';
    this.subscriptionRenewalEnabled.emit(this.organizationId);
  }
}

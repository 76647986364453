// 1. Angular Core imports
import { Component, inject, signal } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 2. Third-party libraries
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { map, Subject, takeUntil, tap } from 'rxjs';

// 3. Application-wide shared models
import { EntityName } from '../../../core/models/entity-name.model';
import { ICategory } from '../../../core/models/categories.model';
import { IColumn } from '../../../core/models/column.model';

// 4. State management
import {
  selectCategoriesWithCurrentAvatar,
  selectPagination,
} from '../../../state/categories/categories.selectors';
import {
  Pagination,
  selectCategoryById,
} from '../../../state/categories/categories.state';
import {
  CategoriesAPIActions,
  CategoriesAppActions,
} from '../../../state/categories/categories.actions';
import { ModalActions } from '../../../state/modal/modal.actions';
import { selectModalVisibility } from '../../../state/modal/modal.state';

// 5. Shared components
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { TableComponent } from '../../../shared/table/table.component';
import { CsvUploadModelComponent } from "../../../shared/csv-upload-model/csv-upload-model.component";

// 6. Feature-specific imports
import { CategoryUpsertModalComponent } from '../category-upsert-modal/category-upsert-modal.component';
import { convertCategoryToCategoryTable } from '../../../utils/entity-formatting.util';

// 7. Interfaces
export interface IModalMetadata {
  title: string;
  modalWidth: string;
  modalID: string;
  isEditMode: boolean;
  category: ICategory | null;
}

@Component({
  selector: 'cap-organization-categories-list',
  standalone: true,
  imports: [
    AsyncPipe,
    CategoryUpsertModalComponent,
    ConfirmModalComponent,
    PaginationComponent,
    SpinnerComponent,
    TableComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './organization-categories-list.component.html',
  styleUrl: './organization-categories-list.component.scss',
})
export class OrganizationCategoriesListComponent {
  // 1. Dependency Injection
  private readonly store = inject(Store);
  private readonly actions = inject(Actions);

  // 2. Private properties
  private destroy$ = new Subject<void>();
  private categoryDropped: ICategory | null = null;

  // 3. Public properties
  modalMetadata!: IModalMetadata;
  searchQuery: string = '';
  current_page: number = 1;
  total_pages: number = 0;
  totalItems: number = 0;
  pageSize: number = 10;

  // 4. Readonly properties and constants
  readonly entityName = EntityName.Category;
  readonly confirmModalData = {
    title: 'Delete Category',
    message: 'Are you sure you want to delete this category?',
  };
  readonly columns: IColumn[] = [
    {
      key: 'avatar',
      label: 'Logo',
      type: 'avatar',
      sortable: false,
    },
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: true,
    },
    {
      key: 'weight',
      label: 'Weight',
      type: 'text',
      sortable: true,
    },
    {
      key: 'isActive',
      label: 'Enabled',
      type: 'button',
      sortable: true,
    },
    {
      key: 'updatedAt',
      label: 'Last Update',
      type: 'date',
      sortable: true,
    },
  ];

  // 5. Signals
  isLoadingCategoryList = signal(true);

  // 6. Observables and Subscriptions
  readonly categories$ = this.store.select(selectCategoriesWithCurrentAvatar);
  readonly categoriesData$ = this.categories$.pipe(
    map((cats) => cats.map((cat) => convertCategoryToCategoryTable(cat))),
  );
  readonly pagination$ = this.store
    .select(selectPagination)
    .subscribe((pagination: Pagination) => {
      this.current_page = pagination.page;
      this.total_pages = pagination.totalPages;
      this.totalItems = pagination.totalCount;
      this.pageSize = pagination.limit;
    });

  // 7. Lifecycle Hooks
  ngOnInit(): void {
    this.store.dispatch(
      CategoriesAppActions.getCategoriesByType({
        categoryType: 'organization',
        page: 1,
        limit: 10,
      }),
    );

    this.actions
      .pipe(
        ofType(
          CategoriesAppActions.getCategoriesByType,
          CategoriesAPIActions.getCategoriesByTypeSuccess,
          CategoriesAPIActions.getCategoriesByTypeFailure,
        ),
        tap((action) =>
          this.isLoadingCategoryList.set(
            action.type === CategoriesAppActions.getCategoriesByType.type,
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // 8. Public Methods - Modal Related
  openCreateCategoryModal(): void {
    this.modalMetadata = {
      modalID: 'add-category',
      modalWidth: 'sm:max-w-7xl',
      title: 'Add new Category',
      isEditMode: false,
      category: null,
    };

    this.store.dispatch(ModalActions.open({ id: 'add-category' }));
  }

  openUpdateCategorynModal(id: string): void {
    this.store
      .select(selectCategoryById(id))
      .pipe(
        map(
          (category) =>
            (this.modalMetadata = {
              modalID: 'edit-category',
              modalWidth: 'sm:max-w-7xl',
              title: 'Edit Category',
              isEditMode: true,
              category,
            }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.store.dispatch(ModalActions.open({ id: 'edit-category' }));
  }

  openConfirmationModal(category: any): void {
    this.categoryDropped = category;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-category' }));
  }

  selectModalVisibilityById(id: string) {
    return this.store.select(selectModalVisibility(id));
  }

  // 9. Public Methods - Category Operations
  sortCategories(data: { entityId: string; previousIndex: number; currentIndex: number }): void {
    this.store.dispatch(
      CategoriesAppActions.updateCategorySortOrder({
        categoryId: data.entityId,
        previousIndex: data.previousIndex,
        currentIndex: data.currentIndex,
      }),
    );
  }

  removeCategory(): void {
    if (this.categoryDropped) {
      this.store.dispatch(
        CategoriesAppActions.removeCategory({ id: this.categoryDropped._id }),
      );
    }
  }

  searchCategories(): void {
    this.store.dispatch(
      CategoriesAppActions.searchCategories({
        search: this.searchQuery,
        page: 1,
        pageSize: this.pageSize,
        categoryType: 'organization',
      }),
    );
  }

  onIndividualCategoryStatusChange(obj: { effectedEntity: string; value: boolean }): void {
    this.store.dispatch(
      CategoriesAppActions.toggleCategoryStatus({
        categoryId: obj.effectedEntity,
        isActive: obj.value,
      }),
    );
  }

  // 10. Public Methods - Pagination
  onPageChange(page: number): void {
    this.store.dispatch(
      CategoriesAppActions.getCategoriesByType({
        categoryType: 'organization',
        page,
        limit: this.pageSize,
      }),
    );
  }
}
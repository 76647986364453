<div
  class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
>
  <div>
    <h2
      class="text-base font-semibold leading-7 text-gray-800 dark:text-gray-50"
    >
      Organization Information
    </h2>
    <p class="mt-1 text-sm leading-6 text-gray-400">
      Use a permanent address where you can receive mail.
    </p>
  </div>
  <div class="md:col-span-2 flex flex-col gap-y-5">
    <!-- <cap-avatar-upload
      [avatars]="avatars$ | async"
      (avatarUpdated)="onAvatarUpdated($event)"
      (avatarRemoved)="onAvatarRemoved($event)"
    ></cap-avatar-upload> -->
    @if (errorMessage$ | async) {
      <cap-feedback type="error" [message]="errorMessage$ | async"></cap-feedback>
    } @if(loading$ | async) {
    <div
      class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
    >
      <cap-spinner class="z-50" [size]="16"></cap-spinner>
    </div>
    }
    <!-- Account Profile Form Component -->
    <cap-organization-profile-from
      [formInput$]="organizationConfig$"
      (orgProfileFormChanged)="handleProfileChange($event)"
    ></cap-organization-profile-from>

    <div class="mt-8 flex">
      <button
        [disabled]="!(organizationProfileFormValidity$ | async)"
        type="button"
        (click)="submitOrganizationForm()"
        class="rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        Update Organization
      </button>
    </div>
  </div>
</div>

<!-- <div
  class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
>
  <div>
    <h2
      class="text-base font-semibold leading-7 text-gray-800 dark:text-gray-50"
    >
      Personal Information
    </h2>
    <p class="mt-1 text-sm leading-6 text-gray-400">
      Use a permanent address where you can receive mail.
    </p>
  </div>
  <div class="md:col-span-2 flex flex-col gap-y-5">
    <cap-avatar-upload
      [avatars]="avatars$ | async"
      (avatarUpdated)="onAvatarUpdated($event)"
      (avatarRemoved)="onAvatarRemoved($event)"
    ></cap-avatar-upload>
    @if (errorMessage$ | async) {
    <div class="rounded-md bg-red-50 p-4" role="alert">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg
            class="h-5 w-5 text-red-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            {{ errorMessage$ | async }}
          </h3>
        </div>
      </div>
    </div>
    } @if(loading$ | async) {
    <div
      class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
    >
      <cap-spinner class="z-50" [size]="16"></cap-spinner>
    </div>
    }
    <cap-profile-form
      [formInput]="userFormInput"
      (profileFormChanged)="onProfileChanged($event)"
    ></cap-profile-form>
    <div class="mt-8 flex">
      <button
        type="submit"
        [disabled]="!(profileForm$ | async)?.valid"
        (click)="onProfileFormSubmit()"
        class="flex justify-center items-center rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 dark:disabled:bg-primary-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        Update Profile
      </button>
    </div>
  </div>
</div> -->
<!-- </div> -->

import { Actions, ofType } from "@ngrx/effects";

import { inject } from "@angular/core";
import { PricingConfigService } from "src/app/core/services/pricing-config.service";

import { createEffect } from "@ngrx/effects";
import { PricingConfigAPIActions } from "./pricing-config.actions";
import { exhaustMap, of } from "rxjs";
import { catchError, map, share, take } from "rxjs/operators";
import { PricingConfigAppActions } from "./pricing-config.actions";
import { handleError } from "src/app/utils/error-handler.util";

export const getPricingConfigs$ = createEffect(
    (actions$ = inject(Actions), pricingConfigService = inject(PricingConfigService)) => {
        return actions$.pipe(
            ofType(PricingConfigAppActions.getAllPricingConfigs),
            exhaustMap(() => pricingConfigService.getAllPricingConfigs().pipe(
                take(1),
                map((pricingConfigs) => PricingConfigAPIActions.getAllPricingConfigsSuccess({ pricingConfigs })),
            )),
            catchError((error)=>{
                const message = handleError(error);
                return of(
                    PricingConfigAPIActions.getAllPricingConfigsFailure
                )
            }),
            share()   
        )
    },
    { functional: true },
)   

export const updatePricingConfig$ = createEffect(
    (actions$ = inject(Actions), pricingConfigService = inject(PricingConfigService)) => {
        return actions$.pipe(
            ofType(PricingConfigAppActions.updatePricingConfigPrice),
            exhaustMap(({ prices }) => pricingConfigService.updatePricingConfigPrice(prices).pipe(
                take(1),
                map(({pricingConfigs}) => PricingConfigAPIActions.updatePricingConfigPriceSuccess({ pricingConfigs })),
            )),
            catchError((error)=>{
                const message = handleError(error);
                return of(
                    PricingConfigAPIActions.updatePricingConfigPriceFailure({ message })
                )
            }),
            share()
        )
    },
    { functional: true },
)
import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { FileWithPreview, IDoc } from 'src/app/core/models/action-items.model';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { selectActionItemById } from 'src/app/state/action-items/action-items.state';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { ActionItemsAppActions } from 'src/app/state/action-items/action-items.actions';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { DownloadActionItemsDocsModalStore } from './download-action-items-docs-modal.store';
import { FeedbackComponent } from 'src/app/shared/feedback/feedback.component';

@Component({
  selector: 'cap-download-action-items-docs-modal',
  standalone: true,
  providers: [DownloadActionItemsDocsModalStore],
  imports: [
    CommonModule,
    ModalContainerComponent,
    SpinnerComponent,
    FeedbackComponent,
  ],
  templateUrl: './download-action-items-docs-modal.component.html',
  styleUrl: './download-action-items-docs-modal.component.scss',
})
export class DownloadActionItemsDocsModalComponent {
  private destroy$ = new Subject<void>();

  constructor() {}

  @Input() actionItemId = '';

  private store = inject(Store);
  private componentStore = inject(DownloadActionItemsDocsModalStore);

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessaage$;

  modalMetadata = {
    modalID: 'download-action-item-docs',
    modalWidth: 'sm:max-w-7xl',
    title: 'Action Item Docs',
  };

  files: FileWithPreview[] = [];
  counter = 0;

  ngOnInit(): void {
    this.store
      .select(selectActionItemById(this.actionItemId))
      .pipe(takeUntil(this.destroy$))
      .subscribe((actionItem) => {
        if (actionItem) {
          this.loadExistingDocs(actionItem?.docs);
        } else {
          this.store.dispatch(
            ActionItemsAppActions.getActionItemById({ id: this.actionItemId }),
          );
        }
      });
  }

  loadExistingDocs(docs: IDoc[]): void {
    this.files = [];
    if (docs) {
      docs.forEach((doc) => {
        const fileWithPreview: FileWithPreview = {
          _id: doc._id,
          src: null,
          name: doc.filename,
          size: 0,
          url: doc.url,
          type: doc.contentType,
          lastModified: 0,
        };
        this.files.push(fileWithPreview);
      });
    }

    this.updateGallery();
  }

  updateGallery(): void {
    const gallery = document.getElementById('gallery');
    const empty = document.getElementById('empty');

    if (gallery && empty && this.files.length > 0) {
      empty.classList.add('hidden');
    }
  }

  closeModal(): void {
    this.store.dispatch(
      ModalActions.close({
        id: this.modalMetadata.modalID,
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

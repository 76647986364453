<div class="flex items-center">
  <!-- Enabled: "bg-red-600", Not Enabled: "bg-gray-200" -->
  <button
    type="button"
    class="relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
    [ngClass]="{
      'bg-gray-200 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 cursor-pointer':
        !button,
      'bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 cursor-pointer':
        button
    }"
    role="switch"
    aria-checked="false"
    aria-labelledby="annual-billing-label"
    (click)="onButtonClick()"
  >
    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
    <span
      aria-hidden="true"
      class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
      [ngClass]="{ 'translate-x-5': button, 'translate-x-0': !button }"
    ></span>
  </button>
</div>

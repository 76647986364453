import { Component, Input, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextCellStore } from './text-cell.store';
import { share } from 'rxjs';

@Component({
  selector: 'cap-text-cell',
  standalone: true,
  imports: [CommonModule],
  providers: [TextCellStore],
  templateUrl: './text-cell.component.html',
  styleUrl: './text-cell.component.scss',
})
export class TextCellComponent {
  private readonly componentStore = inject(TextCellStore);
  @Input() isQuestion = false;
  @Input() isLink = false;

  @Input() set text(value: string) {
    this.componentStore.setText(value);
  }

  readonly text$ = this.componentStore.text$.pipe(share());
}

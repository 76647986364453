import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { FormErrorMessageComponent } from '../form-error-message/form-error-message.component';
import { CountryPhoneCodeService } from 'src/app/core/services/country-phone-code.service';
import { IOrganizationProfileFormInput } from 'src/app/core/models/profile-form-context.model';
import { map, Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { isNumericValidator } from '../validators/is-numeric.validator';
import { IAddress, IPhoneNumber, IRole } from 'src/app/core/models/user.model';
import { atLeastOnePrimaryValidator } from '../validators/at-least-one-primary-control.validator';
import { Store } from '@ngrx/store';
import { selectProfiles } from '../../state/profiles/profiles.selectors';
import { ProfilesAppActions } from '../../state/profiles/profiles.actions';
import { DropdownModule } from 'primeng/dropdown';
import { IOrganization } from '../../core/models/organization.model';
import { selectUserRole } from '../../state/auth/auth.selectors';
import { UserRole } from 'src/app/core/models/user.model';

@Component({
  selector: 'cap-organization-profile-from',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorMessageComponent,
    DropdownModule,
  ],
  templateUrl: './organization-profile-from.component.html',
  styleUrl: './organization-profile-from.component.scss',
})
export class OrganizationProfileFromComponent implements OnInit, OnDestroy {
  private destory$ = new Subject<void>();

  private fb = inject(FormBuilder);
  private store = inject(Store);
  private countryPhoneCodeService = inject(CountryPhoneCodeService);
  @Input() formInput$!: Observable<IOrganizationProfileFormInput>;
  @Output() orgProfileFormChanged: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();

  isPhoneCodeDropdownOpen: { [key: number]: boolean } = {};
  isCountryDropdownOpen: { [key: number]: boolean } = {};

  contriesAndPhoneCodes = this.countryPhoneCodeService.getCountryPhoneCodes();
  profiles$ = this.store.select(selectProfiles);
  dropDownProfileOptions: any[] = [];
  userRole = UserRole;

  currentUserRole$!: Observable<IRole | undefined>;

  orgProfileForm!: FormGroup;

  ngOnInit(): void {
    this.currentUserRole$ = this.store.select(selectUserRole);
    this.store.dispatch(ProfilesAppActions.getOrganizationalProfiles());

    this.initializeForm();

    this.orgProfileForm.valueChanges
      .pipe(takeUntil(this.destory$))
      .subscribe(() => {
        this.orgProfileFormChanged.emit(this.orgProfileForm);
      });

    this.formInput$.pipe(takeUntil(this.destory$)).subscribe((formInput) => {
      if (formInput.data) {
        this.orgProfileForm.patchValue(formInput.data);
        this.populateFormArrays(formInput.data);
      } else {
        this.addAddressRow();
        this.addPhoneNumberRow();
      }
    });

    this.profiles$
      .pipe(
        map((profiles) => profiles.filter((profile) => profile.isEnabled)),
        takeUntil(this.destory$),
      )
      .subscribe((profiles) => {
        this.dropDownProfileOptions = profiles.map((profile) => ({
          label: profile.name,
          value: profile._id,
        }));
      });
  }

  initializeForm(): void {
    this.orgProfileForm = this.fb.group({
      name: ['', Validators.required],
      profile: ['', Validators.required],
      isBranded: [false],
      phoneNumbers: this.fb.array([], atLeastOnePrimaryValidator('isPrimary')),
      addresses: this.fb.array([], atLeastOnePrimaryValidator('isPrimary')),
    });
  }

  private populateFormArrays(data: IOrganization) {
    data.addresses.forEach((address) => {
      this.addresses.push(this.createAddressGroup(address));
    });

    data.phoneNumbers.forEach((phoneNumber) => {
      this.phoneNumbers.push(this.createPhoneNumberGroup(phoneNumber));
    });
  }

  get addresses() {
    return this.orgProfileForm.get('addresses') as FormArray;
  }

  private createAddressGroup(
    address: IAddress | null,
    isFirstRow = false,
  ): FormGroup {
    const addressGroup = this.fb.group({
      street1: [address?.street1 ?? '', Validators.required],
      street2: [address?.street2 ?? ''],
      city: [address?.city ?? '', Validators.required],
      state: [address?.state ?? ''],
      country: [
        address?.country ?? 'United States of America',
        Validators.required,
      ],
      zip: [address?.zip ?? '', [Validators.required, isNumericValidator()]],
      addressType: [address?.addressType ?? 'Office', Validators.required],
      isPrimary: [
        isFirstRow || (address?.isPrimary ?? false),
        Validators.required,
      ],
    });
    this.setupPrimarySubscription(addressGroup, 'addresses');
    return addressGroup;
  }

  togglePhoneCodeDropdown(phoneIndex: number): void {
    this.isPhoneCodeDropdownOpen[phoneIndex] =
      !this.isPhoneCodeDropdownOpen[phoneIndex];
  }

  toggleCountryDropdown(addressIndex: number): void {
    this.isCountryDropdownOpen[addressIndex] =
      !this.isCountryDropdownOpen[addressIndex];
  }

  selectPhoneCode(phoneIndex: number, phoneCode: string): void {
    const phoneNumbersArray = this.orgProfileForm.get(
      'phoneNumbers',
    ) as FormArray;
    phoneNumbersArray.at(phoneIndex).get('phoneCode')?.setValue(phoneCode);
    this.togglePhoneCodeDropdown(phoneIndex);
  }

  selectCountry(addressIndex: number, countryName: string): void {
    const addressesArray = this.orgProfileForm.get('addresses') as FormArray;
    addressesArray.at(addressIndex).get('country')?.setValue(countryName);
    this.toggleCountryDropdown(addressIndex);
  }

  getCountryCodeByName(countryName: string): string {
    return (
      this.contriesAndPhoneCodes
        .find((obj) => obj.countryName === countryName)
        ?.countryCode.toLocaleLowerCase() ?? ''
    );
  }

  addAddressRow(): void {
    this.addresses.push(
      this.createAddressGroup(null, this.addresses.length === 0),
    );
  }

  removeAddress(index: number): void {
    if (this.addresses.length === 1) {
      return;
    }
    this.addresses.removeAt(index);
  }

  get phoneNumbers() {
    return this.orgProfileForm.get('phoneNumbers') as FormArray;
  }

  private createPhoneNumberGroup(
    phone: IPhoneNumber | null,
    isFirstRow: boolean = false,
  ): FormGroup {
    const phoneGroup = this.fb.group({
      phoneCode: [phone?.phoneCode ?? '1', Validators.required],
      number: [phone?.number ?? '', [Validators.required]],
      phoneType: [phone?.phoneType ?? 'Mobile', Validators.required],
      isPrimary: [
        isFirstRow || (phone?.isPrimary ?? false),
        Validators.required,
      ],
    });

    this.setupPrimarySubscription(phoneGroup, 'phoneNumbers');
    return phoneGroup;
  }

  // Setup subscription for primary checkbox
  private setupPrimarySubscription(
    group: FormGroup,
    key: 'addresses' | 'phoneNumbers',
  ) {
    const isPrimaryControl = group.get('isPrimary');
    isPrimaryControl?.valueChanges.subscribe((value) => {
      if (value) {
        this.uncheckOtherPrimary(key, group);
      }
    });
  }

  // Uncheck 'isPrimary' in other groups
  private uncheckOtherPrimary(
    key: 'addresses' | 'phoneNumbers',
    currentGroup: FormGroup,
  ) {
    const formArray = this.orgProfileForm.get(key) as FormArray;
    formArray.controls.forEach((group) => {
      if (group !== currentGroup) {
        (group.get('isPrimary') as FormControl).setValue(false, {
          emitEvent: false,
        });
      }
    });
  }

  addPhoneNumberRow(): void {
    this.phoneNumbers.push(
      this.createPhoneNumberGroup(null, this.phoneNumbers.length === 0),
    );
  }
  removePhoneNumber(index: number): void {
    if (this.phoneNumbers.length === 1) {
      return;
    }
    this.phoneNumbers.removeAt(index);
  }

  toggleBranding() {
    const currentIsBranded = this.orgProfileForm.get('isBranded')!.value;
    this.orgProfileForm.get('isBranded')!.setValue(!currentIsBranded);
  }
  ngOnDestroy(): void {
    this.destory$.next();
    this.destory$.complete();
  }
}

import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import {
  IStandard,
  IStandardBase,
} from 'src/app/core/models/security-controls.model';

import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { EditableTableComponent } from 'src/app/shared/editable-table/editable-table.component';
import { FeedbackComponent } from 'src/app/shared/feedback/feedback.component';
import { StandardsListStore } from './standards-list.store';
import { SecurityControlsAppActions } from 'src/app/state/security-controls/security-controls.actions';
import { selectAll as selectStandards } from 'src/app/state/security-controls/standards.state';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { Pagination } from '../../../state/categories/categories.state';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';
import { CategoriesAppActions } from '../../../state/categories/categories.actions';
import { selectStandardsPagination } from '../../../state/security-controls/security-controls.selectors';

@Component({
  selector: 'cap-standards-list',
  standalone: true,
  imports: [
    CommonModule,
    EditableTableComponent,
    ConfirmModalComponent,
    FeedbackComponent,
    SpinnerComponent,
    PaginationComponent,
  ],
  providers: [StandardsListStore],
  templateUrl: './standards-list.component.html',
  styleUrl: './standards-list.component.scss',
})
export class StandardsListComponent implements OnInit {
  readonly confirmModalData = {
    title: 'Delete question standard',
    message: 'Are you sure you want to delete this standard?',
  };

  standardsTableMetaData: IEditableTableMetadata = {
    title: 'Standards',
    entityName: 'Standard',
    bgColor: 'dark:bg-gray-900',
    accordion: false,
  };

  private store = inject(Store);
  private componentStore = inject(StandardsListStore);

  standards$: Observable<IStandard[]> = of([]);

  private standardDropped: IStandard | null = null;

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;
  listLoading$ = this.componentStore.isListLoading$;
  readonly pagination$ = this.store
    .select(selectStandardsPagination)
    .subscribe((pagination: Pagination) => {
      this.current_page = pagination.page;
      this.total_pages = pagination.totalPages;
      this.totalItems = pagination.totalCount;
      this.pageSize = pagination.limit;
    });
  current_page: number = 1;
  total_pages: number = 0;
  totalItems: number = 0;
  pageSize: number = 10;
  standardsTableColumns: IEditableColumn[] = [
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: false,
    },
    {
      key: 'version',
      label: 'Version',
      type: 'text',
      sortable: false,
    },
    {
      key: 'groupings',
      label: 'Groupings',
      type: 'badge',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      type: 'date',
      sortable: false,
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      type: 'date',
      sortable: false,
    },
  ];

  ngOnInit(): void {
    this.store.dispatch(
      SecurityControlsAppActions.getStandardByPagination({
        page: 1,
        limit: 10,
      }),
    );

    this.standards$ = this.store.select(selectStandards);
  }

  onStandardCreated(newStandard: any) {
    this.store.dispatch(
      SecurityControlsAppActions.createStandard({
        standard: <IStandardBase>newStandard,
      }),
    );
  }

  onStandardUpdated(standard: any) {
    this.store.dispatch(
      SecurityControlsAppActions.updateStandard({
        standard: <IStandard>standard,
      }),
    );
  }

  openConfirmationModal(standard: any) {
    this.standardDropped = standard;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-standard' }));
  }

  removeStandard() {
    if (this.standardDropped) {
      this.store.dispatch(
        SecurityControlsAppActions.removeStandard({
          id: this.standardDropped._id,
        }),
      );
    }
  }
  onPageChange(page: number) {
    this.store.dispatch(
      CategoriesAppActions.getCategories({
        page,
        pageSize: this.pageSize,
      }),
    );
  }
}

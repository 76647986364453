import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-edit-text-area-cell',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './edit-text-area-cell.component.html',
  styleUrl: './edit-text-area-cell.component.scss',
})
export class EditTextAreaCellComponent implements OnInit {
  @Input() isSecurityControl = false;
  @Input() description = '';
  @Output() descriptionChange = new EventEmitter<string>();

  onDescriptionChange(value: string): void {
    this.descriptionChange.emit(value);
  }
  ngOnInit() {
    if (this.isSecurityControl && !this.description) {
      this.onDescriptionChange('N/A');
    }
  }
}

@if (isAssessmentFormLoading | async) {
  <div class="w-full h-32 flex justify-center items-center">
    <p-progressSpinner ariaLabel="loading" />
  </div>
} @else {
  <p-scrollPanel
    styleClass="red-scrollbar"
    [style]="{
      width: '100%',
      height: 'calc(100vh - 200px)',
      padding: '0px 5px 0px 0px'
    }"
  >
    <div
      class="container mx-auto px-4 sm:px-6 lg:px-8 rounded-2xl bg-white dark:bg-slate-800 border-2 border-gray-200 dark:border-gray-700"
    >
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-full">
        <div
          formArrayName="questions"
          *ngFor="
            let questionGroup of questionsFormArray.controls;
            let i = index
          "
          class="w-full border-2 border-gray-200 my-4 rounded-md shadow-md transition-all duration-300 hover:shadow-lg"
        >
          <div [formGroupName]="i" class="p-4">
            <div
              class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4"
            >
              <div class="flex items-center">
                <p class="font-normal text-lg dark:text-white mb-0">
                  {{ questions[i].questionText }}
                </p>
                <div class="ml-2 relative inline-flex items-center">
                  <button
                    type="button"
                    class="text-gray-500 hover:text-gray-700 focus:outline-none flex items-center justify-center"
                    (click)="showQuestionDescription(i)"
                    pTooltip="View question description"
                    tooltipPosition="right"
                  >
                    <span class="material-symbols-outlined leading-none">info</span>
                  </button>
                  <div
                    class="absolute inset-0 rounded-full pointer-events-none"
                    [class.glow-animation]="isNegativeOption(i)"
                  ></div>
                </div>
              </div>
              <div class="flex gap-3 mt-2 sm:mt-0">
                <button
                  type="button"
                  class="flex justify-center items-center rounded-full p-2 bg-red-600 text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 transition-colors duration-200"
                  title="Toggle Action Items"
                  (click)="toggleActionItems(i)"
                >
                  <span class="material-symbols-outlined text-sm"
                    >fact_check</span
                  >
                </button>
                <button
                  type="button"
                  class="flex justify-center items-center rounded-full p-2 bg-red-600 text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 transition-colors duration-200"
                  [title]="
                    expandedTextAreas === i ? 'Hide Comment' : 'Add Comment'
                  "
                  (click)="toggleTextArea(i)"
                >
                  <span class="material-symbols-outlined">add_comment</span>
                </button>
              </div>
            </div>
            <div class="flex flex-wrap gap-4 mb-4">
              @for (option of questions[i].options; track option) {
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    formControlName="optionId"
                    [value]="option._id"
                    (change)="onOptionChange(i, option._id)"
                    class="form-radio h-5 w-5 text-red-600 focus:ring-red-500"
                  />
                  <span class="ml-2 text-gray-700 dark:text-gray-300">{{
                    option.value
                  }}</span>
                </label>
              }
            </div>
            @if (expandedTextAreas === i) {
              <div class="mt-4">
                <textarea
                  id="comment"
                  placeholder="Add a comment here..."
                  formControlName="textResponse"
                  rows="3"
                  class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-red-500 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:focus:border-red-500 transition-colors duration-200"
                ></textarea>
                <div class="flex justify-end mt-2">
                  <button
                    type="button"
                    class="px-4 py-2 bg-red-600 text-white rounded-full hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 transition-colors duration-200"
                    (click)="onCommentSave(i)"
                  >
                    Save
                  </button>
                </div>
              </div>
            }
            <div
              [@expandCollapse]="
                expandedState === i ? 'expanded' : 'collapsed'
              "
              class="overflow-hidden border-t-2 mt-4 rounded-b-md shadow-md"
            >
              <div class="p-4">
                <h3 class="text-lg font-bold mb-4 dark:text-white">
                  Action Items
                </h3>
                @for (
                  actionItem of questions[i].actionItems;
                  track actionItem
                ) {
                  <div
                    class="flex flex-col md:flex-row justify-between items-start md:items-center mb-4 pb-4 border-b last:border-b-0"
                  >
                    <div class="flex items-center flex-wrap flex-grow">
                      <input
                        type="checkbox"
                        class="form-checkbox h-5 w-5 text-red-600 rounded focus:ring-red-500"
                        [checked]="
                          questionsFormArray
                            .at(i)
                            .get('actionItemsIds')
                            ?.value?.includes(actionItem._id) || false
                        "
                        (click)="
                          onActionItemChange(
                            i,
                            actionItem._id,
                            $event
                          )
                        "
                        #currentActionItem
                      />
                      <span
                        class="ml-2 text-gray-700 dark:text-gray-300 flex-1"
                      >
                        {{ actionItem.text }}
                      </span>
                      @if (currentActionItem.checked) {
                        <button
                          type="button"
                          class="flex-shrink-0 flex justify-center items-center rounded-full p-2 bg-red-600 text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 transition-colors duration-200 ml-2 mt-2 md:mt-0"
                          (click)="onAttachEvidence(actionItem, i)"
                        >
                          <span
                            class="material-symbols-outlined"
                            pBadge
                            [value]="getNumberOfEvidance(actionItem._id)"
                          >
                            policy
                          </span>
                        </button>
                      }
                    </div>
                    <div
                      class="flex items-center gap-2 py-1 mt-2 md:mt-0 flex-shrink-0"
                    >
                      <button
                        type="button"
                        class="flex-shrink-0 flex justify-center items-center rounded-full p-2 bg-red-600 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        title="Check Documents"
                        (click)="openDownloadDocsModal(actionItem._id)"
                      >
                        <span
                          class="material-symbols-outlined"
                          pBadge
                          [value]="getNumberOfDocs(actionItem._id)"
                        >
                          file_save
                        </span>
                      </button>

                      <button
                        type="button"
                        class="flex-shrink-0 flex justify-center items-center rounded-full p-2 bg-red-600 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        title="Check Controls"
                        (click)="onShowControls(actionItem)"
                      >
                        <span class="material-symbols-outlined">
                          control_point_duplicate
                        </span>
                      </button>
                      <button
                        type="button"
                        class="flex-shrink-0 flex justify-center items-center rounded-full p-2 bg-red-600 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        title="Check Services"
                        (click)="onShowService(actionItem)"
                      >
                        <span class="material-symbols-outlined">
                          service_toolbox
                        </span>
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    @if (showNextCategoryButton) {
      <div class="w-full flex justify-end mt-6 mb-8">
        <button
          (click)="NavigateToNextCategory()"
          class="px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 transition-colors duration-200"
        >
          Next category
        </button>
      </div>
    }
  </p-scrollPanel>
}

<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="p-4 max-w-md">
      <p *ngIf="selectedQuestionDescription; else noDescription">
        {{ selectedQuestionDescription }}
      </p>
      <ng-template #noDescription>
        <p class="text-gray-500 italic">No description available for this question.</p>
      </ng-template>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog
  [style]="{ width: '90vw', maxWidth: '1200px' }"
  [(visible)]="showServices"
  header="Services"
  [responsive]="true"
  [resizable]="false"
>
  @if (services && services.length === 0) {
    <p class="text-center py-4">No services available</p>
  } @else {
    <p-table [value]="services" [responsive]="true" styleClass="p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Link</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-service>
        <tr>
          <td>{{ service.name }}</td>
          <td>{{ service.description }}</td>
          <td>
            <a
              [href]="service.url"
              target="_blank"
              class="text-blue-600 hover:underline flex items-center"
            >
              Service URL
              <span class="material-symbols-outlined ml-1">link</span>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  }
</p-dialog>

<p-dialog
  [style]="{ width: '90vw', maxWidth: '1200px' }"
  [(visible)]="showControls"
  header="Controls"
  [responsive]="true"
  [resizable]="false"
>
  @if (controls && controls.length === 0) {
    <p class="text-center py-4">No controls available</p>
  } @else {
    <ul class="list-disc pl-5 space-y-2">
      @for (control of controls; track control) {
        <li class="text-gray-700 dark:text-gray-300">
          <ng-container
            *ngIf="findStandard(control.standardId) | async as standard"
          >
            <span class="font-semibold"
              >{{ standard.name }} {{ standard.version }}</span
            >
          </ng-container>
          - {{ control.description }}
        </li>
      }
    </ul>
  }
</p-dialog>

@if (
  (selectModalVisibilityById("action-item-docs") | async) &&
  currentActionItem &&
  currentActionItem._id
) {
  <cap-action-item-docs-modal
    [isEvidence]="true"
    [assessmentId]="selectedAssessmentCategory!.assessment._id"
    [actionItemId]="currentActionItem._id"
    (modalClosed)="onModalClosed()"
  ></cap-action-item-docs-modal>
}
@if (selectModalVisibilityById("download-action-item-docs") | async) {
  <cap-download-action-items-docs-modal
    [actionItemId]="this.currentActionItemId"
  ></cap-download-action-items-docs-modal>
}




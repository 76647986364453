import { IUser } from './user.model';
import { IOrganization } from './organization.model';

export enum ProfileContext {
  UserCreation,
  UserUpdate,
  OrgCreation,
  OrgUpdate,
}

export interface IProfileFormInput {
  context: ProfileContext;
  data: IUser | IOrganization | null;
}

export interface IUserProfileFormInput {
  context: ProfileContext;
  data: IUser | null;
}

export interface IOrganizationProfileFormInput {
  context: ProfileContext;
  data: IOrganization | null;
}

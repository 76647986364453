import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cap-pagination',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
})
export class PaginationComponent implements OnInit {
  @Input() current_page = 0;
  @Input() pageSize = 0;
  @Input() totalItems = 0;
  @Input() totalPages = 0;
  @Input() disableNext = false;
  @Output() pageChange = new EventEmitter<number>();

  navigateCellStyleClasses = {
    active:
      'inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600',
    normal:
      'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
  };

  get pagesToDisplay(): (number | string)[] {
    const pages: (number | string)[] = [];
    const { totalPages, current_page } = this;

    if (totalPages <= 6) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);

      if (current_page <= 3) {
        pages.push(2, 3, 4, '...');
      } else if (current_page >= totalPages - 2) {
        pages.push('...', totalPages - 3, totalPages - 2, totalPages - 1);
      } else {
        pages.push(
          '...',
          current_page - 1,
          current_page,
          current_page + 1,
          '...',
        );
      }

      pages.push(totalPages);
    }

    return pages;
  }

  ngOnInit(): void {}

  getNavStyle(index: number) {
    if (this.current_page === index) {
      return this.navigateCellStyleClasses.active;
    }
    return this.navigateCellStyleClasses.normal;
  }

  goToPage(page: number) {
    this.pageChange.emit(page);
  }

  onNextPage() {
    if (this.current_page < this.totalPages) {
      this.pageChange.emit(this.current_page + 1);
    }
  }

  onPreviousPage() {
    if (this.current_page > 1) {
      this.pageChange.emit(this.current_page - 1);
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-edit-list-cell',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './edit-list-cell.component.html',
  styleUrl: './edit-list-cell.component.scss',
})
export class EditListCellComponent implements OnInit {
  @Input() isSecurityControl = false;
  @Input() options: { value: number | string; label: string }[] = [];
  @Input() selectedOption: number | string = '';
  @Output() optionChange = new EventEmitter<number | string>();

  ngOnInit(): void {
    if (
      this.isSecurityControl &&
      this.options.length > 0 &&
      !this.selectedOption
    ) {
      this.onOptionChange(this.options[0].value);
    }
  }
  onOptionChange(value: number | string): void {
    this.optionChange.emit(value);
  }
}

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { IQuestion } from 'src/app/core/models/questions.model';
import { QuestionsAPIActions, QuestionsAppActions } from './questions.actions';

export interface Pagination {
  page: number;
  limit: number;
  totalPages: number;
  totalCount: number;
}

export interface QuestionsState extends EntityState<IQuestion> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<IQuestion> = createEntityAdapter<IQuestion>(
  {
    selectId: (question: IQuestion) => question._id,
    sortComparer: (a: IQuestion, b: IQuestion) => a.sortOrder - b.sortOrder,
  },
);

export const initialState: QuestionsState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
    totalCount: 0,
  },
});

const questionsReducer = createReducer(
  initialState,
  on(QuestionsAPIActions.getQuestionsSuccess, (state, { questions }) => {
    return adapter.setAll(questions, {
      ...state,
      errorMessage: null,
    });
  }),
  on(QuestionsAPIActions.getQuestionsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(QuestionsAPIActions.getQuestionsByIdSuccess, (state, { question }) => {
    return adapter.upsertOne(question, state);
  }),
  on(QuestionsAPIActions.getQuestionsByIdFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(QuestionsAPIActions.createQuestionSuccess, (state, { question }) => {
    return adapter.addOne(question, state);
  }),
  on(QuestionsAPIActions.createQuestionFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(QuestionsAPIActions.updateQuestionSuccess, (state, { question }) => {
    return adapter.upsertOne(question, state);
  }),
  on(QuestionsAPIActions.updateQuestionFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(QuestionsAPIActions.removeQuestionSuccess, (state, { questionId }) => {
    return adapter.removeOne(questionId, state);
  }),
  on(QuestionsAPIActions.removeQuestionFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    QuestionsAppActions.getQuestionsByCategoryIdSuccess,
    (state, { questions, pagination }) => {
      // Append new questions to the existing ones
      return adapter.upsertMany(questions, {
        ...state,
        pagination: { ...pagination },
      });
    },
  ),
  on(
    QuestionsAPIActions.getQuestionsByCategoryIdFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(QuestionsAPIActions.addOptionToQuestionSuccess, (state, { question }) => {
    return adapter.upsertOne(question, state);
  }),
  on(QuestionsAPIActions.addOptionToQuestionFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(QuestionsAPIActions.updateQuestionOptionSuccess, (state, { question }) => {
    return adapter.upsertOne(question, state);
  }),
  on(QuestionsAPIActions.updateQuestionOptionFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    QuestionsAPIActions.removeQuestionOptionSuccess,
    (state, { questionId, optionId, message }) => {
      const question = state.entities[questionId];
      if (!question) {
        return state; // Question not found, return the current state
      }

      // Filter out the option with the specified optionId
      const updatedOptions = question.options.filter(
        (option) => option._id !== optionId,
      );

      // Update the question with the new options array
      const updatedQuestion = {
        ...question,
        options: updatedOptions,
      };

      return adapter.updateOne(
        { id: questionId, changes: updatedQuestion },
        state,
      );
    },
  ),
  on(QuestionsAPIActions.removeQuestionOptionFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(QuestionsAPIActions.uploadQuestionsCSVSuccess, (state, { questions }) => {
    return adapter.upsertMany(questions, state);
  }),
  on(QuestionsAPIActions.uploadQuestionsCSVFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
);

export const questionsFeature = createFeature({
  name: 'questions',
  reducer: questionsReducer,
  extraSelectors: ({ selectQuestionsState, selectEntities }) => ({
    ...adapter.getSelectors(selectQuestionsState),
    selectQuestionById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
    selectQuestionOptions: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]?.options ?? []),
  }),
});

export const {
  name,
  reducer,
  selectQuestionsState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectQuestionById,
  selectQuestionOptions,
} = questionsFeature;

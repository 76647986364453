import { Injectable } from '@angular/core';
import { getCountryCallingCode, getCountries } from 'libphonenumber-js/min';
import * as i18n from 'i18n-iso-countries';
import * as english from 'i18n-iso-countries/langs/en.json';

export interface ICountryPhoneCode {
  countryCode: string;
  countryName: string | undefined;
  phoneCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountryPhoneCodeService {
  constructor() {
    i18n.registerLocale(english);
  }
  getCountryPhoneCodes(): ICountryPhoneCode[] {
    const countries = getCountries();
    const phoneCodeMap = new Map<string, ICountryPhoneCode[]>();

    countries.forEach((countryCode) => {
      const phoneCode = getCountryCallingCode(countryCode);
      const countryName = i18n.getName(countryCode, 'en');
      
      if (countryName) {
        const entry: ICountryPhoneCode = {
          phoneCode,
          countryCode,
          countryName: typeof countryName === 'object' ? countryName[0] : countryName,
        };

        if (phoneCodeMap.has(phoneCode)) {
          phoneCodeMap.get(phoneCode)!.push(entry);
        } else {
          phoneCodeMap.set(phoneCode, [entry]);
        }
      }
    });

    const result: ICountryPhoneCode[] = [];
    phoneCodeMap.forEach((countries) => {
      if (countries.length === 1) {
        result.push({
          ...countries[0],
          countryName: `${countries[0].countryName} (+${countries[0].phoneCode})`,
        });
      } else {
        countries.forEach((country) => {
          result.push({
            ...country,
            countryName: `${country.countryName} (+${country.phoneCode})`,
          });
        });
      }
    });

    return result.sort((a, b) => a.countryName!.localeCompare(b.countryName!));
  }
}

import { Component, Input, inject } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { IAvatar } from 'src/app/core/models/user.model';
import { AvatarCellStore } from './avatar-cell.store';

@Component({
  selector: 'cap-avatar-cell',
  standalone: true,
  imports: [CommonModule, NgIf],
  providers: [AvatarCellStore],
  templateUrl: './avatar-cell.component.html',
  styleUrl: './avatar-cell.component.scss',
})
export class AvatarCellComponent {
  private readonly componentStore = inject(AvatarCellStore);

  @Input() set avatar(value: IAvatar) {
    this.componentStore.setAvatar(value);
  }

  readonly avatar$ = this.componentStore.avatar$;
}

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { ISecurityControl } from 'src/app/core/models/security-controls.model';
import { SecurityControlsAPIActions } from './security-controls.actions';
import { Pagination } from '../users/users.state';
export interface SecurityControlsState extends EntityState<ISecurityControl> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<ISecurityControl> =
  createEntityAdapter<ISecurityControl>({
    selectId: (securityControl: ISecurityControl) => securityControl._id,
  });

export const initialState: SecurityControlsState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 10,
    totalCount: 0,
  },
});

const securityControlReducer = createReducer(
  initialState,
  on(
    SecurityControlsAPIActions.getSecurityControlsSuccess,
    (state, { securityControls }) => {
      return adapter.setAll(securityControls, { ...state, errorMessage: null });
    },
  ),
  on(
    SecurityControlsAPIActions.getSecurityControlsByPaginationSuccess,
    (state, { securityControls, pagination }) => {
      return adapter.setAll(securityControls, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(
    SecurityControlsAPIActions.getSecurityControlsByPaginationFailure,
    (state, { message }) => {
      return { ...adapter.removeAll(state), errorMessage: message };
    },
  ),
  on(
    SecurityControlsAPIActions.getSecurityControlsFailure,
    (state, { message }) => {
      return { ...adapter.removeAll(state), errorMessage: message };
    },
  ),
  on(
    SecurityControlsAPIActions.getSecurityControlsFailure,
    (state, { message }) => {
      return { ...adapter.removeAll(state), errorMessage: message };
    },
  ),
  on(
    SecurityControlsAPIActions.getSecurityControlsByIdSuccess,
    (state, { securityControl }) => {
      return adapter.upsertOne(securityControl, state);
    },
  ),
  on(
    SecurityControlsAPIActions.getSecurityControlsByIdFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    SecurityControlsAPIActions.createSecurityControlSuccess,
    (state, { securityControl }) => {
      return adapter.addOne(securityControl, state);
    },
  ),
  on(
    SecurityControlsAPIActions.createSecurityControlFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    SecurityControlsAPIActions.updateSecurityControlSuccess,
    (state, { securityControl }) => {
      return adapter.upsertOne(securityControl, state);
    },
  ),
  on(
    SecurityControlsAPIActions.updateSecurityControlFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    SecurityControlsAPIActions.removeSecurityControlSuccess,
    (state, { securityControlId }) => {
      return adapter.removeOne(securityControlId, state);
    },
  ),
  on(
    SecurityControlsAPIActions.removeSecurityControlFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    SecurityControlsAPIActions.getSecurityControlsByStandardIdSuccess,
    (state, { securityControls }) => {
      return adapter.upsertMany(securityControls, state);
    },
  ),
  on(
    SecurityControlsAPIActions.getSecurityControlsByStandardIdFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
);

export const securityControlsFeature = createFeature({
  name: 'securityControls',
  reducer: securityControlReducer,
  extraSelectors: ({ selectSecurityControlsState, selectEntities }) => ({
    ...adapter.getSelectors(selectSecurityControlsState),
    selectSecurityControlById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
    selectSecurityControlGroupings: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]?.groupings),
  }),
});

export const {
  name,
  reducer,
  selectSecurityControlsState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectSecurityControlById,
  selectSecurityControlGroupings,
} = securityControlsFeature;

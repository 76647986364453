import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

interface ITextCellState {
  text: string;
}

@Injectable()
export class TextCellStore extends ComponentStore<ITextCellState> {
  constructor() {
    super({
      text: '',
    });
  }

  readonly text$ = this.select((state) => state.text);

  readonly setText = this.updater((state, text: string) => ({
    ...state,
    text,
  }));
}

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IAvatar } from 'src/app/core/models/user.model';

@Injectable()
export class AvatarUploadStore extends ComponentStore<{ avatars: IAvatar[] }> {
  constructor() {
    super({ avatars: [] });
  }

  readonly avatars$ = this.select((state) => state.avatars);
  readonly currentAvatar$ = this.select(
    (state) => state.avatars?.find((a) => a.isCurrent) ?? null
  );

  setAvatars(avatars: IAvatar[]) {
    this.patchState({ avatars });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Subject, takeUntil, tap } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import {
  ProfilesApiActions,
  ProfilesAppActions,
} from '../../../../state/profiles/profiles.actions';

interface IndividualProfileUpsertState {
  isLoading: boolean;
  errorMessage: string | null;
}

@Injectable()
export class IndividualProfileUpsertStore
  extends ComponentStore<IndividualProfileUpsertState>
  implements OnDestroy
{
  subDestroy$: Subject<void> = new Subject<void>();
  constructor(private readonly actions$: Actions) {
    super({
      isLoading: false,
      errorMessage: null,
    });
  }
  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(
        ProfilesAppActions.createProfile,
        ProfilesAppActions.updateProfile,
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
      takeUntil(this.destroy$),
    )
    .subscribe();
  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(
        ProfilesApiActions.createProfileSuccess,
        ProfilesApiActions.updateProfileSuccess,
      ),
      tap(
        () => this.patchState({ isLoading: false, errorMessage: null }),
        takeUntil(this.subDestroy$),
      ),
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(
        ProfilesApiActions.createProfileFailure,
        ProfilesApiActions.updateProfileFailure,
      ),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message }),
      ),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

import {
  AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, filter, take, tap, withLatestFrom } from 'rxjs';

import { IStep } from 'src/app/core/models/step.model';
import { StepNavigationModalStore } from './step-navigation-modal.store';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { StepNavigatorComponent } from 'src/app/shared/step-navigator/step-navigator.component';
import { SetNewPasswordComponent } from '../../login-page/set-new-password/set-new-password.component';
import { TwoFactorAuthSetupComponent } from '../../login-page/two-factor-auth-setup/two-factor-auth-setup.component';
import { TwoFactorAuthVerificaitonComponent } from '../../login-page/two-factor-auth-verificaiton/two-factor-auth-verificaiton.component';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import {
  IModalConfig,
  IStepConfig,
} from 'src/app/core/models/step-modal-config.model';
import { ModalLoaderComponent } from '../modal-loader/modal-loader.component';
import { selectUserEmail } from 'src/app/state/auth/auth.selectors';
import { SpinnerComponent } from '../spinner/spinner.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { AgreementComponent } from 'src/app/login-page/agreement/agreement.component';
import { DynamicComponentLoaderService } from 'src/app/core/dynamic-component-loader.service';
import { selectModalVisibility } from 'src/app/state/modal/modal.state';

@Component({
  selector: 'cap-step-navigation-modal',
  standalone: true,
  providers: [StepNavigationModalStore],
  templateUrl: './step-navigation-modal.component.html',
  styleUrl: './step-navigation-modal.component.scss',
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    ModalContainerComponent,
    StepNavigatorComponent,
    SetNewPasswordComponent,
    TwoFactorAuthSetupComponent,
    TwoFactorAuthVerificaitonComponent,
    ModalLoaderComponent,
    SpinnerComponent,
    FeedbackComponent,
    AgreementComponent,
    SetNewPasswordComponent,
  ],
})
export class StepNavigationModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private store = inject(Store);
  private componentStore = inject(StepNavigationModalStore);
  private actions$ = inject(Actions);

  private dynamicComponentLoader = inject(DynamicComponentLoaderService);

  @ViewChild('AuthDynamicComponentContainer', {
    read: ViewContainerRef,
    static: true,
  })
  dynamicComponentContainerRef!: ViewContainerRef;

  currentComponentRef: ComponentRef<
    | SetNewPasswordComponent
    | TwoFactorAuthSetupComponent
    | TwoFactorAuthVerificaitonComponent
    | AgreementComponent
  > | null = null;

  @Input() config!: IModalConfig;

  readonly currentStepIndex$ = this.componentStore.currentStepIndex$;
  readonly loading$ = this.componentStore.isLoading$;
  readonly isEula$ = this.componentStore.isEula$;

  readonly isPasswordCredentialsValid$ =
    this.componentStore.isPasswordCredentialsValid$;
  readonly passwordCredentials$ = this.componentStore.passwordCredentials$;
  readonly formErrorMessage$ = this.componentStore.errorMessage$;

  subs: Subscription[] = [];

  navigateStepEmitter = new EventEmitter<'next' | 'previous'>();

  loadComponentForStep(step: IStepConfig): void {
    if (!this.dynamicComponentContainerRef) return;
    this.dynamicComponentContainerRef.clear();

    const componentRef = this.dynamicComponentLoader.loadComponent(
      this.dynamicComponentContainerRef,
      step.component,
      step.inputs,
      step.outputs,
    );
    this.currentComponentRef = componentRef;
  }

  private userEmail$ = this.store.select(selectUserEmail);

  public steps: Omit<IStep, 'id' | 'status'>[] = [];

  ngOnInit(): void {
    // this.store.dispatch(ModalActions.register({ id: this.config.modalID }));
    this.steps = this.config.steps.map((step) => ({
      title: step.title,
    }));

    this.componentStore.setCurrentStepIndex(0);
  }

  isCurrentComponentRefEula() {
    return this.currentComponentRef?.instance instanceof AgreementComponent;
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() =>
      this.loadComponentForStep(this.config.steps[0]),
    );
  }

  handleNextStep() {
    let idx = null;
    const sub = this.componentStore.currentStepIndex$
      .pipe(
        tap((currentIndex) => {
          idx = currentIndex;
        }),
      )
      .subscribe();
    if (typeof idx === 'number') this.config.steps[idx].onNext();

    this.subs.push(sub);
  }

  handleAgreementStep() {
    const sub = this.isEula$
      .pipe(
        take(1),
        tap((isEula) => {
          if (isEula) {
            this.moveNextStep();
          }
        }),
      )
      .subscribe();
    this.subs.push(sub);
  }

  handleSetNewPasswordStep() {
    const sub = this.passwordCredentials$
      .pipe(
        withLatestFrom(this.isPasswordCredentialsValid$, this.isEula$),
        take(1),
        filter(([credentials, isValid, isEula]) => isValid),
        tap(([credentials, isValid, isEula]) => {
          // Dispatch the action
          this.store.dispatch(
            AuthAppActions.setNewPassword({ isEula, ...credentials }),
          );

          // Subscribe to the success or failure outcomes
          this.waitForSetNewPasswordOutcome();
        }),
      )
      .subscribe();
    this.subs.push(sub);
  }

  waitForSetNewPasswordOutcome() {
    const sub = this.actions$
      .pipe(
        ofType(AuthAPIActions.setNewPasswordSuccess),
        take(1),
        tap(() => {
          this.moveNextStep();
        }),
      )
      .subscribe();
    this.subs.push(sub);
  }

  handle2FASetupStep() {
    this.moveNextStep();
  }

  handle2FAVerificationStep(token: string) {
    const sub = this.userEmail$
      .pipe(
        tap((email) => {
          if (email && token) this.send2FAAuthVerification({ email, token });
        }),
      )
      .subscribe();
    this.subs.push(sub);
  }

  send2FAAuthVerification(credentials: { token: string; email: string }) {
    this.store.dispatch(
      AuthAppActions.verify2FAOTP({
        email: credentials.email,
        token: credentials.token,
      }),
    );
    this.waitFor2FAVerificationOutcome();
  }
  waitFor2FAVerificationOutcome() {
    const sub = this.actions$
      .pipe(
        ofType(AuthAPIActions.verify2FAOTPSuccess),
        tap(() => {
          this.store.dispatch(ModalActions.close({ id: 'first-login-setup' }));
        }),
      )
      .subscribe();
    this.subs.push(sub);
  }
  moveNextStep() {
    const sub = this.componentStore.currentStepIndex$
      .pipe(
        take(1),
        tap((currentIndex) => {
          if (currentIndex < this.steps.length - 1) {
            this.navigateStepEmitter.emit('next');
            this.componentStore.setCurrentStepIndex(currentIndex + 1);
            this.loadComponentForStep(this.config.steps[currentIndex + 1]);
          }
        }),
      )
      .subscribe();
    this.subs.push(sub);
  }

  handlePreviousStep() {
    let idx = null;
    const sub = this.componentStore.currentStepIndex$
      .pipe(
        take(1),
        tap((currentIndex) => {
          if (
            !(
              this.config.steps[currentIndex - 1].component instanceof
              SetNewPasswordComponent
            ) &&
            currentIndex > 0
          ) {
            idx = currentIndex;
          }
        }),
      )
      .subscribe();
    if (typeof idx === 'number') {
      this.navigateStepEmitter.emit('previous');
      this.componentStore.setCurrentStepIndex(idx - 1);
      this.loadComponentForStep(this.config.steps[idx - 1]);
    }
    this.subs.push(sub);
  }

  selectModalVisibilityById(id: string) {
    return this.store.select(selectModalVisibility(id));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.store.dispatch(ModalActions.unregister({ id: this.config.modalID }));
  }
}

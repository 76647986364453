<cap-modal-container [modalWidthClass]="usersConfig.modalWidth" [modalID]="usersConfig.modalID">
    <p class="text-xl font-bold text-gray-900 dark:text-gray-50 mb-4" heading>
        {{usersConfig.title}}
    </p>
    <!-- add template download button -->
    <div class="flex justify-center my-2">
        <a href="https://str.devcdt.cyberwa.com/cyberwa-datastore/excel_templates/users-template.csv" download class="rounded-md bg-gray-200 hover:bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm flex items-center transition duration-150 ease-in-out">
            <span class="material-symbols-outlined mr-2 text-base">download</span>
            Download Template
        </a>
    </div>
    <div class="space-y-4">
        <!-- Organization selection dropdown -->
        @if(organizations$ | async; as organizations) {
            <div class="form-group">
                <label for="organization-select" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Select Organization
                </label>
                <select 
                    id="organization-select"
                    class="w-full p-2 border border-gray-300 rounded-md dark:bg-slate-700 dark:text-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    (change)="onOrganizationChange($event)">
                    <option value="">-- Select Organization --</option>
                    @for(organization of organizations; track organization._id) {
                        <option value="{{organization._id}}">{{organization.name}}</option>
                    }
                </select>
            </div>
        }

        @if(IndividualProfiles$ | async; as IndividualProfiles) {
            <div class="form-group">
                <label for="profile-select" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Select Profile
                </label>
                <select 
                    id="profile-select"
                    class="w-full p-2 border border-gray-300 rounded-md dark:bg-slate-700 dark:text-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    (change)="onProfileChange($event)">
                    <option value="">-- Select Profile --</option>
                    @for(profile of IndividualProfiles; track profile._id) {
                        <option value="{{profile._id}}">{{profile.name}}</option>
                    }
                </select>
            </div>
        }

        <!-- File uploader -->
        <div class="form-group">
            <label for="file-upload" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Upload CSV File
            </label>
            <input 
                id="file-upload"
                type="file" 
                accept=".csv" 
                class="w-full p-2 border border-gray-300 rounded-md dark:bg-slate-700 dark:text-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                (change)="onFileSelected($event)">
        </div>
        
        <!-- Preview of parsed data -->
        @if(csvData.length > 0) {
            <div class="mt-4 p-3 border rounded-md bg-gray-50 dark:bg-slate-800">
                <p class="font-semibold text-gray-700 dark:text-gray-200">
                    <span class="text-green-600 dark:text-green-400">✓</span> Successfully parsed {{csvData.length}} records
                </p>
            </div>
        }

        <!-- Add this after the CSV preview section -->
        @if(invalidRecords.length > 0) {
            <div class="mt-4 p-3 border rounded-md bg-red-50 dark:bg-red-900">
                <p class="font-semibold text-red-700 dark:text-red-200">
                    <span class="text-red-600 dark:text-red-400">✗</span> {{invalidRecords.length}} records have invalid format
                </p>
                <p class="text-sm text-red-600 dark:text-red-300 mt-1">
                    Please fix the CSV file and try again.
                </p>
            </div>
        }

        @if(existingEmails.length > 0) {
            <div class="mt-4 p-3 border rounded-md bg-red-50 dark:bg-red-900">
                <p class="font-semibold text-red-700 dark:text-red-200">
                    <span class="text-red-600 dark:text-red-400">✗</span> The following emails already exist:
                </p>
                <ul class="text-sm text-red-600 dark:text-red-300 mt-1 list-disc list-inside">
                    @for(email of existingEmails; track email) {
                        <li>{{email}}</li>
                    }
                </ul>
            </div>
        }

        <!-- Action buttons -->
        <div class="mt-6">
            @if(showPaymentForm) {
                <div class="mt-4">
                    <cap-payment-form 
                        featureType="individual" 
                        [quantity]="csvData.length"
                        [customerEmail]="(currentUserEmail$ | async )?? undefined"
                        [customerDetails]="customerDetails"
                        (paymentCompleted)="onPaymentCompleted($event)">
                    </cap-payment-form>
                </div>
            } @else {
                <!-- Import button (only shown before payment) -->
                <button 
                    [disabled]="!selectedFile || !selectedOrganizationId || isValidating"
                    (click)="proceedToPayment()"
                    class="rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:bg-gray-300 disabled:cursor-not-allowed">
                    @if(isValidating) {
                        <span class="inline-block animate-spin mr-2">⟳</span> Validating...
                    } @else {
                        Proceed to Payment
                    }
                </button>
            }
        </div>
    </div>
</cap-modal-container>
<div class="col-span-3 sm:col-span-full flex items-center gap-x-8">
  @if (currentAvatar$ | async) {
    <img
      [src]="(currentAvatar$ | async)?.url ?? (currentAvatar$ | async)?.data"
      alt="Avatar"
      class="h-24 w-24 flex-none rounded-lg object-cover bg-cover bg-center bg-no-repeat"
    />
  } @else {
    <img
      src="assets/images/default-avatar.jpeg"
      alt="Avatar"
      class="h-24 w-24 flex-none rounded-lg object-cover bg-cover bg-center bg-no-repeat"
    />
  }
  <div>
    <button
      type="button"
      (click)="openModal('avatar')"
      class="rounded-md bg-gray-100 hover:bg-gray-50 dark:bg-white/10 dark:hover:bg-white/20 px-3 py-2 text-sm font-semibold text-gray-800 dark:text-gray-50 shadow-sm"
    >
      Change Logo
    </button>
    <p class="mt-2 text-xs leading-5 text-gray-400">
      SVG, PNG, JPG or GIF (MAX. {{ maxDimension.width }}x{{
        maxDimension.height
      }}px)
    </p>
  </div>
</div>

<!-- <cap-change-avatar-modal
  class="z-50"
  [avatars]="avatars$ | async"
  (avatarRemoved)="onAvatarRemoved($event)"
  (avatarUpdated)="onAvatarUpdated($event)"
/> -->

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { IStandard } from 'src/app/core/models/security-controls.model';
import { SecurityControlsAPIActions } from './security-controls.actions';
import { Pagination } from '../users/users.state';

export interface StandardsState extends EntityState<IStandard> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<IStandard> = createEntityAdapter<IStandard>(
  {
    selectId: (standard: IStandard) => standard._id,
  },
);

export const initialState: StandardsState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 10,
    totalCount: 0,
  },
});

const standardsReducer = createReducer(
  initialState,
  on(SecurityControlsAPIActions.getStandardsSuccess, (state, { standards }) =>
    adapter.setAll(standards, { ...state, errorMessage: null }),
  ),
  on(SecurityControlsAPIActions.getStandardsFailure, (state, { message }) => ({
    ...adapter.removeAll(state),
    errorMessage: message,
  })),
  on(
    SecurityControlsAPIActions.getStandardByPaginationSuccess,
    (state, { standards, pagination }) =>
      adapter.setAll(standards, { ...state, errorMessage: null, pagination }),
  ),
  on(
    SecurityControlsAPIActions.getStandardByPaginationFailure,
    (state, { message }) => ({
      ...adapter.removeAll(state),
      errorMessage: message,
    }),
  ),
  on(SecurityControlsAPIActions.getStandardByIdSuccess, (state, { standard }) =>
    adapter.upsertOne(standard, state),
  ),
  on(
    SecurityControlsAPIActions.getStandardByIdFailure,
    (state, { message }) => ({ ...state, errorMessage: message }),
  ),
  on(SecurityControlsAPIActions.createStandardSuccess, (state, { standard }) =>
    adapter.addOne(standard, state),
  ),
  on(
    SecurityControlsAPIActions.createStandardFailure,
    (state, { message }) => ({ ...state, errorMessage: message }),
  ),
  on(SecurityControlsAPIActions.updateStandardSuccess, (state, { standard }) =>
    adapter.upsertOne(standard, state),
  ),
  on(
    SecurityControlsAPIActions.updateStandardFailure,
    (state, { message }) => ({ ...state, errorMessage: message }),
  ),
  on(
    SecurityControlsAPIActions.removeStandardSuccess,
    (state, { standardId }) => adapter.removeOne(standardId, state),
  ),
  on(
    SecurityControlsAPIActions.removeStandardFailure,
    (state, { message }) => ({ ...state, errorMessage: message }),
  ),
);

export const standardsFeature = createFeature({
  name: 'standards',
  reducer: standardsReducer,
  extraSelectors: ({ selectStandardsState, selectEntities }) => ({
    ...adapter.getSelectors(selectStandardsState),
    selectStandardById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
    selectStandardGroupings: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]?.groupings),
  }),
});

export const {
  name,
  reducer,
  selectStandardsState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectStandardById,
  selectStandardGroupings,
} = standardsFeature;

import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { IAvatar } from 'src/app/core/models/user.model';
import { AlertActions } from 'src/app/state/alert/alert.actions';
import {
  selectUserAvatars,
  selectUserEmail,
} from 'src/app/state/auth/auth.selectors';
import { selectUser } from 'src/app/state/auth/auth.state';
import { AuthAppActions } from 'src/app/state/auth/auth.actions';
import { AccountProfileFormStore } from './account-profile-form.store';
import {
  IProfileFormInput,
  ProfileContext,
} from 'src/app/core/models/profile-form-context.model';
import { AvatarUploadComponent } from 'src/app/shared/avatar-upload/avatar-upload.component';
import { ProfileFormComponent } from 'src/app/shared/profile-form/profile-form.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { FeedbackComponent } from "../../../shared/feedback/feedback.component";

@Component({
    selector: 'cap-account-profile-form',
    standalone: true,
    providers: [AccountProfileFormStore],
    templateUrl: './account-profile-form.component.html',
    styleUrl: './account-profile-form.component.scss',
    imports: [
        CommonModule,
        AvatarUploadComponent,
        ProfileFormComponent,
        SpinnerComponent,
        NgFor,
        FeedbackComponent
    ]
})
export class AccountProfileFormComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private componentStore = inject(AccountProfileFormStore);

  userFormInput: IProfileFormInput = {
    context: ProfileContext.UserUpdate,
    data: null,
  };

  private subs: Subscription[] = [];

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;

  // `destroy$` used in conjunction with the takeUntil operator to manage the subscription lifecycle and avoid memory leaks.
  private destroy$ = new Subject<void>();
  private profileFormSubject = new BehaviorSubject<FormGroup | null>(null);
  profileForm$ = this.profileFormSubject.asObservable();

  avatars$ = this.store.select(selectUserAvatars);
  email$ = this.store.select(selectUserEmail);

  ngOnInit(): void {
    const userSub = this.store
      .select(selectUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.userFormInput.data = user;
        }
      });

    this.subs.push(userSub);
  }

  onProfileChanged(form: FormGroup) {
    this.profileFormSubject.next(form);
  }

  onProfileFormSubmit() {
    const sub = this.profileFormSubject
      .pipe(
        withLatestFrom(this.email$),
        tap(([form, email]) => {
          if (!form) {
            return;
          }
          if (!form.valid) {
            this.store.dispatch(
              AlertActions.addAlert({
                alert: {
                  type: 'warning',
                  message: 'Please fill in all required fields',
                },
              })
            );
            return;
          }

          this.store.dispatch(
            AuthAppActions.updateUserAccount({
              user: { email, ...form.value },
            })
          );
        })
      )
      .subscribe();

    this.subs.push(sub);
  }

  onAvatarUpdated(avatar: IAvatar) {
    this.store.dispatch(AuthAppActions.updateUserAvatar({ avatar }));
  }

  onAvatarRemoved(avatar: IAvatar) {
    this.store.dispatch(AuthAppActions.removeUserAvatar({ avatar }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

<form [formGroup]="orgProfileForm">
  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
    <div class="col-span-3 sm:col-span-full">
      <label
        for="name"
        class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
        >Name</label
      >
      <div class="relative mt-2">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <svg
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z"
            />
          </svg>
        </div>

        <input
          type="text"
          name="name"
          formControlName="name"
          placeholder="Name"
          id="name"
          autocomplete="given-name"
          class="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-10 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
        />
      </div>
      <cap-form-error-message
        [control]="orgProfileForm.get('name')!"
      ></cap-form-error-message>
    </div>

    <!-- Phone Number Fields -->
    <div
      class="col-span-3 sm:col-span-full space-y-6"
      formArrayName="phoneNumbers"
    >
      <div
        *ngFor="let phone of phoneNumbers.controls; let phoneIndex = index"
        [formGroupName]="phoneIndex"
      >
        <div
          class="relative border-2 z-0 rounded-md px-2 py-4 border-gray-100 dark:border-white/10 grid grid-cols-6 gap-x-2 gap-y-7"
        >
          <div class="absolute right-2 top-4 flex space-x-4 -mt-8 mr-2 z-20">
            @if (phoneNumbers.controls.length > 1) {
              <!-- Remove Phone Number Button -->
              <button
                type="button"
                (click)="removePhoneNumber(phoneIndex)"
                class="rounded-full bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 p-1 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M4.75 9.75h10.5a.75.75 0 010 1.5H4.75a.75.75 0 010-1.5z"
                  />
                </svg>
              </button>
            }

            <!-- Add Phone Number Button -->
            <button
              type="button"
              (click)="addPhoneNumberRow()"
              class="rounded-full bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 p-1 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              <svg
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                />
              </svg>
            </button>
          </div>
          <div class="col-span-full sm:col-span-3">
            <label
              for="phone-number"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-50"
              >Phone Number</label
            >
            <div class="z-30 relative mt-2 rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 flex items-center w-24">
                <label for="phone-code" class="sr-only">Country</label>
                <button
                  (click)="togglePhoneCodeDropdown(phoneIndex)"
                  type="button"
                  class="px-2 z-50 flex flex-row justify-between items-center w-full rounded-md border-0 bg-white/5 dark:bg-gray-800 py-1 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
                >
                  @if (phone.get("phoneCode")?.value) {
                    <div class="flex flex-row">
                      <p
                        class="text-sm leading-6 text-gray-800 dark:text-gray-50"
                      >
                        ( +{{ phone.get("phoneCode")?.value }} )
                      </p>
                    </div>
                  } @else {
                    <p
                      class="text-sm leading-6 text-gray-800 dark:text-gray-50"
                    >
                      Select a Phone Code
                    </p>
                  }
                  <span class="material-symbols-outlined">expand_more</span>
                </button>
                @if (isPhoneCodeDropdownOpen[phoneIndex]) {
                  <ul
                    class="absolute bg-gray-50 dark:bg-gray-800 z-50 mt-1 max-h-40 w-full overflow-auto"
                  >
                    @for (
                      code of contriesAndPhoneCodes;
                      track code.countryName
                    ) {
                      <li
                        (click)="selectPhoneCode(phoneIndex, code.phoneCode)"
                        class="flex flex-row p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                      >
                        <span
                          class="mr-2"
                          [ngClass]="
                            'fi fi-' + code.countryCode.toLocaleLowerCase()
                          "
                        ></span>
                        <!-- Flag -->
                        <p
                          class="text-sm leading-6 text-gray-800 dark:text-gray-50"
                        >
                          (+{{ code.phoneCode }})
                        </p>
                      </li>
                    }
                  </ul>
                }
              </div>
              <input
                type="text"
                name="phone-number"
                formControlName="number"
                id="phone-number"
                class="block w-full bg-white/5 rounded-md border-0 py-1.5 pl-28 text-gray-900 dark:text-gray-50 ring-1 ring-inset ring-gray-200 dark:ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
                placeholder="Phone Number"
              />
            </div>
            <cap-form-error-message
              [control]="phone.get('number')!"
            ></cap-form-error-message>
            <cap-form-error-message [control]="phone"></cap-form-error-message>
          </div>

          <div class="col-span-5 sm:col-span-2">
            <label
              for="phone-type"
              class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
              >Phone Type</label
            >
            <div class="mt-2">
              <select
                id="phone-type"
                name="phone-type"
                formControlName="phoneType"
                class="block w-full rounded-md border-0 bg-white/5 dark:bg-gray-800 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
              >
                <option value="Mobile">Mobile</option>
                <option value="Home">Home</option>
                <option value="Office">Office</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>

          <div class="col-span-1">
            <label
              for="primary-phone"
              class="block text-sm text-center font-medium leading-6 text-gray-800 dark:text-gray-50"
              >Primary</label
            >
            <div class="mt-2 h-6 flex justify-center items-end">
              <input
                type="checkbox"
                id="primary-phone"
                name="primary-phone"
                formControlName="isPrimary"
                class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
              />
            </div>
            <cap-form-error-message
              [control]="phone.get('isPrimary')!"
            ></cap-form-error-message>
          </div>
        </div>
      </div>
      <cap-form-error-message [control]="phoneNumbers"></cap-form-error-message>
    </div>

    <!-- End of phone number fields -->
    <div class="col-span-3 sm:col-span-full">
      <div class="w-full border-2 border-gray-100 p-2">
        <label
          for="profile"
          class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
          >Profile</label
        >
        <p-dropdown
          [options]="dropDownProfileOptions"
          formControlName="profile"
          optionLabel="label"
          optionValue="value"
          id="profile"
          [filter]="true"
          filterBy="label"
          [showClear]="true"
          placeholder="Select a Profile"
        >
          <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
              <div>{{ selectedOption.label }}</div>
            </div>
          </ng-template>
          <ng-template let-profile pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ profile.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <cap-form-error-message
        [control]="orgProfileForm.get('profile')!"
      ></cap-form-error-message>
    </div>
    @if ((currentUserRole$ | async)?.name === userRole.SysAdmin) {
      <div class="col-span-3 sm:col-span-full">
        <div class="w-full border-2 border-gray-100 p-2 flex justify-between">
          <label
            for="branding"
            class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
            >Branding</label
          >

          <div class="flex items-center">
            <!-- Enabled: "bg-red-600", Not Enabled: "bg-gray-200" -->
            <button
              type="button"
              class="relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
              [ngClass]="{
                'bg-gray-200 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 cursor-pointer':
                  !orgProfileForm.get('isBranded')?.value,
                'bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 cursor-pointer':
                  orgProfileForm.get('isBranded')?.value
              }"
              role="switch"
              aria-checked="false"
              aria-labelledby="annual-billing-label"
              (click)="toggleBranding()"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                [ngClass]="{
                  'translate-x-5': orgProfileForm.get('isBranded')?.value,
                  'translate-x-0': !orgProfileForm.get('isBranded')?.value
                }"
              ></span>
            </button>
          </div>
        </div>
        <cap-form-error-message
          [control]="orgProfileForm.get('isBranded')!"
        ></cap-form-error-message>
      </div>
    }

    <!-- Address fields  -->
    <div
      class="col-span-3 sm:col-span-full space-y-7"
      formArrayName="addresses"
    >
      <div
        *ngFor="let address of addresses.controls; let addressIndex = index"
        [formGroupName]="addressIndex"
      >
        <div
          class="relative border-2 z-0 rounded-md px-2 py-4 border-gray-100 dark:border-white/10 grid grid-cols-6 gap-x-2 gap-y-7"
        >
          <div class="absolute right-2 top-4 flex space-x-4 -mt-8 mr-2 z-20">
            @if (addresses.controls.length > 1) {
              <!-- Remove Address Button -->
              <button
                type="button"
                (click)="removeAddress(addressIndex)"
                class="rounded-full bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 p-1 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M4.75 9.75h10.5a.75.75 0 010 1.5H4.75a.75.75 0 010-1.5z"
                  />
                </svg>
              </button>
            }

            <!-- Add Address Button -->
            <button
              type="button"
              (click)="addAddressRow()"
              class="rounded-full bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 p-1 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              <svg
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                />
              </svg>
            </button>
          </div>

          <div class="col-span-full">
            <label
              for="street1"
              class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
              >Street 1</label
            >
            <div class="mt-2">
              <input
                type="text"
                id="street1"
                name="street1"
                formControlName="street1"
                class="z-10 block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
            <cap-form-error-message
              [control]="address.get('street1')!"
            ></cap-form-error-message>
          </div>

          <div class="col-span-full">
            <label
              for="street2"
              class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
              >Street 2 (Optional)</label
            >
            <div class="mt-2">
              <input
                type="text"
                id="street2"
                name="street2"
                formControlName="street2"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <!-- City Field -->
          <div class="col-span-2">
            <label
              for="city"
              class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
              >City</label
            >
            <div class="mt-2">
              <input
                type="text"
                id="city"
                name="city"
                formControlName="city"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
            <cap-form-error-message
              [control]="address.get('city')!"
            ></cap-form-error-message>
          </div>

          <!-- State Field -->
          <div class="col-span-2">
            <label
              for="state"
              class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
              >State</label
            >
            <div class="mt-2">
              <input
                type="text"
                id="state"
                name="state"
                formControlName="state"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <!-- Country Field -->
          <div class="col-span-2">
            <label
              for="country"
              class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
              >Country</label
            >
            <div class="relative mt-2">
              <button
                (click)="toggleCountryDropdown(addressIndex)"
                type="button"
                class="px-2 flex flex-row justify-between items-center w-full rounded-md border-0 bg-white/5 dark:bg-gray-800 py-1 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6 truncate"
              >
                @if (address.get("country")?.value) {
                  <div class="flex flex-row">
                    <span
                      class="mr-2"
                      [ngClass]="
                        'fi fi-' +
                        getCountryCodeByName(address.get('country')?.value)
                      "
                    ></span>
                    <p
                      class="text-sm leading-6 text-gray-800 dark:text-gray-50"
                    >
                      {{ address.get("country")?.value }}
                    </p>
                  </div>
                } @else {
                  <p class="text-sm leading-6 text-gray-800 dark:text-gray-50">
                    Select a Country
                  </p>
                }
                <span class="material-symbols-outlined">expand_more</span>
              </button>
              @if (isCountryDropdownOpen[addressIndex]) {
                <ul
                  class="absolute bg-gray-50 dark:bg-gray-800 z-50 mt-1 max-h-52 w-full overflow-auto"
                >
                  @for (code of contriesAndPhoneCodes; track code.countryName) {
                    <li
                      (click)="selectCountry(addressIndex, code.countryName!)"
                      class="z-50 flex flex-row p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                    >
                      <span
                        class="mr-2 z-50"
                        [ngClass]="
                          'fi fi-' + code.countryCode.toLocaleLowerCase()
                        "
                      ></span>
                      <!-- Flag -->
                      <p
                        class="z-50 text-sm leading-6 text-gray-800 dark:text-gray-50"
                      >
                        {{ code.countryName }}
                      </p>
                    </li>
                  }
                </ul>
              }
            </div>
            <cap-form-error-message
              [control]="address.get('country')!"
            ></cap-form-error-message>
          </div>

          <!-- Zip Field -->
          <div class="col-span-2 sm:col-span-1">
            <label
              for="zip"
              class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
              >Zip</label
            >
            <div class="mt-2">
              <input
                type="text"
                id="zip"
                name="zip"
                formControlName="zip"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
            <cap-form-error-message
              [control]="address.get('zip')!"
            ></cap-form-error-message>
          </div>

          <!-- Type Field -->
          <div class="col-span-3 sm:col-span-4">
            <label
              for="address-type"
              class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
              >Address Type</label
            >
            <div class="mt-2">
              <select
                id="address-type"
                name="address-type"
                formControlName="addressType"
                class="block w-full rounded-md border-0 bg-white/5 dark:bg-gray-800 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
              >
                <option value="Office">Office</option>
                <option value="Home">Home</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <cap-form-error-message
              [control]="address.get('addressType')!"
            ></cap-form-error-message>
          </div>

          <div class="col-span-1">
            <label
              for="primary-address"
              class="block text-sm text-center font-medium leading-6 text-gray-800 dark:text-gray-50"
              >Primary</label
            >
            <div class="mt-2 h-6 flex justify-center items-end">
              <input
                type="checkbox"
                id="primary-address"
                name="primary-address"
                formControlName="isPrimary"
                class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
              />
            </div>

            <cap-form-error-message
              [control]="address.get('isPrimary')!"
            ></cap-form-error-message>
          </div>
        </div>
      </div>
      <cap-form-error-message [control]="addresses"></cap-form-error-message>
    </div>

    <!-- End of Adresses Fields -->
  </div>
</form>

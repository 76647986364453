import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cap-edit-button-cell',
  standalone: true,
  imports: [],
  templateUrl: './edit-button-cell.component.html',
  styleUrl: './edit-button-cell.component.scss',
})
export class EditButtonCellComponent {
  @Input() label: string = 'click me';
  @Input() disabled = false;
  @Output() buttonClicked = new EventEmitter<void>();

  onClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.buttonClicked.emit();
  }
}

import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalHostService {
  private modalHost!: ViewContainerRef;

  setModalHost(viewContainerRef: ViewContainerRef) {
    this.modalHost = viewContainerRef;
  }

  getModalHost(): ViewContainerRef {
    return this.modalHost;
  }

  clearModalHost(): void {
    this.modalHost.clear();
  }
}

import { createSelector } from '@ngrx/store';
import { getRouterSelectors } from '@ngrx/router-store';

export const {
  selectRouteParam,
  selectRouteParams,
  selectRouteData,
  selectUrl,
  selectCurrentRoute,
} = getRouterSelectors();

export const selectTokenParam = createSelector(
  selectRouteParams,
  (params) => params['token'] || null
);

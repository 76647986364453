import { Component, inject, Input } from '@angular/core';
import { ModalContainerComponent } from "../modal-container/modal-container.component";
import { IModalFeedbackMetadata } from '../sidebar/sidebar.component';
import { Store } from '@ngrx/store';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FeedbackAppActions } from 'src/app/state/feedback/feedback.actions';
@Component({
  selector: 'cap-user-feedback',
  standalone: true,
  imports: [ModalContainerComponent, ReactiveFormsModule],
  templateUrl: './user-feedback.component.html',
  styleUrl: './user-feedback.component.scss'
})
export class UserFeedbackComponent {
  private store = inject(Store);
  @Input() modalMetadata!: IModalFeedbackMetadata;
  feedbackForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required])
  });


  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.modalMetadata.modalID }));
  }
  onSubmit() {
    this.store.dispatch(FeedbackAppActions.submitFeedback({
      feedback: {
        title: this.feedbackForm.value.title ?? '',
        description: this.feedbackForm.value.description ?? ''
      }
    }));
    this.closeModal();
  }
}

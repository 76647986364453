import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  ISecurityControlBase,
  ISecurityControl,
  IStandardBase,
  IStandard,
} from 'src/app/core/models/security-controls.model';
import { Pagination } from '../users/users.state';

export const SecurityControlsAppActions = createActionGroup({
  source: 'Security Controls',
  events: {
    'Get Security Controls': emptyProps(),
    'Get Security Controls By Pagination': props<{
      page: number;
      limit: number;
    }>(),
    'Get Security Controls By Id': props<{ id: string }>(),
    'Create Security Control': props<{
      securityControl: ISecurityControlBase;
    }>(),
    'Update Security Control': props<{ securityControl: ISecurityControl }>(),
    'Remove Security Control': props<{ id: string }>(),
    'Get Standards': emptyProps(),
    'Get Standard By Pagination': props<{ page: number; limit: number }>(),
    'Get Standard By Id': props<{ id: string }>(),
    'Create Standard': props<{ standard: IStandardBase }>(),
    'Update Standard': props<{ standard: IStandard }>(),
    'Remove Standard': props<{ id: string }>(),
    'Get Security Controls By Standard Id': props<{ standardId: string }>(),
  },
});

export const SecurityControlsAPIActions = createActionGroup({
  source: 'Security Controls',
  events: {
    'Get Security Controls Success': props<{
      securityControls: ISecurityControl[];
    }>(),
    'Get Security Controls Failure': props<{
      message: string;
    }>(),
    'Get Security Controls By Pagination Success': props<{
      securityControls: ISecurityControl[];
      pagination: Pagination;
    }>(),
    'Get Security Controls By Pagination Failure': props<{ message: string }>(),
    'Get Security Controls By Id Success': props<{
      securityControl: ISecurityControl;
    }>(),
    'Get Security Controls By Id Failure': props<{ message: string }>(),
    'Create Security Control Success': props<{
      securityControl: ISecurityControl;
    }>(),
    'Create Security Control Failure': props<{ message: string }>(),
    'Update Security Control Success': props<{
      securityControl: ISecurityControl;
    }>(),
    'Update Security Control Failure': props<{ message: string }>(),
    'Remove Security Control Success': props<{
      securityControlId: string;
      message: string;
    }>(),
    'Remove Security Control Failure': props<{ message: string }>(),
    'Get Standards Success': props<{ standards: IStandard[] }>(),
    'Get Standards Failure': props<{ message: string }>(),
    'Get Standard By Pagination Success': props<{
      standards: IStandard[];
      pagination: Pagination;
    }>(),
    'Get Standard By Pagination Failure': props<{ message: string }>(),
    'Get Standard By Id Success': props<{ standard: IStandard }>(),
    'Get Standard By Id Failure': props<{ message: string }>(),
    'Create Standard Success': props<{ standard: IStandard }>(),
    'Create Standard Failure': props<{ message: string }>(),
    'Update Standard Success': props<{ standard: IStandard }>(),
    'Update Standard Failure': props<{ message: string }>(),
    'Remove Standard Success': props<{ standardId: string; message: string }>(),
    'Remove Standard Failure': props<{ message: string }>(),
    'Get Security Controls By Standard Id Success': props<{
      securityControls: ISecurityControl[];
    }>(),
    'Get Security Controls By Standard Id Failure': props<{
      message: string;
    }>(),
  },
});

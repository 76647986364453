import { createSelector } from '@ngrx/store';

import {
  QuestionsState,
  selectAll,
  selectQuestionsState,
} from './questions.state';

export const selectPagination = createSelector(
  selectQuestionsState,
  (state: QuestionsState) => state.pagination,
);

export const selectQuestionsByCategoryId = (categoryId: string) =>
  createSelector(selectAll, selectPagination, (questions, pagination) => {
    const filteredQuestions = questions.filter(
      (question) => question.categoryId === categoryId,
    );
    return filteredQuestions;
  });

import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule, NgIf } from '@angular/common';
import { Subject, takeUntil, tap } from 'rxjs';

import { ILogin } from '../core/models/login.model';
import { LoginPageComponentStore } from './login-page.store';
import { AuthAPIActions, AuthAppActions } from '../state/auth/auth.actions';
import { AlertActions } from '../state/alert/alert.actions';
import { StepNavigationModalComponent } from '../shared/step-navigation-modal/step-navigation-modal.component';
import { ModalActions } from '../state/modal/modal.actions';
import { ResendOtpComponent } from './resend-otp/resend-otp.component';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { TwoFactorAuthSetupComponent } from './two-factor-auth-setup/two-factor-auth-setup.component';
import { TwoFactorAuthVerificaitonComponent } from './two-factor-auth-verificaiton/two-factor-auth-verificaiton.component';
import { ValidateOtpModalComponent } from './validate-otp-modal/validate-otp-modal.component';
import { IModalConfig } from '../core/models/step-modal-config.model';
import { selectModalVisibility } from '../state/modal/modal.state';
import { AgreementComponent } from './agreement/agreement.component';
import { Reset2FAOtpComponent } from './reset-2fa-otp/reset-2fa-otp.component';
import { Actions, ofType } from '@ngrx/effects';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';
import { ThemeComponent } from '../shared/theme/theme.component';

@Component({
  selector: 'cap-login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  standalone: true,
  providers: [LoginPageComponentStore],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    RouterLink,
    ResendOtpComponent,
    StepNavigationModalComponent,
    ValidateOtpModalComponent,
    Reset2FAOtpComponent,
    ConfirmModalComponent,
    ThemeComponent,
  ],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  @ViewChild('firstLoginSetup')
  firstLoginSetup!: StepNavigationModalComponent;

  @ViewChild('resetTotpSetup')
  resetTotpSetup!: StepNavigationModalComponent;

  @ViewChild('enable2FAAuth') enable2FAAuth!: StepNavigationModalComponent;

  private destory$ = new Subject<void>();

  private readonly route = inject(ActivatedRoute);

  private readonly store = inject(Store);
  private readonly componentStore = inject(LoginPageComponentStore);
  private fb = inject(FormBuilder);
  private actions$ = inject(Actions);

  readonly firsLoginSetupConfig: IModalConfig = {
    modalID: 'first-login-setup-modal',
    steps: [
      {
        title: 'Agreement License',
        component: AgreementComponent,
        onNext: () => this.firstLoginSetup.handleAgreementStep(),
      },
      {
        title: 'Set New Password',
        component: SetNewPasswordComponent,
        onNext: () => this.firstLoginSetup.handleSetNewPasswordStep(),
      },
      {
        title: '(2FA) Auth Setup',
        component: TwoFactorAuthSetupComponent,
        onNext: () => this.firstLoginSetup.handle2FASetupStep(),
      },
      {
        title: '(2FA) Auth Verification',
        component: TwoFactorAuthVerificaitonComponent,
        inputs: {},
        outputs: {
          sendTotpToken: (token: string) =>
            this.firstLoginSetup.handle2FAVerificationStep(token),
        },
        onNext: () => null,
      },
    ],
  };

  readonly resetTotpSetupConfig: IModalConfig = {
    modalID: 'reset-totp-setup-modal',
    steps: [
      {
        title: '(2FA) Auth Setup',
        component: TwoFactorAuthSetupComponent,
        onNext: () => this.resetTotpSetup.handle2FASetupStep(),
      },
      {
        title: '(2FA) Auth Verification',
        component: TwoFactorAuthVerificaitonComponent,
        inputs: {},
        outputs: {
          sendTotpToken: (token: string) =>
            this.resetTotpSetup.handle2FAVerificationStep(token),
        },
        onNext: () => null,
      },
    ],
  };

  public loginForm!: FormGroup;

  public readonly formError$ = this.componentStore.errorMessage$.pipe(
    tap(() => this.loginForm.reset()),
  );

  public readonly loading$ = this.componentStore.isLoading$;

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this.route.queryParams
      .pipe(takeUntil(this.destory$))
      .subscribe((params) => {
        const token = params['token'];
        if (token) {
          this.store.dispatch(AuthAppActions.verifyResetting2FAOTP({ token }));
        }
      });

    this.actions$
      .pipe(
        ofType(AuthAPIActions.verifyResetting2FAOTPSuccess),
        tap(() => {
          this.store.dispatch(
            ModalActions.register({ id: 'reset-totp-setup-modal' }),
          );
          this.store.dispatch(
            ModalActions.open({ id: 'reset-totp-setup-modal' }),
          );
        }),
        takeUntil(this.destory$),
      )
      .subscribe();
  }

  isPasswordVisible$ = this.componentStore.passwordVisibility$;
  togglePasswordVisibility(): void {
    this.componentStore.togglePasswordVisibility();
  }

  public onLoginSubmit(): void {
    if (this.loginForm.invalid) {
      this.loginForm.reset();
      this.store.dispatch(
        AlertActions.addAlert({
          alert: {
            type: 'warning',
            message: 'Please fill all required fields',
          },
        }),
      );
      return;
    }
    const credentials: ILogin = this.loginForm.value;
    this.store.dispatch(AuthAppActions.login({ ...credentials }));
  }

  onOtpExpiredClicked() {
    this.store.dispatch(ModalActions.open({ id: 'resend-otp' }));
  }

  selectModalVisibilityById(id: string) {
    return this.store.select(selectModalVisibility(id));
  }

  ngOnDestroy(): void {
    this.destory$.next();
    this.destory$.complete();
  }
}

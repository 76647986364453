<div class="bg-white dark:bg-gray-900 py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl space-y-16 lg:mx-0 lg:max-w-none">
      <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
        <div>
          <h2
            class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50"
          >
            System Information
          </h2>
          <p class="mt-4 leading-7 text-gray-600 dark:text-gray-400">
            In this section you could checkout front-end and back-end version
            and more informations about system timeout and password
            complexities.
          </p>
        </div>
        <div
          class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8"
        >
          <div class="rounded-2xl bg-gray-50 dark:bg-gray-800 p-10">
            <h3 class="text-base font-semibold leading-7 text-gray-900">
              Frontend version
            </h3>
            <dl
              class="mt-3 space-y-1 text-sm leading-6 text-gray-600 dark:text-gray-400"
            >
              <div>
                <dd>
                  <p
                    class="font-semibold text-primary-700 dark:text-primary-500"
                  >
                    v6.6.8
                  </p>
                </dd>
              </div>
            </dl>
          </div>
          <div class="rounded-2xl bg-gray-50 dark:bg-gray-800 p-10">
            <h3
              class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-50"
            >
              Backend version
            </h3>
            <dl
              class="mt-3 space-y-1 text-sm leading-6 text-gray-600 dark:text-gray-400"
            >
              <div>
                <dt class="sr-only">Version</dt>
                <dd>
                  <p
                    class="font-semibold text-primary-700 dark:text-primary-500"
                  >
                    v6.1.8
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="border-b border-gray-100 dark:border-gray-800"></div>
      <!--        timeout timer-->
      <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
        <div>
          <h2
            class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50"
          >
            Time Out Settings
          </h2>
          <p class="mt-4 leading-7 text-gray-600 dark:text-gray-400">
            In this section you could set the time out for the system. the users
            will be logged out after x minutes of inactivity.
          </p>
        </div>
        <div
          class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8"
        >
          <div class="rounded-2xl bg-gray-50 dark:bg-gray-800 p-10">
            <dl
              class="mt-3 space-y-1 text-sm leading-6 text-gray-600 dark:text-gray-400"
            >
              <div>
                <select
                  id="timeout"
                  name="timeout"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  (change)="updateSettings()"
                  [(ngModel)]="systemTimeout"
                >
                  <option>1</option>
                  <option>5</option>
                  <option>10</option>
                  <option>15</option>
                  <option>30</option>
                </select>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="border-b border-gray-100 dark:border-gray-800"></div>
      <!-- password minimum length-->
      <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
        <div>
          <h2
            class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50"
          >
            Password Complexity
          </h2>
          <p class="mt-4 leading-7 text-gray-600 dark:text-gray-400">
            In this section you could set the minimum length for the password.
          </p>
        </div>
        <div
          class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8"
        >
          <div class="rounded-2xl bg-gray-50 dark:bg-gray-800 p-10">
            <dl
              class="mt-3 space-y-1 text-sm leading-6 text-gray-600 dark:text-gray-400"
            >
              <div>
                <select
                  id="password"
                  name="password"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  (change)="updateSettings()"
                  [(ngModel)]="passwordLength"
                >
                  <option>6</option>
                  <option>8</option>
                  <option>10</option>
                  <option>12</option>
                  <option>14</option>
                  <option>16</option>
                </select>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

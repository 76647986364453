import { inject, Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import {
  AssessmentsAPIActions,
  AssessmentsAppActions,
} from '../../state/assessments/assessments.actions';
import { tap } from 'rxjs';

interface ScrollerState {
  isLoadingScroller: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SharedAssessmentScrollerService
  extends ComponentStore<ScrollerState>
  implements OnDestroy
{
  private actions$ = inject(Actions);
  constructor() {
    super({ isLoadingScroller: true });
  }

  readonly isLoadingScroller$ = this.select((state) => state.isLoadingScroller);
  readonly ScrollerAppActions = this.actions$
    .pipe(
      ofType(
        AssessmentsAppActions.getAssessmentsByUserId,
        AssessmentsAppActions.getAssessmentsByOrganizationId,
      ),
      tap(() => this.patchState({ isLoadingScroller: true })),
    )
    .subscribe();

  readonly ScrollerApiActions = this.actions$
    .pipe(
      ofType(
        AssessmentsAPIActions.getAssessmentsByUserIdSuccess,
        AssessmentsAPIActions.getAssessmentsByOrganizationIdSuccess,
      ),
      tap(() => this.patchState({ isLoadingScroller: false })),
    )
    .subscribe();

  override ngOnDestroy() {
    this.ScrollerAppActions.unsubscribe();
    this.ScrollerApiActions.unsubscribe();
  }
}

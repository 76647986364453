<cap-modal-container
  [modalWidthClass]="modalMetadata.modalWidth"
  [modalID]="modalMetadata.modalID"
>
  <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
    {{ modalMetadata.title }}
  </p>
  @if (loading$ | async) {
    <div
      class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
    >
      <cap-spinner class="z-50" [size]="16"></cap-spinner>
    </div>
  }

  @if (errorMessage$ | async) {
    <cap-feedback type="error" [message]="errorMessage$ | async"></cap-feedback>
  }
  <form [formGroup]="profileForm" (ngSubmit)="submitProfileForm()">
    <div class="min-w-full flex px-4 py-4 gap-x-5">
      <!--      name input -->
      <div>
        <label
          for="name"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
          >Name</label
        >
        <div class="mt-2">
          <input
            type="text"
            name="name"
            id="name"
            formControlName="name"
            class="block w-full rounded-md border-0 py-1.5 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-gray-50 dark:ring-gray-700 dark:placeholder-gray-400 dark:focus:ring-red-600 dark:focus:ring-inset dark:ring-2 dark:ring-gray-700 dark:text-gray-50 dark:shadow-sm dark:ring-1 dark:border-0 dark:py-1.5 dark:text-gray-900 dark:shadow-sm dark:ring-gray-300 dark:ring-inset dark:placeholder-gray-400 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-red-600 dark:sm:text-sm dark:sm:leading-6 dark:rounded-md dark:border-0 dark:py-1.5 dark:text-gray-900 dark:shadow-sm dark:ring-gray-300 dark:ring-inset dark:placeholder-gray-400 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-red-600 dark:sm:text-sm dark:sm:leading-6 dark:rounded-md dark:border-0 dark:py-1.5 dark:text-gray-900 dark:shadow-sm dark:ring-gray-300 dark:ring-inset dark:placeholder:text-gray-400 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-red-600 dark:sm:text-sm dark:sm:leading-6 dark:rounded-md dark:border-0 dark:py-1.5 dark:text-gray-900 dark:shadow-sm dark:ring-gray-300 dark:ring-inset dark:placeholder:text-gray-400 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-red-600 dark:sm:text-sm dark:sm:leading-6 dark:rounded-md dark:border-0 dark:py-1.5 dark:text-gray-900 dark:shadow-sm dark:ring-gray-300 dark:ring-inset dark:placeholder:text-gray-400 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-red-600 dark:sm:text-sm dark:sm:leading-6 dark:rounded-md dark:border-0 dark:py-1.5 dark:text-gray-900 dark:shadow-sm dark:ring-gray-300 dark:ring-inset dark:placeholder:text-gray-400 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-red-600 dark:sm:text-sm dark:sm:leading-6 dark:rounded-md dark:border-0"
            placeholder="New name"
          />
        </div>
      </div>
      <!--      end name input-->

      <!--      enabled start-->
      <div class="flex flex-col gap-3">
        <label>Enabled</label>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button
          type="button"
          (click)="toggleEnabled()"
          [ngClass]="{
            'bg-red-600': profileForm.get('isEnabled')?.value,
            'bg-gray-200': !profileForm.get('Enabled')?.value
          }"
          class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
          role="switch"
          aria-checked="false"
        >
          <span class="sr-only">Use setting</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span
            aria-hidden="true"
            [ngClass]="{
              'translate-x-5': profileForm.get('isEnabled')?.value,
              'translate-x-0': !profileForm.get('isEnabled')?.value
            }"
            class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          ></span>
        </button>
      </div>
      <!--      enabled end-->

      <!--      search and add new category-->
      <div class="flex gap-x-6 items-end">
        <div>
          <label
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >Available Categories</label
          >
          <!--          <p-dropdown-->
          <!--            (onChange)="onCategoryChange($event.value)"-->
          <!--            [options]="availableCategories"-->
          <!--            filter="name"-->
          <!--            optionLabel="name"-->
          <!--            placeholder="Select a Category"-->
          <!--          />-->
          <p-multiSelect
            [options]="availableCategories"
            [formControl]="currentCategory"
            optionLabel="name"
            placeholder="Select Categories"
          >
          </p-multiSelect>
        </div>
        <button
          type="button"
          (click)="addCategory()"
          class="flex items-center justify-center gap-3 px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Add
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
            />
          </svg>
        </button>
      </div>
      <!--      end search and add new category-->
            <div class="flex gap-x-6 items-end">
              <div class="flex flex-col">
                <label
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >Available Service Sources</label>
                <p-multiSelect [options]="(serviceSources| async) || []" [formControl]="selectedServiceSources"  optionLabel="name" placeholder="select Source"></p-multiSelect>

              </div>
            </div>
    </div>
    <cap-form-error-message
      [control]="profileForm.get('name')!"
    ></cap-form-error-message>
    <!--    added Categories list start-->
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="-mx-4 mt-8 sm:-mx-0">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 dark:text-white"
              >
                Category Name
              </th>
              <th
                scope="col"
                class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell dark:text-white"
              >
                Weight
              </th>
              <th
                scope="col"
                class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell dark:text-white"
              >
                Status
              </th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody
            class="divide-y divide-gray-200"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
          >
            @if (addedCategories.length > 0) {
              @for (tabCat of tableCategories; track tabCat) {
                <tr cdkDrag>
                  <td
                    cdkDragHandle
                    class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0 dark:text-white"
                  >
                    {{ tabCat.name }}
                  </td>
                  <td
                    class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell dark:text-white"
                  >
                    {{ convertWeight(tabCat.weight) }}
                  </td>
                  <td
                    class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell dark:text-white"
                  >
                    <button
                      type="button"
                      [ngClass]="{
                        'bg-red-600': tabCat.isActive,
                        'bg-gray-200': !tabCat.isActive
                      }"
                      class="relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 cursor-default"
                      role="switch"
                      aria-checked="false"
                    >
                      <span class="sr-only">Use setting</span>
                      <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                      <span
                        aria-hidden="true"
                        [ngClass]="{
                          'translate-x-5': tabCat.isActive,
                          'translate-x-0': !tabCat.isActive
                        }"
                        class="translate-x-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      ></span>
                    </button>
                  </td>
                  <td
                    class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                  >
                    <button
                      class="text-red-600 hover:text-red-900"
                      (click)="removeCategory(tabCat._id)"
                    >
                      remove
                    </button>
                  </td>
                </tr>
              }
            } @else {
              <tr>
                <td
                  class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white"
                >
                  No categories added
                </td>
              </tr>
            }

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
    <!--    added Categories list end-->

    <div class="w-full flex justify-end">
      <button
        [disabled]="this.profileForm.invalid"
        type="submit"
        class="rounded-md bg-red-500 px-3.5 disabled:bg-red-300 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
      >
        {{ modalMetadata.isEditMode ? "Save" : "Create" }}
      </button>
    </div>
  </form>
</cap-modal-container>

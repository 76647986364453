import { Actions, ofType } from "@ngrx/effects";

import { inject } from "@angular/core";

import { createEffect } from "@ngrx/effects";
import { DiscountApiActions, DiscountAppActions } from "./discount.actions";
import { catchError, map, share, take } from "rxjs/operators";
import { exhaustMap, of } from "rxjs";
import { PricingConfigService } from "src/app/core/services/pricing-config.service";
import { DiscountService } from "src/app/core/services/discount.service";
import { handleError } from "src/app/utils/error-handler.util";

export const getAllDiscountCodes$ = createEffect(
    (actions$ = inject(Actions), discountService = inject(DiscountService)) => {
        return actions$.pipe(
            ofType(DiscountAppActions.getAllDiscountCodes),
            exhaustMap(() => discountService.getAllDiscountCodes().pipe(
                take(1),
                map(({discountCodes}) => DiscountApiActions.getAllDiscountCodesSuccess({discountCodes})),
            )), 
            catchError((error) => {
                const message = handleError(error);
                return of(
                    DiscountApiActions.getAllDiscountCodesFailure({ error: message })
                )
            }),     
            share(),
        )
    },  
    { functional: true },
) 

export const createDiscountCode$ = createEffect(
    (actions$ = inject(Actions), discountService = inject(DiscountService)) => {
        return actions$.pipe(
            ofType(DiscountAppActions.createDiscountCode),
            exhaustMap(({discountCode}) => discountService.createDiscountCode(discountCode).pipe(
                take(1),
                map(({discountCode}) => DiscountApiActions.createDiscountCodeSuccess({ discountCode })),
            )),
            catchError((error) => {
                const message = handleError(error);
                return of(
                    DiscountApiActions.createDiscountCodeFailure({ error: message })
                )
            }),
            share(),
        )
    },
    { functional: true },
)

export const deleteDiscountCode$ = createEffect(
    (actions$ = inject(Actions), discountService = inject(DiscountService)) => {
        return actions$.pipe(
            ofType(DiscountAppActions.deleteDiscountCode),
            exhaustMap(({id}) => discountService.deleteDiscountCode(id).pipe(
                take(1),
                map(({id}) => DiscountApiActions.deleteDiscountCodeSuccess({ id })),
            )),
            catchError((error) => {
                const message = handleError(error);
                return of(
                    DiscountApiActions.deleteDiscountCodeFailure({ error: message })
                )
            }),
            share(),
        )
    },
    { functional: true },
)
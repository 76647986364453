import { Injectable } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import {
  QuestionsAPIActions,
  QuestionsAppActions,
} from '../../state/questions/questions.actions';
import { ComponentStore } from '@ngrx/component-store';
import {
  ProfilesApiActions,
  ProfilesAppActions,
} from '../../state/profiles/profiles.actions';

@Injectable({
  providedIn: 'root',
})
export class ProfileStore extends ComponentStore<{
  isLoading: boolean;
  errorMessage: string | null;
}> {
  subDestroy$ = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({ isLoading: false, errorMessage: null });
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);

  private readonly appActionSub = this.actions$
    .pipe(
      ofType(
        ProfilesAppActions.createProfile,
        ProfilesAppActions.updateProfile,
        ProfilesAppActions.deleteProfile,
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private readonly apiSuccessActionSub = this.actions$
    .pipe(
      ofType(
        ProfilesApiActions.createProfileSuccess,
        ProfilesApiActions.updateProfileSuccess,
        ProfilesApiActions.deleteProfileSuccess,
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private readonly apiFailureActionSub = this.actions$
    .pipe(
      ofType(
        ProfilesApiActions.createProfileFailure,
        ProfilesApiActions.updateProfileFailure,
        ProfilesApiActions.deleteProfileFailure,
      ),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message }),
      ),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

import { createSelector } from '@ngrx/store';

import { selectUser } from './auth.state';
import {
  selectOrganizationsState,
  selectEntities as selectOrganizationEntities,
} from '../organizations/organizations.state'; // Adjust the import path according to your project structure

export const selectUserAvatars = createSelector(
  selectUser,
  (user) => user?.avatars || null,
);

export const selectUserEmail = createSelector(
  selectUser,
  (user) => user?.email || null,
);

export const selectUserRole = createSelector(selectUser, (user) => user?.role);

export const selectCurrentUserAvatar = createSelector(
  selectUserAvatars,
  (avatars) => {
    if (!avatars) {
      return null;
    }

    const currentAvatar = avatars.find((avatar) => avatar.isCurrent);
    if (!currentAvatar) {
      return null;
    }
    return currentAvatar;
  },
);

export const selectCurrentUserOldAvatars = createSelector(
  selectUserAvatars,
  (avatars) => {
    if (!avatars) {
      return null;
    }
    const oldAvatars = avatars.filter((avatar) => !avatar.isCurrent);
    if (!oldAvatars) {
      return null;
    }
    return oldAvatars;
  },
);

export const selectCurrentUserOrganization = createSelector(
  selectUser, // First input selector: gets the current user
  selectOrganizationEntities, // Additional selector to get the organizations state
  (user, entities) => {
    // Check if the user exists and has an organization ID

    if (user && user.organization) {
      // Use the organization entities to find the user's organization by ID
      const organization = entities[user.organization];
      return organization; // Return the organization or null if not found
    }
    return null; // Return null if the user doesn't exist or doesn't have an organization ID
  },
);

<form [formGroup]="otpForm" class="px-4 py-6 space-y-5">
  <p class="text-gray-900 dark:text-gray-50">
    Check your authenticator app, and type the authentication code.
  </p>

  <div formArrayName="digits" class="flex space-x-2 justify-center">
    <ng-container *ngFor="let control of digitControls; let i = index">
      <input
        #digitInput
        [formControlName]="i"
        id="digit-{{ i }}"
        type="text"
        maxlength="1"
        class="block w-12 h-12 rounded-md border-0 bg-white/5 text-center text-lg text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
        (keyup)="focusNext(i, $event)"
        (paste)="handlePaste($event)"
      />
    </ng-container>
  </div>

  <div *ngIf="otpForm.invalid && otpForm.touched" class="text-red-700">
    <p *ngIf="otpForm.errors?.['required']">Token is required.</p>
    <p *ngIf="otpForm.errors?.['pattern']">Please enter a valid token.</p>
  </div>
</form>

import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  map,
  of,
  takeUntil,
  tap,
  take,
} from 'rxjs';

import { TableComponent } from 'src/app/shared/table/table.component';
import { IColumn } from 'src/app/core/models/column.model';
import { VerticalStepNavigationModalComponent } from 'src/app/shared/vertical-step-navigation-modal/vertical-step-navigation-modal.component';
import { IVerticalModalConfig, IVerticalStep } from 'src/app/core/models/vertical-step.model';
import { ChangeAvatarComponent } from 'src/app/shared/change-avatar/change-avatar.component';
import { GridListComponent } from 'src/app/shared/grid-list/grid-list.component';
import { ProfileContext } from 'src/app/core/models/profile-form-context.model';
import { Store } from '@ngrx/store';
import { RolesAppActions } from 'src/app/state/roles/roles.actions';
import { selectAll as selectRoles } from 'src/app/state/roles/roles.state';
import { IAvatar, UserRole } from 'src/app/core/models/user.model';
import { FormGroup, FormsModule } from '@angular/forms';
import { IOrganization } from 'src/app/core/models/organization.model';
import {
  OrganizationsAPIActions,
  OrganizationsAppActions,
} from 'src/app/state/organizations/organizations.actions';
import {
  selectOldAvatarsByOrganizationId,
  selectOrganizationCurrentAvatar,
  selectOrganizationsWithCurrentAvatar,
  selectPaginatedOrganizations,
  selectPagination,
} from '../../state/organizations/organizations.selectors';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { convertOrganizationToOrganizationTable } from 'src/app/utils/entity-formatting.util';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { selectModalVisibility } from 'src/app/state/modal/modal.state';
import {
  selectOrganizationById,
  selectTotal as selectTotalOrganizationsCount,
} from 'src/app/state/organizations/organizations.state';
import { OrganizationProfileFromComponent } from 'src/app/shared/organization-profile-from/organization-profile-from.component';
import { selectUser } from 'src/app/state/auth/auth.state';
import { Actions, ofType } from '@ngrx/effects';
import { PaginationComponent } from '../../shared/pagination/pagination.component';
import { IProfile } from '../../core/models/profiles.model';
import { selectProfiles } from '../../state/profiles/profiles.selectors';
import { ProfilesAppActions } from '../../state/profiles/profiles.actions';
import { PaymentFormComponent } from 'src/app/shared/payment-form/payment-form.component';
import { DisableSubscriptionRenewalComponent } from 'src/app/shared/disable-subscription-renewal/disable-subscription-renewal.component';

const ROLES_REQUIRING_ORGANIZATION = [
  UserRole.Individual,
  UserRole.OrgAdmin,
  UserRole.OrgManager,
  UserRole.OrgStaff,
];

@Component({
  selector: 'cap-organizations-list',
  standalone: true,
  templateUrl: './organizations-list.component.html',
  styleUrl: './organizations-list.component.scss',
  imports: [
    CommonModule,
    TableComponent,
    VerticalStepNavigationModalComponent,
    ConfirmModalComponent,
    PaginationComponent,
    FormsModule,
  ],
})
export class OrganizationsListComponent implements OnInit, OnDestroy {
  page: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  totalItems: number = 0;
  organizationDropped: IOrganization | null = null;

  destroy$ = new Subject<void>();
  orgData$!: Observable<any>;
  readonly confirmModalData = {
    title: 'Delete Organization',
    message: 'Are you sure you want to delete this organization?',
  };

  private readonly store = inject(Store);
  organizations$ = this.store.select(selectOrganizationsWithCurrentAvatar);
  currentUser$ = this.store.select(selectUser);
  paginatedOrganizations$ = this.store.select(selectPaginatedOrganizations);
  isLoadingOrganizaitonList: boolean = false;
  searchQuery: string = '';
  currentUserRole$ = this.store.select(selectUser).pipe(
    map((user) => user?.role?.name),
  );

  @ViewChild('addOrganization')
  addOrganizationSetup!: VerticalStepNavigationModalComponent;

  @ViewChild('updateOrganization')
  updateOrganizationSetup!: VerticalStepNavigationModalComponent;

  addOrganizationSetupConfig$!: Observable<IVerticalModalConfig>;
  updateOrganizationSetupConfig$!: Observable<IVerticalModalConfig>;

  private actions$ = inject(Actions);
  profiles: IProfile[] = [];
  private paymentIntentId: string | null = null;
  private subscriptionId: string | null = null;
  private subscriptionStatus: string | null = null;
  private subscriptionStartTime: Date | null = null;
  private subscriptionEndTime: Date | null = null;
  customerDetails: {
    name: string;
    phone: string;
    country: string;
    description: string;
  } | undefined;
  customerEmail: string | undefined;

  ngOnInit(): void {
    this.store.dispatch(ProfilesAppActions.getProfiles());
    this.store.dispatch(RolesAppActions.getRoles());
    this.store.dispatch(
      OrganizationsAppActions.getOrganizations({
        page: this.page,
        limit: this.pageSize,
      }),
    );
    this.actions$
      .pipe(
        ofType(OrganizationsAppActions.getOrganizations),
        tap(() => (this.isLoadingOrganizaitonList = true)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(
          OrganizationsAPIActions.getOrganizationsSuccess,
          OrganizationsAPIActions.getOrganizationsFailure,
        ),
        tap(() => (this.isLoadingOrganizaitonList = false)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    combineLatest([
      this.store.select(selectTotalOrganizationsCount),
      this.store.select(selectProfiles),
    ])
      .pipe(
        take(1),
        tap(([orgCount, profiles]) => {
          this.profiles = profiles;
          if (orgCount === 0 && profiles.length > 0) {
            this.store.dispatch(
              OrganizationsAppActions.getOrganizations({
                page: this.page,
                limit: this.pageSize,
              }),
            );
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.orgData$ = combineLatest([
      this.organizations$,
      this.store.select(selectProfiles),
    ]).pipe(
      map(([organizations, profiles]) =>
        organizations.map((org) =>
          convertOrganizationToOrganizationTable(org, profiles, organizations),
        ),
      ),
    );

    this.store.select(selectPagination).subscribe((pagination) => {
      this.page = pagination.page;
      this.pageSize = pagination.limit;
      this.totalPages = pagination.totalPages;
      this.totalItems = pagination.totalCount;
    });

    const roles$ = this.store.select(selectRoles);

    this.addOrganizationSetupConfig$ = combineLatest([
      roles$,
      this.organizations$,
      this.currentUser$,
      this.currentUserRole$,
    ]).pipe(
      map(([roles, organizations, currentUser, currentUserRole]) => {
        const isSysAdminOrSuperAdmin = 
          currentUserRole === UserRole.SysAdmin || 
          currentUserRole === UserRole.SuperAdmin;

          this.customerDetails = {
            name: `${currentUser?.firstName} ${currentUser?.lastName}`,
            phone: currentUser?.phoneNumbers?.[0]?.number || '',
            country: currentUser?.addresses?.[0]?.country || '',
            description: `Organization created by ${currentUser?.email}`
          }
          this.customerEmail = currentUser?.email;
          

        const baseSteps: IVerticalStep[] = [
          {
            title: 'Add Organization',
            description: 'Fill the form to add a new organization',
            component: OrganizationProfileFromComponent,
            inputs: {
              formInput$: of({
                context: ProfileContext.OrgCreation,
                data: null,
              }),
              roles: roles,
            },
            outputs: {
              orgProfileFormChanged: (profile: FormGroup) =>
                this.addOrganizationSetup.handleProfileChange(profile),
            },
          },
          {
            title: 'Upload Logo',
            description: 'Upload an logo for your organization',
            component: ChangeAvatarComponent,
            inputs: {
              oldAvatars: null,
              selectedAvatar: null,
              currentAvatar: null,
            },
            outputs: {
              avatarRemoved: (avatar: IAvatar) =>
                this.addOrganizationSetup.removeAvatar(avatar),
              avatarSelected: (avatar: IAvatar) =>
                this.addOrganizationSetup.selectAvatar(avatar),
              avatarUpdated: (avatar: IAvatar) =>
                this.addOrganizationSetup.updateAvatar(avatar),
            },
          },
          {
            title: 'Choose a parent organization',
            description:
              'Choose an organization to set it as a parent for the new organization',
            component: GridListComponent,
            inputs: {
              items: organizations,
              selectedItem: ROLES_REQUIRING_ORGANIZATION.includes(
                currentUser?.role?.name!,
              )
                ? organizations.find(
                    (org) => org._id === currentUser?.organization,
                  )
                : null,
            },
            outputs: {
              itemSelected: (item: IOrganization) =>
                this.addOrganizationSetup.onParentOrgSelected(item),
            },
          },
        ];

        const steps: IVerticalStep[] = [...baseSteps];

        if (!isSysAdminOrSuperAdmin) {
          const paymentStep: IVerticalStep = {
            title: 'Payment',
            description: 'Pay for the new organization',
            component: PaymentFormComponent,
            inputs: {
              featureType: 'organization',
            },
            outputs: {
              paymentCompleted: ({ success, paymentIntentId, subscriptionId, subscriptionStatus, subscriptionStartTime, subscriptionEndTime, discountCode, fullyDiscounted }: { 
                success: boolean; 
                paymentIntentId: string | null;
                subscriptionId: string | null;
                subscriptionStatus: string | null;
                subscriptionStartTime: number | null;
                subscriptionEndTime: number | null;
                discountCode: string | null;
                fullyDiscounted: boolean;
              }) => {
                if ((success && paymentIntentId) || fullyDiscounted) {
                  this.addOrganizationSetup.handlePaymentComplete({
                    success,
                    paymentIntentId,
                    subscriptionId,
                    subscriptionStatus,
                    subscriptionStartTime,
                    subscriptionEndTime,
                    discountCode,
                    fullyDiscounted,
                  });
                  this.addOrganizationSetup.moveNextStep();
                }
              },
            },
          };
          steps.push(paymentStep);
        }

        return {
          modalID: 'add-organization',
          modalWidth: 'sm:max-w-4xl',
          context: ProfileContext.OrgCreation,
          title: 'Add Organization',
          steps,
        };
      }),
    );
  }

  selectModalVisibilityById(id: string) {
    return this.store.select(selectModalVisibility(id));
  }

  openModal() {
    this.addOrganizationSetupConfig$
      .pipe(
        tap((config) => {
          this.store.dispatch(ModalActions.register({ id: config.modalID }));
          this.store.dispatch(ModalActions.open({ id: config.modalID }));
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  readonly columns: IColumn[] = [
    {
      key: 'avatar',
      label: 'Logo',
      type: 'avatar',
      sortable: false,
    },
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: true,
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      sortable: false,
    },
    {
      key: 'isActive',
      label: 'Enabled',
      type: 'button',
      sortable: true,
    },
    {
      key: 'profile',
      label: 'Attached Profile',
      type: 'text',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      type: 'date',
      sortable: true,
    },
    {
      key: 'parentOrg',
      label: 'Parent Organization',
      type: 'text',
      sortable: false,
    },
    {
      key: 'lastAssessmentTime',
      label: 'Last Assessment Time',
      type: 'date',
      sortable: true,
    },
  ];

  callUpdateOrganizationModal(orgId: string) {
    const organizationCurrentAvatar$ = this.store.select(
      selectOrganizationCurrentAvatar(orgId),
    );
    const organizationOldAvatars$ = this.store.select(
      selectOldAvatarsByOrganizationId(orgId),
    );

    this.updateOrganizationSetupConfig$ = combineLatest([
      this.organizations$.pipe(
        map((orgs) => orgs.filter((org) => org._id !== orgId)),
      ),
      this.store.select(selectOrganizationById(orgId)),
      organizationCurrentAvatar$,
      organizationOldAvatars$,
    ]).pipe(
      map(([organizations, currentOrg, currentAvatar, oldAvatars]) => ({
        modalID: 'update-organization',
        modalWidth: 'sm:max-w-4xl',
        context: ProfileContext.OrgUpdate,
        title: 'Update Organization',
        steps: [
          // First step remains the same
          {
            title: 'Update Organization',
            description: 'Fill the form to update the current organization',
            component: OrganizationProfileFromComponent,
            inputs: {
              formInput$: of({
                context: ProfileContext.OrgUpdate,
                data: currentOrg,
              }),
            },
            outputs: {
              orgProfileFormChanged: (profile: FormGroup) =>
                this.updateOrganizationSetup.handleProfileChange(profile),
            },
          },
          // Second step with updated inputs using the combined observables
          {
            title: 'Upload Logo',
            description: 'Upload an logo for your organization',
            component: ChangeAvatarComponent,
            inputs: {
              oldAvatars: oldAvatars,
              selectedAvatar: null, // Keep as is or modify as per requirement
              currentAvatar: currentAvatar,
            },
            outputs: {
              avatarRemoved: (avatar: IAvatar) =>
                this.updateOrganizationSetup.removeAvatar(avatar),
              avatarSelected: (avatar: IAvatar) =>
                this.updateOrganizationSetup.selectAvatar(avatar),
              avatarUpdated: (avatar: IAvatar) =>
                this.updateOrganizationSetup.updateAvatar(avatar),
            },
          },
          // Third and subsequent steps remain the same
          {
            title: 'Choose a parent organization',
            description:
              'Choose an organization to set it as a parent for the current organization',
            component: GridListComponent,
            inputs: {
              items: organizations.filter((org) => org !== currentOrg),
              selctedItem:
                organizations.find(
                  (org) => org._id === currentOrg?.parentOrg,
                ) ?? null,
            },
            outputs: {
              itemSelected: (item: IOrganization) =>
                this.updateOrganizationSetup.onParentOrgSelected(item),
            },
          },
          {
            title: 'Subscription',
            description: 'Disable the subscription renewal for the organization',
            component: DisableSubscriptionRenewalComponent,
            inputs: {
              organizationId: currentOrg?._id,
              subscriptionStatus: currentOrg?.subscriptionStatus,
              subscriptionStartTime: currentOrg?.subscriptionStartTime,
              subscriptionEndTime: currentOrg?.subscriptionEndTime,
            },
            outputs: {
              subscriptionRenewalDisabled: (organizationId: string) => {
                this.disableSubscriptionRenewal(organizationId);
              },
              subscriptionRenewalEnabled: (organizationId: string) => {
                this.enableSubscriptionRenewal(organizationId);
              },
            },
          },
        ],
      })),
    );

    this.store.dispatch(ModalActions.open({ id: 'update-organization' }));
  }

  callConfirmationModal(organization: any) {
    this.organizationDropped = organization;
    this.store.dispatch(
      ModalActions.open({ id: 'confirm-remove-organization' }),
    );
  }

  removeOrganization() {
    if (this.organizationDropped)
      this.store.dispatch(
        OrganizationsAppActions.removeOrganization({
          id: this.organizationDropped._id,
        }),
      );
  }

  disableSubscriptionRenewal(organizationId: string) {
    this.store.dispatch(
      OrganizationsAppActions.disableSubscriptionRenewal({
        organizationId: organizationId,
      }),
    );
  }

  onPageChange($event: number) {
    this.store.dispatch(
      OrganizationsAppActions.getOrganizations({
        page: $event,
        limit: this.pageSize,
      }),
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next(), this.destroy$.complete();
  }

  searchOrganizations() {
    this.store.dispatch(
      OrganizationsAppActions.searchOrganizations({
        search: this.searchQuery,
        page: this.page.toString(),
        limit: this.pageSize.toString(),
      }),
    );
  }

  updateOrganizationStatus(obj: { effectedEntity: string; value: boolean }) {
    this.store.dispatch(
      OrganizationsAppActions.updateOrganizationStatus({
        organizationId: obj.effectedEntity,
        isActive: obj.value,
      }),
    );
  }

  enableSubscriptionRenewal(organizationId: string) {
    this.store.dispatch(
      OrganizationsAppActions.enableSubscriptionRenewal({
        organizationId: organizationId,
      }),
    );
  }

  createOrganization(organizationData: any) {
    // Include payment intent ID in organization creation request
    const organizationWithPayment = {
      ...organizationData,
      paymentIntentId: this.paymentIntentId,
      featureType: 'organization',
      customerDetails: this.customerDetails,
      customerEmail: this.customerEmail,
      subscriptionId: this.subscriptionId,
      subscriptionStatus: this.subscriptionStatus,
      subscriptionStartTime: this.subscriptionStartTime,
      subscriptionEndTime: this.subscriptionEndTime,
    };
    this.store.dispatch(
      OrganizationsAppActions.createOrganization({
        organization: organizationWithPayment,
      })
    );
    
    // Clear payment intent ID after use
    this.paymentIntentId = null;
    this.subscriptionId = null;
    this.subscriptionStatus = null;
    this.subscriptionStartTime = null;
    this.subscriptionEndTime = null;
  }
}

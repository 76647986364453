import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { ConfirmModalState, ConfirmModalStore } from './confirm-modal.store';
import { Store } from '@ngrx/store';
import { ModalActions } from 'src/app/state/modal/modal.actions';

@Component({
  selector: 'cap-confirm-modal',
  standalone: true,
  imports: [CommonModule, NgIf, ModalContainerComponent],
  providers: [ConfirmModalStore],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss',
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  @Output() confirmEvent: EventEmitter<void> = new EventEmitter<void>();

  @Input() modalID = 'confirm';
  @Input() set data(value: ConfirmModalState) {
    if (value) {
      this.componentStore.setTitle(value.title);
      this.componentStore.setMessage(value.message);
    }
  }

  private readonly store = inject(Store);
  private readonly componentStore = inject(ConfirmModalStore);

  title$ = this.componentStore.title$;
  message$ = this.componentStore.message$;

  ngOnInit(): void {
    this.store.dispatch(ModalActions.register({ id: this.modalID }));
  }

  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.modalID }));
  }

  confirm() {
    this.confirmEvent.emit();
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.store.dispatch(ModalActions.unregister({ id: this.modalID }));
  }
}

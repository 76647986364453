import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit, Signal, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { ICreateDiscountCode, IDiscount } from 'src/app/core/models/discount.model';
import { ToggleButtonComponent } from 'src/app/shared/toggle-button/toggle-button.component';
import { OrganizationsAppActions } from 'src/app/state/organizations/organizations.actions';
import { selectOrganizations } from 'src/app/state/organizations/organizations.selectors';
import { PricingConfigAppActions } from 'src/app/state/pricing-config/pricing-config.actions';
import { selectAllPricingConfigs } from 'src/app/state/pricing-config/pricing-config.selectors';
import { TableComponent } from "../../../shared/table/table.component";
import { selectAllDiscountCodes } from 'src/app/state/discount/discount.selectors';
import { IColumn } from 'src/app/core/models/column.model';
import { DiscountAppActions } from 'src/app/state/discount/discount.actions';
import { convertDiscountCodeToDiscountCodeTable } from 'src/app/utils/entity-formatting.util';

@Component({
  selector: 'cap-pricing-page',
  standalone: true,
  imports: [ToggleButtonComponent, FormsModule, AsyncPipe, TableComponent],
  templateUrl: './pricing-page.component.html',
  styleUrl: './pricing-page.component.scss'
})
export class PricingPageComponent implements OnInit{
  private store = inject(Store);
  private updatedPrices = new Map<string, number>()
  pricingConfigs$ = this.store.select(selectAllPricingConfigs);
  organizations$ = this.store.select(selectOrganizations).pipe(map((organizations) => organizations.map((organization) => ({name: organization.name, id: organization._id}))));
  discountCodes$ = this.store.select(selectAllDiscountCodes).pipe(map((discountCodes) => discountCodes.map((discountCode) => convertDiscountCodeToDiscountCodeTable(discountCode, this.pricingConfigs$))));
  paymentSystemStatus: boolean = false;
  isEditingPrice = signal(false);
  isCreatingDiscountCode = signal(false);
  discountCode: ICreateDiscountCode = {
    code: '',
    discount: 0,
    belongTo: '',
    isOneTime: false,
    effectedEntity: {
      entityType: 'individual',
      entityId: ''
    }
  };
  discountCodeColumns: IColumn[] = [
    {key: 'code', label: 'Code',type: 'text', sortable: false},
    {key: 'discount', label: 'Discount',type: 'text', sortable: false},
    {key: 'belongTo', label: 'Belong To',type: 'text', sortable: false},
    {key: 'isOneTime', label: 'Is One Time',type: 'text', sortable: false},
  ]

  ngOnInit(): void {
    this.store.dispatch(PricingConfigAppActions.getAllPricingConfigs());
    this.store.dispatch(OrganizationsAppActions.getOrganizations({}));
    this.store.dispatch(DiscountAppActions.getAllDiscountCodes());
  }


onToggleButtonChange($event: any) {
throw new Error('Method not implemented.');
}

onEditPricing(){
  this.isEditingPrice.update(() => true);
}

onSavePricing(){
  this.isEditingPrice.update(() => false);
  this.store.dispatch(PricingConfigAppActions.updatePricingConfigPrice({prices: Array.from(this.updatedPrices.entries()).map(([id, price]) => ({_id: id, price}))}))
}

onToggleCreateDiscountCode(){
  this.isCreatingDiscountCode.update(() => true);
}

onSaveDiscountCode(){
  if (this.discountCode.belongTo === 'organization') {
    this.discountCode.effectedEntity = {
      entityType: 'organization',
      entityId: this.discountCode.effectedEntity?.entityId || ''
    };
  } else if (this.discountCode.belongTo === 'individual') {
    this.discountCode.effectedEntity = {
      entityType: 'individual',
      entityId: ''
    };
  }

  this.store.dispatch(DiscountAppActions.createDiscountCode({discountCode: this.discountCode}));
  this.isCreatingDiscountCode.update(() => false);
  this.discountCode = {
    code: '',
    discount: 0,
    belongTo: '',
    isOneTime: false,
    effectedEntity: {
      entityType: 'individual',
      entityId: ''
    }
  };
}

onDeleteDiscountCode(entity:any){
  this.store.dispatch(DiscountAppActions.deleteDiscountCode({id: entity._id}));
}

onPriceChange(id: string, price: number){
  this.updatedPrices.set(id, price);
}

onToggleOneTime($event: boolean){
  this.discountCode.isOneTime = $event;
}

onToggleOneTimeUse(event: any){
  this.discountCode.isOneTime = event;
}

}

interface pricingElement {
  name: string;
  id: string;
  price: number;
  description: string;
  features: string[];
}

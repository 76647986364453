import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

export interface INewPasswordVisibility {
  np: boolean;
  cnp: boolean;
}

@Injectable()
export class SetNewPasswordStore
  extends ComponentStore<{
    newPasswordFormVisibility: INewPasswordVisibility;
  }>
  implements OnDestroy
{
  constructor() {
    super({
      newPasswordFormVisibility: { np: false, cnp: false },
    });
  }

  npVisibility$ = this.select((state) => state.newPasswordFormVisibility.np);
  cnpVisibility$ = this.select((state) => state.newPasswordFormVisibility.cnp);

  readonly togglePasswordVisibility = this.updater(
    (state, type: 'np' | 'cnp') => ({
      ...state,
      newPasswordFormVisibility: {
        ...state.newPasswordFormVisibility,
        [type]: !state.newPasswordFormVisibility[type],
      },
    })
  );
}

<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
  <div class="lg:grid lg:grid-cols-3 lg:gap-x-8">
    <div class="lg:col-span-1">
      <h2 class="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">
        Personal Information
      </h2>
      <p class="text-sm text-gray-500 dark:text-gray-400">
        Use a permanent address where you can receive mail.
      </p>
    </div>
    <div class="mt-5 lg:mt-0 lg:col-span-2">
      <div class="space-y-6">
        <cap-avatar-upload
          [avatars]="avatars$ | async"
          (avatarUpdated)="onAvatarUpdated($event)"
          (avatarRemoved)="onAvatarRemoved($event)"
          class="mb-6"
        ></cap-avatar-upload>
        
        @if (errorMessage$ | async) {
          <cap-feedback type="error" [message]="errorMessage$ | async" class="mb-4"></cap-feedback>
        }
        
        <div class="relative">
          @if (loading$ | async) {
            <div class="absolute inset-0 bg-gray-100 dark:bg-gray-800 bg-opacity-50 flex items-center justify-center">
              <cap-spinner [size]="16"></cap-spinner>
            </div>
          }
          
          <cap-profile-form
            [formInput]="userFormInput"
            (profileFormChanged)="onProfileChanged($event)"
          ></cap-profile-form>
        </div>
        
        <div class="flex justify-end mt-6">
          <button
            type="submit"
            [disabled]="!(profileForm$ | async)?.valid"
            (click)="onProfileFormSubmit()"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed transition duration-150 ease-in-out"
          >
            Update Profile
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import {
  IActionItemBase,
  IActionItem,
  FileWithPreview,
  IDoc,
} from '../models/action-items.model';

@Injectable({
  providedIn: 'root',
})
export class ActionItemsService {
  private http = inject(HttpClient);
  private actionItemsPath = 'action-items';
  private questionsPath = 'questions';

  getActionItems(): Observable<{ actionItems: IActionItem[] }> {
    return this.http.get<{ actionItems: IActionItem[] }>(
      `${environment.apiUrl}/${this.actionItemsPath}`,
    );
  }

  getActionItemsById(
    actionItemId: string,
  ): Observable<{ actionItem: IActionItem }> {
    return this.http.get<{ actionItem: IActionItem }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${actionItemId}`,
    );
  }

  createActionItem(
    actionItem: IActionItemBase,
  ): Observable<{ actionItem: IActionItem }> {
    return this.http.post<{ actionItem: IActionItem }>(
      `${environment.apiUrl}/${this.actionItemsPath}`,
      actionItem,
    );
  }

  updateActionItem(
    actionItem: IActionItem,
  ): Observable<{ actionItem: IActionItem }> {
    return this.http.put<{ actionItem: IActionItem }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${actionItem._id}`,
      actionItem,
    );
  }

  removeActionItem(
    actionItemId: string,
  ): Observable<{ actionItemId: string; message: string }> {
    return this.http.delete<{ actionItemId: string; message: string }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${actionItemId}`,
    );
  }

  getActionItemsByQuestionId(
    questionId: string,
  ): Observable<{ actionItems: IActionItem[] }> {
    return this.http.get<{ actionItems: IActionItem[] }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${this.questionsPath}/${questionId}`,
    );
  }

  updateActionItemControls(
    actionItemId: string,
    controls: string[],
  ): Observable<{ actionItem: IActionItem }> {
    return this.http.patch<{ actionItem: IActionItem }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${actionItemId}/controls`,
      { controls },
    );
  }

  updateActionItemServices(
    actionItemId: string,
    services: string[],
  ): Observable<{ actionItem: IActionItem }> {
    return this.http.patch<{ actionItem: IActionItem }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${actionItemId}/services`,
      { services },
    );
  }

  uploadActionItemDocs(
    actionItemId: string,
    files: FileWithPreview[],
  ): Observable<{ actionItemId: string; docs: IDoc[] }> {
    const formData = new FormData();
    files.forEach((file) => {
      if (file._file) formData.append('files', file._file);
    });
    return this.http.patch<{ actionItemId: string; docs: IDoc[] }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${actionItemId}/docs`,
      formData,
    );
  }

  removeActionItemDoc(
    actionItemId: string,
    docId: string,
  ): Observable<{ actionItemId: string; doc: IDoc }> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { docId },
    };

    return this.http.delete<{ actionItemId: string; doc: IDoc }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${actionItemId}/docs`,
      options,
    );
  }

  updateActionItemsSortOrder(
    actionItemId: string,
    previousIndex: number,
    currentIndex: number,
  ): Observable<{ message: string }> {
    return this.http.patch<{ message: string }>(
      `${environment.apiUrl}/${this.actionItemsPath}/${actionItemId}/sort-order`,
      { previousIndex, currentIndex },
    );
  }
}

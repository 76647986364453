<div class="mt-3 px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1
        class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50"
      >
        Users
      </h1>
      <p class="mt-2 text-sm text-gray-700 dark:text-gray-400">
        A list of all the users in your account including their name, email,
        phone number, role and more.
      </p>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <input
        type="text"
        placeholder="Search users..."
        class="w-full border border-gray-300 rounded-md px-3 py-2 dark:bg-slate-700 dark:text-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        [(ngModel)]="searchQuery"
        (input)="searchUsers()"
      />
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <button
        (click)="openModal()"
        type="button"
        class="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add user
      </button>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <button
      (click)="openUsersImport()"
      type="button"
      class="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Import users
    </button>
    </div>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        @if (!(loading$ | async)) {
          <cap-table
            (onEntityUpdated)="openUpdateUserModal($event)"
            (onEntityDeleted)="callConfirmationModal($event)"
            (OnToggleClicked)="updateUserStatus($event)"
            [columns]="columns"
            [data]="(usersData$ | async) ?? []"
          ></cap-table>
          <cap-pagination
            [current_page]="page"
            [pageSize]="pageSize"
            [totalItems]="totalItems"
            [totalPages]="totalPages"
            (pageChange)="onPageChange($event)"
          ></cap-pagination>
        } @else {
          <div
            class="rounded-md w-full"
            *ngFor="let item of [].constructor(5); let i = index"
          >
            <div class="animate-pulse flex space-x-4">
              <div
                class="rounded-full bg-gray-200 dark:bg-slate-700 h-10 w-10"
              ></div>
              <div class="flex-1 space-y-6 py-1">
                <div class="h-2 bg-gray-200 dark:bg-slate-700 rounded"></div>
                <div class="space-y-3">
                  <div class="grid grid-cols-3 gap-4">
                    <div
                      class="h-2 bg-gray-200 dark:bg-slate-700 rounded col-span-2"
                    ></div>
                    <div
                      class="h-2 bg-gray-200 dark:bg-slate-700 rounded col-span-1"
                    ></div>
                  </div>
                  <div class="h-2 bg-gray-200 dark:bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>

@if (selectModalVisibilityById("add-user") | async) {
  <cap-user-profile-modal
    #addUser
    [userConfig]="(addUserSetupConfig$ | async)!"
  >
  </cap-user-profile-modal>
}
@if (selectModalVisibilityById("update-user") | async) {
  <cap-user-profile-modal
    #updateUser
    [userConfig]="(updateUserSetupConfig$ | async)!"
  >
  </cap-user-profile-modal>
}
@if (selectModalVisibilityById('import-users') | async) {
  <cap-users-import-modal
  #importUsers
  [usersConfig]="(importUsersConfig$ | async)!"
  ></cap-users-import-modal>
}

<cap-confirm-modal
  [modalID]="'confirm-removing-user'"
  [data]="confirmModalData"
  (confirmEvent)="removeUser()"
></cap-confirm-modal>

import {
  APP_INITIALIZER,
  ENVIRONMENT_INITIALIZER,
  InjectionToken,
  Provider,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthAppActions } from '../state/auth/auth.actions';

export const CORE_GUARD = new InjectionToken<string>('CORE_GUARD');

function initAuth(store: Store) {
  return () => store.dispatch(AuthAppActions.initAuth());
}

export function provideCore(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [Store],
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue() {
        const coreGuard = inject(CORE_GUARD, {
          skipSelf: true,
          optional: true,
        });
        if (coreGuard) {
          throw new TypeError(
            `provideCore() can be used only once per application (and never in library)`
          );
        }
      },
    },
  ];
}

<div class="space-y-4 flex flex-col">
  @for (option of options; track $index) {
  <div class="flex items-center">
    <input
      [id]="option.label"
      class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
      [name]="groupName"
      type="radio"
      [value]="option.value"
      [(ngModel)]="selectedOption"
      (change)="onOptionChange()"
    />
    <label
      class="ml-3 block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
      [for]="option.label"
      >{{ option.label }}</label
    >
  </div>
  }
</div>

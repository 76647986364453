import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  Observable,
  Subscription,
  map,
  of,
  pipe,
  switchMap,
  tap,
  filter,
} from 'rxjs';

import { RoleNamePipe } from 'src/app/shared/pipes/role-name.pipe';
import { FormErrorMessageComponent } from '../form-error-message/form-error-message.component';
import {
  selectRole,
  selectAll as selectRoles,
} from 'src/app/state/roles/roles.state';
import {
  IUserProfileFormInput,
  ProfileContext,
} from 'src/app/core/models/profile-form-context.model';
import {
  IAddress,
  IPhoneNumber,
  IUser,
  UserRole,
} from 'src/app/core/models/user.model';
import {
  CountryPhoneCodeService,
  ICountryPhoneCode,
} from 'src/app/core/services/country-phone-code.service';
import { isNumericValidator } from '../validators/is-numeric.validator';
import { atLeastOnePrimaryValidator } from '../validators/at-least-one-primary-control.validator';
import { RolesAppActions } from 'src/app/state/roles/roles.actions';
import { selectOrganizationsWithCurrentAvatar } from '../../state/organizations/organizations.selectors';
import { selectUserRole } from 'src/app/state/auth/auth.selectors';
import { IOrganization } from 'src/app/core/models/organization.model';
import { DropdownModule } from 'primeng/dropdown';
import { SharedModule } from 'primeng/api';
import { ProfilesAppActions } from '../../state/profiles/profiles.actions';
import { selectProfiles } from '../../state/profiles/profiles.selectors';
import { PaymentFormComponent } from "../payment-form/payment-form.component";
import { IProfile } from 'src/app/core/models/profiles.model';

const rolesRequiringOrganization = [
  UserRole.OrgAdmin,
  UserRole.OrgManager,
  UserRole.Individual,
  UserRole.OrgStaff,
];
const rolesNotRequiringOrganization = [UserRole.SuperAdmin, UserRole.SysAdmin];

@Component({
  selector: 'cap-user-profile-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorMessageComponent,
    RoleNamePipe,
    DropdownModule,
    SharedModule,
    PaymentFormComponent
],
  templateUrl: './user-profile-form.component.html',
  styleUrl: './user-profile-form.component.scss',
})
export class UserProfileFormComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private readonly store = inject(Store);
  private countryPhoneCodeService = inject(CountryPhoneCodeService);
  private subs: Subscription[] = [];
  @ViewChildren('phoneCodeDropdownContainer') phoneCodeDropdownContainers!: QueryList<ElementRef>;
  @HostListener('document:click', ['$event']) handleClickOutside(event: Event): void {
    this.phoneCodeDropdownContainers.forEach((container, index)=>{
      if(!container.nativeElement.contains(event.target as Node)){
        this.isPhoneCodeDropdownOpen[index] = false;
      }
    })
  }
  profiles$ = this.store.select(selectProfiles);
  dropDownProfileOptions: any[] = [];
  @ViewChild('orgDropdownContainer') orgDropdownContainer!: ElementRef;
  paymentCompleted = false;
  paymentIntentId: string | null = null;
  isCreatingUser = false;
  isIndividualUser = false;
  isAdminUser = false;

  isOrgDropdownOpen = false;
  isPhoneCodeDropdownOpen: { [key: number]: boolean } = {};
  isCountryDropdownOpen: { [key: number]: boolean } = {};
  selectedOrganization$: Observable<IOrganization | null> = of(null);

  contriesAndPhoneCodes = this.countryPhoneCodeService.getCountryPhoneCodes();

  @Input() formInput!: IUserProfileFormInput;
  @Output() userProfileFormChanges: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();

  userProfileForm!: FormGroup;

  roles$ = this.store.select(selectRoles);
  organizations$: Observable<IOrganization[]> = this.store.select(
    selectOrganizationsWithCurrentAvatar,
  );
  readonly currentUserRole$ = this.store.select(selectUserRole);

  ngOnInit(): void {
    if (this.formInput.context === ProfileContext.UserCreation) {
      this.isCreatingUser = true;
      this.store.dispatch(RolesAppActions.getRoles());
    }
    this.store.dispatch(ProfilesAppActions.getIndividualProfiles());
    this.profiles$
      .pipe(map((profiles) => profiles.filter((profile) => profile.isEnabled)))
      .subscribe((profiles) => {
        profiles.filter((profile: IProfile)=> profile.type === 'individual').map((profile: { name: any; _id: any }) =>
          this.dropDownProfileOptions.push({
            label: profile.name,
            value: profile._id,
          }),
        );
      });
    this.initializeForm();
    const formSub = this.userProfileForm.valueChanges.subscribe(() => {
      this.userProfileFormChanges.emit(this.userProfileForm);
    });
    this.currentUserRole$.subscribe(role => {
      this.isAdminUser = role?.name === UserRole.SysAdmin || role?.name === UserRole.SuperAdmin;
    });
    this.subs.push(formSub);
  }

  initializeForm(): void {
    this.userProfileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: new FormControl(
        {
          value: '',
          disabled: this.formInput.context === ProfileContext.UserUpdate,
        },
        [Validators.required, Validators.email],
      ),
      role: ['', Validators.required],
      phoneNumbers: this.fb.array([], atLeastOnePrimaryValidator('isPrimary')),
      addresses: this.fb.array([], atLeastOnePrimaryValidator('isPrimary')),
    });

    if (
      this.formInput.context === ProfileContext.UserUpdate &&
      this.formInput.data
    ) {
      this.userProfileForm.patchValue(this.formInput.data);
      this.userProfileForm.patchValue({
        role: (this.formInput.data as IUser).role._id,
      });
      if (this.rolesRequiringProfile.includes(this.formInput.data.role.name)) {
        this.userProfileForm.addControl(
          'profile',
          this.fb.control(this.formInput.data.profile, Validators.required),
        );
      } else {
        this.userProfileForm.removeControl('profile');
      }
      if (this.formInput.data.organization) {
        this.userProfileForm.addControl(
          'organization',
          this.fb.control(
            this.formInput.data.organization,
            Validators.required,
          ),
        );

        this.selectedOrganization$ = this.organizations$.pipe(
          map((orgs) =>
            orgs.find((org) => org._id === this.formInput.data?.organization),
          ),
          map((org) => (!!org ? org : null)),
        );
      }

      this.formInput.data.addresses.map((address) => {
        this.addresses.push(this.createAddressGroup(address));
      });
      this.formInput.data.phoneNumbers.map((phoneNumber) => {
        this.phoneNumbers.push(this.createPhoneNumberGroup(phoneNumber));
      });
    }
    if (this.addresses.length === 0) {
      this.addAddressRow();
    }
    if (this.phoneNumbers.length === 0) {
      this.addPhoneNumberRow();
    }
    this.onRoleChange();
  }

  toggleOrgDropdown(): void {
    this.isOrgDropdownOpen = !this.isOrgDropdownOpen;
  }

  selectOrganization(org: IOrganization): void {
    // Replace any with your organization type
    this.selectedOrganization$ = of(org);
    this.userProfileForm.get('organization')?.setValue(org._id);
    this.toggleOrgDropdown();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event): void {
    if (this.orgDropdownContainer) {
      if (!this.orgDropdownContainer.nativeElement.contains(event.target)) {
        this.isOrgDropdownOpen = false;
      }
    }
  }

  // --------------------------------------------------------------//

  togglePhoneCodeDropdown(phoneIndex: number): void {
    this.isPhoneCodeDropdownOpen[phoneIndex] =
      !this.isPhoneCodeDropdownOpen[phoneIndex];
  }

  toggleCountryDropdown(addressIndex: number): void {
    this.isCountryDropdownOpen[addressIndex] =
      !this.isCountryDropdownOpen[addressIndex];
  }

  selectPhoneCode(phoneIndex: number, phoneCode: string): void {
    const phoneNumbersArray = this.userProfileForm.get(
      'phoneNumbers',
    ) as FormArray;
    phoneNumbersArray.at(phoneIndex).get('phoneCode')?.setValue(phoneCode);
    this.togglePhoneCodeDropdown(phoneIndex);
  }

  selectCountry(addressIndex: number, countryName: string): void {
    const addressesArray = this.userProfileForm.get('addresses') as FormArray;
    addressesArray.at(addressIndex).get('country')?.setValue(countryName);
    this.toggleCountryDropdown(addressIndex);
  }

  getCountryCodeByName(countryName: string): string {
    return (
      this.contriesAndPhoneCodes
        .find((obj) => obj.countryName === countryName)
        ?.countryCode.toLocaleLowerCase() ?? ''
    );
  }

  // --------------------------------------------------------------//
  rolesRequiringProfile = [UserRole.Individual];
  onRoleChange() {
    const sub = this.userProfileForm
      .get('role')!
      .valueChanges.pipe(
        switchMap((roleId) => this.store.select(selectRole(roleId))),
        map((role) => role!.name),
      )
      .subscribe((role) => {
        if (rolesRequiringOrganization.includes(role)) {
          if (!this.userProfileForm.get('organization')) {
            this.userProfileForm.addControl(
              'organization',
              this.fb.control('', Validators.required),
            );
          }
        } else if (rolesNotRequiringOrganization.includes(role)) {
          if (this.userProfileForm.get('organization')) {
            this.userProfileForm.removeControl('organization');
          }
        }
        if (this.rolesRequiringProfile.includes(role)) {
          this.userProfileForm.addControl(
            'profile',
            this.fb.control('', Validators.required),
          );
        } else {
          this.userProfileForm.removeControl('profile');
        }
        if (role === UserRole.Individual) {
          this.isIndividualUser = true;
          this.userProfileForm.addControl('paymentCompleted', new FormControl(false));
          this.userProfileForm.addControl('paymentIntentId', new FormControl(null));
          this.userProfileForm.addControl('discountCode', new FormControl(null));
        } else {
          this.userProfileForm.removeControl('paymentCompleted');
          this.userProfileForm.removeControl('paymentIntentId');
          this.userProfileForm.removeControl('discountCode');
        }
      });

    this.subs.push(sub);
  }

  isActionCreate(): boolean {
    return this.formInput.context === ProfileContext.UserCreation;
  }

  isActionUpdate(): boolean {
    return this.formInput.context === ProfileContext.UserUpdate;
  }

  // TODO: Change this to If a user can Change role
  canChangeRole(): Observable<boolean> {
    const adminRoles = [
      UserRole.SysAdmin,
      UserRole.SuperAdmin,
      UserRole.OrgAdmin,
      UserRole.OrgManager,
    ];
    return this.currentUserRole$.pipe(
      map((role) => {
        if (role?.name) {
          return adminRoles.includes(role?.name);
        } else {
          return false;
        }
      }),
    );
  }
  // Uncheck 'isPrimary' in other groups
  private uncheckOtherPrimary(
    key: 'addresses' | 'phoneNumbers',
    currentGroup: FormGroup,
  ) {
    const formArray = this.userProfileForm.get(key) as FormArray;
    formArray.controls.forEach((group) => {
      if (group !== currentGroup) {
        (group.get('isPrimary') as FormControl).setValue(false, {
          emitEvent: false,
        });
      }
    });
  }

  private setupPrimarySubscription(
    group: FormGroup,
    key: 'addresses' | 'phoneNumbers',
  ) {
    const isPrimaryControl = group.get('isPrimary');
    isPrimaryControl?.valueChanges.subscribe((value) => {
      if (value) {
        this.uncheckOtherPrimary(key, group);
      }
    });
  }

  // Handle Addresses Form Array
  get addresses() {
    return this.userProfileForm.get('addresses') as FormArray;
  }

  private createAddressGroup(
    address: IAddress | null,
    isFirstRow: boolean = false,
  ): FormGroup {
    const addressGroup = this.fb.group({
      street1: [address?.street1 ?? '', Validators.required],
      street2: [address?.street2 ?? ''],
      city: [address?.city ?? '', Validators.required],
      state: [address?.state ?? ''],
      country: [
        address?.country ?? 'United States of America',
        Validators.required,
      ],
      zip: [address?.zip ?? '', [Validators.required, isNumericValidator()]],
      addressType: [address?.addressType ?? 'Office', Validators.required],
      isPrimary: [
        isFirstRow || (address?.isPrimary ?? false),
        Validators.required,
      ],
    });
    this.setupPrimarySubscription(addressGroup, 'addresses');
    return addressGroup;
  }

  addAddressRow(): void {
    this.addresses.push(
      this.createAddressGroup(null, this.addresses.length === 0),
    );
    this.isCountryDropdownOpen[this.addresses.length - 1] = false;
  }

  removeAddress(index: number): void {
    if (this.addresses.length === 1) {
      return;
    }
    this.addresses.removeAt(index);
  }

  // Handle Phone Numbers Form Array

  get phoneNumbers() {
    return this.userProfileForm.get('phoneNumbers') as FormArray;
  }

  private createPhoneNumberGroup(
    phone: IPhoneNumber | null,
    isFirstRow: boolean = false,
  ): FormGroup {
    const phoneGroup = this.fb.group({
      phoneCode: [phone?.phoneCode ?? '1', Validators.required],
      number: [phone?.number ?? '', [Validators.required]],
      phoneType: [phone?.phoneType ?? 'Mobile', Validators.required],
      isPrimary: [
        isFirstRow || (phone?.isPrimary ?? false),
        Validators.required,
      ],
    });

    this.setupPrimarySubscription(phoneGroup, 'phoneNumbers');
    return phoneGroup;
  }

  addPhoneNumberRow(): void {
    this.phoneNumbers.push(
      this.createPhoneNumberGroup(null, this.phoneNumbers.length === 0),
    );
    this.isPhoneCodeDropdownOpen[this.phoneNumbers.length - 1] = false;
  }
  removePhoneNumber(index: number): void {
    if (this.phoneNumbers.length === 1) {
      return;
    }
    this.phoneNumbers.removeAt(index);
  }

  handlePaymentCompleted(result: { success: boolean; paymentIntentId: string | null, discountCode?: string }) {
    this.userProfileForm.get('paymentCompleted')?.setValue(result.success);
    this.userProfileForm.get('paymentIntentId')?.setValue(result.paymentIntentId);
    this.userProfileForm.get('discountCode')?.setValue(result.discountCode);
  }

  handleFullDiscountApplied(event: any) {
    this.handlePaymentCompleted({
      success: true,
      paymentIntentId: null,
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cap-password-control-message',
    templateUrl: './password-control-message.component.html',
    styleUrls: ['./password-control-message.component.scss'],
    standalone: true,
})
export class PasswordControlMessageComponent implements OnInit {
  @Input() isError!: boolean;
  @Input() message!: string;

  path = '';

  ngOnInit(): void {
    if (this.isError) {
      this.path =
        'M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z';
    } else {
      this.path =
        'M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z';
    }
  }
}

<div class="flex">
  <div class="flex-shrink-0">
    <svg
      class="h-5 w-5"
      [class.text-red-400]="isError"
      [class.text-green-400]="!isError"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path fill-rule="evenodd" [attr.d]="path" clip-rule="evenodd" />
    </svg>
  </div>
  <div class="ml-3">
    <h3
      class="text-sm font-medium"
      [class.text-red-800]="isError"
      [class.text-green-800]="!isError"
    >
      {{ message }}
    </h3>
  </div>
</div>

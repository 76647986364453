import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  exhaustMap,
  take,
  map,
  catchError,
  of,
  switchMap,
  share,
  debounceTime,
} from 'rxjs';

import { AuditLogsService } from 'src/app/core/services/audit-logs.service';
import { AuditLogsAppActions, AuditLogsAPIActions } from './audit-logs.actions';
import { handleError } from 'src/app/utils/error-handler.util';

export const getAuditLogs$ = createEffect(
  (actions$ = inject(Actions), auditLogsService = inject(AuditLogsService)) => {
    return actions$.pipe(
      ofType(AuditLogsAppActions.getAuditLogs),
      exhaustMap(({ page, limit }) =>
        auditLogsService.getAuditLogs(page, limit).pipe(
          take(1),
          map(({ auditLogs, pagination }) => {
            return AuditLogsAPIActions.getAuditLogsSuccess({
              auditLogs,
              pagination,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              AuditLogsAPIActions.getAuditLogsFailure({
                message,
              }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  {
    functional: true,
  },
);

export const searchAuditLogs$ = createEffect(
  (actions$ = inject(Actions), auditLogsService = inject(AuditLogsService)) => {
    return actions$.pipe(
      ofType(AuditLogsAppActions.searchAuditLogs),
      debounceTime(300),
      exhaustMap(({ search, page, limit }) =>
        auditLogsService.searchAuditLogs(search, page, limit).pipe(
          take(1),
          map(({ auditLogs, pagination }) => {
            return AuditLogsAPIActions.searchAuditLogsSuccess({
              auditLogs,
              pagination,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuditLogsAPIActions.searchAuditLogsFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

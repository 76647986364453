<div class="mt-3 px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1
        class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50"
      >
        Organizations
      </h1>
      <p class="mt-2 text-sm text-gray-700 dark:text-gray-400">
        A list of all the organizations in your account including their name,
        email, phone number and more.
      </p>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <input
        type="text"
        placeholder="Search Organizations..."
        class="w-full border border-gray-300 rounded-md px-3 py-2 dark:bg-slate-700 dark:text-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        [(ngModel)]="searchQuery"
        (input)="searchOrganizations()"
      />
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <button
        type="button"
        (click)="openModal()"
        class="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add organization
      </button>
    </div>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        @if (!isLoadingOrganizaitonList) {
          <cap-table
            (onEntityUpdated)="callUpdateOrganizationModal($event)"
            (onEntityDeleted)="callConfirmationModal($event)"
            (OnToggleClicked)="updateOrganizationStatus($event)"
            [columns]="columns"
            [data]="(orgData$ | async) ?? []"
          ></cap-table>
          <cap-pagination
            [current_page]="page"
            [pageSize]="pageSize"
            [totalItems]="totalItems"
            [totalPages]="totalPages"
            (pageChange)="onPageChange($event)"
          ></cap-pagination>
        } @else {
          <div
            class="rounded-md w-full"
            *ngFor="let item of [].constructor(5); let i = index"
          >
            <div class="animate-pulse flex space-x-4">
              <div
                class="rounded-full bg-gray-200 dark:bg-slate-700 h-10 w-10"
              ></div>
              <div class="flex-1 space-y-6 py-1">
                <div class="h-2 bg-gray-200 dark:bg-slate-700 rounded"></div>
                <div class="space-y-3">
                  <div class="grid grid-cols-3 gap-4">
                    <div
                      class="h-2 bg-gray-200 dark:bg-slate-700 rounded col-span-2"
                    ></div>
                    <div
                      class="h-2 bg-gray-200 dark:bg-slate-700 rounded col-span-1"
                    ></div>
                  </div>
                  <div class="h-2 bg-gray-200 dark:bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>

@if (selectModalVisibilityById("add-organization") | async) {
  <cap-vertical-step-navigation-modal
    [config$]="addOrganizationSetupConfig$"
    #addOrganization
  ></cap-vertical-step-navigation-modal>
}
@if (selectModalVisibilityById("update-organization") | async) {
  <cap-vertical-step-navigation-modal
    [config$]="updateOrganizationSetupConfig$"
    #updateOrganization
  ></cap-vertical-step-navigation-modal>
}

<cap-confirm-modal
  [modalID]="'confirm-remove-organization'"
  [data]="confirmModalData"
  (confirmEvent)="removeOrganization()"
></cap-confirm-modal>

import {
  AssessmentsState,
  selectAll,
  selectAssessmentsState,
} from './assessments.state';
import { createSelector } from '@ngrx/store';

export const selectAssessments = selectAll;

export const selectAssessmentById = (assessmentId: string) =>
  createSelector(selectAssessments, (assessments) =>
    assessments.find((assessment) => assessment._id === assessmentId),
  );

export const selectAssessmentByCategoryId = (categoryId: string) =>
  createSelector(selectAssessments, (state) =>
    state.find((assessment) => assessment.categoryId === categoryId),
  );

import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';

import { TableComponent } from 'src/app/shared/table/table.component';
import { ICategory } from 'src/app/core/models/categories.model';
import { CategoryUpsertModalComponent } from './category-upsert-modal/category-upsert-modal.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { PaginationComponent } from '../../shared/pagination/pagination.component';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { TabsComponent } from '../../shared/tabs/tabs.component';
import { ITab } from '../../core/models/tab.model';

export interface IModalMetadata {
  title: string;
  modalWidth: string;
  modalID: string;
  isEditMode: boolean;
  category: ICategory | null;
}

@Component({
  selector: 'cap-categories-list',
  standalone: true,
  templateUrl: './categories-list.component.html',
  styleUrl: './categories-list.component.scss',
  imports: [
    CommonModule,
    TableComponent,
    CategoryUpsertModalComponent,
    ConfirmModalComponent,
    PaginationComponent,
    SpinnerComponent,
    RouterOutlet,
    TabsComponent,
  ],
})
export class CategoriesListComponent implements OnInit {
  private readonly router = inject(Router);
  constructor() {}
  tabs: ITab[] = [
    {
      name: 'Individuals',
      route: 'administration/categories/individuals',
      current: true,
      icon: 'person',
    },
    {
      name: 'Organizational',
      route: 'administration/categories/organizations',
      current: false,
      icon: 'corporate_fare',
    },
  ];
  ngOnInit(): void {
    this.router.navigate([this.tabs[0].route]);
  }

  onTabChange(route: string) {
    this.router.navigate([route]);
  }
}

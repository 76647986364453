import { CommonModule, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { selectErrorMessage } from 'src/app/state/auth/auth.state';
import { Reset2FAOtpStore } from './reset-2fa-otp.store';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { AlertActions } from 'src/app/state/alert/alert.actions';
import { AuthAppActions } from 'src/app/state/auth/auth.actions';

@Component({
  selector: 'cap-reset-2fa-otp',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgIf, ModalContainerComponent],
  providers: [Reset2FAOtpStore],
  templateUrl: './reset-2fa-otp.component.html',
  styleUrl: './reset-2fa-otp.component.scss',
})
export class Reset2FAOtpComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private readonly store = inject(Store);
  private readonly componentStore = inject(Reset2FAOtpStore);
  private fb = inject(FormBuilder);

  reset2FAOtpForm!: FormGroup;

  public readonly formError$ = this.store.select(selectErrorMessage);
  public readonly loading$ = this.componentStore.isLoading$;
  public readonly formSubmissionSuccess$ =
    this.componentStore.formSubmissionSuccess$;

  ngOnInit(): void {
    this.store.dispatch(ModalActions.register({ id: 'reset-2fa-otp' }));
    this.initializeForm();
    this.formSubmissionSuccess$
      .pipe(
        tap((isSuccess) => {
          if (!isSuccess) {
            return;
          }
          this.store.dispatch(ModalActions.close({ id: 'reset-2fa-otp' }));
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  initializeForm() {
    this.reset2FAOtpForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (!this.reset2FAOtpForm.valid) {
      this.store.dispatch(
        AlertActions.addAlert({
          alert: {
            type: 'warning',
            message: 'Please enter a valid email',
          },
        }),
      );
    }

    this.store.dispatch(
      AuthAppActions.reset2FAOTP({ ...this.reset2FAOtpForm.value }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

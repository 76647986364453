import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  RouterOutlet,
  RouterLink,
  RouterLinkActive,
  Router,
} from '@angular/router';
import { ITab } from 'src/app/core/models/tab.model';
import { TabsComponent } from 'src/app/shared/tabs/tabs.component';

@Component({
  selector: 'cap-security-controls-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    TabsComponent,
  ],
  templateUrl: './security-controls-page.component.html',
  styleUrl: './security-controls-page.component.scss',
})
export class SecurityControlsPageComponent implements OnInit {
  private readonly router = inject(Router);

  tabs: ITab[] = [
    {
      name: 'Standards',
      route: '/administration/controls/standards',
      current: true,
      icon: 'encrypted',
    },
    {
      name: 'Security Controls',
      route: '/administration/controls/security-controls',
      current: false,
      icon: 'shield_lock',
    },
  ];

  ngOnInit(): void {
    this.router.navigate([this.tabs[0].route]);
  }

  onTabChange(route: string) {
    this.router.navigate([route]);
  }
}

import { createSelector } from '@ngrx/store';
import {
  ProfilesState,
  selectAll,
  selectProfilesState,
} from './profiles.state';

export const selectProfiles = createSelector(selectAll, (profiles) => profiles);

export const selectProfileById = (id: string) =>
  createSelector(selectProfiles, (profiles) =>
    profiles.find((profile) => profile._id === id),
  );

import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { ModalActions } from './modal.actions';
import { IModal } from 'src/app/core/models/modal.model';

export interface ModalState extends EntityState<IModal> {}

export const adapter: EntityAdapter<IModal> = createEntityAdapter<IModal>();

export const initialState: ModalState = adapter.getInitialState();

const modalReducer = createReducer(
  initialState,
  on(ModalActions.open, (state, { id }) => {
    return adapter.upsertOne({ id, visible: true }, state);
  }),
  // Update this handler to remove the modal instead of hiding it
  on(ModalActions.close, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(ModalActions.register, (state, { id }) => {
    return adapter.upsertOne({ id, visible: false }, state);
  }),
  on(ModalActions.unregister, (state, { id }) => {
    return adapter.removeOne(id, state);
  })
);

export const modalsFeature = createFeature({
  name: 'modals',
  reducer: modalReducer,
  extraSelectors: ({ selectModalsState, selectEntities, selectIds }) => ({
    ...adapter.getSelectors(selectModalsState),
    /* The `selectModalVisibility` selector is a factory function that takes an ID and
       returns a new selector that returns the visibility of the modal with that ID. */

    selectModalVisibility: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]?.visible ?? false),
  }),
});

export const {
  name,
  reducer,
  selectModalsState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectModalVisibility,
} = modalsFeature;

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

interface InfoCellState {
  fullName: string;
  email: string;
}

@Injectable()
export class InfoCellStore extends ComponentStore<InfoCellState> {
  constructor() {
    super({
      fullName: '',
      email: '',
    });
  }

  readonly fullName$ = this.select((state) => state.fullName);
  readonly email$ = this.select((state) => state.email);

  readonly setFullName = this.updater((state, fullName: string) => ({
    ...state,
    fullName,
  }));

  readonly setEmail = this.updater((state, email: string) => ({
    ...state,
    email,
  }));
}

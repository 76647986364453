import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { exhaustMap, take, map, catchError, of, switchMap, share } from 'rxjs';

import { SecurityControlsService } from 'src/app/core/services/security-controls.service';
import {
  SecurityControlsAppActions,
  SecurityControlsAPIActions,
} from './security-controls.actions';
import { handleError } from 'src/app/utils/error-handler.util';

export const getSecurityControls$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.getSecurityControls),
      exhaustMap(() =>
        securityControlsService.getSecurityControls().pipe(
          take(1),
          map(({ securityControls }) => {
            return SecurityControlsAPIActions.getSecurityControlsSuccess({
              securityControls,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.getSecurityControlsFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const getPaginatedSecurityControls$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.getSecurityControlsByPagination),
      exhaustMap(({ page, limit }) =>
        securityControlsService.getPaginatedSecurityControls(page, limit).pipe(
          take(1),
          map(({ securityControls, pagination }) =>
            SecurityControlsAPIActions.getSecurityControlsByPaginationSuccess({
              securityControls,
              pagination,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.getSecurityControlsByPaginationFailure(
                {
                  message,
                },
              ),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const getSecurityById$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.getSecurityControlsById),
      exhaustMap(({ id }) =>
        securityControlsService.getSecurityControlById(id).pipe(
          take(1),
          map(({ securityControl }) =>
            SecurityControlsAPIActions.getSecurityControlsByIdSuccess({
              securityControl,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.getSecurityControlsByIdFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const createSecurityControl$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.createSecurityControl),
      exhaustMap(({ securityControl }) =>
        securityControlsService.createSecurityControl(securityControl).pipe(
          take(1),
          map(({ securityControl }) =>
            SecurityControlsAPIActions.createSecurityControlSuccess({
              securityControl,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.createSecurityControlFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const updateSecurityControl$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.updateSecurityControl),
      exhaustMap(({ securityControl }) =>
        securityControlsService.updateSecurityControl(securityControl).pipe(
          take(1),
          map(({ securityControl }) =>
            SecurityControlsAPIActions.updateSecurityControlSuccess({
              securityControl,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.updateSecurityControlFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const removeSecurityControl$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.removeSecurityControl),
      exhaustMap(({ id }) =>
        securityControlsService.removeSecurityContro(id).pipe(
          take(1),
          map(({ securityControlId, message }) =>
            SecurityControlsAPIActions.removeSecurityControlSuccess({
              securityControlId,
              message,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.removeSecurityControlFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const getAllStandards$ = createEffect(
  (
    action$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return action$.pipe(
      ofType(SecurityControlsAppActions.getStandards),
      exhaustMap(() =>
        securityControlsService.getStandards().pipe(
          take(1),
          map(({ standards }) =>
            SecurityControlsAPIActions.getStandardsSuccess({ standards }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.getStandardsFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const getPaginatedStandards$ = createEffect(
  (
    action$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return action$.pipe(
      ofType(SecurityControlsAppActions.getStandardByPagination),
      exhaustMap(({ page, limit }) =>
        securityControlsService.getPaginatedStandards(page, limit).pipe(
          take(1),
          map(({ standards, pagination }) =>
            SecurityControlsAPIActions.getStandardByPaginationSuccess({
              standards,
              pagination,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.getStandardByPaginationFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const getStandardsById$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.getSecurityControlsById),
      exhaustMap(({ id }) =>
        securityControlsService.getStandardsById(id).pipe(
          take(1),
          map(({ standard }) =>
            SecurityControlsAPIActions.getStandardByIdSuccess({ standard }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.getStandardByIdFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const createStandard$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.createStandard),
      exhaustMap(({ standard }) =>
        securityControlsService.createStandard(standard).pipe(
          take(1),
          map(({ standard }) =>
            SecurityControlsAPIActions.createStandardSuccess({ standard }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.createStandardFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const updateStandard$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.updateStandard),
      exhaustMap(({ standard }) =>
        securityControlsService.updateStandard(standard).pipe(
          take(1),
          map(({ standard }) =>
            SecurityControlsAPIActions.updateStandardSuccess({ standard }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.updateStandardFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const removeStandard$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.removeStandard),
      exhaustMap(({ id }) =>
        securityControlsService.removeStandard(id).pipe(
          take(1),
          map(({ standardId, message }) =>
            SecurityControlsAPIActions.removeStandardSuccess({
              standardId,
              message,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              SecurityControlsAPIActions.removeStandardFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const getSecurityControlsByStandardId$ = createEffect(
  (
    actions$ = inject(Actions),
    securityControlsService = inject(SecurityControlsService),
  ) => {
    return actions$.pipe(
      ofType(SecurityControlsAppActions.getSecurityControlsByStandardId),
      exhaustMap(({ standardId }) =>
        securityControlsService
          .getSecurityControlsByStandardId(standardId)
          .pipe(
            take(1),
            map(({ securityControls }) => {
              return SecurityControlsAPIActions.getSecurityControlsByStandardIdSuccess(
                {
                  securityControls,
                },
              );
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                SecurityControlsAPIActions.getSecurityControlsByStandardIdFailure(
                  {
                    message,
                  },
                ),
              );
            }),
            share(),
          ),
      ),
    );
  },
  { functional: true },
);

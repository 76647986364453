import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, tap } from 'rxjs';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';

export interface IPasswordsVisibility {
  cp: boolean;
  np: boolean;
  cnp: boolean;
}

@Injectable()
export class ChangePasswordStore
  extends ComponentStore<{
    passwordsVisibility: IPasswordsVisibility;
    isLoading: boolean;
    errorMessage: string | null;
  }>
  implements OnDestroy
{
  private actionsSubscriptions: Subscription[] = [];

  constructor(private actions$: Actions) {
    super({
      passwordsVisibility: { cp: false, np: false, cnp: false },
      isLoading: false,
      errorMessage: null,
    });

    this.actionsSubscriptions.push(
      this.appActionsSub,
      this.apiSuccessActionsSub,
      this.apiFailureActionsSub
    );
  }

  npVisibility$ = this.select((state) => state.passwordsVisibility.np);
  cpVisibility$ = this.select((state) => state.passwordsVisibility.cp);
  cnpVisibility$ = this.select((state) => state.passwordsVisibility.cnp);
  isLoading$ = this.select((state) => state.isLoading);
  errorMessage$ = this.select((state) => state.errorMessage);

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(AuthAppActions.changePassword),
      tap(() => this.patchState({ isLoading: true }))
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.changePasswordSuccess),
      tap(() => this.patchState({ isLoading: false, errorMessage: null }))
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.changePasswordFailure),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message })
      )
    )
    .subscribe();

  readonly togglePasswordVidibility = this.updater(
    (state, type: 'np' | 'cp' | 'cnp') => ({
      ...state,
      passwordsVisibility: {
        ...state.passwordsVisibility,
        [type]: !state.passwordsVisibility[type],
      },
    })
  );

  override ngOnDestroy(): void {
    this.actionsSubscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import { inject, Injectable } from '@angular/core';
import { IPricingConfig, IUpdatePricingConfigPrice } from '../models/pricing-config.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IDiscount } from '../models/discount.model';
import { ICreateDiscountCode } from '../models/discount.model';

@Injectable({
  providedIn: 'root'
})
export class PricingConfigService {
  private http = inject(HttpClient)
  constructor() { }

  getAllPricingConfigs(): Observable<IPricingConfig[]> {
    return this.http.get<IPricingConfig[]>(`${environment.apiUrl}/pricing-config`);
  }

  updatePricingConfigPrice(prices: IUpdatePricingConfigPrice[]): Observable<{pricingConfigs: IPricingConfig[]}> {
    return this.http.put<{pricingConfigs: IPricingConfig[]}>(`${environment.apiUrl}/pricing-config`, { prices });
  }
}

<div class="mt-3 px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1
        class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50"
      >
        Individuals profiles
      </h1>
      <p class="mt-2 text-sm text-gray-700 dark:text-gray-400">
        A list of all the Individuals profiles you have created.
      </p>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <input
        type="text"
        placeholder="Search individual profiles..."
        class="w-full border border-gray-300 rounded-md px-3 py-2 dark:bg-slate-700 dark:text-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        [(ngModel)]="searchQuery"
        (input)="searchProfiles()"
      />
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <button
        type="button"
        (click)="openCreateProfileModal()"
        class="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add individuals profile
      </button>
    </div>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        @if (errorMessage$ | async) {
          <cap-feedback
            type="error"
            [message]="errorMessage$ | async"
          ></cap-feedback>
        }
        @if (!isLoadingProfileList()) {
          <cap-table
            [columns]="individualProfileColumns"
            (onEntityDeleted)="openConfirmationModel($event)"
            (onEntityUpdated)="openUpdateProfileModal($event)"
            (OnToggleClicked)="UpdateEnableStatus($event)"
            [data]="(individualProfiles$ | async) ?? []"
          ></cap-table>
          <!--          <cap-pagination-->
          <!--            [current_page]="current_page"-->
          <!--            [totalItems]="totalItems"-->
          <!--            [totalPages]="total_pages"-->
          <!--            [pageSize]="pageSize"-->
          <!--            (pageChange)="onPageChange($event)"-->
          <!--            [disableNext]="current_page == total_pages"-->
          <!--          ></cap-pagination>-->
        } @else {
          <cap-spinner class="w-full"></cap-spinner>
        }
      </div>
    </div>
  </div>
</div>
@if (selectModalVisibilityById("create-individual-profile") | async) {
  <cap-individual-profile-upsert
    [modalMetadata]="modalMetaData"
  ></cap-individual-profile-upsert>
}
@if (selectModalVisibilityById("update-individual-profile") | async) {
  <cap-individual-profile-upsert
    [modalMetadata]="modalMetaData"
  ></cap-individual-profile-upsert>
}

<cap-confirm-modal
  [modalID]="'confirm-delete-profile'"
  [data]="confirmModalData"
  (confirmEvent)="removeProfile()"
></cap-confirm-modal>

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { IAvatar } from 'src/app/core/models/user.model';

interface ChangeAvatarState {
  selectedAvatar: IAvatar | null;
  currentAvatar: IAvatar | null;
  oldAvatars: IAvatar[] | null;
}

@Injectable()
export class ChangeAvatarStore
  extends ComponentStore<ChangeAvatarState>
  implements OnDestroy
{
  constructor() {
    super({
      selectedAvatar: null,
      currentAvatar: null,
      oldAvatars: null,
    });
  }

  readonly selectedAvatar$ = this.select((state) => state.selectedAvatar);
  readonly currentAvatar$ = this.select((state) => state.currentAvatar);
  readonly oldAvatars$ = this.select((state) => state.oldAvatars);

  readonly setSelectedAvatar = this.updater(
    (state, selectedAvatar: IAvatar | null) => ({
      ...state,
      selectedAvatar,
    })
  );

  readonly setCurrentAvatar = this.updater(
    (state, currentAvatar: IAvatar | null) => ({
      ...state,
      currentAvatar,
    })
  );

  readonly setOldAvatars = this.updater(
    (state, oldAvatars: IAvatar[] | null) => ({
      ...state,
      oldAvatars,
    })
  );
}

<p-divider></p-divider>



<div class="w-full flex flex-wrap justify-between items-center gap-4 mb-4 ">
  <h2 class="text-2xl font-bold">Assessment Report</h2>
  <div class="flex flex-wrap gap-2">
    <p-button text (onClick)="handlePdfDownload()">
      <p class="ml-2">Download pdf</p>
      <span class="material-symbols-outlined"> picture_as_pdf </span>
    </p-button>
    <p-button text (onClick)="expandAll()"><p>Expand All</p></p-button>
    <p-button text (onClick)="collapseAll()"><p>Collapse All</p></p-button>
  </div>
</div>

<div class="card">
  <!-- Filter Panel -->
  <div class="flex flex-wrap gap-4 mb-4 p-4 bg-gray-50 dark:bg-slate-800 rounded-lg">
    <div class="flex flex-col gap-2">
      <label for="categoryFilter" class="font-medium">Category</label>
      <p-dropdown
        id="categoryFilter"
        [(ngModel)]="categoryFilter"
        [options]="Array.from((categories$ | async) ?? [])"
        (onChange)="applyFilters()"
        (onClear)="clear()"
        placeholder="All Categories"
        [showClear]="true"
        class="w-48"
      ></p-dropdown>
    </div>

    <div class="flex flex-col gap-2">
      <label for="weightFilter" class="font-medium">Weight</label>
      <p-dropdown
        id="weightFilter"
        [(ngModel)]="weightFilter"
        [options]="weights"
        (onChange)="applyActionItemFilters()"
        (onClear)="clearActionItemFilters()"
        placeholder="All Weights"
        [showClear]="true"
        class="w-48"
      ></p-dropdown>
    </div>

    <div class="flex flex-col gap-2">
      <label for="completedFilter" class="font-medium">Status</label>
      <p-dropdown
        id="completedFilter"
        [(ngModel)]="completedFilter"
        [options]="isCompletedOptions"
        (onChange)="applyActionItemFilters()"
        (onClear)="clearActionItemFilters()"
        placeholder="All Status"
        [showClear]="true"
        class="w-48"
      ></p-dropdown>
    </div>

    <div class="flex flex-col gap-2">
      <label for="globalSearch" class="font-medium">Search</label>
      <input
        id="globalSearch"
        type="text"
        pInputText
        [(ngModel)]="globalFilterText"
        class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
        placeholder="Search anything..."
        (input)="applyFilters()"
      />
    </div>
  </div>

  <!-- Table with simplified headers -->
  <p-table
    #dt
    [value]="(questionsWithActionItems$ | async) ?? []"
    dataKey="id"
    [tableStyle]="{ 'min-width': '60rem' }"
    [expandedRowKeys]="expandedRows"
    (onRowExpand)="onRowExpand($event)"
    [globalFilterFields]="['questionText', 'weight', 'categoryName']"
    (onRowCollapse)="onRowCollapse($event)"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 25, 50]"
    styleClass="p-datatable-responsive-demo"
  >
    <ng-template pTemplate="caption">
      <div
        class="flex flex-column sm:flex-row justify-between items-center gap-4"
      >
        <h5 class="m-0">Questions and Action Items</h5>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th pSortableColumn="questionText">
          Question<p-sortIcon
            field="questionText"
            class="inline-block"
          ></p-sortIcon>
        </th>
        <th pSortableColumn="categoryName">
          Category
          <p-sortIcon field="categoryName" class="inline-block"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-question let-expanded="expanded">
      <tr>
        <td>
          <button
            type="button"
            pRipple
            [pRowToggler]="question"
            class="p-2 rounded-full transition-colors duration-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <span class="material-symbols-outlined text-gray-600">
              {{ expanded ? "expand_less" : "expand_more" }}
            </span>
          </button>
        </td>
        <td>
          <span class="p-column-title">Question</span>
          <span [innerHTML]="question.highlightedQuestionText"></span>
        </td>
        <!-- <td>
          <span class="p-column-title">Weight</span
          >{{ convertWeight(question.weight) }}
        </td> -->
        <td>
          <span class="p-column-title">Category</span
          >{{ question.categoryName }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-question>
      <tr>
        <td colspan="4">
          <div class="p-3">
            <p-table
              [value]="question.actionItems"
              dataKey="id"
              styleClass="p-datatable-sm"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="text">
                    Action Item
                    <p-sortIcon field="text" class="inline-block"></p-sortIcon>
                  </th>
                  <th pSortableColumn="weight">
                    Weight
                    <p-sortIcon field="weight" class="inline-block"></p-sortIcon>
                  </th>
                  <th pSortableColumn="isCompleted">
                    Completed
                    <p-sortIcon field="isCompleted" class="inline-block"></p-sortIcon>
                  </th>
                  @if ((currentUserRole | async)?.name !== 'I') {
                  <th>Controls & Services</th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-actionItem>
                <tr>
                  <td>
                    <span class="p-column-title">Action Item</span>
                    <span [innerHTML]="actionItem.highlightedText"></span>
                  </td>
                  <td>
                    <span class="p-column-title">Weight</span
                    >{{ convertWeight(actionItem.weight) }}
                  </td>
                  <td>
                    <span class="p-column-title">Completed</span>
                    <p-tag
                      [value]="actionItem.isCompleted ? 'Yes' : 'No'"
                      [severity]="actionItem.isCompleted ? 'success' : 'danger'"
                    ></p-tag>
                  </td>
                  @if ((currentUserRole | async)?.name !== 'I') {
                  <td>
                    <span class="p-column-title">Controls & Services</span>
                    <div class="flex gap-2 py-1">
                      <button
                        type="button"
                        [class]="'h-fit flex justify-center items-center rounded-full p-1 ' + 
                          (actionItem.controls?.length ? 'bg-red-600 hover:bg-red-500' : 'bg-gray-400 hover:bg-gray-300') + 
                          ' text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'"
                        title="check controls"
                        (click)="onShowControls(actionItem)"
                      >
                        <span class="material-symbols-outlined">
                          control_point_duplicate
                        </span>
                      </button>
                      <button
                        type="button"
                        [class]="'h-fit flex justify-center items-center rounded-full p-1 ' + 
                          (actionItem.services?.length ? 'bg-red-600 hover:bg-red-500' : 'bg-gray-400 hover:bg-gray-300') + 
                          ' text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'"
                        title="check services"
                        (click)="onShowService(actionItem)"
                      >
                        <span class="material-symbols-outlined">
                          service_toolbox
                        </span>
                      </button>
                    </div>
                  </td>
                }
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">
          <div class="flex justify-center items-center h-24">
            <p>No questions found.</p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="showServices"
  header="Services"
  [style]="{ width: '90vw', maxWidth: '100%' }"
  [maximizable]="true"
  [modal]="true"
  [resizable]="true"
>
  <p-table
    [value]="services"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 25, 50]"
    [responsive]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Link</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-service>
      <tr>
        <td><span class="p-column-title">Name</span>{{ service.name }}</td>
        <td>
          <span class="p-column-title">Description</span
          >{{ service.description }}
        </td>
        <td>
          <span class="p-column-title">Link</span>
          <a
            [href]="service.url"
            target="_blank"
            class="text-blue-600 hover:underline"
          >
            Service URL
            <span class="material-symbols-outlined align-middle ml-1"
              >link</span
            >
          </a>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">
          <div class="flex justify-center items-center h-24">
            <p>No services available.</p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<p-dialog
  [(visible)]="showControls"
  header="Controls"
  [style]="{ width: '90vw', maxWidth: '100%' }"
  [maximizable]="true"
  [modal]="true"
  [resizable]="true"
>
  <p-table
    [value]="controls"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 25, 50]"
    [responsive]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Description</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-control>
      <tr>
        <td>
          <span class="p-column-title">Description</span
          >{{ control.description }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td>
          <div class="flex justify-center items-center h-24">
            <p>No controls available.</p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<p-dialog
  [(visible)]="confirmPdf"
  header="PDF Content"
  [style]="{ width: '90vw', maxWidth: '500px' }"
  [modal]="true"
>
  <div class="flex flex-column gap-4 py-4">
    <div class="flex items-center">
      <input
        type="checkbox"
        [(ngModel)]="includeServices"
        class="size-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
        id="includeServices"
      />
      <label for="includeServices" class="ml-2">Include Services</label>
    </div>
    <div class="flex items-center">
      <input
        type="checkbox"
        [(ngModel)]="includeControls"
        class="size-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
      />
      <label for="includeControls" class="ml-2">Include Controls</label>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-end gap-2">
      <p-button text (onClick)="downloadPdf()">
        <p>Download</p>
      </p-button>
      <p-button text (onClick)="confirmPdf = false">
        <p>Cancel</p>
      </p-button>
    </div>
  </ng-template>
</p-dialog>

@if (showDownloadIndicator()) {
  <div class="fixed z-50 inset-0 flex justify-center items-center bg-white bg-opacity-50">
    <p-progressSpinner [strokeWidth]="'5'" [fill]="'0.75'" [animationDuration]="'2s'"></p-progressSpinner>
    <p class="text-xl text-black font-bold">Generating report...</p>
  </div>
}

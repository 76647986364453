import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { IOrganization } from 'src/app/core/models/organization.model';
import { OrganizationsAPIActions } from './organizations.actions';

export interface Pagination {
  page: number;
  limit: number;
  totalPages: number;
  totalCount: number;
}

export interface OrganizationsState extends EntityState<IOrganization> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<IOrganization> =
  createEntityAdapter<IOrganization>({
    selectId: (organization: IOrganization) => organization._id,
  });

export const initialState: OrganizationsState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
    totalCount: 0,
  },
});

const organizationsReducer = createReducer(
  initialState,
  on(
    OrganizationsAPIActions.getOrganizationsSuccess,
    (state, { organizations, pagination }) => {
      return adapter.setAll(organizations, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(OrganizationsAPIActions.getOrganizationsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(
    OrganizationsAPIActions.searchOrganizationsSuccess,
    (state, { organizations, pagination }) => {
      return adapter.setAll(organizations, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(
    OrganizationsAPIActions.getOrganizationByIdSuccess,
    (state, { organization }) => {
      return adapter.upsertOne(organization, state);
    },
  ),
  on(
    OrganizationsAPIActions.getOrganizationByIdFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    OrganizationsAPIActions.createOrganizationSuccess,
    (state, { organization }) => {
      return adapter.addOne(organization, state);
    },
  ),
  on(
    OrganizationsAPIActions.createOrganizationFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    OrganizationsAPIActions.updateOrganizationSuccess,
    (state, { organization }) => {
      return adapter.upsertOne(organization, state);
    },
  ),
  on(
    OrganizationsAPIActions.updateOrganizationFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    OrganizationsAPIActions.removeOrganizationSuccess,
    (state, { organizationId }) => {
      return adapter.removeOne(organizationId, state);
    },
  ),
  on(
    OrganizationsAPIActions.removeOrganizationFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    OrganizationsAPIActions.updateOrganizationAvatarSuccess,
    (state, { organizationId, avatars }) => {
      return adapter.updateOne(
        {
          id: organizationId,
          changes: { avatars },
        },
        state,
      );
    },
  ),
  on(
    OrganizationsAPIActions.removeOrganizationAvatarSuccess,
    (state, { organizationId, avatar }) => {
      const organization = state.entities[organizationId];
      if (!organization) {
        return state;
      }
      const updatedAvatars = organization.avatars.filter((av) => av !== avatar);
      return adapter.updateOne(
        {
          id: organizationId,
          changes: { avatars: updatedAvatars },
        },
        state,
      );
    },
  ),
  on(
    OrganizationsAPIActions.updateOrganizationStatusSuccess,
    (state, { organization }) => {
      return adapter.upsertOne(organization, state);
    },
  ),
  on(
    OrganizationsAPIActions.updateOrganizationStatusFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
);

export const organizationsFeature = createFeature({
  name: 'organizations',
  reducer: organizationsReducer,
  extraSelectors: ({
    selectOrganizationsState,
    selectEntities,
    selectIds,
  }) => ({
    ...adapter.getSelectors(selectOrganizationsState),
    selectOrganizationById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
  }),
});

export const {
  name,
  reducer,
  selectOrganizationsState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectOrganizationById,
} = organizationsFeature;

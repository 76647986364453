import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, tap } from 'rxjs';

import { IAvatar } from 'src/app/core/models/user.model';
import { AvatarService } from 'src/app/core/services/avatar.service';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';

interface ChangeAvatarModalState {
  selectedAvatar: IAvatar | null;
  avatars: IAvatar[] | null;
  maxDimensions: { width: number; height: number } | null;
  errorMessage: string | null;
  isLoading: boolean;
}

@Injectable()
export class ChangeAvatarModalStore
  extends ComponentStore<ChangeAvatarModalState>
  implements OnDestroy
{
  private subs: Subscription[] = [];

  constructor(private avatarService: AvatarService, private actions$: Actions) {
    super({
      selectedAvatar: null,
      maxDimensions: null,
      avatars: null,
      errorMessage: null,
      isLoading: false,
    });

    this.subs.push(
      this.appActionsSub,
      this.apiActionsSub,
      this.apiErrorPositiveActionsSub,
      this.apiErrorNegativeActionsSub
    );

    this.updater((state) => ({
      ...state,
      maxDimensions: this.avatarService.maxDimensions,
    }));
  }

  readonly selectedAvatar$ = this.select((state) => state.selectedAvatar);
  readonly maxDimensions$ = this.select((state) => state.maxDimensions);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly loading$ = this.select((state) => state.isLoading);

  readonly currentAvatar$ = this.select(
    (state) => state.avatars?.find((a) => a.isCurrent) ?? null
  );

  readonly oldAvatars$ = this.select(
    (state) =>
      state.avatars?.filter(
        (a) =>
          a.data !== state.selectedAvatar?.data ||
          a.contentType !== state.selectedAvatar?.contentType
      ) ?? null
  );

  readonly setSelectedAvatar = this.updater(
    (state, selectedAvatar: IAvatar | null) => ({
      ...state,
      selectedAvatar,
    })
  );

  readonly setAvatars = this.updater((state, avatars: IAvatar[] | null) => ({
    ...state,
    avatars,
  }));

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(AuthAppActions.updateUserAvatar, AuthAppActions.removeUserAvatar),
      tap(() => this.patchState({ isLoading: true }))
    )
    .subscribe();

  private readonly apiActionsSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.updateUserAvatarSuccess,
        AuthAPIActions.updateUserAvatarFailure,
        AuthAPIActions.removeUserAvatarSuccess,
        AuthAPIActions.removeUserAvatarFailure
      ),
      tap(() => this.patchState({ isLoading: false }))
    )
    .subscribe();

  private readonly apiErrorPositiveActionsSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.updateUserAvatarFailure,
        AuthAPIActions.removeUserAvatarFailure
      ),
      tap(({ message }) => this.patchState({ errorMessage: message }))
    )
    .subscribe();

  private readonly apiErrorNegativeActionsSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.updateUserAvatarSuccess,
        AuthAPIActions.removeUserAvatarSuccess
      ),
      tap(() => this.patchState({ errorMessage: null }))
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { IProfile } from '../../../core/models/profiles.model';

@Component({
  selector: 'cap-button-cell',
  standalone: true,
  imports: [NgClass],
  templateUrl: './button-cell.component.html',
  styleUrl: './button-cell.component.scss',
})
export class ButtonCellComponent implements OnInit {
  @Input() data: any = {};
  @Input() button: boolean = false;
  @Input() type: string = '';
  @Output() tabButtonClicked = new EventEmitter();
  notAllowed = false;
  onButtonClick() {
    this.button = !this.button;
    this.tabButtonClicked.emit({
      type: this.type,
      effectedEntity: this.data._id,
      value: this.button,
    });
  }

  ngOnInit(): void {
    if (
      this.data.hasOwnProperty('associatedIndividuals') ||
      this.data.hasOwnProperty('associatedOrganizations')
    ) {
      this.notAllowed =
        this.data.associatedIndividuals > 0 ||
        this.data.associatedOrganizations > 0;
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil, tap } from 'rxjs';

import {
  SecurityControlsAPIActions,
  SecurityControlsAppActions,
} from 'src/app/state/security-controls/security-controls.actions';

@Injectable()
export class SecurityControlsListStore
  extends ComponentStore<{
    isLoading: boolean;
    isListLoading: boolean;
    errorMessage: string | null;
  }>
  implements OnDestroy
{
  private subDestroy$ = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({ isListLoading: false, isLoading: false, errorMessage: null });
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly isListLoading$ = this.select((state) => state.isListLoading);

  private readonly appListingActionSub = this.actions$
    .pipe(
      ofType(SecurityControlsAppActions.getSecurityControls),
      tap(() => this.patchState({ isListLoading: true })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly apiListingActionSub = this.actions$
    .pipe(
      ofType(
        SecurityControlsAPIActions.getSecurityControlsSuccess,
        SecurityControlsAPIActions.getSecurityControlsFailure
      ),
      tap(() => this.patchState({ isListLoading: false })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly appActionSub = this.actions$
    .pipe(
      ofType(
        SecurityControlsAppActions.createSecurityControl,
        SecurityControlsAppActions.updateSecurityControl,
        SecurityControlsAppActions.removeSecurityControl
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly apiSuccessActionSub = this.actions$
    .pipe(
      ofType(
        SecurityControlsAPIActions.createSecurityControlSuccess,
        SecurityControlsAPIActions.updateSecurityControlSuccess,
        SecurityControlsAPIActions.removeSecurityControlSuccess
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly apiFailureSub = this.actions$
    .pipe(
      ofType(
        SecurityControlsAPIActions.createSecurityControlFailure,
        SecurityControlsAPIActions.updateSecurityControlFailure,
        SecurityControlsAPIActions.removeSecurityControlFailure
      ),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message })
      ),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

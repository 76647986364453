import { Component, Input, OnInit, inject } from '@angular/core';
import { ModalContainerComponent } from "../../../shared/modal-container/modal-container.component";
import { IUserModalConfig } from 'src/app/core/models/vertical-step.model';
import { Store } from '@ngrx/store';
import { selectOrganizations } from 'src/app/state/organizations/organizations.selectors';
import { OrganizationsAppActions } from 'src/app/state/organizations/organizations.actions';
import { AsyncPipe } from '@angular/common';
import { UsersAppActions } from 'src/app/state/users/users.actions';
import { PaymentFormComponent } from 'src/app/shared/payment-form/payment-form.component';
import { UsersService } from 'src/app/core/services/users.service';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { selectIndividualProfiles } from 'src/app/state/profiles/profiles.selectors';
import { ProfilesAppActions } from 'src/app/state/profiles/profiles.actions';
import { selectUserEmail } from 'src/app/state/auth/auth.selectors';
import { selectUser } from 'src/app/state/auth/auth.state';
@Component({
  selector: 'cap-users-import-modal',
  standalone: true,
  imports: [ModalContainerComponent, AsyncPipe, PaymentFormComponent],
  templateUrl: './users-import-modal.component.html',
  styleUrl: './users-import-modal.component.scss'
})
export class UsersImportModalComponent implements OnInit {
  private store = inject(Store);
  private usersService = inject(UsersService);
  
  @Input() usersConfig!: IUserModalConfig;
  organizations$ = this.store.select(selectOrganizations);
  IndividualProfiles$ = this.store.select(selectIndividualProfiles);
  currentUserEmail$ = this.store.select(selectUserEmail);
  currentUser$ = this.store.select(selectUser);
  
  selectedOrganizationId: string = '';
  selectedProfileId: string = '';
  csvData: any[] = [];
  selectedFile: File | null = null;
  showPaymentForm: boolean = false;
  paymentCompleted: boolean = false;
  paymentSubscriptionId: string | null = null;
  customerDetails: {
    name: string;
    phone: string;
    country: string;
    description: string;
  } | undefined;
  
  // New properties for validation
  isValidating: boolean = false;
  existingEmails: string[] = [];
  invalidRecords: any[] = [];
  
  ngOnInit(): void {
    this.store.dispatch(OrganizationsAppActions.getOrganizations({}));
    this.store.dispatch(ProfilesAppActions.getProfiles());
    this.currentUser$.subscribe((user) => {
      if (user) {
        this.customerDetails = {
          name: `${user.firstName} ${user.lastName}`,
          phone: user.phoneNumbers?.[0]?.number || '',
          country: user.addresses?.[0]?.country || '',
          description: `Cyber Audit Platform Licenses (bulk import)`
        };
      }
    });
  }
  
  
  onOrganizationChange(event: Event): void {
    this.selectedOrganizationId = (event.target as HTMLSelectElement).value;
  }

  onProfileChange(event: Event): void {
    this.selectedProfileId = (event.target as HTMLSelectElement).value;
  }
  
  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;
    
    // Reset validation state when a new file is selected
    this.existingEmails = [];
    this.invalidRecords = [];
    this.showPaymentForm = false;
    
    this.selectedFile = file;
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      this.parseCSV(reader.result as string);
    };
  }
  
  parseCSV(csvText: string): void {
    // Split by lines and filter out empty lines
    const lines = csvText.split('\n').filter(line => line.trim() !== '');
    if (lines.length < 2) return;
    
    // Clean up each line by removing surrounding quotes
    const cleanLines = lines.map(line => {
      return line.replace(/^"(.*)"$/, '$1');
    });
    
    // Split headers and get field names
    const headerLine = cleanLines[0];
    const headers = headerLine.split(';');
    
    // Process data rows
    this.csvData = [];
    
    for (let i = 1; i < cleanLines.length; i++) {
      const dataLine = cleanLines[i];
      const values = dataLine.split(';');
      
      const row: Record<string, string> = {};
      
      for (let j = 0; j < headers.length; j++) {
        const header = headers[j];
        const value = j < values.length ? values[j] : '';
        
        // Map to the correct property name
        const propertyName = this.mapHeaderToProperty(header);
        row[propertyName] = value;
      }
      
      this.csvData.push(row);
    }
    
    // Validate the CSV format after parsing
    this.validateCsvFormat();
  }
  
  private mapHeaderToProperty(header: string): string {
    // Map headers exactly as they appear in the template to backend property names
    const headerMappings: Record<string, string> = {
      'First Name': 'firstName',
      'Last Name': 'lastName',
      'Email': 'email',
      'street 1': 'street1',
      'street 2': 'street2',
      'city': 'city',
      'state': 'state',
      'zip': 'zipCode',
      'country': 'country',
      'addressType': 'addressType',
      'isPrimary': 'isPrimary',
      'phone Code': 'phoneCode',
      'phone Number': 'phoneNumber',
      'phone Type': 'phoneType',
      'isPrimary2': 'isPrimaryPhone'
    };
    
    return headerMappings[header] || header;
  }
  
  validateCsvFormat(): void {
    this.invalidRecords = [];
    
    for (const record of this.csvData) {
      // Check required fields
      if (!record.email || !record.firstName || !record.lastName) {
        this.invalidRecords.push({
          ...record,
          error: 'Missing required fields (email, firstName, or lastName)'
        });
        continue;
      }
      
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(record.email)) {
        this.invalidRecords.push({
          ...record,
          error: 'Invalid email format'
        });
      }
    }
  }
  
  validateEmails(): void {
    if (this.csvData.length === 0) {
      return;
    }
    
    // Reset validation state
    this.existingEmails = [];
    this.isValidating = true;
    
    // Extract all emails from the CSV data
    const emails = this.csvData.map(user => user.email?.toLowerCase().trim()).filter(Boolean);
    
    if (emails.length === 0) {
      alert('No valid emails found in the CSV file');
      this.isValidating = false;
      return;
    }
    
    // Make direct API call to check emails
    this.usersService.checkIfEmailsExist(emails).subscribe({
      next: (response) => {
        this.isValidating = false;
        
        if (response.existingEmails && response.existingEmails.length > 0) {
          this.existingEmails = response.existingEmails;
          console.log('Existing emails:', this.existingEmails);
        } else {
          // All emails are valid, proceed to payment
          this.showPaymentForm = true;
        }
      },
      error: (error) => {
        this.isValidating = false;
        console.error('Error checking emails:', error);
        alert('Failed to validate emails. Please try again.');
      }
    });
  }
  
  proceedToPayment(): void {
    if (!this.selectedOrganizationId) {
      alert('Please select an organization');
      return;
    }
    
    if (this.csvData.length === 0) {
      alert('Please upload a CSV file');
      return;
    }
    
    // Check for invalid records first
    if (this.invalidRecords.length > 0) {
      alert(`${this.invalidRecords.length} records have invalid format. Please fix the CSV file and try again.`);
      return;
    }
    
    // Check if emails already exist in the database
    this.validateEmails();
  }
  
  onPaymentCompleted(event: { 
    success: boolean; 
    paymentIntentId: string | null;
    subscriptionId: string | undefined;
    discountCode?: string;
    fullyDiscounted?: boolean;
  }): void {
    if (event.success) {
      this.paymentCompleted = true;
      this.paymentSubscriptionId = event.subscriptionId || null;
      
      // If payment was successful or fully discounted, proceed with import
      this.importUsers(event.fullyDiscounted || false, event.discountCode);
    } else {
      // Handle payment failure
      console.error('Payment failed');
    }
  }
  
  importUsers(fullyDiscounted: boolean = false, discountCode?: string): void {
    // Prepare the data that will be sent to the backend
    const importData = {
      jsonData: this.csvData,
      organizationId: this.selectedOrganizationId,
      subscriptionId: this.paymentSubscriptionId,
      fullyDiscounted,
      discountCode,
      featureType: 'individual',
      quantity: this.csvData.length, // Add the quantity of users being imported
      profileId: this.selectedProfileId
    };
    this.store.dispatch(UsersAppActions.importUsers(importData));
    // The modal will be closed by the parent component after successful import
    this.closeModal();
  }
  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.usersConfig.modalID }));
  }
}

import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';

import { PasswordControlMessageContainerComponent } from 'src/app/shared/password-control-message-container/password-control-message-container.component';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { passwordStrengthValidator } from 'src/app/shared/validators/password-strength.validator';
import { passwordMatchValidator } from 'src/app/shared/validators/password-match.validator';

import { SetNewPasswordStore } from './set-new-password.store';
import { selectErrorMessage } from 'src/app/state/auth/auth.state';
import { selectUserEmail } from 'src/app/state/auth/auth.selectors';
import { ISetNewPassword } from 'src/app/core/models/password.modal';
import { StepNavigationCommunicationService } from 'src/app/core/services/step-navigation-communication.service';

@Component({
  selector: 'cap-set-new-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalContainerComponent,
    PasswordControlMessageContainerComponent,
  ],
  providers: [SetNewPasswordStore],
  templateUrl: './set-new-password.component.html',
  styleUrl: './set-new-password.component.scss',
})
export class SetNewPasswordComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  private readonly store = inject(Store);
  private readonly componentStore = inject(SetNewPasswordStore);
  private fb = inject(FormBuilder);
  private stepCommunicationService = inject(StepNavigationCommunicationService);

  email$ = this.store.select(selectUserEmail);

  public readonly formError$ = this.store.select(selectErrorMessage);

  public readonly isNpVisible$ = this.componentStore.npVisibility$;
  public readonly isCnpVisible$ = this.componentStore.cnpVisibility$;

  passwordForm!: FormGroup;

  ngOnInit(): void {
    this.initializeForm();
    this.subscribeToFormChanges();
  }

  private initializeForm(): void {
    this.passwordForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            passwordStrengthValidator,
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            passwordStrengthValidator,
          ],
        ],
      },
      { validators: passwordMatchValidator('newPassword', 'confirmPassword') },
    );

    const sub = this.email$.subscribe((email) => {
      if (email) {
        this.passwordForm.patchValue({ email });
      }
    });

    this.subs.push(sub);
  }

  private subscribeToFormChanges(): void {
    const formSub = this.passwordForm.valueChanges.subscribe(() => {
      this.stepCommunicationService.emitNewPasswordCredentials({
        credentials: this.passwordForm.value,
        formValidity: this.passwordForm.valid,
      });
    });
    this.subs.push(formSub);
  }

  togglePasswordVisibility(type: 'np' | 'cnp'): void {
    this.componentStore.togglePasswordVisibility(type);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ISettings } from '../../core/models/settings.model';

export const SettingsAppActions = createActionGroup({
  source: 'Settings',
  events: {
    'Get Settings': emptyProps(),
    'Update Settings': props<{ settings: ISettings }>(),
  },
});

export const SettingsApiActions = createActionGroup({
  source: 'settings',
  events: {
    'Get Settings Success': props<{ settings: ISettings }>(),
    'Get Settings Failure': props<{ message: string }>(),
    'Update Settings Success': props<{ settings: ISettings }>(),
    'Update Settings Failure': props<{ message: string }>(),
  },
});

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';

import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { TabsStore } from './tabs.store';
import {
  selectCurrentRoute,
  selectUrl,
} from 'src/app/state/router/router.selectors';
import { ITab } from 'src/app/core/models/tab.model';

@Component({
  selector: 'cap-tabs',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, FormsModule, NgFor],
  providers: [TabsStore],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class TabsComponent implements OnInit {
  private readonly componentStore = inject(TabsStore);
  private readonly route = inject(ActivatedRoute);
  private readonly store = inject(Store);

  @Input() set tabs(value: ITab[]) {
    this.componentStore.setTabs(value);
  }

  readonly tabs$ = this.componentStore.tabs$;

  @Output() tabChange = new EventEmitter<string>();

  ngOnInit(): void {
    this.store.select(selectUrl).subscribe((routerState) => {
      this.componentStore.setCurrentTabByUrl(routerState);
    });
  }

  selectTab(tab: ITab) {
    this.componentStore.setCurrentTab(tab);
    this.tabChange.emit(tab.route);
  }
}

import { HttpErrorResponse } from '@angular/common/http';

export const handleError = (error: HttpErrorResponse | any): string => {
  let errorMessage = 'An unknown error occurred!';

  // Check if the error is an instance of an HttpErrorResponse
  if (error instanceof HttpErrorResponse) {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMessage = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      errorMessage = `${error.status}: ${error.message}`;
      if (
        error.error &&
        typeof error.error === 'object' &&
        'message' in error.error
      ) {
        errorMessage = error.error.message;
      }
    }
  } else {
    // A non-HttpErrorResponse error occurred.
    errorMessage = error.message;
  }

  return errorMessage;
};

import { IAssessment } from '../../core/models/assessments.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssessmentsAPIActions } from './assessments.actions';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

export interface AssessmentsState extends EntityState<IAssessment> {
  errorMessage: string | null;
}

export const adapter: EntityAdapter<IAssessment> =
  createEntityAdapter<IAssessment>({
    selectId: (assessment: IAssessment) => assessment._id,
  });

export const initialState: AssessmentsState = adapter.getInitialState({
  errorMessage: null,
});

export const assessmentsReducer = createReducer(
  initialState,
  on(AssessmentsAPIActions.getAssessmentsSuccess, (state, { assessments }) => {
    return adapter.setAll(assessments, {
      ...state,
      errorMessage: null,
    });
  }),
  on(AssessmentsAPIActions.getAssessmentsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(
    AssessmentsAPIActions.getAssessmentByIdSuccess,
    (state, { assessment }) => {
      return adapter.upsertOne(assessment, state);
    },
  ),
  on(AssessmentsAPIActions.getAssessmentByIdFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    AssessmentsAPIActions.getAssessmentsByUserIdSuccess,
    (state, { assessments }) => {
      return adapter.setAll(assessments, {
        ...state,
        errorMessage: null,
      });
    },
  ),
  on(
    AssessmentsAPIActions.getAssessmentsByUserIdFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    AssessmentsAPIActions.getAssessmentsByOrganizationIdSuccess,
    (state, { assessments }) => {
      return adapter.setAll(assessments, {
        ...state,
        errorMessage: null,
      });
    },
  ),
  on(
    AssessmentsAPIActions.getAssessmentsByOrganizationIdFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(AssessmentsAPIActions.updateAssessmentSuccess, (state, { assessment }) => {
    return adapter.upsertOne(assessment, state);
  }),
  on(AssessmentsAPIActions.updateAssessmentFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(AssessmentsAPIActions.removeAssessmentSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(AssessmentsAPIActions.removeAssessmentFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(AssessmentsAPIActions.resetAssessmentsStateSuccess, (state) => {
    return adapter.removeAll(state);
  }),
  on(
    AssessmentsAPIActions.uploadAssessmentEvidenceSuccess,
    (state, { assessmentId, actionItemId, docs }) => {
      const assessment = state.entities[assessmentId];
      if (!assessment) return state;

      const updatedEvidence = assessment.evidence.map((e) => {
        if (e.actionItemId === actionItemId) {
          return {
            ...e,
            doc: [...e.doc, ...docs],
          };
        }
        return e;
      });

      if (!updatedEvidence.some((e) => e.actionItemId === actionItemId)) {
        updatedEvidence.push({ actionItemId, doc: [...docs] });
      }

      return adapter.updateOne(
        {
          id: assessmentId,
          changes: {
            evidence: updatedEvidence,
          },
        },
        state,
      );
    },
  ),
  on(
    AssessmentsAPIActions.uploadAssessmentEvidenceFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    AssessmentsAPIActions.removeAssessmentEvidenceSuccess,
    (state, { assessmentId, actionItemId, docId }) => {
      const assessment = state.entities[assessmentId];
      if (!assessment) return state;

      const updatedAssessment = {
        ...assessment,
        evidence: assessment.evidence.map((e) => {
          if (e.actionItemId === actionItemId) {
            return {
              ...e,
              doc: e.doc.filter((d) => d._id !== docId),
            };
          }
          return e;
        }),
      };

      return adapter.upsertOne(updatedAssessment, state);
    },
  ),
  on(
    AssessmentsAPIActions.removeAssessmentEvidenceFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    AssessmentsAPIActions.removeAllEvidenceSuccess,
    (state, { assessmentId, actionItemId }) => {
      const assessment = state.entities[assessmentId];
      if (!assessment) return state;

      const updatedAssessment = {
        ...assessment,
        evidence: assessment.evidence.filter(
          (e) => e.actionItemId !== actionItemId,
        ),
      };
      return adapter.upsertOne(updatedAssessment, state);
    },
  ),
  on(
    AssessmentsAPIActions.setAssessmentAsNotApplicableSuccess,
    (state, { assessment }) => {
      return adapter.upsertOne(assessment, state);
    },
  ),
  on(
    AssessmentsAPIActions.setAssessmentAsNotApplicableFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    AssessmentsAPIActions.setAssessmentNotApplicableReasonSuccess,
    (state, {assessment}) => {
      return adapter.upsertOne(assessment, state);
    }
  ),
  on(
    AssessmentsAPIActions.setAssessmentNotApplicableReasonFailure,
    (state, {message}) => {
      return { ...state, errorMessage: message };
    }
  ),
  on(
    AssessmentsAPIActions.setAssessmentQuestionAsNotApplicableSuccess,
    (state, {assessment}) => {
      return adapter.upsertOne(assessment, state);
    }
  ),
  on(
    AssessmentsAPIActions.setAssessmentQuestionAsNotApplicableFailure,
    (state, {message}) => {
      return { ...state, errorMessage: message };
    }
  )
);

export const AssessmentsFeature = createFeature({
  name: 'assessments',
  reducer: assessmentsReducer,
  extraSelectors: ({ selectAssessmentsState, selectEntities }) => ({
    ...adapter.getSelectors(selectAssessmentsState),
    selectAssessmentById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id] ?? null),
  }),
});

export const {
  name,
  reducer,
  selectAssessmentsState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectAssessmentById,
} = AssessmentsFeature;

import { createSelector, MemoizedSelector } from '@ngrx/store';

import {
  selectEntities as selectSecurityControls,
  selectSecurityControlsState,
} from './security-controls.state';
import {
  selectEntities as selectStandards,
  selectStandardsState,
} from './standards.state';
import {
  IAttachedSecurityControl,
  IEnrichedSecurityControl,
  ISecurityControl,
  IStandard,
} from 'src/app/core/models/security-controls.model';
import { Dictionary } from '@ngrx/entity';

export const selectEnrichedSecurityControls = createSelector(
  selectStandards,
  selectSecurityControls,
  (standards, securityControls) => {
    return Object.values(securityControls).map((securityControl) => {
      const relatedStandard = standards[securityControl!.standardId];
      return {
        ...securityControl,
        standard: relatedStandard
          ? `${relatedStandard.name} ${relatedStandard.version}`
          : null,
        suggestedGroupings: relatedStandard?.groupings ?? [],
      } as IEnrichedSecurityControl;
    });
  },
);

export const selectSecurityControlsPagination = createSelector(
  selectSecurityControlsState,
  (state) => state.pagination,
);

export const selectStandardsPagination = createSelector(
  selectStandardsState,
  (state) => state.pagination,
);

export const selectSecurityControlsByStandardId = (
  standardId: string,
): MemoizedSelector<
  Record<string, any>,
  IAttachedSecurityControl[],
  (
    s1: Dictionary<ISecurityControl>,
    s2: Dictionary<IStandard>,
  ) => IAttachedSecurityControl[]
> =>
  createSelector(
    selectSecurityControls,
    selectStandards,
    (securityControls, standards) => {
      if (!securityControls) {
        return [];
      }

      const controls = Object.values(securityControls).filter(
        (securityControl) => securityControl?.standardId === standardId,
      );

      return controls
        .filter((control): control is ISecurityControl => control !== undefined)
        .map((control) => ({
          ...control,
          name: `${standards[control.standardId]?.name ?? ''} ${standards[control.standardId]?.version ?? ''} ${control.subVersion}`,
        }));
    },
  );

export const selectSecurityControlsByIds = (
  ids: string[],
): MemoizedSelector<
  Dictionary<ISecurityControl>,
  IAttachedSecurityControl[],
  (
    s1: Dictionary<ISecurityControl>,
    s2: Dictionary<IStandard>,
  ) => ISecurityControl[]
> =>
  createSelector(
    selectSecurityControls,
    selectStandards,
    (securityControls, standards) => {
      if (!securityControls) {
        return [];
      }

      return ids
        .map((id) => securityControls[id])
        .filter((control): control is ISecurityControl => control !== undefined)
        .map((control) => ({
          ...control,
          name: `${standards[control.standardId]?.name ?? ''} ${standards[control.standardId]?.version ?? ''} ${control.subVersion}`,
        }));
    },
  );

export const selectAllStandards = createSelector(selectStandards, (standards) =>
  Object.values(standards),
);

import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { ComponentStore } from '@ngrx/component-store';
import { Subscription, map, tap } from 'rxjs';
import { AuthAPIActions, AuthAppActions } from '../state/auth/auth.actions';

@Injectable()
export class ForgotPasswordPageStore
  extends ComponentStore<{
    formSubmissionSuccess: boolean;
    isLoading: boolean;
  }>
  implements OnDestroy
{
  private actionsSubscription: Subscription[] = [];
  constructor(private readonly actions$: Actions) {
    super({ formSubmissionSuccess: false, isLoading: false });

    this.handleFormSubmissionSuccess();
    this.actionsSubscription.push(this.appActionsSub, this.apiActionsSub);
  }

  readonly isLoading$ = this.select((state) => state.isLoading);

  readonly formSubmissionSuccess$ = this.select(
    (state) => state.formSubmissionSuccess
  );

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(AuthAppActions.forgotPassword),
      tap(() => this.patchState({ isLoading: true }))
    )
    .subscribe();

  private readonly apiActionsSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.forgotPasswordSuccess,
        AuthAPIActions.forgotPasswordFailure
      ),
      tap(() => this.patchState({ isLoading: false }))
    )
    .subscribe();

  readonly setFormSubmissionSuccess = this.updater(
    (state, formSubmissionSuccess: boolean) => ({
      ...state,
      formSubmissionSuccess,
    })
  );

  handleFormSubmissionSuccess() {
    const subscription = this.actions$
      .pipe(
        ofType(AuthAPIActions.forgotPasswordSuccess),
        map(() => true)
      )
      .subscribe((formSubmissionSuccess) => {
        this.setFormSubmissionSuccess(formSubmissionSuccess);
      });
    this.actionsSubscription.push(subscription);
  }

  override ngOnDestroy(): void {
    this.actionsSubscription.forEach((sub) => sub.unsubscribe());
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-edit-radio-cell',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './edit-radio-cell.component.html',
  styleUrl: './edit-radio-cell.component.scss',
})
export class EditRadioCellComponent {
  @Input() options: { value: string; label: string }[] = [];
  @Input() selectedOption: string = '';
  @Input() groupName: string = '';
  @Output() optionChange = new EventEmitter<string>();

  onOptionChange() {
    this.optionChange.emit(this.selectedOption);
  }
}

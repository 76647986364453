<div class="flex gap-4 justify-center py-4">
    <h1 class="text-2xl font-bold">Payment Details</h1>
</div>
<div class="payment-form">
    @if (error) {
      <cap-feedback 
        type="error" 
        [message]="error">
      </cap-feedback>
    }

    <!-- Price Display -->
    <div class="mb-4 p-4 bg-gray-50 rounded-md">
      <div class="flex justify-between items-center">
        <span class="text-gray-600">Original Price:</span>
        <span class="text-lg font-semibold">${{ originalPrice }}/Year</span>
      </div>
      @if (discountApplied) {
        <div class="flex justify-between items-center mt-2 text-green-600">
          <span>Discount:</span>
          <span>-${{ discount }}</span>
        </div>
        <div class="flex justify-between items-center mt-2 font-bold border-t border-gray-200 pt-2">
          <span>Final Price:</span>
          <span class="text-lg">${{ finalPrice }}/Year</span>
        </div>
      }
    </div>

    <div class="mb-4">
      <form [formGroup]="discountForm" (submit)="applyDiscount($event)" class="flex gap-2">
        <input
          type="text"
          formControlName="discountCode"
          placeholder="Enter discount code"
          class="flex-1 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm"
        />
        <button
          type="submit"
          [disabled]="isLoading || !discountForm.valid"
          class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 disabled:opacity-50">
          Apply
        </button>
      </form>
    </div>

    <form (submit)="handleSubmit($event)">
      <div id="payment-element"></div>
      
      <button 
        type="submit" 
        [disabled]="isLoading || paymentSucceeded" 
        class="mt-4 w-full py-2 px-4 rounded"
        [class]="paymentSucceeded ? 'bg-green-500 text-white' : 'bg-red-500 text-white'">
        {{ isLoading ? 'Processing...' : (paymentSucceeded ? 'Payment Successful!' : 'Pay $' + finalPrice + ' Now') }}
      </button>
    </form>
  </div>
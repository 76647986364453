import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IRegister } from 'src/app/core/models/register.model';
import { IUser, IUserBase } from 'src/app/core/models/user.model';
import { Pagination } from './users.state';

export const UsersAppActions = createActionGroup({
  source: 'Users',
  events: {
    'Register New User': props<{ user: IUserBase }>(),
    'Update User Account': props<{ user: IUser }>(),
    'Update User Organization': props<{ userId: string; orgId: string }>(),
    'Remove User Account': props<{ id: string }>(),
    'Get Users Accounts': props<{ page: number; limit: number }>(),
    'Get All Users Accounts': emptyProps(),
    'Search User Accounts': props<{
      search: string;
      page: string;
      limit: string;
    }>(),
    'Get Users Accounts By Organization Id': props<{
      organizationId: string;
    }>(),
    'Update User Account Status': props<{
      userId: string;
      isActive: boolean;
    }>(),
  },
});

export const UsersAPIActions = createActionGroup({
  source: 'Users',
  events: {
    'Register New User Success': props<{ user: IUser }>(),
    'Register New User Failure': props<{ message: string }>(),
    'Update User Account Success': props<{ user: IUser }>(),
    'Update User Account Failure': props<{ message: string }>(),
    'Update User Organization Success': props<{ user: IUser }>(),
    'Update User Organization Failure': props<{ message: string }>(),
    'Remove User Account Success': props<{ userId: string; message: string }>(),
    'Remove User Account Failure': props<{ message: string }>(),
    'Get Users Accounts Success': props<{
      users: IUser[];
      pagination: Pagination;
    }>(),
    'Get Users Accounts Failure': props<{ message: string }>(),
    'Get Users Accounts By Organization Id Success': props<{
      users: IUser[];
    }>(),
    'Get Users Accounts By Organization Id Failure': props<{
      userIds: string[];
      message: string;
    }>(),
    'Get All Users Accounts Success': props<{ users: IUser[] }>(),
    'Get All Users Accounts Failure': props<{ message: string }>(),
    'Search User Accounts Success': props<{
      users: IUser[];
      pagination: Pagination;
    }>(),
    'Search User Accounts Failure': props<{ message: string }>(),
    'Update User Account Status Success': props<{ user: IUser }>(),
    'Update User Account Status Failure': props<{ message: string }>(),
  },
});

<nav
  class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
>
  <div class="-mt-px flex w-0 flex-1">
    <a
      (click)="current_page > 1 && onPreviousPage()"
      [ngClass]="{
        'text-gray-400 cursor-not-allowed': current_page <= 1,
        'text-gray-500 hover:text-gray-700 hover:cursor-pointer':
          current_page > 1
      }"
      class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium"
    >
      <!-- SVG for Previous Arrow -->
      <svg
        class="mr-3 h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
          clip-rule="evenodd"
        />
      </svg>
      Previous
    </a>
  </div>
  <div class="hidden md:-mt-px md:flex">
    <ng-container *ngFor="let page of pagesToDisplay">
      <ng-container *ngIf="page === '...'; else pageNumber">
        <span
          class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
          >...</span
        >
      </ng-container>
      <ng-template #pageNumber>
        <a
          (click)="goToPage(+page)"
          [ngClass]="getNavStyle(+page)"
          class="hover:cursor-pointer"
        >
          {{ page }}
        </a>
      </ng-template>
    </ng-container>
  </div>
  <div class="-mt-px flex w-0 flex-1 justify-end">
    <a
      (click)="current_page < totalPages && onNextPage()"
      [ngClass]="{
        'text-gray-400 cursor-not-allowed': current_page >= totalPages,
        'text-gray-500 hover:text-gray-700 hover:cursor-pointer':
          current_page < totalPages
      }"
      class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium"
    >
      Next
      <!-- SVG for Next Arrow -->
      <svg
        class="ml-3 h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
          clip-rule="evenodd"
        />
      </svg>
    </a>
  </div>
</nav>

import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, map, take } from 'rxjs';

import { ProfileContext } from 'src/app/core/models/profile-form-context.model';
import { IRole, IUser, UserRole } from 'src/app/core/models/user.model';
import { IUserModalConfig } from 'src/app/core/models/vertical-step.model';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { UserProfileFormComponent } from 'src/app/shared/user-profile-form/user-profile-form.component';
import { UserProfileModalStore } from './user-profile-modal.store';
import {
  UsersAPIActions,
  UsersAppActions,
} from 'src/app/state/users/users.actions';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { FeedbackComponent } from "../../../shared/feedback/feedback.component";

@Component({
    selector: 'cap-user-profile-modal',
    standalone: true,
    providers: [UserProfileModalStore],
    templateUrl: './user-profile-modal.component.html',
    styleUrl: './user-profile-modal.component.scss',
    imports: [
        CommonModule,
        ModalContainerComponent,
        SpinnerComponent,
        UserProfileFormComponent,
        FeedbackComponent
    ]
})
export class UserProfileModalComponent implements OnInit, OnDestroy {
  @Input() userConfig!: IUserModalConfig;

  private store = inject(Store);
  private componentStore = inject(UserProfileModalStore);
  private actions$ = inject(Actions);

  private subs: Subscription[] = [];

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;
  userProfileValue$ = this.componentStore.user$;
  userProfileFormValidity$ = this.componentStore.userFormValidity$;

  ngOnInit(): void {
    if (this.userConfig.data) this.componentStore.setUser(this.userConfig.data);
  }

  handleProfileChange(profile: FormGroup) {
    this.componentStore.setUser(profile.value);
    this.componentStore.setUserFormValidity(profile.valid);
  }

  submitUserForm() {
    const sub = this.userProfileValue$
      .pipe(
        map((userProfileValue) => {
          if (userProfileValue) {
            if (this.userConfig.context === ProfileContext.UserCreation) {
              if (userProfileValue.role.name === UserRole.Individual && !userProfileValue.paymentCompleted) {
                this.componentStore.setErrorMessage('Payment is required to create a new individual user');
              } else {
                this.store.dispatch(
                  UsersAppActions.registerNewUser({ user: userProfileValue })
                );
              }
            } else {
              this.store.dispatch(
                UsersAppActions.updateUserAccount({
                  user: userProfileValue as IUser,
                })
              );
            }
          }
          this.waitForResponse();
        })
      )
      .subscribe();

    this.subs.push(sub);
  }

  private waitForResponse() {
    const sub = this.actions$
      .pipe(
        ofType(
          UsersAPIActions.registerNewUserSuccess,
          UsersAPIActions.updateUserAccountSuccess
        ),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(
          ModalActions.close({ id: this.userConfig.modalID })
        );
      });
    this.subs.push(sub);
  }
  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.userConfig.modalID }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      ModalActions.unregister({ id: this.userConfig.modalID })
    );
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISettings } from '../models/settings.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly http = inject(HttpClient);
  private settingsPath = 'settings';

  constructor() {}

  getSettings(): Observable<{ settings: ISettings }> {
    return this.http.get<{ settings: ISettings }>(
      `${environment.apiUrl}/${this.settingsPath}`,
    );
  }

  updateSettings(settings: ISettings): Observable<{ settings: ISettings }> {
    return this.http.put<{ settings: ISettings }>(
      `${environment.apiUrl}/${this.settingsPath}/update`,
      settings,
    );
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil, tap } from 'rxjs';
import {
  CategoriesAPIActions,
  CategoriesAppActions,
} from 'src/app/state/categories/categories.actions';

interface CategoryUpsertModalState {
  isLoading: boolean;
  errorMessage: string | null;
}

@Injectable()
export class CategoryUpsertModalStore
  extends ComponentStore<CategoryUpsertModalState>
  implements OnDestroy
{
  subDestroy$: Subject<void> = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({
      isLoading: false,
      errorMessage: null,
    });
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessaage$ = this.select((state) => state.errorMessage);

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(
        CategoriesAppActions.createCategory,
        CategoriesAppActions.updateCategory
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
      takeUntil(this.destroy$)
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(
        CategoriesAPIActions.createCategorySuccess,
        CategoriesAPIActions.updateCategorySuccess
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(
        CategoriesAPIActions.createCategoryFailure,
        CategoriesAPIActions.updateCategoryFailure
      ),
      tap(({ message }) =>
        this.patchState({ errorMessage: message, isLoading: false })
      ),
      takeUntil(this.subDestroy$)
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { FormErrorMessageComponent } from '../form-error-message/form-error-message.component';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  IProfileFormInput,
  ProfileContext,
} from 'src/app/core/models/profile-form-context.model';
import { atLeastOnePrimaryValidator } from '../validators/at-least-one-primary-control.validator';
import { isNumericValidator } from '../validators/is-numeric.validator';
import {
  IAddress,
  IPhoneNumber,
  IRole,
  IUser,
  UserRole,
} from 'src/app/core/models/user.model';
import { phoneNumberFormatValidator } from '../validators/phone-number-format.validator';
import { CountryPhoneCodeService } from 'src/app/core/services/country-phone-code.service';
import { RoleNamePipe } from 'src/app/shared/pipes/role-name.pipe';
import { Observable, Subscription, combineLatestWith, map } from 'rxjs';
import { selectUserRole } from 'src/app/state/auth/auth.selectors';
import { RolesAppActions } from 'src/app/state/roles/roles.actions';
import { selectAll as selectRoles } from 'src/app/state/roles/roles.state';
import {DropdownModule} from "primeng/dropdown";
import {SharedModule} from "primeng/api";
import {ProfilesAppActions} from "../../state/profiles/profiles.actions";
import {selectProfiles} from "../../state/profiles/profiles.selectors";
import { AnimationEvent } from '@angular/animations';

@Component({
  selector: 'cap-profile-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorMessageComponent,
    RoleNamePipe,
    DropdownModule,
    SharedModule,
  ],
  templateUrl: './profile-form.component.html',
  styleUrl: './profile-form.component.scss',
})
export class ProfileFormComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private readonly store = inject(Store);
  private countryPhoneCodeService = inject(CountryPhoneCodeService);


  @Input() formInput!: IProfileFormInput;
  @Output() profileFormChanged: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();
  roles$ = this.store.select(selectRoles);

  contriesAndPhoneCodes = this.countryPhoneCodeService.getCountryPhoneCodes();

  private subs: Subscription[] = [];

  profileForm!: FormGroup;

  readonly currentUserRole$ = this.store.select(selectUserRole);
  ngOnInit(): void {

    if (this.formInput.context === ProfileContext.UserCreation)
      this.store.dispatch(RolesAppActions.getRoles());

    this.initializeForm();
    const formSub = this.profileForm.valueChanges.subscribe(() => {
      this.profileFormChanged.emit(this.profileForm);
    });
    this.subs.push(formSub);
  }

  initializeForm(): void {
    switch (this.formInput.context) {
      case ProfileContext.UserCreation:
      case ProfileContext.UserUpdate:
        this.profileForm = this.fb.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: [
            {
              value: '',
              disabled: this.formInput.context === ProfileContext.UserUpdate,
            },
          ],
          role: ['', Validators.required],
          phoneNumbers: this.fb.array(
            [],
            atLeastOnePrimaryValidator('isPrimary')
          ),
          addresses: this.fb.array([], atLeastOnePrimaryValidator('isPrimary')),
        });

        if (ProfileContext.UserUpdate && this.formInput.data) {
          this.profileForm.patchValue(this.formInput.data);
          this.profileForm.patchValue({
            role: (this.formInput.data as IUser).role.name,
          });
          this.formInput.data.addresses.map((address) => {
            this.addresses.push(this.createAddressGroup(address));
          });
          this.formInput.data.phoneNumbers.map((phoneNumber) => {
            this.phoneNumbers.push(this.createPhoneNumberGroup(phoneNumber));
          });
        }
        if (this.addresses.length === 0) {
          this.addAddressRow();
        }
        if (this.phoneNumbers.length === 0) {
          this.addPhoneNumberRow();
        }
        break;

      case ProfileContext.OrgCreation:
      case ProfileContext.OrgUpdate:
        this.profileForm = this.fb.group({
          name: ['', Validators.required],
          //profile: ['', Validators.required],
          phoneNumbers: this.fb.array(
            [],
            atLeastOnePrimaryValidator('isPrimary')
          ),
          addresses: this.fb.array([], atLeastOnePrimaryValidator('isPrimary')),
        });
        if (ProfileContext.OrgUpdate && this.formInput.data) {
          this.profileForm.patchValue(this.formInput.data);
          this.formInput.data.addresses.map((address) => {
            this.addresses.push(this.createAddressGroup(address));
          });
          this.formInput.data.phoneNumbers.map((phoneNumber) => {
            this.phoneNumbers.push(this.createPhoneNumberGroup(phoneNumber));
          });
        }
        if (this.addresses.length === 0) {
          this.addAddressRow();
        }
        if (this.phoneNumbers.length === 0) {
          this.addPhoneNumberRow();
        }
    }
  }

  get addresses() {
    return this.profileForm.get('addresses') as FormArray;
  }

  private createAddressGroup(address: IAddress | null): FormGroup {
    const addressGroup = this.fb.group({
      street1: [address?.street1 ?? '', Validators.required],
      street2: [address?.street2 ?? ''],
      city: [address?.city ?? '', Validators.required],
      state: [address?.state ?? ''],
      country: [
        address?.country ?? 'United States of America',
        Validators.required,
      ],
      zip: [address?.zip ?? '', [Validators.required, isNumericValidator()]],
      addressType: [address?.addressType ?? 'Home', Validators.required],
      isPrimary: [address?.isPrimary ?? false, Validators.required],
    });
    this.setupPrimarySubscription(addressGroup, 'addresses');
    return addressGroup;
  }

  addAddressRow(): void {
    this.addresses.push(this.createAddressGroup(null));
  }

  removeAddress(index: number): void {
    if (this.addresses.length === 1) {
      return;
    }
    this.addresses.removeAt(index);
  }

  get phoneNumbers() {
    return this.profileForm.get('phoneNumbers') as FormArray;
  }

  private createPhoneNumberGroup(phone: IPhoneNumber | null): FormGroup {
    const phoneGroup = this.fb.group({
      phoneCode: [phone?.phoneCode ?? '1', Validators.required],
      number: [phone?.number ?? '', [Validators.required]],
      phoneType: [phone?.phoneType ?? 'Mobile', Validators.required],
      isPrimary: [phone?.isPrimary ?? false, Validators.required],
    });

    this.setupPrimarySubscription(phoneGroup, 'phoneNumbers');
    return phoneGroup;
  }

  // Setup subscription for primary checkbox
  private setupPrimarySubscription(
    group: FormGroup,
    key: 'addresses' | 'phoneNumbers'
  ) {
    const isPrimaryControl = group.get('isPrimary');
    isPrimaryControl?.valueChanges.subscribe((value) => {
      if (value) {
        this.uncheckOtherPrimary(key, group);
      }
    });
  }

  // Setup Validator for phone number group
  private initOrUpdatePhoneNumbers() {
    this.phoneNumbers.controls.forEach((phoneGroup, index) => {
      phoneGroup.setValidators(phoneNumberFormatValidator('phoneCode'));
      phoneGroup.updateValueAndValidity({ emitEvent: false });
    });
  }

  // Uncheck 'isPrimary' in other groups
  private uncheckOtherPrimary(
    key: 'addresses' | 'phoneNumbers',
    currentGroup: FormGroup
  ) {
    const formArray = this.profileForm.get(key) as FormArray;
    formArray.controls.forEach((group) => {
      if (group !== currentGroup) {
        (group.get('isPrimary') as FormControl).setValue(false, {
          emitEvent: false,
        });
      }
    });
  }

  addPhoneNumberRow(): void {
    this.phoneNumbers.push(this.createPhoneNumberGroup(null));
  }
  removePhoneNumber(index: number): void {
    if (this.phoneNumbers.length === 1) {
      return;
    }
    this.phoneNumbers.removeAt(index);
  }

  isUserContext(): boolean {
    return (
      this.formInput.context === ProfileContext.UserCreation ||
      this.formInput.context === ProfileContext.UserUpdate
    );
  }

  isOrgContext(): boolean {
    return (
      this.formInput.context === ProfileContext.OrgCreation ||
      this.formInput.context === ProfileContext.OrgUpdate
    );
  }

  isActionUpdate(): boolean {
    return (
      this.formInput.context === ProfileContext.UserUpdate ||
      this.formInput.context === ProfileContext.OrgUpdate
    );
  }

  isActionCreate(): boolean {
    return (
      this.formInput.context === ProfileContext.UserCreation ||
      this.formInput.context === ProfileContext.OrgCreation
    );
  }

  isCurrentUserAdmin(): Observable<boolean> {
    return this.profileForm.valueChanges.pipe(
      combineLatestWith(this.currentUserRole$),
      map(([form, role]) => {
        if (form.role.value === role?._id) {
          return false;
        } else {
          return (
            role?.name === UserRole.SuperAdmin ||
            role?.name === UserRole.OrgAdmin ||
            role?.name === UserRole.OrgManager
          );
        }
      })
    );
  }

  onAnimationDone(event: AnimationEvent): void {
    // This method is called when the fade animation is complete
    // You can add any logic you want to execute after the animation
    console.log('Animation completed:', event.toState);
    
    // Example: You might want to do something specific based on the animation state
    if (event.toState === 'in') {
      console.log('Fade-in animation completed');
    } else if (event.toState === 'out') {
      console.log('Fade-out animation completed');
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { exhaustMap, take, map, catchError, of, switchMap, share } from 'rxjs';

import { ServicesService } from 'src/app/core/services/services.service';
import { ServicesAppActions, ServicesAPIActions } from './services.actions';
import { handleError } from 'src/app/utils/error-handler.util';
import { Store } from '@ngrx/store';
import { AlertActions } from '../alert/alert.actions';

export const getServices$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.getServices),
      exhaustMap(({ page, pageSize }) =>
        servicesService.getServices(page, pageSize).pipe(
          take(1),
          map(({ services, pagination }) =>
            ServicesAPIActions.getServicesSuccess({ services, pagination }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(ServicesAPIActions.getServicesFailure({ message }));
          }),
          share(),
        ),
      ),
    ),
  { functional: true },
);

export const getServiceById$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.getServiceById),
      exhaustMap(({ id }) =>
        servicesService.getServiceById(id).pipe(
          take(1),
          map(({ service }) =>
            ServicesAPIActions.getServicesByIdSuccess({ service }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(ServicesAPIActions.getServicesFailure({ message }));
          }),
          share(),
        ),
      ),
    ),
  { functional: true },
);

export const createService$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.createService),
      exhaustMap(({ service }) =>
        servicesService.createService(service).pipe(
          take(1),
          map(({ service }) =>
            ServicesAPIActions.createServiceSuccess({ service }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(ServicesAPIActions.createServiceFailure({ message }));
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const updateService$ = createEffect(
  (actions$ = inject(Actions), servicesServie = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.updateService),
      exhaustMap(({ service }) =>
        servicesServie.updateService(service).pipe(
          take(1),
          map(({ service }) =>
            ServicesAPIActions.updateServiceSuccess({ service }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(ServicesAPIActions.updateServiceFailure({ message }));
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const removeService$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.removeService),
      exhaustMap(({ id }) =>
        servicesService.removeService(id).pipe(
          take(1),
          map(({ serviceId, message }) =>
            ServicesAPIActions.removeServiceSuccess({ serviceId, message }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(ServicesAPIActions.removeServiceFailure({ message }));
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const getAllServiceSources$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.getServiceSources),
      exhaustMap(({ page, pageSize }) =>
        servicesService.getServiceSources(page, pageSize).pipe(
          take(1),
          map(({ serviceSources, pagination }) =>
            ServicesAPIActions.getServiceSourcesSuccess({
              serviceSources,
              pagination,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(ServicesAPIActions.getServiceSourcesFailure({ message }));
          }),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const getServiceSourceById$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.getServiceSourceById),
      exhaustMap(({ id }) =>
        servicesService.getServiceSourceById(id).pipe(
          take(1),
          map(({ serviceSource }) =>
            ServicesAPIActions.getServiceSourceByIdSuccess({ serviceSource }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ServicesAPIActions.getServiceSourceByIdFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const createServiceSource$ = createEffect(
  (actions$ = inject(Actions), servciesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.createServiceSource),
      exhaustMap(({ serviceSource }) =>
        servciesService.createServiceSource(serviceSource).pipe(
          take(1),
          map(({ serviceSource }) =>
            ServicesAPIActions.createServiceSourceSuccess({ serviceSource }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ServicesAPIActions.createServiceSourceFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const updateServiceSource$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.updateServiceSource),
      exhaustMap(({ serviceSource }) =>
        servicesService.updateServiceSource(serviceSource).pipe(
          take(1),
          map(({ serviceSource }) =>
            ServicesAPIActions.updateServiceSourceSuccess({ serviceSource }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ServicesAPIActions.updateServiceSourceFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const removeServiceSource$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.removeServiceSource),
      exhaustMap(({ id }) =>
        servicesService.removeServiceSource(id).pipe(
          take(1),
          map(({ serviceSourceId, message }) =>
            ServicesAPIActions.removeServiceSourceSuccess({
              serviceSourceId,
              message,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ServicesAPIActions.removeServiceSourceFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const getAllServiceVendors$ = createEffect(
  (actions$ = inject(Actions), servciesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.getServiceVendors),
      exhaustMap(({ page, pageSize }) =>
        servciesService.getServiceVendors(page, pageSize).pipe(
          take(1),
          map(({ serviceVendors, pagination }) =>
            ServicesAPIActions.getServiceVendorsSuccess({
              serviceVendors,
              pagination,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(ServicesAPIActions.getServiceVendorsFailure({ message }));
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const getServiceVendorById$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.getServiceVendorById),
      exhaustMap(({ id }) =>
        servicesService.getServiceVendorById(id).pipe(
          take(1),
          map(({ serviceVendor }) =>
            ServicesAPIActions.getServiceVendorByIdSuccess({ serviceVendor }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ServicesAPIActions.getServiceVendorByIdFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const createServiceVendor$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.createServiceVendor),
      exhaustMap(({ serviceVendor }) =>
        servicesService.createServiceVendor(serviceVendor).pipe(
          take(1),
          map(({ serviceVendor }) =>
            ServicesAPIActions.createServiceVendorSuccess({ serviceVendor }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ServicesAPIActions.createServiceVendorFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    ),
  { functional: true },
);

export const updateServiceVendor$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.updateServiceVendor),
      exhaustMap(({ serviceVendor }) =>
        servicesService.updateServiceVendor(serviceVendor).pipe(
          take(1),
          map(({ serviceVendor }) =>
            ServicesAPIActions.updateServiceVendorSuccess({ serviceVendor }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ServicesAPIActions.updateServiceVendorFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const removeServiceVendor = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.removeServiceVendor),
      exhaustMap(({ id }) =>
        servicesService.removeServiceVendor(id).pipe(
          take(1),
          map(({ serviceVendorId, message }) =>
            ServicesAPIActions.removeServiceVendorSuccess({
              serviceVendorId,
              message,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ServicesAPIActions.removeServiceVendorFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const uploadServicesCSV$ = createEffect(
  (actions$ = inject(Actions),store$ = inject(Store), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.uploadServicesCSV),
      exhaustMap(({ jsonData }) =>
        servicesService.uploadServicesCSV(jsonData).pipe(
          take(1),
          switchMap(({ services, vendors, sources }) => {
            store$.dispatch(AlertActions.addAlert({
              alert: {
                type: 'success',
                message: 'Services CSV uploaded successfully'
              }
            }))
            return [
            ServicesAPIActions.uploadServicesCSVSuccess({ services }),
            ...vendors.map(vendor => ServicesAPIActions.createServiceVendorSuccess({ serviceVendor: vendor })),
            ...sources.map(source => ServicesAPIActions.createServiceSourceSuccess({ serviceSource: source }))
          ]}),
          catchError((error) => {
            store$.dispatch(AlertActions.addAlert({
              alert: {
                type: 'error',
                message: 'Failed to upload services CSV'
              }
            }))
            const message = handleError(error);
            return of(ServicesAPIActions.uploadServicesCSVFailure({ message }));
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

export const downloadServicesCSV$ = createEffect(
  (actions$ = inject(Actions), servicesService = inject(ServicesService)) =>
    actions$.pipe(
      ofType(ServicesAppActions.downloadServicesCSV),
      exhaustMap(() =>
        servicesService.downloadServicesCSV().pipe(
          take(1),
          map((csvData ) => ServicesAPIActions.downloadServicesCSVSuccess()),
          catchError((error) => {
            const message = handleError(error);
            return of(ServicesAPIActions.downloadServicesCSVFailure({ message }));
          }),
          share(),
        ),
      ),
    ),
  {
    functional: true,
  },
);

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { EditableTableComponent } from 'src/app/shared/editable-table/editable-table.component';
import { FeedbackComponent } from 'src/app/shared/feedback/feedback.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import {
  selectAll as selectServices,
  selectTotal as selectServicesCount,
} from 'src/app/state/services/services.state';
import { selectAll as selectSources } from 'src/app/state/services/service-sources.state';
import { selectAll as selectVendors } from 'src/app/state/services/service-vendors.state';
import { IService, IServiceBase } from 'src/app/core/models/services.model';
import { ServicesListStore } from './services-list.store';
import { ServicesAppActions } from 'src/app/state/services/services.actions';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';
import { selectPagination } from '../../../state/services/services.selectors';
@Component({
  selector: 'cap-services-list',
  standalone: true,
  imports: [
    CommonModule,
    EditableTableComponent,
    ConfirmModalComponent,
    FeedbackComponent,
    SpinnerComponent,
    PaginationComponent,
  ],
  providers: [ServicesListStore],
  templateUrl: './services-list.component.html',
  styleUrl: './services-list.component.scss',
})
export class ServicesListComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly componentStore = inject(ServicesListStore);
  private destroy$ = new Subject<void>();
  readonly pagination$ = this.store.select(selectPagination);
  page = 1;
  pageSize = 10;
  totalPages = 1;
  totalCount = 0;
  readonly confirmModalData = {
    title: 'Delete question service',
    message: 'Are you sure you want to delete this service?',
  };

  servicesTableMetaData: IEditableTableMetadata = {
    title: 'Services',
    entityName: 'Service',
    bgColor: 'dark:bg-gray-900',
    accordion: false,
  };

  services$: Observable<IService[]> = this.store.select(selectServices);
  sourcesOptionsList: { label: string; value: string }[] = [];
  vendorsOptionsList: { label: string; value: string }[] = [];

  private serviceDropped: IService | null = null;

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;
  listLoading$ = this.componentStore.isListLoading$;

  serviceTableColumns: IEditableColumn[] = [
    {
      key: 'sourceId',
      label: 'Source',
      type: 'list',
      sortable: false,
      options: [],
    },
    {
      key: 'vendorId',
      label: 'Vendor',
      type: 'list',
      sortable: false,
      options: [],
    },
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: false,
    },
    {
      key: 'description',
      label: 'Description',
      type: 'description',
      sortable: false,
    },
    {
      key: 'isFree',
      label: 'Paid',
      type: 'cost',
      sortable: false,
      options: [
        { label: 'Free', value: true },
        { label: 'Premium', value: false },
      ],
    },
    {
      key: 'url',
      label: 'Link',
      type: 'text',
      sortable: false,
    },
  ];

  ngOnInit(): void {
    this.store
      .select(selectSources)
      .pipe(
        tap(
          (sources) =>
            (this.sourcesOptionsList = sources.map((source) => ({
              label: source.name,
              value: source._id,
            }))),
        ),
        tap(
          () => (this.serviceTableColumns[0].options = this.sourcesOptionsList),
        ),
        tap((sources) => {
          if (!sources.length) {
            this.store.dispatch(ServicesAppActions.getServiceSources({}));
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.store
      .select(selectVendors)
      .pipe(
        tap(
          (vendors) =>
            (this.vendorsOptionsList = vendors.map((vendor) => ({
              label: vendor.name,
              value: vendor._id,
            }))),
        ),
        tap(
          () => (this.serviceTableColumns[1].options = this.vendorsOptionsList),
        ),
        tap((vendors) => {
          if (!vendors.length) {
            this.store.dispatch(ServicesAppActions.getServiceVendors({}));
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.store
      .select(selectServicesCount)
      .pipe(
        tap((count) => {
          if (!count) {
            this.store.dispatch(
              ServicesAppActions.getServices({
                page: this.page,
                pageSize: this.pageSize,
              }),
            );
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.pagination$.subscribe((pagination) => {
      this.page = pagination.page;
      this.pageSize = pagination.pageSize;
      this.totalPages = pagination.totalPages;
      this.totalCount = pagination.totalCount;
    });
  }

  onServiceCreated(newService: any) {
    this.store.dispatch(
      ServicesAppActions.createService({
        service: <IServiceBase>newService,
      }),
    );
  }

  onServiceUpdated(service: any) {
    this.store.dispatch(
      ServicesAppActions.updateService({
        service: <IService>service,
      }),
    );
  }

  openConfirmationModal(service: any) {
    this.serviceDropped = service;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-service' }));
  }

  removeService() {
    if (this.serviceDropped) {
      this.store.dispatch(
        ServicesAppActions.removeService({
          id: this.serviceDropped._id,
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPageChange($event: number) {
    this.store.dispatch(
      ServicesAppActions.getServices({ page: $event, pageSize: this.pageSize }),
    );
  }

  importServicesCSV() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';
    fileInput.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {
        this.readAndUploadCSV(file);
      }
    };
    fileInput.click();
  }

  downloadServicCSV(){
    this.store.dispatch(ServicesAppActions.downloadServicesCSV())
  }

  private readAndUploadCSV(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;
      this.uploadCSVData(csvData);
    };
    reader.readAsText(file, 'UTF-8');
  }

  private uploadCSVData(csvData: string) {
    const lines = csvData.split('\n');
    const headers = lines[0].split(';').map(header => header.trim());
    const jsonData = lines.slice(1).map(line => {
      const values = line.split(';').map(value => value.trim());
      return headers.reduce((obj: any, header, index) => {
        obj[header] = values[index];
        return obj;
      }, {});
    });

    // Filter out any empty objects (e.g., from empty lines)
    const filteredJsonData = jsonData.filter(obj => Object.keys(obj).length > 0);

    this.store.dispatch(ServicesAppActions.uploadServicesCSV({ jsonData: filteredJsonData }));
  }
}

import { Component, inject, OnInit } from '@angular/core';
import { TabsComponent } from "../../shared/tabs/tabs.component";
import { ITab } from 'src/app/core/models/tab.model';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'cap-system-settings',
  standalone: true,
  imports: [TabsComponent, RouterOutlet],
  templateUrl: './system-settings.component.html',
  styleUrl: './system-settings.component.scss'
})
export class SystemSettingsComponent implements OnInit {
  private readonly router = inject(Router);

  tabs: ITab[] = [
    {
      name: 'System',
      route: '/administration/settings/system',
      current: true,
      icon: 'settings',
    },
    {
      name: 'Audit',
      route: '/administration/settings/audit',
      current: false,
      icon: 'private_connectivity',
    },
    {
      name: 'Feedback',
      route: '/administration/settings/feedback',
      current: false,
      icon: 'feedback',
    },
  ];

  onTabChange(route: string) {
    this.router.navigate([route]);
  }

  ngOnInit() {
    this.router.navigate([this.tabs[0].route]);
  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { AccountProfileFormComponent } from './account-profile-form/account-profile-form.component';
import { EnableTwoFactorComponent } from './enable-two-factor/enable-two-factor.component';
import { FeedbackComponent } from "../../shared/feedback/feedback.component";

@Component({
    selector: 'cap-account-page',
    standalone: true,
    templateUrl: './account-page.component.html',
    styleUrl: './account-page.component.scss',
    imports: [
        CommonModule,
        AccountProfileFormComponent,
        ChangePasswordComponent,
        EnableTwoFactorComponent,
        FeedbackComponent
    ]
})
export class AccountPageComponent {}

import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { AlertActions } from 'src/app/state/alert/alert.actions';

@Component({
  selector: 'cap-edit-badge-cell',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './edit-badge-cell.component.html',
  styleUrl: './edit-badge-cell.component.scss',
})
export class EditBadgeCellComponent implements OnInit {
  @Input() suggestedBadges: string[] = []; // Now accepts suggested badges from parent
  @Output() badgesChange = new EventEmitter<string[]>();

  @Input() badges!: string[];
  filteredBadges: string[] = [];
  @ViewChild('badgeInput') badgeInput!: ElementRef;
  @ViewChild('suggestGroupingList') suggestedGroupingList!: ElementRef;

  isSuggesteGroupingListOpen: WritableSignal<boolean> = signal(false);

  private readonly store = inject(Store);

  badgeInputControl = new FormControl();

  filterBadges(event: Event): void {
    const query = (event.target as HTMLInputElement)?.value ?? '';
    this.filteredBadges = this.suggestedBadges.filter(
      (badge) =>
        badge.toLowerCase().startsWith(query.toLowerCase()) &&
        !this.badges.includes(badge),
    );
  }

  addBadge(badge: string) {
    this.badges = [...this.badges, badge];
    this.badgesChange.emit(this.badges);
    this.clearInputAndFilteredBadges();
  }

  ngOnInit(): void {
    this.filteredBadges = this.suggestedBadges.filter(
      (badge) => !this.badges.includes(badge),
    );

    this.badgeInputControl.valueChanges
      .pipe(
        debounceTime(300), // Wait for 300 ms of silence before triggering
        distinctUntilChanged(), // Only emit if the current value is different from the last
      )
      .subscribe((value) => {
        this.filterBadges(value);
      });
  }

  checkAddBadge(badge: string): void {
    if (this.suggestedBadges.length > 0) {
      if (this.suggestedBadges.includes(badge)) {
        if (!this.badges.includes(badge)) {
          this.addBadge(badge);
        } else {
          this.store.dispatch(
            AlertActions.addAlert({
              alert: {
                type: 'warning',
                message: 'This badge already exists',
              },
            }),
          );
        }
      } else {
        this.store.dispatch(
          AlertActions.addAlert({
            alert: {
              type: 'warning',
              message: 'this badge does not exist in the suggested list',
            },
          }),
        );
      }
    } else {
      if (!this.badges.includes(badge)) {
        this.addBadge(badge);
      } else {
        this.store.dispatch(
          AlertActions.addAlert({
            alert: {
              type: 'warning',
              message: 'This badge already exists',
            },
          }),
        );
      }
    }
  }

  handleInputKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      const value = (this.badgeInput.nativeElement.value || '').trim();
      if (value) {
        this.checkAddBadge(value);
      }
    }
  }

  removeBadge(removedBadge: string): void {
    this.badges = this.badges.filter((badge) => badge !== removedBadge);
    this.badgesChange.emit(this.badges);
  }

  private clearInputAndFilteredBadges(): void {
    this.filteredBadges = [];
    if (this.badgeInput && this.badgeInput.nativeElement) {
      this.badgeInput.nativeElement.value = '';
    }
  }

  toggleSuggestedGroupingsListView() {
    this.isSuggesteGroupingListOpen.update((isOpen) => !isOpen);
  }

  closeSuggestedGroupingsListView() {
    this.isSuggesteGroupingListOpen.update((isOpen) => false);
  }

  openSuggestedGroupingsListView() {
    this.isSuggesteGroupingListOpen.update((isOpen) => true);
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (
      !this.suggestedGroupingList?.nativeElement.contains(event.target) &&
      this.isSuggesteGroupingListOpen()
    ) {
      this.closeSuggestedGroupingsListView();
    }

    if (
      this.badgeInput?.nativeElement.contains(event.target) &&
      !this.isSuggesteGroupingListOpen()
    ) {
      this.openSuggestedGroupingsListView();
    }
  }
}

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { IServiceVendor } from 'src/app/core/models/services.model';
import { ServicesAPIActions } from './services.actions';
import { Pagination } from './services.state';

export interface ServiceVendorsState extends EntityState<IServiceVendor> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<IServiceVendor> =
  createEntityAdapter<IServiceVendor>({
    selectId: (serviceVendor: IServiceVendor) => serviceVendor._id,
  });

export const initialState: ServiceVendorsState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  },
});

const serviceVendorsReducer = createReducer(
  initialState,
  on(
    ServicesAPIActions.getServiceVendorsSuccess,
    (state, { serviceVendors, pagination }) =>
      adapter.setAll(serviceVendors, {
        ...state,
        errorMessage: null,
        pagination,
      }),
  ),
  on(ServicesAPIActions.getServiceVendorsFailure, (state, { message }) => ({
    ...adapter.removeAll(state),
    errorMessage: message,
  })),
  on(
    ServicesAPIActions.getServiceVendorByIdSuccess,
    (state, { serviceVendor }) => adapter.upsertOne(serviceVendor, state),
  ),
  on(ServicesAPIActions.getServiceSourceByIdFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(
    ServicesAPIActions.createServiceVendorSuccess,
    (state, { serviceVendor }) => adapter.addOne(serviceVendor, state),
  ),
  on(ServicesAPIActions.createServiceVendorFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(
    ServicesAPIActions.updateServiceVendorSuccess,
    (state, { serviceVendor }) => adapter.upsertOne(serviceVendor, state),
  ),
  on(ServicesAPIActions.updateServiceVendorFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(
    ServicesAPIActions.removeServiceVendorSuccess,
    (state, { serviceVendorId, message }) =>
      adapter.removeOne(serviceVendorId, state),
  ),
  on(ServicesAPIActions.removeServiceVendorFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
);

export const serviceVendorsFeature = createFeature({
  name: 'serviceVendors',
  reducer: serviceVendorsReducer,
  extraSelectors: ({ selectServiceVendorsState, selectEntities }) => ({
    ...adapter.getSelectors(selectServiceVendorsState),
    selectServiceVendorById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
  }),
});

export const {
  name,
  reducer,
  selectServiceVendorsState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectServiceVendorById,
} = serviceVendorsFeature;

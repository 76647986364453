import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IProfile } from '../models/profiles.model';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  private readonly http = inject(HttpClient);
  private profilesPath = 'profiles';
  getProfiles() {
    return this.http.get<{ profiles: IProfile[] }>(
      `${environment.apiUrl}/${this.profilesPath}`,
    );
  }

  searchProfiles(search: string, profileType: string) {
    return this.http.get<{ profiles: IProfile[] }>(
      `${environment.apiUrl}/${this.profilesPath}/search?search=${search}&profileType=${profileType}`,
    );
  }
  getIndividualProfiles() {
    return this.http.get<{ profiles: IProfile[] }>(
      `${environment.apiUrl}/${this.profilesPath}/indvs`,
    );
  }

  getOrganizationalProfiles() {
    return this.http.get<{ profiles: IProfile[] }>(
      `${environment.apiUrl}/${this.profilesPath}/orgs`,
    );
  }

  createProfile(profile: {
    name: string;
    type: 'individual' | 'organizational';
    categories: string[];
    isEnabled: boolean;
    serviceSources: string[],
  }) {
    return this.http.post<{ profile: IProfile }>(
      `${environment.apiUrl}/${this.profilesPath}`,
      profile,
    );
  }

  updateProfile(
    profile: {
      name: string;
      categories: string[];
      isEnabled: boolean;
      serviceSources: string[],
    },
    _id: string,
  ) {
    return this.http.put<{
      profile: IProfile;
    }>(`${environment.apiUrl}/${this.profilesPath}/${_id}`, profile);
  }

  deleteProfile(id: string) {
    return this.http.delete<{ id: string }>(
      `${environment.apiUrl}/${this.profilesPath}/${id}`,
    );
  }

  enableProfile(id: string, isEnabled: boolean) {
    return this.http.put<{ id: string; isEnabled: boolean }>(
      `${environment.apiUrl}/${this.profilesPath}/${id}/enable`,
      { isEnabled },
    );
  }

  toggleProfilePrivate(id: string){
    return this.http.post<{profile: IProfile}>(`${environment.apiUrl}/${this.profilesPath}/${id}/toggle-private`, {})
  }
  constructor() {}
}

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { map, share, Subject, takeUntil, tap } from 'rxjs';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';

@Injectable()
export class Reset2FAOtpStore
  extends ComponentStore<{
    formSubmissionSuccess: boolean;
    isLoading: boolean;
  }>
  implements OnDestroy
{
  private subDestroy$ = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({ formSubmissionSuccess: false, isLoading: false });
  }

  readonly isLoading$ = this.select((state) => state.isLoading);

  readonly formSubmissionSuccess$ = this.select(
    (state) => state.formSubmissionSuccess,
  );

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(AuthAppActions.reset2FAOTP),
      tap(() => this.patchState({ isLoading: true })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.reset2FAOTPSuccess),
      tap(() =>
        this.patchState({ isLoading: false, formSubmissionSuccess: true }),
      ),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private apiFailureActionsSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.reset2FAOTPFailure),
      tap(() =>
        this.patchState({ isLoading: false, formSubmissionSuccess: false }),
      ),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

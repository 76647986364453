import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IEditableColumn } from 'src/app/core/models/column.model';

interface EditAccordionSectionState {
  columns: IEditableColumn[];
  data: any[];
  totalItems: number;
}

@Injectable()
export class EditAccoridionSectionStore extends ComponentStore<EditAccordionSectionState> {
  constructor() {
    super({
      columns: [],
      data: [],
      totalItems: 0,
    });
  }

  readonly columns$ = this.select((state) => state.columns);
  readonly data$ = this.select((state) => state.data);
  readonly totalItems$ = this.select((state) => state.totalItems);
  getDataItem$ = (entityId: string) =>
    this.select((state) => state.data.find((item) => item._id === entityId));

  readonly setColumns = this.updater((state, columns: IEditableColumn[]) => ({
    ...state,
    columns,
  }));

  readonly setData$ = this.updater((state, data: any[]) => ({
    ...state,
    data,
  }));

  readonly setTotalItems$ = this.updater((state, totalItems: number) => ({
    ...state,
    totalItems,
  }));

  readonly addData = this.updater((state, newItem: any) => ({
    ...state,
    data: [...state.data, newItem],
  }));

  // Inside EditableTableStore
  readonly updateDataItem = this.updater(
    (
      state,
      {
        entityId,
        columnKey,
        newValue,
      }: { entityId: string; columnKey: string; newValue: any }
    ) => {
      const newData = state.data.map((item) => {
        if (item._id === entityId) {
          return { ...item, [columnKey]: newValue };
        }
        return item;
      });

      return { ...state, data: newData };
    }
  );

  readonly removeDataItem = this.updater((state, entityId: string) => {
    const newData = state.data.filter((item) => item._id !== entityId);
    return { ...state, data: newData };
  });
}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  of,
  share,
  switchMap,
  take,
} from 'rxjs';
import {
  AssessmentsAPIActions,
  AssessmentsAppActions,
} from './assessments.actions';
import { handleError } from '../../utils/error-handler.util';
import { inject } from '@angular/core';
import { AssessmentsService } from '../../core/services/assessment.service';

export const getAssessments$ = createEffect(
  (
    actions$ = inject(Actions),
    assessmentsService = inject(AssessmentsService),
  ) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.getAssessments),
      exhaustMap(() =>
        assessmentsService.getAssessments().pipe(
          take(1),
          map(({ assessments }) => {
            return AssessmentsAPIActions.getAssessmentsSuccess({
              assessments,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              AssessmentsAPIActions.getAssessmentsFailure({
                message,
              }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const getAssessmentById$ = createEffect(
  (
    actions$ = inject(Actions),
    assessmentsService = inject(AssessmentsService),
  ) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.getAssessmentById),
      exhaustMap(({ id }) =>
        assessmentsService.getAssessmentById(id).pipe(
          take(1),
          map(({ assessment }) => {
            return AssessmentsAPIActions.getAssessmentByIdSuccess({
              assessment,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              AssessmentsAPIActions.getAssessmentByIdFailure({
                message,
              }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const getAssessmentsByUserId$ = createEffect(
  (
    actions$ = inject(Actions),
    assessmentsService = inject(AssessmentsService),
  ) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.getAssessmentsByUserId),
      exhaustMap(({ userId }) =>
        assessmentsService.getAssessmentsByUserId(userId).pipe(
          take(1),
          map(({ assessments }) => {
            return AssessmentsAPIActions.getAssessmentsByUserIdSuccess({
              assessments,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              AssessmentsAPIActions.getAssessmentsByUserIdFailure({
                message,
              }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const getAssessmentsByOrganizationId$ = createEffect(
  (
    actions$ = inject(Actions),
    assessmentsService = inject(AssessmentsService),
  ) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.getAssessmentsByOrganizationId),
      exhaustMap(({ organizationId }) =>
        assessmentsService.getAssessmentsByOrganizationId(organizationId).pipe(
          take(1),
          map(({ assessments }) => {
            return AssessmentsAPIActions.getAssessmentsByOrganizationIdSuccess({
              assessments,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              AssessmentsAPIActions.getAssessmentsByOrganizationIdFailure({
                message,
              }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const updateAssessment$ = createEffect(
  (
    actions$ = inject(Actions),
    assessmentsService = inject(AssessmentsService),
  ) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.updateAssessment),
      exhaustMap(({ assessmentId, questionsResponses }) =>
        assessmentsService
          .updateAssessment(assessmentId, questionsResponses)
          .pipe(
            take(1),
            map(({ assessment }) => {
              return AssessmentsAPIActions.updateAssessmentSuccess({
                assessment,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                AssessmentsAPIActions.updateAssessmentFailure({ message }),
              );
            }),
          ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const resetAssessmentsState$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.restAssessmentsState),
      exhaustMap(() =>
        of(AssessmentsAPIActions.resetAssessmentsStateSuccess()),
      ),
      share(),
    );
  },
  { functional: true },
);

export const uploadAssessmentEvidence$ = createEffect(
  (
    actions$ = inject(Actions),
    assessmentsService = inject(AssessmentsService),
  ) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.uploadAssessmentEvidence),
      exhaustMap(({ assessmentId, actionItemId, files }) =>
        assessmentsService
          .uploadAssessmentEvidence(assessmentId, actionItemId, files)
          .pipe(
            take(1),
            map(({ assessmentId, actionItemId, docs }) => {
              return AssessmentsAPIActions.uploadAssessmentEvidenceSuccess({
                assessmentId,
                actionItemId,
                docs,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                AssessmentsAPIActions.uploadAssessmentEvidenceFailure({
                  message,
                }),
              );
            }),
          ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const removeAssessmentEvidence$ = createEffect(
  (
    actions$ = inject(Actions),
    assessmentsService = inject(AssessmentsService),
  ) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.removeAssessmentEvidence),
      exhaustMap(({ assessmentId, actionItemId, docId }) =>
        assessmentsService
          .removeAssessmentEvidence(assessmentId, actionItemId, docId)
          .pipe(
            take(1),
            map(() => {
              return AssessmentsAPIActions.removeAssessmentEvidenceSuccess({
                assessmentId,
                actionItemId,
                docId: docId,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                AssessmentsAPIActions.removeAssessmentEvidenceFailure({
                  message,
                }),
              );
            }),
          ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const removeAllEvidence$ = createEffect(
  (
    actions$ = inject(Actions),
    assessmentsService = inject(AssessmentsService),
  ) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.removeAllEvidence),
      exhaustMap(({ assessmentId, actionItemId }) =>
        assessmentsService
          .deleteAllEvidanceOfActionItem(assessmentId, actionItemId)
          .pipe(
            take(1),
            map(() => {
              return AssessmentsAPIActions.removeAllEvidenceSuccess({
                assessmentId,
                actionItemId,
              });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(
                AssessmentsAPIActions.removeAllEvidenceFailure({ message }),
              );
            }),
          ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const setAssessmentAsNotApplicable$ = createEffect(
  (actions$= inject(Actions), assessmentsService= inject(AssessmentsService))=>{
    return actions$.pipe(
      ofType(AssessmentsAppActions.setAssessmentAsNotApplicable),
      exhaustMap(({assessmentId, isNotApplicable})=> assessmentsService.setAssessmentAsNotApplicable(assessmentId, isNotApplicable).pipe(
        take(1),
        map(({assessment})=> AssessmentsAPIActions.setAssessmentAsNotApplicableSuccess({assessment})),
        catchError((error)=>{
          const message = handleError(error);
          return of(AssessmentsAPIActions.setAssessmentAsNotApplicableFailure({message}))
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);


export const setAssessmentNotApplicableReason$ = createEffect(
  (actions$ = inject(Actions), assessmentsService = inject(AssessmentsService)) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.setAssessmentNotApplicableReason),
      debounceTime(3000),
      exhaustMap(({assessmentId, notApplicableReason})=> assessmentsService.setAssessmentNotApplicableReason(assessmentId, notApplicableReason).pipe(
        take(1),
        map(({assessment})=> AssessmentsAPIActions.setAssessmentNotApplicableReasonSuccess({assessment})),
        catchError((error)=>{
          const message = handleError(error);
          return of(AssessmentsAPIActions.setAssessmentNotApplicableReasonFailure({message}))
        })
      )),
      share()
    );
  },
  { functional: true },
);

export const setAssessmentQuestionAsNotApplicable$ = createEffect(
  (actions$ = inject(Actions), assessmentsService = inject(AssessmentsService)) => {
    return actions$.pipe(
      ofType(AssessmentsAppActions.setAssessmentQuestionAsNotApplicable),
      exhaustMap(({assessmentId, questionId, isNotApplicable})=> assessmentsService.setAssessmentQuestionAsNotApplicable(assessmentId, questionId, isNotApplicable).pipe(
        take(1),
        map(({assessment})=> AssessmentsAPIActions.setAssessmentQuestionAsNotApplicableSuccess({assessment})),
        catchError((error)=>{
          const message = handleError(error);
          return of(AssessmentsAPIActions.setAssessmentQuestionAsNotApplicableFailure({message}))
        })
      )),
      share()
    );
  },
  { functional: true },
);

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { IFeedback } from "src/app/core/models/feedback.model";
import { Pagination } from "../categories/categories.state";
import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { FeedbackApiActions } from "./feedback.actions";

export interface FeedbackState extends EntityState<IFeedback> {
    errorMessage: string | null;
    pagination: Pagination;
}

export const adapter: EntityAdapter<IFeedback> = createEntityAdapter<IFeedback>({
    selectId: (feedback: IFeedback)=> feedback._id
});

export const initialState: FeedbackState = adapter.getInitialState({
    errorMessage: null,
    pagination: {
        page: 1,
        limit: 10,
        totalPages: 0,
        totalCount: 0,
    }
});

const feedbackReducer = createReducer(
    initialState,
    on(FeedbackApiActions.submitFeedbackSuccess, (state, { feedback }) => {
        return adapter.addOne(feedback, state);
    }),
    on(FeedbackApiActions.submitFeedbackFailure, (state, { message }) => {
        return { ...state, errorMessage: message };
    }),
    on(FeedbackApiActions.getFeedbacksSuccess, (state, { feedbacks, pagination }) => {
        return adapter.setAll(feedbacks, { ...state, pagination: {...pagination}, errorMessage: null });
    }),
    on(FeedbackApiActions.getFeedbacksFailure, (state, { message }) => {
        return { ...adapter.removeAll(state), errorMessage: message };
    }),
    on(FeedbackApiActions.resolveFeedbackSuccess, (state, { feedback }) => {
        return adapter.updateOne({id: feedback._id, changes: {isResolved: feedback.isResolved}}, state);
    }),
    on(FeedbackApiActions.resolveFeedbackFailure, (state, { message }) => {
        return { ...state, errorMessage: message };
    }),
    on(FeedbackApiActions.deleteFeedbacksSuccess, (state, { ids }) => {
        return adapter.removeMany(ids, state);
    }),
    on(FeedbackApiActions.deleteFeedbacksFailure, (state, { message }) => {
        return { ...state, errorMessage: message };
    }),
)

export const feedbackFeature = createFeature({
    name: 'feedback',
    reducer: feedbackReducer,
    extraSelectors: ({selectFeedbackState, selectEntities}) =>({
        ...adapter.getSelectors(selectFeedbackState),
        selectFeedbackById: (id: string) => createSelector(selectEntities, (entities) => entities[id] ?? null)
    })
})

export const {
    name,
    reducer,
    selectFeedbackState,
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
    selectFeedbackById
} = feedbackFeature;

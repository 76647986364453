<div class="flex items-center">
  <div class="h-11 w-11 flex-shrink-0">
    @if (avatar$ | async) {
      <img
        class="h-11 w-11 rounded-full"
        [src]="(avatar$ | async)?.url"
        alt="Avatar"
      />
    } @else {
      <img
        class="h-11 w-11 rounded-full"
        src="assets/images/default-avatar.jpeg"
        alt="Avatar"
      />
    }
  </div>
</div>

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, createSelector, on } from "@ngrx/store";
import { createFeature } from "@ngrx/store";
import { IDiscount } from "src/app/core/models/discount.model";
import { DiscountApiActions } from "./discount.actions";

export interface DiscountState extends EntityState<IDiscount>{
    errorMessage: string | null;
}

export const adapter: EntityAdapter<IDiscount> = createEntityAdapter<IDiscount>(
    {selectId: (discount: IDiscount) => discount._id}   
);

export const initialState: DiscountState = adapter.getInitialState({
    errorMessage: null,
})  

const discountReducer = createReducer(
    initialState,
    on(DiscountApiActions.createDiscountCodeSuccess, (state, {discountCode}) => adapter.addOne(discountCode, state)),
    on(DiscountApiActions.createDiscountCodeFailure, (state, {error}) => ({...state, errorMessage: error})),
    on(DiscountApiActions.deleteDiscountCodeSuccess, (state, {id}) => adapter.removeOne(id, state)),
    on(DiscountApiActions.deleteDiscountCodeFailure, (state, {error}) => ({...state, errorMessage: error})),
    on(DiscountApiActions.getAllDiscountCodesSuccess, (state, {discountCodes}) => adapter.setAll(discountCodes, state)),
    on(DiscountApiActions.getAllDiscountCodesFailure, (state, {error}) => ({...state, errorMessage: error})),
)

export const discountFeature = createFeature({
    name: 'discount',
    reducer: discountReducer,
    extraSelectors: ({selectDiscountState, selectIds, selectEntities}) => ({
        ...adapter.getSelectors(selectDiscountState),
        selectDiscountById: (id: string) => createSelector(selectEntities, (entities) => entities[id]),
    }),
})

export const {
    name,
    reducer,
    selectDiscountState,
    selectDiscountById,
    selectAll,
} = discountFeature;
import { createSelector } from '@ngrx/store';
import { selectServicesState } from './services.state';
import { selectServiceSourcesState } from './service-sources.state';
import { selectServiceVendorsState } from './service-vendors.state';

export const selectPagination = createSelector(
  selectServicesState,
  (state) => state.pagination,
);

export const selectSourcesPagination = createSelector(
  selectServiceSourcesState,
  (state) => state.pagination,
);

export const selectVendorsPagination = createSelector(
  selectServiceVendorsState,
  (state) => state.pagination,
);

export const selectServiceVendors = createSelector(
  selectServiceVendorsState,
  (state) => state.entities,
);

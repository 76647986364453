import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
  CdkDragStart,
  CdkDragRelease,
} from '@angular/cdk/drag-drop';
import { map, Subject, take, takeUntil } from 'rxjs';

import { TableStore } from './table.store';
import { IColumn } from 'src/app/core/models/column.model';
import { AvatarCellComponent } from './avatar-cell/avatar-cell.component';
import { DateCellComponent } from './date-cell/date-cell.component';
import { InfoCellComponent } from './info-cell/info-cell.component';
import { StatusCellComponent } from './status-cell/status-cell.component';
import { TextCellComponent } from './text-cell/text-cell.component';
import { IUser } from 'src/app/core/models/user.model';
import { IOrganization } from 'src/app/core/models/organization.model';
import { ICategory } from 'src/app/core/models/categories.model';
import { Pagination } from 'src/app/state/categories/categories.state';
import { ButtonCellComponent } from './button-cell/button-cell.component';

@Component({
  selector: 'cap-table',
  standalone: true,
  imports: [
    CommonModule,
    AvatarCellComponent,
    DateCellComponent,
    InfoCellComponent,
    StatusCellComponent,
    TextCellComponent,
    CdkDropList,
    CdkDrag,
    ButtonCellComponent,
  ],
  providers: [TableStore],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent implements OnDestroy, OnInit {
  @Input() isDraggable = false;
  @Input() displayActions = true;
  private destroy$ = new Subject<void>();
  @Output() onEntityDeleted: EventEmitter<IUser | IOrganization | ICategory> =
    new EventEmitter<IUser | IOrganization | ICategory>();

  @Output() onEntityUpdated: EventEmitter<string> = new EventEmitter<string>();

  @Output() onSortChanged = new EventEmitter<{
    entityId: string;
    previousIndex: number;
    currentIndex: number;
  }>();
  @Output() OnToggleClicked = new EventEmitter();

  private readonly componentStore = inject(TableStore);
  @Input() set columns(value: IColumn[]) {
    this.componentStore.setColumns(value);
  }

  @Input() set data(value: any[]) {
    this.componentStore.setData$(value);
  }
  @Input() set totalItems(value: number) {
    this.componentStore.setTotalItems$(value);
  }

  onDeleteClicked(entity: IUser | IOrganization) {
    this.onEntityDeleted.emit(entity);
  }

  onUpdateClicked(entity: IUser | IOrganization) {
    this.onEntityUpdated.emit(entity._id);
  }
  drop(event: CdkDragDrop<any[]>) {
    this.data$.pipe(take(1)).subscribe((data) => {
      if (event.previousIndex !== event.currentIndex) {
        this.onSortChanged.emit({
          entityId: data[event.previousIndex]._id,
          previousIndex: event.previousIndex,
          currentIndex: event.currentIndex,
        });
      }
      moveItemInArray(data, event.previousIndex, event.currentIndex);
    });
  }

  isDragging = false;

  onDragStarted(event: CdkDragStart) {
    this.isDragging = true;
  }

  onDragReleased(event: CdkDragRelease) {
    this.isDragging = false;
  }

  readonly columns$ = this.componentStore.columns$;
  readonly data$ = this.componentStore.data$;
  readonly totalItems$ = this.componentStore.totalItems$;
  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleEnabled($event: any) {
    this.OnToggleClicked.emit($event);
  }
}

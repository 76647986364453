import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { IRole } from 'src/app/core/models/user.model';

export const RolesAppActions = createActionGroup({
  source: 'Roles',
  events: {
    'Get Roles': emptyProps(),
  },
});

export const RolesAPIActions = createActionGroup({
  source: 'Roles API',
  events: {
    'Get Roles Success': props<{ roles: IRole[] }>(),
    'Get Roles Failure': props<{ message: string }>(),
  },
});

<div class="flex h-screen bg-gray-100 dark:bg-gray-900">
  <!-- Off-canvas menu for mobile -->
  <div
    class="fixed inset-0 z-50 lg:hidden"
    role="dialog"
    aria-modal="true"
    [ngClass]="{ 'block': isSidebarOpen(), 'hidden': !isSidebarOpen() }"
  >
    <div class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity duration-300 ease-in-out"></div>

    <div
      class="fixed inset-0 flex"
      [@slideInOut]="isSidebarOpen() ? 'in' : 'out'"
    >
      <div class="relative flex w-full max-w-xs flex-1 flex-col bg-white dark:bg-gray-800 pt-5 pb-4">
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button
            type="button"
            class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            (click)="closeSidebar()"
          >
            <span class="sr-only">Close sidebar</span>
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Mobile Sidebar content -->
        <div class="flex-shrink-0 flex items-center px-4">
          <img class="h-8 w-auto" [src]="orgAvatarUrl" alt="Your Company">
        </div>
        <div class="mt-5 flex-1 h-0 overflow-y-auto">
          <nav class="px-2">
            <ul class="space-y-1">
              <li *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']">
                <a [routerLink]="['/dashboard']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-base font-medium rounded-md">
                  <span class="material-symbols-outlined mr-4 text-gray-400 group-hover:text-gray-500">home</span>
                  Dashboard
                </a>
              </li>
              <li *capRoleCheck="['OM', 'OA', 'SSA', 'SA']">
                <a [routerLink]="['/management']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-base font-medium rounded-md">
                  <span class="material-symbols-outlined mr-4 text-gray-400 group-hover:text-gray-500">manage_accounts</span>
                  Management
                </a>
              </li>
              <li *capRoleCheck="['SA', 'SSA']">
                <a [routerLink]="['/administration']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-base font-medium rounded-md">
                  <span class="material-symbols-outlined mr-4 text-gray-400 group-hover:text-gray-500">admin_panel_settings</span>
                  Administration
                </a>
              </li>
              <li *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']">
                <a [routerLink]="['/assessments']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-base font-medium rounded-md">
                  <span class="material-symbols-outlined mr-4 text-gray-400 group-hover:text-gray-500">checklist</span>
                  Assessments
                </a>
              </li>
              <li *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']">
                <a [routerLink]="['/reports']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-base font-medium rounded-md">
                  <span class="material-symbols-outlined mr-4 text-gray-400 group-hover:text-gray-500">report</span>
                  Reports
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div class="flex w-64 flex-col">
      <div class="flex flex-col flex-grow bg-white dark:bg-gray-800 pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <img class="h-8 w-auto" [src]="orgAvatarUrl" alt="Your Company">
        </div>
        <div class="mt-5 flex-1 flex flex-col">
          <nav class="flex-1 px-2 space-y-1">
            <ul class="space-y-1">
              <li *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']">
                <a [routerLink]="['/dashboard']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <span class="material-symbols-outlined mr-3 text-gray-400 group-hover:text-gray-500">home</span>
                  Dashboard
                </a>
              </li>
              <li *capRoleCheck="['OM', 'OA', 'SSA', 'SA']">
                <a [routerLink]="['/management']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <span class="material-symbols-outlined mr-3 text-gray-400 group-hover:text-gray-500">manage_accounts</span>
                  Management
                </a>
              </li>
              <li *capRoleCheck="['SA', 'SSA']">
                <a [routerLink]="['/administration']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <span class="material-symbols-outlined mr-3 text-gray-400 group-hover:text-gray-500">admin_panel_settings</span>
                  Administration
                </a>
              </li>
              <li *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']">
                <a [routerLink]="['/assessments']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <span class="material-symbols-outlined mr-3 text-gray-400 group-hover:text-gray-500">checklist</span>
                  Assessments
                </a>
              </li>
              <li *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']">
                <a [routerLink]="['/reports']" routerLinkActive="bg-gray-100 dark:bg-gray-700 text-red-500" class="group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <span class="material-symbols-outlined mr-3 text-gray-400 group-hover:text-gray-500">report</span>
                  Reports
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col w-0 flex-1 overflow-hidden">
    <div class="relative z-10 flex-shrink-0 flex h-16 bg-white dark:bg-gray-800 shadow">
      <button
        type="button"
        class="px-4 border-r border-gray-200 dark:border-gray-700 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
        (click)="openSidebar()"
      >
        <span class="sr-only">Open sidebar</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      <div class="flex-1 px-4 flex justify-between">
        <div class="flex-1 flex">
          <!-- Add search functionality if needed -->
        </div>
        <div class="ml-4 flex items-center md:ml-6">
          <cap-theme></cap-theme>
          <!-- feedback button -->
          <button (click)="openFeedbackModal()" class="bg-white dark:bg-gray-800 p-1 pt-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Feedback</span>
            <span class="material-symbols-outlined">feedback</span>
          </button>
          <!-- Notification bell -->
          <button class="bg-white dark:bg-gray-800 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">View notifications</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </button>

          <!-- Profile dropdown -->
          <div class="ml-3 relative" #profileDropdown>
            <div>
              <button
                type="button"
                class="max-w-xs bg-white dark:bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="user-menu-button"
                (click)="toggleDropdown()"
                [attr.aria-expanded]="isDropdownOpen"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" [src]="(currentAvatar$ | async)?.url || 'assets/images/default-avatar.jpeg'" alt="">
              </button>
            </div>
            @if (isDropdownOpen()) {
              <div
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
                [@fadeInOut]
              >
                <a routerLink="/settings/account" class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600" role="menuitem" tabindex="-1" id="user-menu-item-0">Your Account</a>
                <button (click)="onLogout()" class="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600" role="menuitem" tabindex="-1" id="user-menu-item-1">Sign out</button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>

    <main class="flex-1 relative overflow-y-auto focus:outline-none">
      <div class="py-6">
        <div class="max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <!-- Content -->
          <ng-content />
        </div>
      </div>
    </main>
  </div>
</div>
@if (selectModalVisibilityById('feedback') | async) {
  <cap-user-feedback [modalMetadata]="modalFeedbackMetadata"></cap-user-feedback>
}

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, firstValueFrom, Observable, tap, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private http = inject(HttpClient);
  private stripe: Promise<Stripe>;
  private paymentIntentId: string | null = null;
  constructor() {
    this.stripe = loadStripe(environment.stripePublicKey) as Promise<Stripe>;
  }

  async getStripe() {
    return await this.stripe;
  }

  createPaymentIntent(featureType: string, discountCode?: string): Observable<{ 
    clientSecret: string | null;
    originalPrice: number;
    finalPrice: number;
    discount: number;
    fullyDiscounted?: boolean;
  }> {
    return this.http.post<any>(
      `${environment.apiUrl}/payment/create-payment-session`,
      { featureType, discountCode }
    ).pipe(
      tap(response => {
        if (!response.fullyDiscounted) {
          this.paymentIntentId = response.clientSecret?.split('_secret')[0] || null;
        }
      })
    );
  }

  getPaymentIntentId(): string | null {
    return this.paymentIntentId;
  }

  getPaymentStatus(paymentIntentId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/payment/${paymentIntentId}/status`
    ).pipe(
      catchError(error => {
        console.error('Payment status check failed:', error);
        throw new Error(error.error?.message || 'Failed to check payment status');
      })
    );
  }

  clearPaymentIntentId(): void {
    this.paymentIntentId = null;
  }
}

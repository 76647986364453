<div class="flex gap-4 py-4 justify-between">
    <div class="flex flex-col gap-2">
        <h1 class="text-2xl font-bold">Payment System Status</h1>
        <p class="text-sm text-gray-500">The creation of new Account will be a payed feature.</p>
    </div>
    <div>
        <cap-toggle-button [button]="true" (checkChange)="onToggleButtonChange($event)"></cap-toggle-button>
    </div>
</div>
<div class="flex gap-2 justify-between">
    <div>
        <h1 class="text-2xl font-bold">Pricing</h1>
        <p class="text-sm text-gray-500">Set the price for each feature.</p>
    </div>
    @if(!isEditingPrice()){
        <div>
            <button class="bg-red-500 text-white px-4 py-2 rounded-md" (click)="onEditPricing()">Edit Pricing</button>
        </div>
    } @else {
        <div>
            <button class="bg-green-500 text-white px-4 py-2 rounded-md" (click)="onSavePricing()">Save Pricing</button>
        </div>
    }
</div>
<div class="flex w-full justify-center gap-4">
    @for (pricing of (pricingConfigs$ | async); track pricing._id) {
        <div class="rounded-3xl p-8 ring-1 ring-gray-200 bg-white">
            <h2 id="tier-freelancer" class="text-lg/8 font-semibold text-gray-900">{{pricing.featureType}}</h2>
            <p class="mt-6 flex items-baseline gap-x-1">
              <!-- Price, update based on frequency toggle state -->
               @if(isEditingPrice()) {
                <input type="number" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" min="0"[(ngModel)]="pricing.price" (ngModelChange)="onPriceChange(pricing._id,$event)" />
               } @else {
                <span class="text-4xl font-semibold tracking-tight text-gray-900">${{pricing.price}}</span>
               }
              <!-- Payment frequency, update based on frequency toggle state -->
              <span class="text-sm/6 font-semibold text-gray-600">/Year</span>
            </p>
          </div>
    }
</div>

<div class="flex justify-between">
    <div class="flex ">
        <div class="flex flex-col gap-2">
            <h1 class="text-2xl font-bold">Discount Codes</h1>
            <p class="text-sm text-gray-500">Create discount codes to offer special pricing to your customers.</p>
        </div>
    </div>
    <div>
        <button class="bg-red-500 text-white px-4 py-2 rounded-md" (click)="onToggleCreateDiscountCode()">Create Discount Code</button>
    </div>
</div>
@if (isCreatingDiscountCode()) {
    <div class="flex items-center mt-4 gap-4">
        <div class="flex flex-col gap-2">
            <label for="code" class="block text-sm/6 font-medium text-gray-900">Code</label>
            <input type="text" id="code" class="w-32 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" [(ngModel)]="discountCode.code" />
        </div>
        <div class="flex flex-col gap-2">
            <label for="discount" class="block text-sm/6 font-medium text-gray-900">Discount (%)</label>
            <input type="number" min="0" max="100" id="discount" class="w-24 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" [(ngModel)]="discountCode.discount" />
        </div>
        <div class="flex flex-col gap-2">
            <label for="belongTo" class="block text-sm/6 font-medium text-gray-900">Apply To:</label>
            <select id="belongTo" class="rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" [(ngModel)]="discountCode.belongTo">
                <option value="">Select type</option>
                <option value="individual">Individual</option>
                <option value="organization">Organization</option>
            </select>
        </div>
        @if (discountCode.belongTo === 'organization') {
            <div class="flex flex-col gap-2">
                <label for="organization" class="block text-sm/6 font-medium text-gray-900">Organization:</label>
                <select id="organization" class="rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" 
                    [(ngModel)]="discountCode.effectedEntity.entityId">
                    <option value="">Select organization</option>
                    @for (org of organizations$ | async; track org.id) {
                        <option [value]="org.id">{{org.name}}</option>
                    }
                </select>
            </div>
        }
        <div class="flex flex-col gap-2">
            <label class="block text-sm/6 font-medium text-gray-900">One-time Use</label>
            <cap-toggle-button (checkChange)="onToggleOneTimeUse($event)" [(ngModel)]="discountCode.isOneTime"></cap-toggle-button>
        </div>
    </div>
    <div class="mt-4">
        <button class="bg-red-500 text-white px-4 py-2 rounded-md" (click)="onSaveDiscountCode()">Save Discount Code</button>
    </div>
}
<div class="py-4">
    <cap-table [data]="(discountCodes$ | async) ?? []" [columns]="discountCodeColumns" (onEntityDeleted)="onDeleteDiscountCode($event)"></cap-table>
</div>
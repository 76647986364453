import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { FeedbackComponent } from '../../../../shared/feedback/feedback.component';
import { ModalContainerComponent } from '../../../../shared/modal-container/modal-container.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SpinnerComponent } from '../../../../shared/spinner/spinner.component';
import { IModalProfileMetadata } from '../../individuals-profiles/individuals-profiles.component';
import { map, Subject } from 'rxjs';
import { CategoriesService } from '../../../../core/services/categories.service';
import { ICategory } from '../../../../core/models/categories.model';
import { Store } from '@ngrx/store';
import { convertWeight } from '../../../../utils/entity-formatting.util';
import { Actions } from '@ngrx/effects';
import { ProfilesAppActions } from '../../../../state/profiles/profiles.actions';
import { OrganizationProfileUpsertStore } from './organizational-profile-upsert.store';
import { ConfirmModalState } from '../../../../shared/confirm-modal/confirm-modal.store';
import { ModalActions } from '../../../../state/modal/modal.actions';
import { FormErrorMessageComponent } from '../../../../shared/form-error-message/form-error-message.component';
import { DropdownModule } from 'primeng/dropdown';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MultiSelectModule } from 'primeng/multiselect';
import { selectAllServiceSources } from 'src/app/state/services/services.selectors';
import { ServicesAppActions } from 'src/app/state/services/services.actions';

@Component({
  selector: 'cap-organizational-profile-upsert',
  standalone: true,
  imports: [
    AsyncPipe,
    FeedbackComponent,
    ModalContainerComponent,
    ReactiveFormsModule,
    SpinnerComponent,
    NgClass,
    FormErrorMessageComponent,
    DropdownModule,
    CdkDropList,
    NgIf,
    CdkDrag,
    CdkDragHandle,
    NgForOf,
    MultiSelectModule,
  ],
  templateUrl: './organizational-profile-upsert.component.html',
  styleUrl: './organizational-profile-upsert.component.scss',
})
export class OrganizationalProfileUpsertComponent implements OnInit {
  currentCategory: FormControl = new FormControl([]);
  selectedServiceSources: FormControl = new FormControl([]);
  serviceSources = this.store.select(selectAllServiceSources).pipe(map((sources)=>{
    return sources.map((source)=> ({id: source._id, name: source.name}))
  }))
  @Input() modalMetadata!: IModalProfileMetadata;
  private destroy = new Subject<void>();
  private categoriesService = inject(CategoriesService);
  addedCategories: { category: string; order: number }[] = [];
  availableCategories: { id: string; name: string }[] = [];
  allCategories: ICategory[] = [];
  tableCategories: any = [];
  loading$ = this.componentStore.select((state) => state.isLoading);
  errorMessage$ = this.componentStore.select((state) => state.errorMessage);
  profileForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private readonly store: Store,
    private componentStore: OrganizationProfileUpsertStore,
    private readonly actions$: Actions,
  ) {
    this.profileForm = this.fb.group({
      name: ['', Validators.required],
      categories: [this.addedCategories, Validators.required],
      type: ['organizational', Validators.required],
      isEnabled: [false, Validators.required],
      currentCategory: this.currentCategory,
      serviceSources: this.selectedServiceSources
    });
  }

  ngOnInit() {
    if (this.modalMetadata.isEditMode) {
      if (this.modalMetadata.profile) {
        this.profileForm.patchValue({
          name: this.modalMetadata.profile.name,
          categories: [...this.modalMetadata.profile.categories],
          type: this.modalMetadata.profile.type,
          isEnabled: this.modalMetadata.profile.isEnabled,
          serviceSources: this.modalMetadata.profile.serviceSources,
        });
        this.addedCategories = [...this.modalMetadata.profile.categories];
      }
      this.categoriesService
        .getCategoriesByType('organization')
        .subscribe((externalCategories) => {
          this.allCategories = externalCategories.categories
            .filter((cat) => cat.isActive)
            .map((category) => category);
          if (this.modalMetadata.profile?.categories) {
            this.addedCategories = [...this.modalMetadata.profile?.categories];
            this.populateCategoryTable(this.addedCategories);
          }
          this.availableCategories = externalCategories.categories
            .map((category) => {
              return { id: category._id, name: category.name };
            })
            .filter(
              (category) =>
                !this.addedCategories.some(
                  (cat) => cat.category === category.id,
                ),
            );
        });
    } else {
      this.categoriesService
        .getCategoriesByType('organization')
        .subscribe((externalCategories) => {
          this.allCategories = externalCategories.categories;
          this.availableCategories = externalCategories.categories
            .filter((cat) => cat.isActive)
            .map((category) => {
              return { id: category._id, name: category.name };
            });
        });
    }
    this.store.dispatch(ServicesAppActions.getServiceSources({}))
  }

  submitProfileForm() {
    if (this.profileForm.valid) {
      const profile = this.profileForm.value;
      profile.categories = this.tableCategories.map((cat: any) => ({
        category: cat._id,
        order: cat.order,
      }));

      if (this.modalMetadata.isEditMode && this.modalMetadata.profile?._id) {
        this.store.dispatch(
          ProfilesAppActions.updateProfile({
            profile: {
              name: profile.name,
              categories: profile.categories,
              type: 'organizational',
              isEnabled: profile.isEnabled,
              serviceSources: profile.serviceSources
            },
            id: this.modalMetadata.profile?._id,
          }),
        );
      } else {
        this.store.dispatch(
          ProfilesAppActions.createProfile({
            profile: {
              name: profile.name,
              categories: profile.categories,
              type: 'organizational',
              isEnabled: profile.isEnabled,
              serviceSources: profile.serviceSources
            },
          }),
        );
      }

      // Close the modal after dispatching the action
      const modalId = this.modalMetadata.isEditMode
        ? 'update-organization-profile'
        : 'add-organization-profile';
      this.store.dispatch(ModalActions.close({ id: modalId }));
    }
  }

  // UI methods + form
  populateCategoryTable(categories: { category: string; order: number }[]) {
    this.tableCategories = categories.map((cat) => {
      const category = this.allCategories.find((c) => c._id === cat.category);
      return category ? { ...category, order: cat.order } : {};
    });
    this.tableCategories.sort(
      (a: { order: number }, b: { order: number }) => a.order - b.order,
    );
  }

  toggleEnabled() {
    this.profileForm
      .get('isEnabled')
      ?.setValue(!this.profileForm.get('isEnabled')?.value);
  }

  addCategory() {
    const selectedCategories = this.currentCategory.value;
    if (selectedCategories && selectedCategories.length > 0) {
      selectedCategories.forEach(
        (selectedCategory: { id: string; name: string }) => {
          if (
            !this.addedCategories.some(
              (cat) => cat.category === selectedCategory.id,
            )
          ) {
            const newCategory = {
              category: selectedCategory.id,
              order: this.tableCategories.length,
            };
            this.addedCategories.push(newCategory);

            // Remove the added category from availableCategories
            this.availableCategories = this.availableCategories.filter(
              (availableCategory) =>
                availableCategory.id !== selectedCategory.id,
            );
          }
        },
      );

      // Update the form control with all added categories
      this.profileForm.get('categories')?.setValue(this.addedCategories);

      // Repopulate the category table
      this.populateCategoryTable(this.addedCategories);

      // Clear the current selection
      this.currentCategory.setValue([]);
    }
  }

  onCategoryChange(value: any) {
    this.currentCategory = value.id;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.tableCategories,
      event.previousIndex,
      event.currentIndex,
    );
    this.updateCategoryOrder();
  }

  updateCategoryOrder() {
    this.tableCategories.forEach((category: any, index: any) => {
      category.order = index;
    });
  }

  protected readonly convertWeight = convertWeight;

  confirmModalData: ConfirmModalState = {
    title: 'Delete Category',
    message: 'Are you sure you want to delete this category?',
  };

  removeCategory(id: string) {
    // Find the category to be removed
    const removedCategory = this.addedCategories.find(
      (cat) => cat.category === id,
    );

    // Remove the category from the added categories list
    this.addedCategories = this.addedCategories.filter(
      (cat) => cat.category !== id,
    );

    // Update the profile form with the new list of added categories
    this.profileForm.get('categories')?.setValue(this.addedCategories);

    // Repopulate the category table with the new list of added categories
    this.populateCategoryTable(this.addedCategories);

    // Add the removed category back to the available list of categories if it exists
    if (removedCategory) {
      this.availableCategories.push({
        id: removedCategory.category,
        name:
          this.allCategories.find((cat) => cat._id === removedCategory.category)
            ?.name ?? '',
      });
    }
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }
}

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

interface GridListState {
  items: any[];
  selectedItem: any | null;
  filterValue: string;
}

@Injectable()
export class GridListStore extends ComponentStore<GridListState> {
  constructor() {
    super({
      items: [],
      selectedItem: null,
      filterValue: '',
    });
  }

  readonly items$ = this.select((state) => state.items);
  readonly selectedItem$ = this.select((state) => state.selectedItem);
  readonly filterValue$ = this.select((state) => state.filterValue);
  readonly filteredItems$ = this.select(
    this.items$,
    this.filterValue$,
    (items, filterValue) => {
      return items.filter((item) =>
        item.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
    },
  );

  readonly setItems = this.updater((state, items: any[]) => ({
    ...state,
    items,
  }));

  readonly setSelectedItem = this.updater((state, selectedItem: any) => ({
    ...state,
    selectedItem,
  }));

  readonly setFilterValue = this.updater((state, filterValue: string) => ({
    ...state,
    filterValue,
  }));
}

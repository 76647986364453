<div
  class="absolute z-50 inset-0 overflow-y-auto"
  id="modal"
  [ngClass]="{ hidden: !(isModalVisible$ | async) }"
  [@modalAnimation]="(isModalVisible$ | async) === true ? 'enter' : 'leave'"
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <!-- Modal BG Overlay -->
    <div class="fixed inset-0 transition-opacity" (click)="closeModal()">
      <div class="absolute inset-0 bg-gray-800/80 dark:bg-black/20"></div>
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
      >&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full"
      [ngClass]="modalWidthClass"
    >
      <div class="py-4 text-left px-6 max-h-[80vh] overflow-y-auto">
        <!--Title-->
        <div class="flex justify-between items-center pb-4">
          <ng-content select="[heading]"></ng-content>
          <!-- Modal Close Button -->
          <div
            class="modal-close cursor-pointer z-50 text-gray-900 dark:text-gray-200"
            (click)="closeModal()"
          >
            &#x2715;
          </div>
        </div>

        <!-- Separator -->
        <hr class="h-px bg-gray-200 border-0 dark:bg-gray-600" />

        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { IColumn } from 'src/app/core/models/column.model';
import { EntityName } from 'src/app/core/models/entity-name.model';
import { PaginationComponent } from 'src/app/shared/pagination/pagination.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { TableComponent } from 'src/app/shared/table/table.component';
import { Pagination } from 'src/app/state/organizations/organizations.state';
import {
  selectAll as selectAuditLogs,
  selectPagination as selectAuditLogsPagination,
} from '../../state/audit-logs/audit-logs.state';
import { AuditLogsAppActions } from 'src/app/state/audit-logs/audit-logs.actions';
import { selectAuditLogsWithUserEmail } from 'src/app/state/audit-logs/audit-logs.selectors';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-audit-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PaginationComponent,
    TableComponent,
    SpinnerComponent,
  ],
  templateUrl: './audit-page.component.html',
  styleUrl: './audit-page.component.scss',
})
export class AuditPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private readonly store = inject(Store);

  current_page: number = 1;
  total_pages: number = 0;
  totalItems: number = 0;
  pageSize: number = 10;

  searchQuery = '';

  readonly auditLogs$ = this.store.select(selectAuditLogsWithUserEmail);
  readonly pagination$ = this.store
    .select(selectAuditLogsPagination)
    .pipe(takeUntil(this.destroy$))
    .subscribe((pagination: Pagination) => {
      this.current_page = pagination.page;
      this.total_pages = pagination.totalPages;
      this.totalItems = pagination.totalCount;
      this.pageSize = pagination.limit;
    });

  readonly entityName = EntityName.AuditLog;

  readonly columns: IColumn[] = [
    {
      key: 'userId',
      label: 'Actor',
      type: 'text',
      sortable: false,
    },
    {
      key: 'action',
      label: 'Action',
      type: 'text',
      sortable: false,
    },
    {
      key: 'entity',
      label: 'Entity Name',
      type: 'text',
      sortable: false,
    },
    {
      key: 'entityId',
      label: 'Entity Id',
      type: 'text',
      sortable: false,
    },
    {
      key: 'timestamp',
      label: 'Happend At',
      type: 'date',
      sortable: false,
    },
    {
      key: 'description',
      label: 'Description',
      type: 'description',
      sortable: false,
    },
  ];

  ngOnInit() {
    this.store.dispatch(
      AuditLogsAppActions.getAuditLogs({
        page: this.current_page,
        limit: this.pageSize,
      }),
    );
  }

  onPageChange(page: number) {
    this.store.dispatch(
      AuditLogsAppActions.getAuditLogs({
        page,
        limit: this.pageSize,
      }),
    );
  }

  searchAuditLogs() {
    this.store.dispatch(
      AuditLogsAppActions.searchAuditLogs({
        search: this.searchQuery,
        page: this.current_page.toString(),
        limit: this.pageSize.toString(),
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { IAuditLog, IAuditLogBase } from '../models/audit-logs.model';
import { Pagination } from 'src/app/state/categories/categories.state';

@Injectable({
  providedIn: 'root',
})
export class AuditLogsService {
  private readonly http = inject(HttpClient);
  private auditLogsPath = 'audit';

  getAuditLogs(
    page: number,
    pageSize: number,
  ): Observable<{ auditLogs: IAuditLog[]; pagination: Pagination }> {
    return this.http.get<{ auditLogs: IAuditLog[]; pagination: Pagination }>(
      `${environment.apiUrl}/${this.auditLogsPath}?page=${page}&limit=${pageSize}`,
    );
  }

  searchAuditLogs(
    search: string,
    page: string,
    limit: string,
  ): Observable<{ auditLogs: IAuditLog[]; pagination: Pagination }> {
    return this.http.get<{ auditLogs: IAuditLog[]; pagination: Pagination }>(
      `${environment.apiUrl}/${this.auditLogsPath}/search?search=${search}&page=${page}&limit=${limit}`,
    );
  }
}

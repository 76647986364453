import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { selectErrorMessage } from 'src/app/state/auth/auth.state';
import { ResendOtpStore } from './resend-otp.store';
import { AlertActions } from 'src/app/state/alert/alert.actions';
import { AuthAppActions } from 'src/app/state/auth/auth.actions';

@Component({
  selector: 'cap-resend-otp',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgIf, ModalContainerComponent],
  providers: [ResendOtpStore],
  templateUrl: './resend-otp.component.html',
  styleUrl: './resend-otp.component.scss',
})
export class ResendOtpComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly componentStore = inject(ResendOtpStore);
  private fb = inject(FormBuilder);
  resendOtpForm!: FormGroup;

  public readonly formError$ = this.store.select(selectErrorMessage);
  public readonly loading$ = this.componentStore.isLoading$;
  public readonly formSubmissionSuccess$ =
    this.componentStore.formSubmissionSuccess$;

  ngOnInit(): void {
    this.store.dispatch(ModalActions.register({ id: 'resend-otp' }));
    this.initializeForm();
    const sub = this.formSubmissionSuccess$
      .pipe(
        tap((isSuccess) => {
          if (!isSuccess) {
            return;
          }
          this.store.dispatch(ModalActions.close({ id: 'resend-otp' }));
        }),
      )
      .subscribe();
  }

  initializeForm() {
    this.resendOtpForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (!this.resendOtpForm.valid) {
      this.store.dispatch(
        AlertActions.addAlert({
          alert: {
            type: 'warning',
            message: 'Please enter a valid email',
          },
        }),
      );
    }

    this.store.dispatch(
      AuthAppActions.resendEmailOTP({ ...this.resendOtpForm.value }),
    );

    // this.store.dispatch(ModalActions.close({ id: 'resend-otp' }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(ModalActions.unregister({ id: 'resend-otp' }));
  }
}

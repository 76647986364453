<nav aria-label="Progress">
  <ol
    role="list"
    class="divide-y divide-gray-300 dark:divide-gray-600 rounded-md border border-gray-300 dark:border-gray-600 md:flex md:divide-y-0"
  >
    @for (step of (steps$| async); track step.id) {
    <li [@stepTransition] class="relative md:flex md:flex-1">
      @if(step.status == 'completed') {
      <!-- Completed Step -->
      <div class="flex w-full items-center">
        <span class="flex items-center px-6 py-2 text-xs font-medium">
          <span
            class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-primary-600 dark:bg-primary-500"
          >
            <svg
              class="h-6 w-6 text-white"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <span
            class="ml-4 text-xs font-medium text-gray-900 dark:text-gray-50"
            >{{ step.title }}</span
          >
        </span>
      </div>
      } @else if (step.status == 'current') {
      <!-- Current Step -->
      <div
        class="flex items-center px-6 py-2 text-xs font-medium"
        aria-current="step"
      >
        <span
          class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-600 dark:border-primary-500"
        >
          <span class="text-primary-600 dark:text-primary-500">{{
            step.id | leadingZeros : 2
          }}</span>
        </span>
        <span
          class="ml-4 text-xs font-medium text-primary-600 dark:text-primary-500"
          >{{ step.title }}</span
        >
      </div>
      } @else if (step.status == 'upcoming') {
      <!-- Upcoming Step -->
      <div class="flex items-center">
        <span class="flex items-center px-6 py-2 text-xs font-medium">
          <span
            class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 dark:border-gray-600"
          >
            <span class="text-gray-500 dark:text-gray-400">{{
              step.id | leadingZeros : 2
            }}</span>
          </span>
          <span
            class="ml-4 text-xs font-medium text-gray-500 dark:text-gray-400"
            >{{ step.title }}</span
          >
        </span>
      </div>
      } @if(step.id !== (steps$|async)!.length) {
      <!-- Arrow separator for lg screens and up -->
      <div
        class="absolute right-0 top-0 hidden h-full w-5 md:block"
        aria-hidden="true"
      >
        <svg
          class="h-full w-full text-gray-300 dark:text-gray-600"
          viewBox="0 0 22 80"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 -2L20 40L0 82"
            vector-effect="non-scaling-stroke"
            stroke="currentcolor"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      }
    </li>
    }
  </ol>
</nav>

@if (!value) {
  <div>
    <svg
      fill="#40C057"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="36px"
      height="36px"
    >
      <path
        d="M40.85,13.271l-18.29,18.29C22.268,31.854,21.884,32,21.5,32s-0.768-0.146-1.061-0.439l-5-5	c-0.586-0.586-0.586-1.535,0-2.121s1.535-0.586,2.121,0l3.939,3.939l17.53-17.53C35.361,6.661,29.991,4,24,4C12.972,4,4,12.972,4,24	s8.972,20,20,20s20-8.972,20-20C44,20.051,42.834,16.375,40.85,13.271z"
      />
    </svg>
  </div>
} @else {
  <p></p>
}

import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { LoginPageComponent } from './login-page/login-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountPageComponent } from './settings/account-page/account-page.component';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { AdministrationPageComponent } from './administration-page/administration-page.component';
import { OrganizationsListComponent } from './management-page/organizations-list/organizations-list.component';
import { UsersListComponent } from './management-page/users-list/users-list.component';
import { ProfilesListComponent } from './administration-page/profiles-list/profiles-list.component';
import { CategoriesListComponent } from './administration-page/categories-list/categories-list.component';
import { ManagementPageComponent } from './management-page/management-page.component';
import { roleGuard } from './core/guards/role.guard';
import { UserRole } from './core/models/user.model';
import { SecurityControlsPageComponent } from './administration-page/security-controls-page/security-controls-page.component';
import { ServicesPageComponent } from './administration-page/services-page/services-page.component';
import { SecurityControlsListComponent } from './administration-page/security-controls-page/security-controls-list/security-controls-list.component';
import { StandardsListComponent } from './administration-page/security-controls-page/standards-list/standards-list.component';
import { ServicesListComponent } from './administration-page/services-page/services-list/services-list.component';
import { ServiceSourcesListComponent } from './administration-page/services-page/service-sources-list/service-sources-list.component';
import { ServiceVendorsListComponent } from './administration-page/services-page/service-vendors-list/service-vendors-list.component';
import { IndividualsProfilesComponent } from './administration-page/profiles-list/individuals-profiles/individuals-profiles.component';
import { OrganizationalProfilesComponent } from './administration-page/profiles-list/organizational-profiles/organizational-profiles.component';
import { OrganizationCategoriesListComponent } from './administration-page/categories-list/organization-categories-list/organization-categories-list.component';
import { IndividualCategoriesListComponent } from './administration-page/categories-list/individual-categories-list/individual-categories-list.component';
import { AssessmentPageComponent } from './assessment-page/assessment-page.component';
import { SystemPageComponent } from './settings/system-page/system-page.component';
import { DashboardPageAssessmentComponent } from './dashboard-page/dashboard-page-assessment/dashboard-page-assessment.component';
import { DashbaordPageAdminComponent } from './dashboard-page/dashbaord-page-admin/dashbaord-page-admin.component';
import { ReportsPageComponent } from './reports-page/reports-page.component';
import { roleRedirectGuard } from './core/guards/role-redirect.guard';
import { AuditPageComponent } from './settings/audit-page/audit-page.component';
import { SystemSettingsComponent } from './administration-page/system-settings/system-settings.component';
import { FeedbackPageComponent } from './administration-page/system-settings/feedback-page/feedback-page.component';
import { BrandingPageComponent } from './settings/branding-page/branding-page.component';
import { PricingPageComponent } from './administration-page/system-settings/pricing-page/pricing-page.component';
import { RedirectionPageComponent } from './shared/redirection-page/redirection-page.component';
import { orgLinkGuard } from './core/guards/org-link.guard';
import { BrandedSignupPageComponent } from './branded-signup-page/branded-signup-page.component';
import { BrandedPageComponent } from './branded-page/branded-page.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [roleRedirectGuard],
    children: [],
  },
  {
    path: 'dashboard',
    canActivate: [authGuard, roleGuard],
    data: {
      roles: [
        UserRole.OrgAdmin,
        UserRole.OrgStaff,
        UserRole.Individual,
        UserRole.SysAdmin,
        UserRole.SuperAdmin,
      ],
      animation: 'dashboard',
    },
    component: DashboardPageComponent,
    children: [
      {
        path: 'organizations',
        component: DashbaordPageAdminComponent,
      },
      {
        path: 'assessments',
        component: DashboardPageAssessmentComponent,
      },
    ],
  },
  {
    path: 'administration',
    canActivate: [authGuard, roleGuard],
    data: {
      roles: [UserRole.SuperAdmin, UserRole.SysAdmin],
      animation: 'administration',
    },
    component: AdministrationPageComponent,
    children: [
      {
        path: 'profiles',
        component: ProfilesListComponent,
        children: [
          {
            path: 'individuals',
            component: IndividualsProfilesComponent,
          },
          {
            path: 'organizational',
            component: OrganizationalProfilesComponent,
          },
        ],
      },
      {
        path: 'categories',
        component: CategoriesListComponent,
        children: [
          {
            path: 'organizations',
            component: OrganizationCategoriesListComponent,
          },
          {
            path: 'individuals',
            component: IndividualCategoriesListComponent,
          },
        ],
      },
      {
        path: 'controls',
        component: SecurityControlsPageComponent,
        children: [
          {
            path: 'standards',
            component: StandardsListComponent,
          },
          {
            path: 'security-controls',
            component: SecurityControlsListComponent,
          },
        ],
      },
      {
        path: 'security-services',
        component: ServicesPageComponent,
        children: [
          {
            path: 'services',
            component: ServicesListComponent,
          },
          {
            path: 'sources',
            component: ServiceSourcesListComponent,
          },
          {
            path: 'vendors',
            component: ServiceVendorsListComponent,
          },
        ],
      },
      {
        path: 'settings',
        component: SystemSettingsComponent,
        children: [
          {
            path: 'system',
            component: SystemPageComponent,
          },
          {
            path: 'audit',
            component: AuditPageComponent,
          },
          {
            path: 'feedback',
            component: FeedbackPageComponent,
          },
          {
            path: 'branding',
            component: BrandingPageComponent,
          },
          {
            path: 'pricing',
            component: PricingPageComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'settings',
    canActivate: [authGuard],
    component: SettingsComponent,
    children: [
      {
        path: 'account',
        component: AccountPageComponent,
      },
      {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'management',
    canActivate: [authGuard, roleGuard], // roleGuard
    data: {
      roles: [
        UserRole.OrgAdmin,
        UserRole.OrgManager,
        UserRole.SysAdmin,
        UserRole.SuperAdmin,
      ],
      animation: 'management',
    },
    component: ManagementPageComponent,
    children: [
      {
        path: 'organizations',
        component: OrganizationsListComponent,
      },
      {
        path: 'users',
        component: UsersListComponent,
      },
    ],
  },
  {
    path: 'reports',
    canActivate: [authGuard, roleGuard],
    data: {
      roles: [
        UserRole.OrgAdmin,
        UserRole.SysAdmin,
        UserRole.SuperAdmin,
        UserRole.OrgStaff,
        UserRole.Individual,
      ],
      animation: 'reports',
    },
    component: ReportsPageComponent,
  },
  {
    path: 'assessments',
    canActivate: [authGuard, roleGuard],
    data: {
      roles: [
        UserRole.OrgStaff,
        UserRole.OrgAdmin,
        UserRole.Individual,
        UserRole.SysAdmin,
        UserRole.SuperAdmin,
      ],
    },
    component: AssessmentPageComponent,
  },
  {
    path: 'auth/login',
    component: LoginPageComponent,
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordPageComponent,
  },
  {
    path: 'auth/reset-password/:token',
    component: ResetPasswordPageComponent,
  },
  {
    path: 'not-found',
    component: NotFoundPageComponent,
  },
  {
    path: 'redirect',
    component: RedirectionPageComponent,
  },
  {
    path: 'auth/:orgLink/signup',
    component: BrandedSignupPageComponent,
    canActivate: [orgLinkGuard]
  },
  {
    path: 'auth/:orgLink',
    component: BrandedPageComponent,
    canActivate: [orgLinkGuard]
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
];

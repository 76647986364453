import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { TabsComponent } from '../shared/tabs/tabs.component';
import { ITab } from '../core/models/tab.model';
import { Store } from '@ngrx/store';
import { ProfilesAppActions } from '../state/profiles/profiles.actions';

@Component({
  selector: 'cap-management-page',
  standalone: true,
  imports: [
    CommonModule,
    SidebarComponent,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    TabsComponent,
  ],
  templateUrl: './management-page.component.html',
  styleUrl: './management-page.component.scss',
})
export class ManagementPageComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  tabs: ITab[] = [
    {
      name: 'Organizations',
      route: '/management/organizations',
      current: false,
      icon: 'corporate_fare',
    },
    {
      name: 'Users',
      route: '/management/users',
      current: false,
      icon: 'group',
    },
  ];

  ngOnInit(): void {
    this.router.navigate(['/management/organizations']);
  }

  onTabChange(route: string) {
    this.router.navigate([route]);
  }
}

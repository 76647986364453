import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { delay, map, mergeMap, take, tap, withLatestFrom } from 'rxjs';

import { AlertActions } from './alert.actions';
import { selectAlertCounter } from './alert.state';
import { AuthAPIActions } from '../auth/auth.actions';
import { OrganizationsAPIActions } from '../organizations/organizations.actions';
import { UsersAPIActions } from '../users/users.actions';
import { CategoriesAPIActions } from '../categories/categories.actions';
import { QuestionsAPIActions } from '../questions/questions.actions';
import { ActionItemsAPIActions } from '../action-items/action-items.actions';
import { SecurityControlsAPIActions } from '../security-controls/security-controls.actions';
import { ServicesAPIActions } from '../services/services.actions';

const autoRemovalDelay = 5000;

export const autoRemoveAlertEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(AlertActions.addAlert),
      withLatestFrom(store.pipe(select(selectAlertCounter))),
      delay(autoRemovalDelay),
      map(([, alertId]) => AlertActions.removeAlert({ id: alertId })),
    );
  },
  { functional: true },
);

export const showSuccessAlert$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        AuthAPIActions.validate2FAOTPSuccess,
        AuthAPIActions.logoutSuccess,
        AuthAPIActions.changePasswordSuccess,
        AuthAPIActions.setNewPasswordSuccess,
        AuthAPIActions.forgotPasswordSuccess,
        AuthAPIActions.resetPasswordSuccess,
        AuthAPIActions.updateUserAccountSuccess,
        AuthAPIActions.verify2FAOTPSuccess,
        AuthAPIActions.resendEmailOTPSuccess,
        AuthAPIActions.updateUserAvatarSuccess,
        AuthAPIActions.removeUserAvatarSuccess,
        AuthAPIActions.reset2FAOTPSuccess,
        AuthAPIActions.verifyResetting2FAOTPSuccess,
        OrganizationsAPIActions.createOrganizationSuccess,
        OrganizationsAPIActions.updateOrganizationSuccess,
        OrganizationsAPIActions.removeOrganizationSuccess,
        OrganizationsAPIActions.updateOrganizationAvatarSuccess,
        OrganizationsAPIActions.removeOrganizationAvatarSuccess,
        OrganizationsAPIActions.updateOrganizationStatusSuccess,
        UsersAPIActions.registerNewUserSuccess,
        UsersAPIActions.updateUserAccountSuccess,
        UsersAPIActions.updateUserOrganizationSuccess,
        UsersAPIActions.removeUserAccountSuccess,
        UsersAPIActions.updateUserAccountStatusSuccess,
        CategoriesAPIActions.createCategorySuccess,
        CategoriesAPIActions.updateCategorySuccess,
        CategoriesAPIActions.removeCategorySuccess,
        CategoriesAPIActions.updateCategoryAvatarSuccess,
        CategoriesAPIActions.removeCategoryAvatarSuccess,
        CategoriesAPIActions.updateCategorySortOrderSuccess,
        QuestionsAPIActions.createQuestionSuccess,
        QuestionsAPIActions.updateQuestionSuccess,
        QuestionsAPIActions.removeQuestionSuccess,
        QuestionsAPIActions.updateQuestionSortOrderSuccess,
        QuestionsAPIActions.addOptionToQuestionSuccess,
        QuestionsAPIActions.updateQuestionOptionSuccess,
        QuestionsAPIActions.removeQuestionOptionSuccess,
        ActionItemsAPIActions.createActionItemSuccess,
        ActionItemsAPIActions.updateActionItemSuccess,
        ActionItemsAPIActions.removeActionItemSuccess,
        ActionItemsAPIActions.updateActionItemControlsSuccess,
        ActionItemsAPIActions.updateActionItemServicesSuccess,
        ActionItemsAPIActions.uploadActionItemDocsSuccess,
        ActionItemsAPIActions.removeActionItemDocSuccess,
        SecurityControlsAPIActions.createSecurityControlSuccess,
        SecurityControlsAPIActions.updateSecurityControlSuccess,
        SecurityControlsAPIActions.removeSecurityControlSuccess,
        SecurityControlsAPIActions.createStandardSuccess,
        SecurityControlsAPIActions.updateStandardSuccess,
        SecurityControlsAPIActions.removeStandardSuccess,
        ServicesAPIActions.createServiceSuccess,
        ServicesAPIActions.updateServiceSuccess,
        ServicesAPIActions.removeServiceSuccess,
        ServicesAPIActions.createServiceSourceSuccess,
        ServicesAPIActions.updateServiceSourceSuccess,
        ServicesAPIActions.removeServiceSourceSuccess,
        ServicesAPIActions.createServiceVendorSuccess,
        ServicesAPIActions.updateServiceVendorSuccess,
        ServicesAPIActions.removeServiceVendorSuccess,
      ),
      mergeMap((action) => [
        AlertActions.addAlert({
          alert: {
            type: 'success',
            message: getAlertMessage(action),
          },
        }),
      ]),
    );
  },
  {
    functional: true,
  },
);

export const showFailureAlert$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(
        AuthAPIActions.validate2FAOTPFailure,
        AuthAPIActions.logoutFailure,
        AuthAPIActions.changePasswordFailure,
        AuthAPIActions.setNewPasswordFailure,
        AuthAPIActions.forgotPasswordFailure,
        AuthAPIActions.resetPasswordFailure,
        AuthAPIActions.updateUserAccountFailure,
        AuthAPIActions.verify2FAOTPFailure,
        AuthAPIActions.resendEmailOTPFailure,
        AuthAPIActions.updateUserAvatarFailure,
        AuthAPIActions.removeUserAvatarFailure,
        AuthAPIActions.reset2FAOTPFailure,
        AuthAPIActions.verifyResetting2FAOTPFailure,
        OrganizationsAPIActions.createOrganizationFailure,
        OrganizationsAPIActions.updateOrganizationFailure,
        OrganizationsAPIActions.removeOrganizationFailure,
        OrganizationsAPIActions.updateOrganizationAvatarFailure,
        OrganizationsAPIActions.removeOrganizationAvatarFailure,
        OrganizationsAPIActions.updateOrganizationStatusFailure,
        UsersAPIActions.registerNewUserFailure,
        UsersAPIActions.updateUserAccountFailure,
        UsersAPIActions.updateUserOrganizationFailure,
        UsersAPIActions.removeUserAccountFailure,
        UsersAPIActions.updateUserAccountStatusFailure,
        CategoriesAPIActions.createCategoryFailure,
        CategoriesAPIActions.updateCategoryFailure,
        CategoriesAPIActions.removeCategoryFailure,
        CategoriesAPIActions.updateCategoryAvatarFailure,
        CategoriesAPIActions.removeCategoryAvatarFailure,
        CategoriesAPIActions.updateCategorySortOrderFailure,
        QuestionsAPIActions.createQuestionFailure,
        QuestionsAPIActions.updateQuestionFailure,
        QuestionsAPIActions.removeQuestionFailure,
        QuestionsAPIActions.updateQuestionSortOrderFailure,
        QuestionsAPIActions.addOptionToQuestionFailure,
        QuestionsAPIActions.updateQuestionOptionFailure,
        QuestionsAPIActions.removeQuestionOptionFailure,
        ActionItemsAPIActions.createActionItemFailure,
        ActionItemsAPIActions.updateActionItemFailure,
        ActionItemsAPIActions.removeActionItemFailure,
        ActionItemsAPIActions.updateActionItemControlsFailure,
        ActionItemsAPIActions.updateActionItemServicesFailure,
        ActionItemsAPIActions.uploadActionItemDocsFailure,
        ActionItemsAPIActions.removeActionItemDocFailure,
        SecurityControlsAPIActions.createSecurityControlFailure,
        SecurityControlsAPIActions.updateSecurityControlFailure,
        SecurityControlsAPIActions.removeSecurityControlFailure,
        SecurityControlsAPIActions.createStandardFailure,
        SecurityControlsAPIActions.updateStandardFailure,
        SecurityControlsAPIActions.removeStandardFailure,
        ServicesAPIActions.createServiceFailure,
        ServicesAPIActions.updateServiceFailure,
        ServicesAPIActions.removeServiceFailure,
        ServicesAPIActions.createServiceSourceFailure,
        ServicesAPIActions.updateServiceSourceFailure,
        ServicesAPIActions.removeServiceSourceFailure,
        ServicesAPIActions.createServiceVendorFailure,
        ServicesAPIActions.updateServiceVendorFailure,
        ServicesAPIActions.removeServiceVendorFailure,
      ),
      mergeMap((action) => [
        AlertActions.addAlert({
          alert: {
            type: 'error',
            message: getAlertMessage(action),
          },
        }),
      ]),
    );
  },
  {
    functional: true,
  },
);

const getAlertMessage = (action: any): string => {
  switch (action.type) {
    case AuthAPIActions.validate2FAOTPSuccess.type:
      return 'Logged In Successfully';
    case AuthAPIActions.logoutSuccess.type:
      return 'Logged Out Successfully';
    case AuthAPIActions.changePasswordSuccess.type:
      return 'Password Changed Successfully';
    case AuthAPIActions.setNewPasswordSuccess.type:
      return 'New Password Set Successfully';
    case AuthAPIActions.forgotPasswordSuccess.type:
      return 'Email Sent Successfully';
    case AuthAPIActions.resetPasswordSuccess.type:
      return 'Password Reset Successfully';
    case AuthAPIActions.resetPasswordFailure.type:
      return 'Invalid / expired token plase try again';
    case AuthAPIActions.updateUserAccountSuccess.type:
      return 'Account Updated Successfully';
    case AuthAPIActions.verify2FAOTPSuccess.type:
      return '2FA OTP Verified Successfully';
    case AuthAPIActions.resendEmailOTPSuccess.type:
      return 'Email OTP Sent Successfully, please check you mailbox';
    case AuthAPIActions.updateUserAvatarSuccess.type:
      return 'Avatar Updated Successfully';
    case AuthAPIActions.removeUserAvatarSuccess.type:
      return 'Avatar Removed Successfully';
    case AuthAPIActions.reset2FAOTPSuccess.type:
      return action.message;
    case AuthAPIActions.verifyResetting2FAOTPSuccess.type:
      return action.message;
    case OrganizationsAPIActions.createOrganizationSuccess.type:
      return 'Organization Created Successfully';
    case OrganizationsAPIActions.updateOrganizationSuccess.type:
      return 'Organization Updated Successfully';
    case OrganizationsAPIActions.removeOrganizationSuccess.type:
      return 'Organization Removed Successfully';
    case OrganizationsAPIActions.updateOrganizationAvatarSuccess.type:
      return 'Avatar Updated Successfully';
    case OrganizationsAPIActions.removeOrganizationAvatarSuccess.type:
      return 'Avatar Removed Successfully';
    case OrganizationsAPIActions.updateOrganizationStatusSuccess.type:
      return 'Organization Status Updated Successfully';
    case OrganizationsAPIActions.updateOrganizationStatusFailure.type:
      return action.message;
    case UsersAPIActions.registerNewUserSuccess.type:
      return 'New User Registered Successfully';
    case UsersAPIActions.updateUserAccountSuccess.type:
      return 'User Account Updated Successfully';
    case UsersAPIActions.updateUserOrganizationSuccess.type:
      return 'User Organization Updated Successfully';
    case UsersAPIActions.removeUserAccountSuccess.type:
      return 'User Removed Successfully';
    case UsersAPIActions.removeUserAccountFailure.type:
      return 'Failed To Remove User';
    case UsersAPIActions.updateUserAccountStatusSuccess.type:
      return 'User Status Updated Successfully';
    case UsersAPIActions.updateUserAccountStatusFailure.type:
      return action.message;
    case CategoriesAPIActions.createCategorySuccess.type:
      return 'Category Created Successfully';
    case CategoriesAPIActions.updateCategorySuccess.type:
      return 'Category Updated Successfully';
    case CategoriesAPIActions.removeCategorySuccess.type:
      return 'Category Removed Successfully';
    case CategoriesAPIActions.updateCategoryAvatarSuccess.type:
      return 'Category Avatar Updated Successfully';
    case CategoriesAPIActions.removeCategoryAvatarSuccess.type:
      return 'Category Avatar Removed Successfully';
    case CategoriesAPIActions.updateCategorySortOrderSuccess.type:
      return 'Categories Reordered Successfully';
    case CategoriesAPIActions.updateCategorySortOrderFailure.type:
      return 'Categories Reordering Failed';
    case QuestionsAPIActions.createQuestionSuccess.type:
      return 'Question Created Successfully';
    case QuestionsAPIActions.updateQuestionSuccess.type:
      return 'Question Updated Successfully';
    case QuestionsAPIActions.removeQuestionSuccess.type:
      return 'Question Deleted Successfully';
    case QuestionsAPIActions.updateQuestionSortOrderSuccess.type:
      return 'Questions Reordered Successfully';
    case QuestionsAPIActions.updateQuestionSortOrderFailure.type:
      return 'Questions Reorder Failed';
    case QuestionsAPIActions.addOptionToQuestionSuccess.type:
      return 'Option Added To Question Successfully';
    case QuestionsAPIActions.updateQuestionOptionSuccess.type:
      return 'Question Option Updated Successfully';
    case QuestionsAPIActions.removeQuestionOptionSuccess.type:
      return 'Question Option Removed Successfully';
    case ActionItemsAPIActions.createActionItemSuccess.type:
      return 'Action Item Created Successfully';
    case ActionItemsAPIActions.updateActionItemSuccess.type:
      return 'Action Item Updated Successfully';
    case ActionItemsAPIActions.removeActionItemSuccess.type:
      return 'Action Item Removed Successfully';
    case ActionItemsAPIActions.updateActionItemControlsSuccess.type:
      return 'Action Item Controls Updated Successfully';
    case ActionItemsAPIActions.updateActionItemServicesSuccess.type:
      return 'Action Item Services Updated Successfully';
    case ActionItemsAPIActions.uploadActionItemDocsSuccess.type:
      return 'Action Item Docs Uploaded Successfully';
    case ActionItemsAPIActions.removeActionItemDocSuccess.type:
      return 'Action Item Doc Removed Successfully';
    case SecurityControlsAPIActions.createSecurityControlSuccess.type:
      return 'Security Control Created Successfully';
    case SecurityControlsAPIActions.updateSecurityControlSuccess.type:
      return 'Security Control Updated Successfully';
    case SecurityControlsAPIActions.removeSecurityControlSuccess.type:
      return 'Security Control Removed Successfully';
    case SecurityControlsAPIActions.createStandardSuccess.type:
      return 'Standard Created Successfully';
    case SecurityControlsAPIActions.updateStandardSuccess.type:
      return 'Standard Updated Successfully';
    case SecurityControlsAPIActions.removeStandardSuccess.type:
      return 'Standard Removed Successfully';
    case ServicesAPIActions.createServiceSuccess.type:
      return 'Service Created Successfully';
    case ServicesAPIActions.updateServiceSuccess.type:
      return 'Service Updated Successfully';
    case ServicesAPIActions.removeServiceSuccess.type:
      return 'Service Removed Successfully';
    case ServicesAPIActions.createServiceSourceSuccess.type:
      return 'Service Source Created Successfully';
    case ServicesAPIActions.updateServiceSourceSuccess.type:
      return 'Service Source Updated Successfully';
    case ServicesAPIActions.removeServiceSourceSuccess.type:
      return 'Service Source Removed Successfully';
    case ServicesAPIActions.createServiceVendorSuccess.type:
      return 'Service Vendor Create Successfully';
    case ServicesAPIActions.updateServiceVendorSuccess.type:
      return 'Service Vendor Updated Successfully';
    case ServicesAPIActions.removeServiceVendorSuccess.type:
      return 'Service Vendor Removed Successfully';
    case AuthAPIActions.validate2FAOTPFailure.type:
      return action.message;
    case AuthAPIActions.logoutFailure.type:
      return action.message;
    case AuthAPIActions.changePasswordFailure.type:
      return action.message;
    case AuthAPIActions.setNewPasswordFailure.type:
      return action.message;
    case AuthAPIActions.forgotPasswordFailure.type:
      return action.message;
    case AuthAPIActions.updateUserAccountFailure.type:
      return action.message;
    case AuthAPIActions.verify2FAOTPFailure.type:
      return action.message;
    case AuthAPIActions.resendEmailOTPFailure.type:
      return action.message;
    case AuthAPIActions.updateUserAvatarFailure.type:
      return action.message;
    case AuthAPIActions.removeUserAvatarFailure.type:
      return action.message;
    case AuthAPIActions.reset2FAOTPFailure.type:
      return action.message;
    case AuthAPIActions.verifyResetting2FAOTPFailure.type:
      return action.message;
    case OrganizationsAPIActions.createOrganizationFailure.type:
      return action.message;
    case OrganizationsAPIActions.updateOrganizationFailure.type:
      return action.message;
    case OrganizationsAPIActions.removeOrganizationFailure.type:
      return action.message;
    case OrganizationsAPIActions.updateOrganizationAvatarFailure.type:
      return action.message;
    case OrganizationsAPIActions.removeOrganizationAvatarFailure.type:
      return action.message;
    case UsersAPIActions.registerNewUserFailure.type:
      return action.message;
    case UsersAPIActions.updateUserAccountFailure.type:
      return action.message;
    case UsersAPIActions.updateUserOrganizationFailure.type:
      return action.message;
    case CategoriesAPIActions.createCategoryFailure.type:
      return action.message;
    case CategoriesAPIActions.updateCategoryFailure.type:
      return action.message;
    case CategoriesAPIActions.removeCategoryFailure.type:
      return action.message;
    case CategoriesAPIActions.updateCategoryAvatarFailure.type:
      return action.message;
    case CategoriesAPIActions.removeCategoryAvatarFailure.type:
      return action.message;
    case QuestionsAPIActions.createQuestionFailure.type:
      return action.message;
    case QuestionsAPIActions.updateQuestionFailure.type:
      return action.message;
    case QuestionsAPIActions.removeQuestionFailure.type:
      return action.message;
    case QuestionsAPIActions.addOptionToQuestionFailure.type:
      return action.message;
    case QuestionsAPIActions.updateQuestionOptionFailure.type:
      return action.message;
    case QuestionsAPIActions.removeQuestionOptionFailure.type:
      return action.message;
    case ActionItemsAPIActions.createActionItemFailure.type:
      return action.message;
    case ActionItemsAPIActions.updateActionItemFailure.type:
      return action.message;
    case ActionItemsAPIActions.removeActionItemFailure.type:
      return action.message;
    case ActionItemsAPIActions.updateActionItemControlsFailure.type:
      return action.message;
    case ActionItemsAPIActions.updateActionItemServicesFailure.type:
      return action.message;
    case ActionItemsAPIActions.uploadActionItemDocsFailure.type:
      return action.message;
    case ActionItemsAPIActions.removeActionItemDocFailure.type:
      return action.message;
    case SecurityControlsAPIActions.createSecurityControlFailure.type:
      return action.message;
    case SecurityControlsAPIActions.updateSecurityControlFailure.type:
      return action.message;
    case SecurityControlsAPIActions.removeSecurityControlFailure.type:
      return action.message;
    case SecurityControlsAPIActions.createStandardFailure.type:
      return action.message;
    case SecurityControlsAPIActions.updateStandardFailure.type:
      return action.message;
    case SecurityControlsAPIActions.removeStandardFailure.type:
      return action.message;
    case ServicesAPIActions.createServiceFailure.type:
      return action.message;
    case ServicesAPIActions.updateServiceFailure.type:
      return action.message;
    case ServicesAPIActions.removeServiceFailure.type:
      return action.message;
    case ServicesAPIActions.createServiceSourceFailure.type:
      return action.message;
    case ServicesAPIActions.updateServiceSourceFailure.type:
      return action.message;
    case ServicesAPIActions.removeServiceSourceFailure.type:
      return action.message;
    case ServicesAPIActions.createServiceVendorFailure.type:
      return action.message;
    case ServicesAPIActions.updateServiceVendorFailure.type:
      return action.message;
    case ServicesAPIActions.removeServiceVendorFailure.type:
      return action.message;

    default:
      return 'Operation Unkown';
  }
};

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js';

export function phoneNumberFormatValidator(
  countryCodeKey: string
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const countryCode = formGroup.get(countryCodeKey)?.value || '';
    const phoneNumber = formGroup.get('number')?.value || '';

    if (!countryCode.trim() || !phoneNumber.trim()) return null;

    try {
      const phoneNum = parsePhoneNumber(`+${countryCode}${phoneNumber}`);
      return phoneNum?.isValid() ? null : { invalidPhoneNumber: true };
    } catch (e) {
      console.error('Error parsing phone number:', e);
      return { invalidPhoneNumber: true };
    }
  };
}

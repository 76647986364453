import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'src/environments/environment';
import { IRole } from '../models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private readonly http = inject(HttpClient);

  getRoles(): Observable<{ roles: IRole[] }> {
    return this.http.get<{ roles: IRole[] }>(`${environment.apiUrl}/roles`, {
      withCredentials: true,
    });
  }
}

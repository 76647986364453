import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';

import { LeadingZerosPipe } from '../pipes/leading-zeros.pipe';
import { StepNavigatorStore } from './step-navigator.store';
import { IStep } from 'src/app/core/models/step.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cap-step-navigator',
  standalone: true,
  imports: [CommonModule, LeadingZerosPipe],
  providers: [StepNavigatorStore],
  templateUrl: './step-navigator.component.html',
  styleUrl: './step-navigator.component.scss',
  animations: [
    trigger('stepTransition', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class StepNavigatorComponent implements OnInit, OnDestroy {
  private readonly componentStore = inject(StepNavigatorStore);
  private sub!: Subscription;

  @Input() set steps(value: Omit<IStep, 'id' | 'status'>[]) {
    this.componentStore.setSteps(value);
  }

  @Input() eventEmitter!: EventEmitter<'next' | 'previous'>;

  readonly steps$ = this.componentStore.steps$;
  readonly currentStep$ = this.componentStore.currentStep$;

  ngOnInit(): void {
    this.subscribeToParentEmitter();
  }

  onNext() {
    this.componentStore.nextStep();
  }

  onPrevious() {
    this.componentStore.previousStep();
  }

  subscribeToParentEmitter() {
    this.sub = this.eventEmitter.subscribe((event) => {
      if (event === 'next') {
        this.onNext();
      } else if (event === 'previous') {
        this.onPrevious();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { EditableTableComponent } from 'src/app/shared/editable-table/editable-table.component';
import { FeedbackComponent } from 'src/app/shared/feedback/feedback.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { SecurityControlsAppActions } from 'src/app/state/security-controls/security-controls.actions';
import {
  selectAll as selectSecuirtyControls,
  selectTotal as selectSecurityControlsCount,
} from 'src/app/state/security-controls/security-controls.state';
import { SecurityControlsListStore } from './security-controls-list.store';
import {
  ISecurityControl,
  ISecurityControlBase,
} from 'src/app/core/models/security-controls.model';
import { selectAll as selectStandards } from 'src/app/state/security-controls/standards.state';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';
import { selectPagination } from '../../../state/categories/categories.selectors';
import { Pagination } from '../../../state/categories/categories.state';
import { selectSecurityControlsPagination } from '../../../state/security-controls/security-controls.selectors';
import { CategoriesAppActions } from '../../../state/categories/categories.actions';

@Component({
  selector: 'cap-security-controls-list',
  standalone: true,
  imports: [
    CommonModule,
    EditableTableComponent,
    ConfirmModalComponent,
    FeedbackComponent,
    SpinnerComponent,
    PaginationComponent,
  ],
  providers: [SecurityControlsListStore],
  templateUrl: './security-controls-list.component.html',
  styleUrl: './security-controls-list.component.scss',
})
export class SecurityControlsListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  readonly confirmModalData = {
    title: 'Delete question security control',
    message: 'Are you sure you want to delete this security control?',
  };

  securityControlsTableMetaData: IEditableTableMetadata = {
    title: 'Security Controls',
    entityName: 'Security Controls',
    bgColor: 'dark:bg-gray-900',
    accordion: false,
  };

  private readonly store = inject(Store);
  private componentStore = inject(SecurityControlsListStore);

  securityControls$: Observable<ISecurityControl[]> = this.store.select(
    selectSecuirtyControls,
  );
  standardsOptionsList: { label: string; value: string }[] = [];

  private securityControlDropped: ISecurityControl | null = null;

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;
  listLoading$ = this.componentStore.isListLoading$;

  readonly pagination$ = this.store
    .select(selectSecurityControlsPagination)
    .subscribe((pagination: Pagination) => {
      this.current_page = pagination.page;
      this.total_pages = pagination.totalPages;
      this.totalItems = pagination.totalCount;
      this.pageSize = pagination.limit;
    });
  current_page: number = 1;
  total_pages: number = 0;
  totalItems: number = 0;
  pageSize: number = 10;

  securityControlTableColumns: IEditableColumn[] = [
    {
      key: 'standardId',
      label: 'Standard',
      type: 'list',
      sortable: false,
      options: [],
    },
    {
      key: 'version',
      label: 'Control Version',
      type: 'text',
      sortable: false,
    },
    {
      key: 'subVersion',
      label: 'Control SubVersion',
      type: 'text',
      sortable: false,
    },
    {
      key: 'description',
      label: 'Description',
      type: 'description',
      sortable: false,
    },
    {
      key: 'groupings',
      label: 'Groupings',
      type: 'badge',
      sortable: false,
    },
  ];

  ngOnInit(): void {
    this.store.dispatch(
      SecurityControlsAppActions.getSecurityControlsByPagination({
        page: 1,
        limit: 10,
      }),
    );
    this.store
      .select(selectStandards)
      .pipe(
        tap(
          (standards) =>
            (this.standardsOptionsList = standards.map((standard) => ({
              label: `${standard.name} ${standard.version}`,
              value: standard._id,
            }))),
        ),
        tap(
          () =>
            (this.securityControlTableColumns[0].options =
              this.standardsOptionsList),
        ),
        tap((standards) => {
          if (!standards.length) {
            this.store.dispatch(SecurityControlsAppActions.getStandards());
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.store
      .select(selectSecurityControlsCount)
      .pipe(
        tap((count) => {
          if (!count) {
            this.store.dispatch(
              SecurityControlsAppActions.getSecurityControlsByPagination({
                page: 1,
                limit: 10,
              }),
            );
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onPageChange(page: number) {
    this.store.dispatch(
      SecurityControlsAppActions.getSecurityControlsByPagination({
        page,
        limit: this.pageSize,
      }),
    );
  }

  onSecurityControlCreated(newSecurityControl: any) {
    this.store.dispatch(
      SecurityControlsAppActions.createSecurityControl({
        securityControl: <ISecurityControlBase>newSecurityControl,
      }),
    );
  }

  onSecurityControlUpdate(securityControl: any) {
    this.store.dispatch(
      SecurityControlsAppActions.updateSecurityControl({
        securityControl: <ISecurityControl>securityControl,
      }),
    );
  }

  openConfirmationModal(securityControl: any) {
    this.securityControlDropped = securityControl;
    this.store.dispatch(
      ModalActions.open({ id: 'confirm-removing-security-control' }),
    );
  }

  removeSecurityControl() {
    if (this.securityControlDropped) {
      this.store.dispatch(
        SecurityControlsAppActions.removeSecurityControl({
          id: this.securityControlDropped._id,
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

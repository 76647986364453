@if (isLink) {
  <input
    #urlInput="ngModel"
    type="url"
    [ngModel]="text"
    (ngModelChange)="onTextChange($event)"
    placeholder="Type a URL ..."
    required
    pattern="https?:\/\/(?:www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}((\/[a-zA-Z0-9-]+)*\/?)"
    class="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-10 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
  />
  @if (urlInput.errors?.["pattern"] && urlInput.touched) {
    <p class="text-red-600 text-sm">Please enter a valid URL</p>
  }
} @else {
  <input
    [type]="isLink ? 'url' : 'text'"
    [ngModel]="text"
    (ngModelChange)="onTextChange($event)"
    placeholder="Type a text ..."
    class="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-10 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
  />
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-edit-status-cell',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './edit-status-cell.component.html',
  styleUrl: './edit-status-cell.component.scss',
})
export class EditStatusCellComponent {
 @Input() options: { value: boolean; label: string }[] = [
    {
      label: 'Active',
      value: true,
    },
    {
      label: 'Inactive',
      value: false,
    },
  ];
  @Input() selectedOption: boolean = false;
  @Input() groupName: string = '';
  @Output() optionChange = new EventEmitter<boolean>();

  onOptionChange() {
    this.optionChange.emit(this.selectedOption);
  }
}

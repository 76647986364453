import { inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import {
  OrganizationsAPIActions,
  OrganizationsAppActions,
} from '../state/organizations/organizations.actions';
import { UsersAPIActions, UsersAppActions } from '../state/users/users.actions';
import { filter, map, Subscription, takeUntil, tap, combineLatest } from 'rxjs';
import {
  CategoriesAPIActions,
  CategoriesAppActions,
} from '../state/categories/categories.actions';
import {
  AssessmentsAPIActions,
  AssessmentsAppActions,
} from '../state/assessments/assessments.actions';

export interface IDashboardPageState {
  isLoadingEntitySelector: boolean;
  showAssessmentStats: boolean;
  isLoadingCategories: boolean;
  isLoadingAdminData: boolean;
  isLoadingAssessmentData: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardPageStore
  extends ComponentStore<IDashboardPageState>
  implements OnDestroy
{
  private actions$ = inject(Actions);
  private subs: Subscription[] = [];
  constructor() {
    super({
      isLoadingEntitySelector: false,
      showAssessmentStats: false,
      isLoadingCategories: false,
      isLoadingAdminData: false,
      isLoadingAssessmentData: false,
    });
    this.subs.push(
      this.entitySelectorAppActions,
      this.entitySelectorApiActions,
      this.categoryScrollerAppActions,
      this.categoryScrollerApiActions,
      this.adminDashboardApiActions,
      this.adminDashboardAppActions,
      this.assessmentDashboardApiActions,
    );
  }

  readonly isLoadingCategories$ = this.select(
    (state) => state.isLoadingCategories,
  );
  readonly isLoadingEntitySelector$ = this.select(
    (state) => state.isLoadingEntitySelector,
  );
  readonly isLoadingAdminData$ = this.select(
    (state) => state.isLoadingAdminData,
  );
  readonly isLoadingAssessmentData$ = this.select(
    (state) => state.isLoadingAssessmentData,
  );

  toggleAssessmentStats(value: boolean) {
    this.patchState((state) => {
      return {
        ...state,
        showAssessmentStats: value,
      };
    });
  }

  entitySelectorAppActions = this.actions$
    .pipe(
      ofType(
        OrganizationsAppActions.getOrganizations,
        UsersAppActions.getAllUsersAccounts,
      ),
      tap(() => this.patchState({ isLoadingEntitySelector: true })),
    )
    .subscribe();

  entitySelectorApiActions = this.actions$
    .pipe(
      ofType(
        OrganizationsAPIActions.getOrganizationsSuccess,
        OrganizationsAPIActions.getOrganizationsFailure,
        UsersAPIActions.getAllUsersAccountsSuccess,
        UsersAPIActions.getAllUsersAccountsFailure,
      ),
      tap(() => this.patchState({ isLoadingEntitySelector: false })),
    )
    .subscribe();
  readonly categoryScrollerAppActions = this.actions$
    .pipe(
      ofType(
        CategoriesAppActions.getCategories,
        CategoriesAppActions.getCategoryById,
        AssessmentsAppActions.getAssessmentsByOrganizationId,
        AssessmentsAppActions.getAssessmentsByUserId,
      ),
      tap(() => this.patchState({ isLoadingCategories: true })),
    )
    .subscribe();

  readonly categoryScrollerApiActions = this.actions$
    .pipe(
      ofType(
        CategoriesAPIActions.getCategoriesSuccess,
        CategoriesAPIActions.getCategoriesFailure,
        CategoriesAPIActions.getCategoryByIdSuccess,
        CategoriesAPIActions.getCategoryByIdFailure,
        AssessmentsAPIActions.getAssessmentsByOrganizationIdSuccess,
        AssessmentsAPIActions.getAssessmentsByOrganizationIdFailure,
        AssessmentsAPIActions.getAssessmentsByUserIdSuccess,
        AssessmentsAPIActions.getAssessmentsByUserIdFailure,
      ),
      tap(() => this.patchState({ isLoadingCategories: false })),
    )
    .subscribe();

  readonly adminDashboardAppActions = this.actions$
    .pipe(
      ofType(
        AssessmentsAppActions.getAssessments,
        CategoriesAppActions.getCategories,
      ),
      tap(() => this.patchState({ isLoadingAdminData: true })),
    )
    .subscribe();
  private adminAssessmentLoaded$ = this.actions$.pipe(
    ofType(
      AssessmentsAPIActions.getAssessmentsSuccess,
      AssessmentsAPIActions.getAssessmentsFailure,
    ),
    map(() => true),
  );
  private adminCategoriesLoaded$ = this.actions$.pipe(
    ofType(
      CategoriesAPIActions.getCategoriesSuccess,
      CategoriesAPIActions.getCategoriesFailure,
    ),
    map(() => true),
  );
  readonly adminDashboardApiActions = combineLatest([
    this.adminAssessmentLoaded$,
    this.adminCategoriesLoaded$,
  ])
    .pipe(
      map(
        ([assessmentsLoaded, categoriesLoaded]) =>
          assessmentsLoaded && categoriesLoaded,
      ),
      filter((allLoaded) => allLoaded),
      tap(() => this.patchState({ isLoadingAdminData: false })),
      takeUntil(this.destroy$),
    )
    .subscribe();

  private usersLoaded$ = this.actions$.pipe(
    ofType(UsersAPIActions.getAllUsersAccountsSuccess),
    map(() => true),
  );
  private organizationsLoaded$ = this.actions$.pipe(
    ofType(OrganizationsAPIActions.getOrganizationsSuccess),
    map(() => true),
  );

  readonly assessmentDashboardApiActions = combineLatest([
    this.usersLoaded$,
    this.organizationsLoaded$,
  ])
    .pipe(
      map(
        ([usersLoaded, organizationsLoaded]) =>
          usersLoaded && organizationsLoaded,
      ),
      filter((allLoaded) => allLoaded),
      tap(() => this.patchState({ isLoadingAssessmentData: false })),
      takeUntil(this.destroy$),
    )
    .subscribe();

  override ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

<div class="flex gap-2 flex-wrap">
  @for(badge of badges$ | async; track $index) {
  <span
    class="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700"
    >{{ badge }}</span
  >
  } @if((badges$ | async)?.length === 0) {
  <span
    class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"
    >Empty</span
  >
  }
</div>

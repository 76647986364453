import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, tap } from 'rxjs';
import { IOrganization } from 'src/app/core/models/organization.model';
import {
  OrganizationsAPIActions,
  OrganizationsAppActions,
} from 'src/app/state/organizations/organizations.actions';

interface OrganizationPageState {
  isLoading: boolean;
  errorMessage: string | null;
  organization: IOrganization | null;
  organizationFormValidity: boolean;
}

@Injectable()
export class OrganizationPageStore
  extends ComponentStore<OrganizationPageState>
  implements OnDestroy
{
  private subs: Subscription[] = [];

  constructor(private readonly actions$: Actions) {
    super({
      isLoading: false,
      errorMessage: null,
      organization: null,
      organizationFormValidity: false,
    });

    this.subs.push(
      this.appActionsSub,
      this.apiSuccessActionsSub,
      this.apiFailureActionsSub
    );
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly organization$ = this.select((state) => state.organization);
  readonly organizationFormValidity$ = this.select(
    (state) => state.organizationFormValidity
  );

  readonly setErrorMessage = this.updater(
    (state, newErrorMessage: string | null) => ({
      ...state,
      errorMessage: newErrorMessage,
    })
  );

  readonly setOrganization = this.updater(
    (state, newOrganization: IOrganization) => ({
      ...state,
      organization: {
        ...state.organization,
        ...newOrganization,
      },
    })
  );

  readonly setOrganizationFormValidity = this.updater(
    (state, newOrganizationFormValidity: boolean) => ({
      ...state,
      organizationFormValidity: newOrganizationFormValidity,
    })
  );

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(OrganizationsAppActions.updateOrganization),
      tap(() => this.patchState({ isLoading: true, errorMessage: null }))
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(OrganizationsAPIActions.updateOrganizationSuccess),
      tap(() => this.patchState({ isLoading: false, errorMessage: null }))
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(OrganizationsAPIActions.updateOrganizationFailure),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message })
      )
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

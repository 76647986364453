import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  IAssessment,
  IAssessmentResponse,
} from '../../core/models/assessments.model';
import { FileWithPreview, IDoc } from '../../core/models/action-items.model';

export const AssessmentsAppActions = createActionGroup({
  source: 'Assessments',
  events: {
    'Get Assessments': emptyProps(),
    'Get Assessment By Id': props<{ id: string }>(),
    'Get Assessments By User Id': props<{ userId: string }>(),
    'Get Assessments By Organization Id': props<{ organizationId: string }>(),
    'Update Assessment': props<{
      assessmentId: string;
      questionsResponses: IAssessmentResponse[];
    }>(),
    'Upload Assessment Evidence': props<{
      assessmentId: string;
      actionItemId: string;
      files: FileWithPreview[];
    }>(),
    'Remove Assessment Evidence': props<{
      assessmentId: string;
      actionItemId: string;
      docId: string;
    }>(),
    'Remove Assessment': props<{ id: string }>(),
    'Remove All Evidence': props<{
      assessmentId: string;
      actionItemId: string;
    }>(),
    'Rest Assessments State': emptyProps(),
  },
});

export const AssessmentsAPIActions = createActionGroup({
  source: 'Assessments',
  events: {
    'Get Assessments Success': props<{ assessments: IAssessment[] }>(),
    'Get Assessments Failure': props<{ message: string }>(),
    'Get Assessment By Id Success': props<{ assessment: IAssessment }>(),
    'Get Assessment By Id Failure': props<{ message: string }>(),
    'Get Assessments By User Id Success': props<{
      assessments: IAssessment[];
    }>(),
    'Get Assessments By User Id Failure': props<{ message: string }>(),
    'Get Assessments By Organization Id Success': props<{
      assessments: IAssessment[];
    }>(),
    'Get Assessments By Organization Id Failure': props<{ message: string }>(),
    'Update Assessment Success': props<{ assessment: IAssessment }>(),
    'Update Assessment Failure': props<{ message: string }>(),
    'Remove Assessment Success': props<{ id: string }>(),
    'Reset Assessments State Success': emptyProps(),
    'Rest Assessments State Failure': props<{ message: string }>(),
    'Remove Assessment  Failure': props<{ message: string }>(),
    'Upload Assessment Evidence Success': props<{
      assessmentId: string;
      actionItemId: string;
      docs: IDoc[];
    }>(),
    'Upload Assessment Evidence Failure': props<{ message: string }>(),

    'Remove Assessment Evidence Success': props<{
      assessmentId: string;
      actionItemId: string;
      docId: string;
    }>(),
    'Remove All Evidence Success': props<{
      assessmentId: string;
      actionItemId: string;
    }>(),
    'Remove All Evidence Failure': props<{ message: string }>(),
    'Remove Assessment Evidence Failure': props<{ message: string }>(),
  },
});

// theme.store.ts
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap } from 'rxjs';

export type ThemeMode = 'dark' | 'light' | 'system';

export interface ThemeState {
  themeMode: ThemeMode;
  isDropdownOpen: boolean;
}

@Injectable({ providedIn: 'root' })
export class ThemeStore extends ComponentStore<ThemeState> {
  constructor() {
    super({
      themeMode: 'system',
      isDropdownOpen: false,
    });

    // Initialize the store
    this.initializeStore();
  }

  private initializeStore() {
    const savedTheme = localStorage.getItem('themeMode') as ThemeMode;
    const initialTheme = savedTheme || 'system';

    this.setState((state) => ({
      ...state,
      themeMode: initialTheme,
    }));

    // Apply the initial theme
    this.applyTheme(initialTheme);

    // Initialize system theme listener
    this.initSystemThemeListener();
  }

  readonly themeMode$ = this.select((state) => state.themeMode);
  readonly isDropdownOpen$ = this.select((state) => state.isDropdownOpen);

  readonly setThemeMode = this.updater((state, themeMode: ThemeMode) => ({
    ...state,
    themeMode,
  }));

  readonly toggleDropdown = this.updater((state) => ({
    ...state,
    isDropdownOpen: !state.isDropdownOpen,
  }));

  readonly closeDropdown = this.updater((state) => ({
    ...state,
    isDropdownOpen: false,
  }));

  readonly updateThemeMode = this.effect(
    (themeMode$: Observable<ThemeMode>) => {
      return themeMode$.pipe(
        tap((themeMode) => {
          localStorage.setItem('themeMode', themeMode);
          this.patchState({ themeMode });
          this.applyTheme(themeMode);
          this.toggleDropdown();
        }),
      );
    },
  );

  private applyTheme(themeMode: ThemeMode) {
    if (themeMode === 'system') {
      const isDarkMode =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      document.body.classList.toggle('dark', isDarkMode);
      this.setPrimeNGTheme(isDarkMode ? 'dark' : 'light');
    } else {
      document.body.classList.toggle('dark', themeMode === 'dark');
      this.setPrimeNGTheme(themeMode);
    }
  }

  private setPrimeNGTheme(theme: 'dark' | 'light') {
    const themeLink = document.getElementById('app-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href =
        theme === 'dark'
          ? 'theme-page-lara-dark-blue.css'
          : 'theme-page-lara-blue.css';
    }
  }

  private initSystemThemeListener() {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const listener = (e: MediaQueryListEvent) => {
      if (this.get().themeMode === 'system') {
        this.applyTheme('system');
      }
    };
    mediaQuery.addEventListener('change', listener);
  }
}

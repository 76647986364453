import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Subscription, tap } from 'rxjs';
import { AuthAPIActions, AuthAppActions } from '../state/auth/auth.actions';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class LoginPageComponentStore
  extends ComponentStore<{
    passwordVisibility: boolean;
    isLoading: boolean;
    errorMessage: string | null;
  }>
  implements OnDestroy
{
  private subs: Subscription[] = [];

  constructor(private readonly actions$: Actions) {
    super({ passwordVisibility: false, isLoading: false, errorMessage: null });
    this.subs.push(
      this.appActionsSub,
      this.apiActionsSub,
      this.apiErrorPositiveActionsSub,
      this.apiErrorNegativeActionsSub,
    );
  }

  passwordVisibility$ = this.select((state) => state.passwordVisibility);
  isLoading$ = this.select((state) => state.isLoading);
  errorMessage$ = this.select((state) => state.errorMessage);

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(AuthAppActions.login),
      tap(() => this.patchState({ isLoading: true })),
    )
    .subscribe();

  private readonly apiActionsSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.loginSuccess,
        AuthAPIActions.loginFailure,
        AuthAppActions.firstLoginDetected,
        AuthAPIActions.resendEmailOTPSuccess,
      ),
      tap(() => this.patchState({ isLoading: false })),
    )
    .subscribe();

  private readonly apiErrorPositiveActionsSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.loginFailure),
      tap(({ message }) => this.patchState({ errorMessage: message })),
    )
    .subscribe();

  private readonly apiErrorNegativeActionsSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.loginSuccess, AuthAppActions.firstLoginDetected),
      tap(() => this.patchState({ errorMessage: null })),
    )
    .subscribe();

  readonly togglePasswordVisibility = this.updater((state) => ({
    ...state,
    passwordVisibility: !state.passwordVisibility,
  }));

  override ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

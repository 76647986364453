<div @fadeInOut class="rounded-md my-3 p-4" [ngClass]="getClass('bg', 50)">
  <div class="flex">
    <div class="flex-shrink-0">
      <svg
        class="h-5 w-5"
        [ngClass]="getClass('text', 400)"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          [attr.d]="getIcon().path"
          fill-rule="evenodd"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div class="ml-3">
      <p class="text-sm font-medium" [ngClass]="getClass('text', 800)">
        {{ alert.message }}
      </p>
    </div>
    <div class="ml-auto pl-3">
      <div class="-mx-1.5 -my-1.5">
        <button
          (click)="onDismiss()"
          type="button"
          class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
          [ngClass]="{
            'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50': alert.type === 'success',
            'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50': alert.type === 'info',
            'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50': alert.type === 'warning',
            'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50': alert.type === 'error',
          }"
        >
          <span class="sr-only">Dismiss</span>
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

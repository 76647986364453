<div class="flex gap-4 justify-center py-4">
    <h1 class="text-2xl font-bold dark:text-white">Payment Details</h1>
</div>
<div class="payment-form w-full">
    @if (error) {
      <cap-feedback 
        type="error" 
        [message]="error">
      </cap-feedback>
    }

    <!-- Price Display -->
    <div class="mb-4 p-4 bg-gray-50 dark:bg-gray-800 rounded-md">
      <div class="flex justify-between items-center">
        <span class="text-gray-600 dark:text-gray-300">Original Price:</span>
        <span class="text-lg font-semibold dark:text-white">${{ originalPrice }}/Year</span>
      </div>
      @if(quantity > 1){
        <div class="flex justify-between items-center">
          <span class="text-gray-600 dark:text-gray-300">Number of Users:</span>
          <span class="text-lg font-semibold dark:text-white">{{ quantity }}</span>
        </div>
        <div class="flex justify-between items-center">
          <span class="text-gray-600 dark:text-gray-300">Total Price:</span>
          <span class="text-lg font-semibold dark:text-white">${{ originalPrice * quantity }}/Year</span>
        </div>
      }
      @if (discountApplied) {
        <div class="flex justify-between items-center mt-2 text-green-600 dark:text-green-400">
          <span>Discount:</span>
          <span>-${{ discount }}</span>
        </div>
        <div class="flex justify-between items-center mt-2 font-bold border-t border-gray-200 dark:border-gray-700 pt-2">
          <span class="dark:text-white">Final Price:</span>
          <span class="text-lg dark:text-white">${{ finalPrice }}/Year</span>
        </div>
      }
    </div>

    <div class="mb-4">
      <form [formGroup]="discountForm" (submit)="applyDiscount($event)" class="flex gap-2">
        <input
          type="text"
          formControlName="discountCode"
          placeholder="Enter discount code"
          class="flex-1 rounded-md border-0 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-red-600 dark:focus:ring-red-500 sm:text-sm dark:bg-gray-700"
        />
        <button
          type="submit"
          [disabled]="isLoading || !discountForm.valid"
          class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 disabled:opacity-50">
          Apply
        </button>
      </form>
    </div>

    <form (submit)="handleSubmit($event)">
      <div id="payment-element" class="dark:bg-gray-800"></div>
      
      <button 
        type="submit" 
        [disabled]="isLoading || paymentSucceeded" 
        class="mt-4 w-full py-2 px-4 rounded"
        [class]="paymentSucceeded ? 'bg-green-500 text-white' : 'bg-red-500 text-white'">
        {{ isLoading ? 'Processing...' : (paymentSucceeded ? 'Payment Successful!' : 'Pay $' + finalPrice + ' Now') }}
      </button>
    </form>
  </div>
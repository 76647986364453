import { createActionGroup, props } from '@ngrx/store';

import { IAlert } from 'src/app/core/models/alert.model';

export const AlertActions = createActionGroup({
  source: 'Alert',
  events: {
    'Add Alert': props<{ alert: Omit<IAlert, 'id'> }>(),
    'Remove Alert': props<{ id: number }>(),
  },
});

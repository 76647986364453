import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState, adapter } from './users.state';

export const selectUsersState = createFeatureSelector<UsersState>('users');

export const selectAllUsers = createSelector(
  selectUsersState,
  adapter.getSelectors().selectAll
);

export const selectPagination = createSelector(
  selectUsersState,
  (state: UsersState) => state.pagination
);

export const selectPaginatedUsers = createSelector(
  selectAllUsers,
  selectPagination,
  (users, pagination) => {
    const start = (pagination.page - 1) * pagination.limit;
    const end = start + pagination.limit;
    return users.slice(start, end);
  }
);

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { IAuditLogBase, IAuditLog } from 'src/app/core/models/audit-logs.model';
import { Pagination } from '../organizations/organizations.state';

export const AuditLogsAppActions = createActionGroup({
  source: 'AuditLogs',
  events: {
    'Get AuditLogs': props<{ page: number; limit: number }>(),
    'Search AuditLogs': props<{
      search: string;
      page: string;
      limit: string;
    }>(),
  },
});

export const AuditLogsAPIActions = createActionGroup({
  source: 'AuditLogs',
  events: {
    'Get AuditLogs Success': props<{
      auditLogs: IAuditLog[];
      pagination: {
        page: number;
        limit: number;
        totalPages: number;
        totalCount: number;
      };
    }>(),
    'Get auditLogs Failure': props<{ message: string }>(),
    'Search AuditLogs Success': props<{
      auditLogs: IAuditLog[];
      pagination: Pagination;
    }>(),
    'Search AuditLogs Failure': props<{ message: string }>(),
  },
});

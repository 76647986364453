<div
  class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
>
  <table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
    <thead class="bg-gray-50 dark:bg-gray-800">
      <tr>
        @for (col of columns$ | async; track col.key; let isFirst = $first) {
          <th
            scope="col"
            class="py-3.5 px-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 dark:text-gray-400"
            [ngClass]="{ 'sm:pl-6': isFirst }"
          >
            {{ col.label }}
          </th>
        }
        <th scope="col" class="relative py-3.5 px-3 sm:pr-6">
          <span class="sr-only">Actions</span>
        </th>
      </tr>
    </thead>
    @if (isDraggable) {
      <tbody
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        class="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900"
      >
        @if ((data$ | async)?.length !== 0) {
          @for (item of data$ | async; track item.id) {
            <tr
              cdkDrag
              [class.opacity-50]="isDragging"
              [class.shadow-md]="isDragging"
              (cdkDragStarted)="onDragStarted($event)"
              (cdkDragReleased)="onDragReleased($event)"
              class="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200 ease-in-out cursor-move active:cursor-grabbing"
            >
              <div
                class="animate-pulse bg-gray-300 dark:bg-gray-700 h-12"
                *cdkDragPlaceholder
              ></div>

              @for (
                col of columns$ | async;
                track col.key;
                let isFirst = $first
              ) {
                <td
                  class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-gray-400"
                  [ngClass]="{ 'sm:pl-6': isFirst }"
                >
                  @switch (col.type) {
                    @case ("avatar") {
                      <cap-avatar-cell [avatar]="item.avatar"></cap-avatar-cell>
                    }
                    @case ("info") {
                      <cap-name-cell [infoCell]="item.info"></cap-name-cell>
                    }
                    @case ("text") {
                      <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                    }
                    @case ("date") {
                      <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                    }
                    @case ("status") {
                      <cap-status-cell
                        [status]="item[col.key]"
                      ></cap-status-cell>
                    }
                    @case ("button") {
                      <cap-button-cell
                        [button]="item[col.key]"
                        [data]="item"
                        [type]="col.key"
                        (tabButtonClicked)="toggleEnabled($event)"
                      ></cap-button-cell>
                    }
                    @default {
                      <p></p>
                    }
                  }
                </td>
              }
              <td
                class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
              >
                <button
                  (click)="onUpdateClicked(item)"
                  class="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 mr-4"
                >
                  Edit<span class="sr-only">, {{ item.name }}</span>
                </button>
                <button
                  (click)="onDeleteClicked(item)"
                  class="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                >
                  Remove<span class="sr-only">, {{ item.name }}</span>
                </button>
              </td>
            </tr>
          }
        } @else {
          <tr>
            <td
              colspan="100%"
              class="py-6 text-center text-sm text-gray-500 dark:text-gray-400"
            >
              No data available
            </td>
          </tr>
        }
      </tbody>
    } @else {
      <tbody
        class="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900"
      >
        @if ((data$ | async)?.length !== 0) {
          @for (item of data$ | async; track item.id) {
            <tr
              class="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200 ease-in-out"
            >
              @for (
                col of columns$ | async;
                track col.key;
                let isFirst = $first
              ) {
                <td
                  class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-gray-400"
                  [ngClass]="{ 'sm:pl-6': isFirst }"
                >
                  @switch (col.type) {
                    @case ("avatar") {
                      <cap-avatar-cell [avatar]="item.avatar"></cap-avatar-cell>
                    }
                    @case ("info") {
                      <cap-name-cell [infoCell]="item.info"></cap-name-cell>
                    }
                    @case ("text") {
                      <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                    }
                    @case ("date") {
                      <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                    }
                    @case ("status") {
                      <cap-status-cell
                        [status]="item[col.key]"
                      ></cap-status-cell>
                    }
                    @case ("button") {
                      <cap-button-cell
                        [button]="item[col.key]"
                        [data]="item"
                        [type]="col.key"
                        (tabButtonClicked)="toggleEnabled($event)"
                      ></cap-button-cell>
                    }
                    @default {
                      <p></p>
                    }
                  }
                </td>
              }
              @if (displayActions) {
                <td
                  class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                >
                  <button
                    (click)="onUpdateClicked(item)"
                    class="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 mr-4"
                  >
                    Edit<span class="sr-only">, {{ item.name }}</span>
                  </button>
                  <button
                    (click)="onDeleteClicked(item)"
                    class="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                  >
                    Remove<span class="sr-only">, {{ item.name }}</span>
                  </button>
                </td>
              }
            </tr>
          }
        } @else {
          <tr>
            <td
              colspan="100%"
              class="py-6 text-center text-sm text-gray-500 dark:text-gray-400"
            >
              No data available
            </td>
          </tr>
        }
      </tbody>
    }
  </table>
</div>

import {
  ComponentRef,
  EventEmitter,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DynamicComponentLoaderService {
  constructor() {}

  loadComponent<T extends { [key: string]: any }>(
    viewContainerRef: ViewContainerRef,
    component: Type<T>,
    inputs?: Partial<T>,
    outputs?: { [K in keyof T]?: Function }
  ): ComponentRef<T> {
    const componentRef = viewContainerRef.createComponent(component);
    if (inputs) {
      Object.assign(componentRef.instance, inputs);
    }
    if (outputs) {
      Object.keys(outputs).forEach((key) => {
        // Ensure that the key is actually a key of T
        if (key in componentRef.instance) {
          const emitter: EventEmitter<any> = componentRef.instance[key];
          if (emitter instanceof EventEmitter) {
            emitter.subscribe(outputs[key]);
          }
        }
      });
    }
    return componentRef;
  }
}

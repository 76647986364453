@if (form.controls[control].touched || form.controls[control].dirty) {
  <div
    class="mt-2 rounded-md p-4"
    [class.bg-red-50]="form.invalid"
    [class.bg-green-50]="form.valid"
  >
    <ul class="list-none space-y-2">
      <li>
        @if (form.controls[control].errors?.["required"]) {
          <cap-password-control-message
            [isError]="true"
            [message]="'Password is required.'"
          />
        } @else {
          <cap-password-control-message
            [isError]="false"
            [message]="'Password is required.'"
          />
        }
      </li>
      <li>
        @if (
(          form.controls[control].errors?.["minlength"] ||
          form.controls[control].value?.length <( (minPasswordLength$ | async) ?? 8))
        ) {
          <cap-password-control-message
            [isError]="true"
            [message]="
              'Password must be at least ' +
              (minPasswordLength$ | async) +
              ' characters long.'
            "
          />
        } @else {
          <cap-password-control-message
            [isError]="false"
            [message]="
              'Password must be at least ' +
              (minPasswordLength$ | async) +
              ' characters long.'
            "
          />
        }
      </li>
      <li>
        @if (form.controls[control].errors?.["uppercaseRequired"]) {
          <cap-password-control-message
            [isError]="true"
            [message]="'Password must contain at least one uppercase letter.'"
          />
        } @else {
          <cap-password-control-message
            [isError]="false"
            [message]="'Password must contain at least one uppercase letter.'"
          />
        }
      </li>
      <li>
        @if (form.controls[control].errors?.["digitRequired"]) {
          <cap-password-control-message
            [isError]="true"
            [message]="'Password must contain at least one digit.'"
          />
        } @else {
          <cap-password-control-message
            [isError]="false"
            [message]="'Password must contain at least one digit.'"
          />
        }
      </li>
      <li>
        @if (form.controls[control].errors?.["symbolRequired"]) {
          <cap-password-control-message
            [isError]="true"
            [message]="'Password must contain at least one symbol.'"
          />
        } @else {
          <cap-password-control-message
            [isError]="false"
            [message]="'Password must contain at least one symbol.'"
          />
        }
      </li>
      <li>
        @if (form.controls[control].errors?.["controlNotFound"]) {
          <cap-password-control-message
            [isError]="true"
            [message]="'Form controls not found in the form group.'"
          />
        }
      </li>
      <li>
        @if (
          form.hasError("passwordMismatch") && control === "confirmPassword"
        ) {
          <cap-password-control-message
            [isError]="true"
            [message]="'Password confirmation must match the New Password.'"
          />
        } @else if (control === "confirmPassword") {
          <cap-password-control-message
            [isError]="false"
            [message]="'Password confirmation must match the New Password.'"
          />
        }
      </li>
      <li>
        @if (
          form.controls[control].errors?.["sameAsCurrentPassword"] &&
          control === "newPassword"
        ) {
          <cap-password-control-message
            [isError]="true"
            [message]="'New Password must be different than the current one.'"
          />
        } @else if (control === "newPassword") {
          <cap-password-control-message
            [isError]="false"
            [message]="'New Password must be different than the current one.'"
          />
        }
      </li>
    </ul>
  </div>
}

import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  of,
  share,
  take,
  switchMap,
} from 'rxjs';
import { UsersAPIActions, UsersAppActions } from './users.actions';
import { UsersService } from 'src/app/core/services/users.service';
import { handleError } from 'src/app/utils/error-handler.util';
import { PaymentService } from 'src/app/core/services/payment.service';

export const getAllUsersAccounts$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.getUsersAccounts),
      exhaustMap(({ page, limit }) =>
        usersService.getAllUsers(page, limit).pipe(
          take(1),
          map(({ users, pagination }) => {
            return UsersAPIActions.getUsersAccountsSuccess({
              users,
              pagination,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.getUsersAccountsFailure({ message }));
          }),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const getUsersAccounts$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.getAllUsersAccounts),
      exhaustMap(() =>
        usersService.getUsersAccountsNoPagination().pipe(
          take(1),
          map(({ users }) => {
            return UsersAPIActions.getAllUsersAccountsSuccess({ users });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.getAllUsersAccountsFailure({ message }));
          }),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const getUserAccountsByOrganizationId$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.getUsersAccountsByOrganizationId),
      exhaustMap(({ organizationId }) =>
        usersService.getUsersByOgranizationId(organizationId).pipe(
          take(1),
          map(({ users }) => {
            return UsersAPIActions.getUsersAccountsByOrganizationIdSuccess({
              users,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              UsersAPIActions.getUsersAccountsByOrganizationIdFailure({
                message,
                userIds: [],
              }),
            );
          }),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const searchUserAccounts$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.searchUserAccounts),
      debounceTime(300),
      exhaustMap(({ search, page, limit }) =>
        usersService.searchUsers(search, page, limit).pipe(
          take(1),
          map(({ users, pagination }) => {
            return UsersAPIActions.searchUserAccountsSuccess({
              users,
              pagination,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.searchUserAccountsFailure({ message }));
          }),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const registerNewUser$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.registerNewUser),
      exhaustMap(({ user }) =>
        usersService.registerNewUser(user).pipe(
          take(1),
          map(({ user }) => {
            return UsersAPIActions.registerNewUserSuccess({ user });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.registerNewUserFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateUserAccount$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.updateUserAccount),
      exhaustMap(({ user }) =>
        usersService.updateUser(user).pipe(
          take(1),
          map(({ user }) => {
            return UsersAPIActions.updateUserAccountSuccess({ user });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.updateUserAccountFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateUserOrganization$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.updateUserOrganization),
      exhaustMap(({ userId, orgId }) =>
        usersService.updateUserOrganization({ userId, orgId }).pipe(
          take(1),
          map(({ user }) => {
            return UsersAPIActions.updateUserOrganizationSuccess({ user });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              UsersAPIActions.updateUserOrganizationFailure({ message }),
            );
          }),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const removeUserAccount$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.removeUserAccount),
      exhaustMap(({ id }) =>
        usersService.removeUser(id).pipe(
          take(1),
          map(({ userId, message }) => {
            return UsersAPIActions.removeUserAccountSuccess({
              userId,
              message,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.removeUserAccountFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateUserStatus$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.updateUserAccountStatus),
      exhaustMap(({ userId, isActive }) =>
        usersService.updateUserStatus({ userId, isActive }).pipe(
          take(1),
          map(({ user }) =>
            UsersAPIActions.updateUserAccountStatusSuccess({ user }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              UsersAPIActions.updateUserAccountStatusFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const importUsers$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.importUsers),
      exhaustMap(({ jsonData, organizationId, subscriptionId, fullyDiscounted, discountCode, profileId }) =>
        usersService.importUsers(jsonData, organizationId, subscriptionId, fullyDiscounted, discountCode, profileId).pipe(
          take(1),
          map(({ users, subscriptionId }) => {
            usersService.linkUsersToSubscription(subscriptionId, users.map(user => user._id)).subscribe();
            return UsersAPIActions.importUsersSuccess({ users });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.importUsersFailure({ message }));
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const cancelSubscription$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService), paymentService = inject(PaymentService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.cancelSubscription),
      exhaustMap(({ userId }) =>
        paymentService.disableUserSubscriptionRenewal(userId).pipe(
          take(1),
          map(({ userId, success, status, newQuantity }) => {
            return UsersAPIActions.cancelSubscriptionSuccess({ userId, success, status, newQuantity });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.cancelSubscriptionFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const enableUserSubscriptionRenewal$ = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService), paymentService = inject(PaymentService)) => {
    return actions$.pipe(
      ofType(UsersAppActions.enableUserSubscriptionRenewal),
      exhaustMap(({ userId, subscriptionId }) =>
        paymentService.enableUserSubscriptionRenewal(userId, subscriptionId).pipe(
          take(1),
          map(({ userId, success, status, newQuantity }) => {
            return UsersAPIActions.enableUserSubscriptionRenewalSuccess({ userId, success, status, newQuantity });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(UsersAPIActions.enableUserSubscriptionRenewalFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

import { VerticalStepNavigatorStore } from './vertical-step-navigator.store';
import { IVStep } from 'src/app/core/models/vertical-step.model';

@Component({
  selector: 'cap-vertical-step-navigator',
  standalone: true,
  imports: [CommonModule],
  providers: [VerticalStepNavigatorStore],
  templateUrl: './vertical-step-navigator.component.html',
  styleUrl: './vertical-step-navigator.component.scss',
  animations: [
    trigger('stepTransition', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class VerticalStepNavigatorComponent implements OnInit, OnDestroy {
  private readonly componentStore = inject(VerticalStepNavigatorStore);
  private subs: Subscription[] = [];

  paddingClass: string = '30%';

  @Input() set steps(value: Omit<IVStep, 'id' | 'status'>[]) {
    this.componentStore.setSteps(value);
  }

  @Input() eventEmitter!: EventEmitter<'next' | 'previous'>;
  @Output() stepChanged = new EventEmitter<number>();

  readonly steps$ = this.componentStore.steps$;
  readonly currentStep$ = this.componentStore.currentStep$;

  ngOnInit(): void {
    this.subscribeToParentEmitter();

    const sub = this.steps$.subscribe((steps) => {
      if (steps) {
        const stepsCount = steps.length;
        const calculatedPadding = Math.round(100 / stepsCount);
        this.paddingClass = `${calculatedPadding}%`;
      }
    });

    this.subs.push(sub);
  }

  onNext() {
    this.componentStore.nextStep();
  }

  onPrevious() {
    this.componentStore.previousStep();
  }

  subscribeToParentEmitter() {
    const sub = this.eventEmitter.subscribe((event) => {
      if (event === 'next') {
        this.onNext();
      } else if (event === 'previous') {
        this.onPrevious();
      }
    });
    this.subs.push(sub);
  }

  onStepClick(step: IVStep) {
    this.componentStore.setStepAsCurrent(step);
    this.stepChanged.emit(step.id);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

<div class="flex flex-col px-4 py-6 gap-y-5">
  <form class="md:col-span-2" [formGroup]="passwordForm">
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
      <div class="col-span-full">
        <label
          for="new-password"
          class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
          >New password</label
        >
        <div class="relative mt-2">
          <input
            id="new-password"
            name="new_password"
            formControlName="newPassword"
            [type]="(isNpVisible$ | async) ? 'text' : 'password'"
            autocomplete="new-password"
            class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
          />
          <!-- Icon to toggle password visibility -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            [title]="(isNpVisible$ | async) ? 'Hide password' : 'Show password'"
            (click)="togglePasswordVisibility('np')"
          >
            <span class="material-symbols-outlined text-gray-400">
              {{ (isNpVisible$ | async) ? "visibility_off" : "visibility" }}
            </span>
          </button>
        </div>
        <!-- New Password error message -->
        <cap-password-control-message-container
          [form]="passwordForm"
          [control]="'newPassword'"
        ></cap-password-control-message-container>
      </div>

      <div class="col-span-full">
        <label
          for="confirm-password"
          class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
          >Confirm password</label
        >
        <div class="relative mt-2">
          <input
            id="confirm-password"
            name="confirm_password"
            formControlName="confirmPassword"
            [type]="(isCnpVisible$ | async) ? 'text' : 'password'"
            autocomplete="new-password"
            class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
          />
          <!-- Icon to toggle password visibility -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            [title]="
              (isCnpVisible$ | async) ? 'Hide password' : 'Show password'
            "
            (click)="togglePasswordVisibility('cnp')"
          >
            <span class="material-symbols-outlined text-gray-400">
              {{ (isCnpVisible$ | async) ? "visibility_off" : "visibility" }}
            </span>
          </button>
        </div>
        <!-- Confirm Password error message -->
        <cap-password-control-message-container
          [form]="passwordForm"
          [control]="'confirmPassword'"
        ></cap-password-control-message-container>
      </div>
    </div>
  </form>
</div>

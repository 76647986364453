<cap-modal-container [modalID]="modalId">
  <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
    Verify your OTP
  </p>

  <div class="w-full flex flex-col justify-center content-between">
    <!-- TWO FACTOR AUTHENTICATION (2FA) VERIFICATION COMPONENT -->
    @if (formErrorMessage$ | async) {
      <cap-feedback
        type="error"
        [message]="formErrorMessage$ | async"
      ></cap-feedback>
    }
    @if (loading$ | async) {
      <div
        class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
      >
        <cap-spinner class="z-50" [size]="16"></cap-spinner>
      </div>
    }
    <cap-two-factor-auth-verificaiton
      (sendTotpToken)="setTotpToken($event)"
    ></cap-two-factor-auth-verificaiton>
  </div>
  <div class="flex justify-end">
    <button
      type="button"
      (click)="onReset2FAOTPClicked()"
      class="text-sm font-semibold leading-6 text-primary-600 hover:text-primary-500"
    >
      New Device?
    </button>
  </div>
</cap-modal-container>

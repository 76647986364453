import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';

import { DualPickListComponent } from 'src/app/shared/dual-pick-list/dual-pick-list.component';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { ActionItemServicesModalStore } from './action-item-services-modal.store';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { FeedbackComponent } from 'src/app/shared/feedback/feedback.component';
import { selectAll as selectAllServices } from 'src/app/state/services/services.state';
import { selectActionItemById } from 'src/app/state/action-items/action-items.state';
import { ServicesAppActions } from 'src/app/state/services/services.actions';
import { IService } from 'src/app/core/models/services.model';
import {
  ActionItemsAPIActions,
  ActionItemsAppActions,
} from 'src/app/state/action-items/action-items.actions';

@Component({
  selector: 'cap-action-item-services-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalContainerComponent,
    DualPickListComponent,
    SpinnerComponent,
    FeedbackComponent,
  ],
  providers: [ActionItemServicesModalStore],
  templateUrl: './action-item-services-modal.component.html',
  styleUrl: './action-item-services-modal.component.scss',
})
export class ActionItemServicesModalComponent implements OnInit, OnDestroy {
  @Input() actionItemId = '';

  destroy$ = new Subject<void>();

  modalMetadata = {
    modalID: 'action-item-services',
    modalWidth: 'sm:max-w-6xl',
    title: 'Add Services To Action Items',
  };

  private store = inject(Store);
  private componentStore = inject(ActionItemServicesModalStore);
  private actions$ = inject(Actions);

  servicesList$: Observable<IService[]> = this.store.select(selectAllServices);
  actionItemServiceIdsList$: Observable<string[]> = of([]);
  sourceServices$: Observable<IService[]> = of([]);
  targetServices$: Observable<IService[]> = of([]);

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;

  ngOnInit(): void {
    this.servicesList$
      .pipe(
        tap((services) => {
          if (services.length === 0) {
            this.store.dispatch(ServicesAppActions.getServices({}));
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.actionItemServiceIdsList$ = this.store
      .select(selectActionItemById(this.actionItemId))
      .pipe(map((actionItem) => actionItem?.services ?? []));

    this.sourceServices$ = combineLatest([
      this.servicesList$,
      this.actionItemServiceIdsList$,
    ]).pipe(
      map(([allServices, serviceIds]) =>
        allServices.filter((service) => !serviceIds.includes(service._id)),
      ),
    );

    this.targetServices$ = combineLatest([
      this.servicesList$,
      this.actionItemServiceIdsList$,
    ]).pipe(
      map(([allServices, serviceIds]) =>
        allServices.filter((service) => serviceIds.includes(service._id)),
      ),
    );

    this.actions$
      .pipe(
        ofType(ActionItemsAPIActions.updateActionItemServicesSuccess),
        tap(() => this.closeModal()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onServicesChange(changeObj: { source: IService[]; target: IService[] }) {
    this.componentStore.setServices(changeObj.target);
  }

  updateActionItemServices() {
    this.componentStore.services$
      .pipe(
        take(1),
        map((services) => services.map((service) => service._id)),
        tap((services) => {
          this.store.dispatch(
            ActionItemsAppActions.updateActionItemServices({
              id: this.actionItemId,
              services,
            }),
          );
        }),
      )
      .subscribe();
  }

  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.modalMetadata.modalID }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

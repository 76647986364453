import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepNavigationCommunicationService {
  constructor() {}

  private setNewPasswordFormSubject = new Subject<{
    credentials: {
      email: string;
      newPassword: string;
      confirmPassword: string;
    };
    formValidity: boolean;
  }>();

  private setIsEulaSubject = new Subject<{ isEula: boolean }>();

  readonly isEula$ = this.setIsEulaSubject.asObservable();

  readonly setNewPasswordFormData$ =
    this.setNewPasswordFormSubject.asObservable();

  emitIsEulaData(data: { isEula: boolean }) {
    this.setIsEulaSubject.next(data);
  }

  emitNewPasswordCredentials(data: {
    credentials: {
      email: string;
      newPassword: string;
      confirmPassword: string;
    };
    formValidity: boolean;
  }) {
    this.setNewPasswordFormSubject.next(data);
  }
}

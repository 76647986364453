import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { EditableTableComponent } from 'src/app/shared/editable-table/editable-table.component';
import { FeedbackComponent } from 'src/app/shared/feedback/feedback.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import {
  selectAll as selectServiceSources,
  selectTotal as selectServiceSourcesCount,
} from 'src/app/state/services/service-sources.state';
import { ServiceSourcesListStore } from './service-sources.store';
import {
  IServiceSourceBase,
  IServiceSource,
} from 'src/app/core/models/services.model';
import { ServicesAppActions } from 'src/app/state/services/services.actions';
import { selectSourcesPagination } from '../../../state/services/services.selectors';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';

@Component({
  selector: 'cap-service-sources-list',
  standalone: true,
  imports: [
    CommonModule,
    EditableTableComponent,
    ConfirmModalComponent,
    FeedbackComponent,
    SpinnerComponent,
    PaginationComponent,
  ],
  providers: [ServiceSourcesListStore],
  templateUrl: './service-sources-list.component.html',
  styleUrl: './service-sources-list.component.scss',
})
export class ServiceSourcesListComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private componentStore = inject(ServiceSourcesListStore);
  private destroy$ = new Subject<void>();

  readonly pagination$ = this.store.select(selectSourcesPagination);

  page = 1;
  pageSize = 10;
  totalPages = 1;
  totalCount = 0;

  readonly confirmModalData = {
    title: 'Delete question service source',
    message: 'Are you sure you want to delete this service source?',
  };

  serviceSourcesTableMetaData: IEditableTableMetadata = {
    title: 'Service Sources',
    entityName: 'Service Source',
    bgColor: 'dark:bg-gray-900',
    accordion: false,
  };

  serviceSources$: Observable<IServiceSource[]> =
    this.store.select(selectServiceSources);

  private serviceSourceDropped: IServiceSource | null = null;

  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessage$;
  listLoading$ = this.componentStore.isListLoading$;

  serviceSourceTableColumns: IEditableColumn[] = [
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: false,
    },
    {
      key: 'description',
      label: 'Description',
      type: 'description',
      sortable: false,
    },
    {
      key: 'url',
      label: 'Link',
      type: 'text',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      type: 'date',
      sortable: false,
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      type: 'date',
      sortable: false,
    },
  ];

  ngOnInit(): void {
    this.store.dispatch(
      ServicesAppActions.getServiceSources({
        page: this.page,
        pageSize: this.pageSize,
      }),
    );
    this.store
      .select(selectServiceSourcesCount)
      .pipe(
        tap((count) => {
          if (!count) {
            this.store.dispatch(
              ServicesAppActions.getServiceSources({
                page: 1,
                pageSize: 10,
              }),
            );
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.pagination$.subscribe((pagination) => {
      this.page = pagination.page;
      this.pageSize = pagination.pageSize;
      this.totalPages = pagination.totalPages;
      this.totalCount = pagination.totalCount;
    });
  }

  onServiceSourceCreated(newSource: any) {
    this.store.dispatch(
      ServicesAppActions.createServiceSource({
        serviceSource: <IServiceSourceBase>newSource,
      }),
    );
  }

  onServiceSourceUpdated(source: any) {
    this.store.dispatch(
      ServicesAppActions.updateServiceSource({
        serviceSource: <IServiceSource>source,
      }),
    );
  }

  openConfirmationModal(source: any) {
    this.serviceSourceDropped = source;
    this.store.dispatch(
      ModalActions.open({ id: 'confirm-removing-service-source' }),
    );
  }

  removeServiceSource() {
    if (this.serviceSourceDropped) {
      this.store.dispatch(
        ServicesAppActions.removeServiceSource({
          id: this.serviceSourceDropped._id,
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPageChange($event: number) {
    this.store.dispatch(
      ServicesAppActions.getServiceSources({
        page: $event,
        pageSize: this.pageSize,
      }),
    );
  }
}

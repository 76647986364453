<div class="px-4 py-6 space-y-5">
  <div class="space-y-3">
    <p
      class="border-b border-gray-200 dark:border-gray-800 text-lg font-medium text-primary-600 dark:text-primary-500"
    >
      Configuring Google Authenticator or Authy
    </p>
    <ol class="list-decimal list-inside text-gray-900 dark:text-gray-50">
      <li>
        Install Google Authenticator (IOS - Android) or Authy (IOS - Android).
      </li>
      <li>In the authenticator app select `+` icon.</li>
      <li>
        Select `Scan a barcode (or QR cose)` and use the phone's camera to scan
        this barcode.
      </li>
    </ol>
  </div>
  <div class="space-y-3">
    <p
      class="border-b border-gray-200 dark:border-gray-800 text-lg font-medium text-primary-600 dark:text-primary-500"
    >
      Scan QR Code
    </p>
    <!-- HERE GOES THE QR CODE -->
    @if(qrCode$ | async) {
    <img [src]="qrCode$ | async" alt="qr-code" class="w-2/3 h-fit mx-auto" />
    } @else {
    <p class="text-gray-900 dark:text-gray-50">QR Code not available</p>
    }
  </div>

  <div class="space-y-3">
    <p
      class="border-b border-gray-200 dark:border-gray-800 text-lg font-medium text-primary-600 dark:text-primary-500"
    >
      Or Enter Code into Your App
    </p>
    <!-- HERE GOES THE CODE -->
    <p class="text-gray-900 dark:text-gray-50">
      <span class="font-semibold text-primary-600 dark:text-primary-500"
        >Secret Key:</span
      >
      {{ (user$ | async)?.otpBase32 }} (Base32 encoded)
    </p>
  </div>
</div>

import { ISettings } from '../../core/models/settings.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { SettingsApiActions } from './settings.actions';

export interface SettingsState {
  errorMessage: string | null;
  logoutTime: number;
  minimumPasswordLength: number;
  maxLoginAttempts: number;
  windowMs: number;
  blockDuration: number;
}

export const initialState: SettingsState = {
  errorMessage: null,
  logoutTime: 30,
  minimumPasswordLength: 12,
  maxLoginAttempts: 5,
  windowMs: 15,
  blockDuration: 60,
};

export const settingsReducer = createReducer(
  initialState,
  on(SettingsApiActions.getSettingsSuccess, (state, { settings }) => ({
    ...state,
    logoutTime: settings.logoutTime,
    minimumPasswordLength: settings.minimumPasswordLength,
    maxLoginAttempts: settings.maxLoginAttempts,
    windowMs: settings.windowMs,
    blockDuration: settings.blockDuration,
  })),
  on(SettingsApiActions.getSettingsFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(SettingsApiActions.updateSettingsSuccess, (state, { settings }) => {
    return {
      ...state,
      logoutTime: settings.logoutTime,
      minimumPasswordLength: settings.minimumPasswordLength,
      maxLoginAttempts: settings.maxLoginAttempts,
      windowMs: settings.windowMs,
      blockDuration: settings.blockDuration,
    };
  }),
  on(SettingsApiActions.updateSettingsFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
);

export const settingsFeature = createFeature({
  name: 'settings',
  reducer: settingsReducer,
});

export const { name, reducer, selectSettingsState } = settingsFeature;

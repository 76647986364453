import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Subscription, tap } from 'rxjs';
import { Store } from '@ngrx/store';

import { AlertActions } from '../state/alert/alert.actions';
import {
  selectErrorMessage,
  selectIsAuthenticated,
} from '../state/auth/auth.state';
import { AuthAppActions } from '../state/auth/auth.actions';
import { ForgotPasswordPageStore } from './forgot-password-page.store';

@Component({
  selector: 'cap-forgot-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterLink],
  providers: [ForgotPasswordPageStore],
  templateUrl: './forgot-password-page.component.html',
  styleUrl: './forgot-password-page.component.scss',
  animations: [
    trigger('fadeSlideInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('300ms ease-out'),
      ]),
      transition(':leave', [
        animate(
          '300ms ease-in',
          style({ opacity: 0, transform: 'translateY(100%)' })
        ),
      ]),
    ]),
    trigger('growAndCenterLogo', [
      state('normal', style({ transform: 'scale(1)', top: 'initial' })),
      state('centered', style({ transform: 'scale(2)', top: '50%' })),
      transition('normal => centered', animate('500ms ease-in')),
    ]),
  ],
})
export class ForgotPasswordPageComponent implements OnInit, OnDestroy {
  public form!: FormGroup;

  private subs: Subscription[] = [];
  private sotre = inject(Store);
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private readonly componentStore = inject(ForgotPasswordPageStore);

  public readonly formError$ = this.sotre.select(selectErrorMessage);
  public readonly loading$ = this.componentStore.isLoading$;

  public readonly formSubmissionSuccess$ =
    this.componentStore.formSubmissionSuccess$;

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    const sub = this.sotre
      .select(selectIsAuthenticated)
      .pipe(
        tap((isAuthenticated) => {
          if (isAuthenticated) {
            this.router.navigate(['/']);
          }
        })
      )
      .subscribe();

    this.subs.push(sub);
  }

  onFormSubmit() {
    if (this.form.invalid) {
      this.sotre.dispatch(
        AlertActions.addAlert({
          alert: {
            type: 'warning',
            message: 'Please enter a valid email',
          },
        })
      );
      return;
    }
    const credentials = this.form.value;
    this.sotre.dispatch(AuthAppActions.forgotPassword({ ...credentials }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

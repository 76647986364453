// Angular imports
import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';

// Third-party library imports
import { Store } from '@ngrx/store';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  catchError,
  combineLatest,
  debounceTime,
  filter,
  finalize,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

// PrimeNG imports
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { KnobModule } from 'primeng/knob';
import { CarouselModule } from 'primeng/carousel';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { OverlayModule } from 'primeng/overlay';
import { DropdownModule } from 'primeng/dropdown';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

// Application-specific imports
import { IAssessment } from '../../core/models/assessments.model';
import { ICategory } from '../../core/models/categories.model';
import { AssessmentsAppActions } from '../../state/assessments/assessments.actions';
import { QuestionsService } from '../../core/services/questions.service';
import { DashboardPageStore } from '../dashboard-page.store';
import {
  convertUserRoleName,
  convertWeight,
} from '../../utils/entity-formatting.util';
import { IQuestion } from '../../core/models/questions.model';
import { AssessmentPageStore } from '../../assessment-page/assessment-page.store';
import { IUser } from '../../core/models/user.model';
import { selectUser } from '../../state/auth/auth.state';
import { SharedAssessmentScrollerComponent } from '../../shared/shared-assessment-scroller/shared-assessment-scroller.component';
import { SharedHeaderDataService } from '../../core/services/shared-header-data.service';

// Other imports
import 'chartjs-adapter-date-fns';
@Component({
  selector: 'cap-dashboardp-page-list',
  standalone: true,
  imports: [
    DataViewModule,
    NgClass,
    NgForOf,
    TagModule,
    DividerModule,
    ScrollPanelModule,
    AsyncPipe,
    KnobModule,
    FormsModule,
    ChartModule,
    TableModule,
    CarouselModule,
    NgIf,
    TabViewModule,
    CheckboxModule,
    DialogModule,
    ProgressSpinnerModule,
    SharedAssessmentScrollerComponent,
    OverlayModule,
    DropdownModule,
  ],
  templateUrl: './dashboard-page-list.component.html',
  styleUrl: './dashboard-page-list.component.scss',
})
export class DashboardPageListComponent
  implements OnInit, OnChanges, OnDestroy
{
  private store = inject(Store);
  private questionsService = inject(QuestionsService);
  private destroy$ = new Subject<void>();
  private dashboardPageStore = inject(DashboardPageStore);
  private assessmentPageStore = inject(AssessmentPageStore);
  private cdRef = inject(ChangeDetectorRef);
  private currentUser: IUser | undefined;
  private sharedHeaderDataService = inject(SharedHeaderDataService);
  protected showStats = false;
  private fullScoreHistory: any[] = [{}];
  private categoryChange$ = new Subject<{
    assessment: IAssessment;
    category: ICategory;
  }>();
  protected showGraphs = signal(true);
  @Input() entity: {
    type: 'individual' | 'organization';
    entityId: string;
    profileId: string;
  } | null = null;

  currentSelectedCategory: any;
  displayConfirmation: boolean = false;
  selectedProduct: any;
  showUncompletedItems: boolean = false;
  activeIndexTab: number = 1;
  selectedActionItems: any[] = [];
  numberOfDataPoints = signal(-20);
  dataPointOptions: any[] = [
    {
      name: '20',
      value: 20,
    },
    {
      name: '50',
      value: 50,
    },
    {
      name: '100',
      value: 100,
    },
    {
      name: 'All',
      value: 0,
    },
  ];

  currentAssessmentStats: {
    totalQuestions: number;
    totalActionItems: number;
    totalCompletedActionItems: number;
    actionItemsGraphData: any;
    questionsGraphData: any;
    unCompletedItemsList: any;
    completedItemsList: any;
    scoreProgressionData: any;
    scoreHistory: any;
    questionsWithId: any;
  } = {} as any;

  options: any = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--text-color',
          ),
        },
      },
      datalabels: {
        color: 'white',
        display: true,
        formatter: (value: any, context: any) => {
          return value; // Display the value directly on the chart
        },
      },
    },
  };
  scoreProgressionChartOptions: any = {
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 99,
      },
    },
    plugins: {
      datalabels: {
        color: 'black',
        display: true,
        formatter: (value: any, context: any) => {
          return ''; // Hide data labels on the points
        },
      },
      tooltip: {
        callbacks: {
          label: (context: { dataIndex: any }) => {
            const dataIndex = context.dataIndex;
            const scoreHistoryEntry =
              this.currentAssessmentStats.scoreHistory[dataIndex];
            return `${scoreHistoryEntry.userName}`;
          },
        },
      },
    },
  };
  selectedLabel: 'Completed' | 'Remaining' = 'Remaining';
  hoveredActionItem: any = null;
  hoveredQuestion: string = '';
  protected isTableLoading: boolean = false;

  ngOnInit(): void {
    this.store
      .select(selectUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) this.currentUser = user;
      }),
      combineLatest([
        this.sharedHeaderDataService.selectedCategory$,
        this.sharedHeaderDataService.selectedEntity$,
      ])
        .pipe(
          takeUntil(this.destroy$),
          filter(([category, entity]) => !!category && !!entity),
          map(([category, entity]) => ({
            assessment: category.assessment,
            category: category.category,
          })),
          tap(() => (this.showStats = false)),
        )
        .subscribe((assessmentCategory) =>
          this.categoryChange$.next(assessmentCategory),
        );

    this.categoryChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        switchMap((assessmentCategory) => {
          return this.extractAssessmentCategoryInfo(assessmentCategory).pipe(
            finalize(() => {}),
          );
        }),
      )
      .subscribe(() => {
        this.showStats = true;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entity'] && !changes['entity'].isFirstChange()) {
    }
  }

  onHoverActionItem(actionItem: any) {
    this.hoveredActionItem = actionItem;
    this.hoveredQuestion = this.currentAssessmentStats.questionsWithId.filter(
      (question: any) => question[actionItem.questionId],
    )[0][actionItem.questionId].questionText;
  }

  onLeaveActionItem() {
    this.hoveredActionItem = null;
    this.hoveredQuestion = '';
  }

  extractAssessmentCategoryInfo(assessmentCategory: {
    assessment: IAssessment;
    category: ICategory;
  }): Observable<void> {
    this.dashboardPageStore.toggleAssessmentStats(false);
    this.assessmentPageStore.setSelectedAssessmentCategory(assessmentCategory);
    this.currentSelectedCategory = assessmentCategory;

    return this.questionsService
      .getQuestionsWithOptionsAndActions(assessmentCategory.category._id)
      .pipe(
        debounceTime(500),
        tap((questions: any) => {
          this.showUncompletedItems = false;
          let totalQuestions = 0;
          let totalActionItems = 0;
          let totalCompletedActionItems = 0;
          let questionsWithId: { [key: string]: IQuestion }[] = [];
          let unCompletedItemsList: any[] = [];
          let completedItemsList: any[] = [];

          questionsWithId = questions.questions.map((question: any) => ({
            [question._id]: question,
          }));
          totalQuestions = questions.questions.length;
          totalActionItems = questions.questions.reduce(
            (total: number, question: any) =>
              total + question.actionItems.length,
            0,
          );
          totalCompletedActionItems =
            assessmentCategory.assessment.questionsResponses.reduce(
              (total: number, response: any) =>
                total + response.actionItemsIds.length,
              0,
            );

          // Extracting uncompleted and completed action items
          questions.questions.forEach((question: any) => {
            question.actionItems.forEach((actionItem: any) => {
              if (
                assessmentCategory.assessment.questionsResponses.some(
                  (response: any) =>
                    response.actionItemsIds.includes(actionItem._id),
                )
              ) {
                completedItemsList.push(actionItem);
              } else {
                unCompletedItemsList.push(actionItem);
              }
            });
          });
          this.fullScoreHistory = assessmentCategory.assessment.scoreHistory;
          const scoreHistory =
            assessmentCategory.assessment.scoreHistory.slice(
              this.numberOfDataPoints(),
            ) || [];
          const scoreColors = scoreHistory.map((entry) =>
            this.getScoreColor(entry.score),
          );
          const scoreProgressionData = {
            labels: scoreHistory.map((entry: any) =>
              new Date(entry.timestamp).toLocaleDateString(),
            ),
            datasets: [
              {
                label: 'History',
                data: scoreHistory.map((entry: any) => entry.score),
                fill: false,
                borderColor: '#4bc0c0',
                pointBackgroundColor: scoreColors,
                pointBorderColor: scoreColors,
              },
            ],
          };

          this.currentAssessmentStats = {
            totalQuestions: totalQuestions,
            totalActionItems: totalActionItems,
            totalCompletedActionItems: totalCompletedActionItems,
            actionItemsGraphData: {
              labels: ['Completed', 'Remaining'],
              datasets: [
                {
                  data: [
                    totalCompletedActionItems,
                    totalActionItems - totalCompletedActionItems,
                  ],
                  backgroundColor: ['#7300ff', '#a2a19f'],
                },
              ],
            },
            questionsGraphData: {
              labels: ['Answered', 'Remaining'],
              datasets: [
                {
                  data: [
                    assessmentCategory.assessment.questionsResponses.filter(
                      (response: any) => response.optionId !== null,
                    ).length,
                    totalQuestions -
                      assessmentCategory.assessment.questionsResponses.filter(
                        (response: any) => response.optionId !== null,
                      ).length,
                  ],
                  backgroundColor: ['#0a1688', '#939191'],
                },
              ],
            },
            scoreProgressionData: scoreProgressionData,
            scoreHistory: scoreHistory,
            unCompletedItemsList: unCompletedItemsList,
            completedItemsList: completedItemsList,
            questionsWithId: questionsWithId,
          };
          this.showUncompletedItems = unCompletedItemsList.length > 0;
          if (this.activeIndexTab === 0) {
            this.selectedLabel = 'Completed';
            this.selectedActionItems = completedItemsList;
          } else {
            this.selectedLabel = 'Remaining';
            this.selectedActionItems = unCompletedItemsList;
          }
          this.cdRef.detectChanges(); // Ensure UI refresh
        }),
        map(() => void 0),
        catchError((error) => {
          console.error('Error extracting assessment category info:', error);
          this.dashboardPageStore.toggleAssessmentStats(false);
          return of(void 0);
        }),
      );
  }

  showConfirmationDialog(product: any) {
    this.selectedProduct = product;
    this.displayConfirmation = true;
  }

  confirmChangeStatus() {
    this.updateAssessmentStatus(this.selectedProduct);
    this.displayConfirmation = false;
    this.showStats = false;
    this.isTableLoading = true;
  }

  cancelChangeStatus() {
    this.selectedProduct = null;
    this.displayConfirmation = false;
  }

  updateAssessmentStatus(product: any) {
    const updatedResponses =
      this.currentSelectedCategory.assessment.questionsResponses.map(
        (response: any) => {
          if (response.questionId === product.questionId) {
            const actionItemsIds = response.actionItemsIds.includes(product._id)
              ? response.actionItemsIds.filter((id: any) => id !== product._id)
              : [...response.actionItemsIds, product._id];
            return { ...response, actionItemsIds };
          }
          return response;
        },
      );

    // Fetch questions and action items, then calculate the new score
    this.questionsService
      .getQuestionsWithOptionsAndActions(
        this.currentSelectedCategory.category._id,
      )
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((data: any) => {
        const questions = data.questions;
        const newScore = this.calculateCategoryScore(
          questions,
          updatedResponses,
        );
        const newScoreHistoryEntry = {
          timestamp: new Date(),
          score: newScore,
          userName: `${this.currentUser?.firstName} ${this.currentUser?.lastName}`,
          role: this.currentUser?.role.name,
        };

        // Update the score history
        const updatedScoreHistory = [
          ...this.currentSelectedCategory.assessment.scoreHistory,
          newScoreHistoryEntry,
        ];

        const updatedAssessment = {
          ...this.currentSelectedCategory.assessment,
          questionsResponses: updatedResponses,
          scoreHistory: updatedScoreHistory,
          score: newScore, // Add this line to set the new score in the assessment
        };

        this.store.dispatch(
          AssessmentsAppActions.updateAssessment({
            assessmentId: this.currentSelectedCategory.assessment._id,
            questionsResponses: updatedResponses,
          }),
        );

        this.currentSelectedCategory.assessment = updatedAssessment;
        // this.updateLocalAssessmentStats(updatedAssessment, questions);
        this.isTableLoading = false;
      });
  }
  private calculateCategoryScore(
    questions: any[],
    questionsResponses: any,
  ): number {
    let totalQuestionScores = 0;
    let maxPossibleScore = 0;

    questions.forEach((question: any) => {
      const totalActionWeightSum = question.actionItems.reduce(
        (sum: number, item: any) => sum + item.weight,
        0,
      );
      maxPossibleScore += question.weight * totalActionWeightSum;
    });

    for (const response of questionsResponses) {
      const question = questions.find((q) => q._id === response.questionId);
      if (!question) continue;

      const completedActionWeightSum = response.actionItemsIds
        .map((id: string) =>
          question.actionItems.find((item: any) => item._id === id),
        )
        .filter((item: any) => item)
        .reduce((sum: number, item: any) => sum + item.weight, 0);

      const questionScore = question.weight * completedActionWeightSum;
      totalQuestionScores += questionScore;
    }

    const categoryScore =
      maxPossibleScore > 0 ? (totalQuestionScores / maxPossibleScore) * 99 : 0;

    return categoryScore >= 0 ? categoryScore : 0;
  }

  private updateSelectedActionItems() {
    if (this.selectedLabel === 'Completed') {
      this.selectedActionItems = this.currentAssessmentStats.completedItemsList;
    } else {
      this.selectedActionItems =
        this.currentAssessmentStats.unCompletedItemsList;
    }
  }

  getScoreColor(assessmentScore: number) {
    if (assessmentScore === 0) {
      return '#8c908d';
    } else if (assessmentScore < 33) {
      return '#DC3444';
    } else if (assessmentScore < 66) {
      return '#FFC007';
    } else {
      return '#28A745';
    }
  }

  onDataSelectActionItem(event: any) {
    this.activeIndexTab = 1;
    const selectedIndex = event.element.index;
    this.selectedLabel =
      this.currentAssessmentStats.actionItemsGraphData.labels[selectedIndex];
    if (this.selectedLabel === 'Completed') {
      this.selectedActionItems = this.currentAssessmentStats.completedItemsList;
    } else {
      this.selectedActionItems =
        this.currentAssessmentStats.unCompletedItemsList;
    }
  }

  onSelectDataPoints(value: any) {
    this.numberOfDataPoints.set(value.value);
    this.updateChartData();
  }

  updateChartData() {
    if (this.currentAssessmentStats && this.fullScoreHistory) {
      const limit = this.numberOfDataPoints();
      const scoreHistory =
        limit === 0
          ? this.fullScoreHistory
          : this.fullScoreHistory.slice(-limit);

      const scoreColors = scoreHistory.map((entry: { score: number }) =>
        this.getScoreColor(entry.score),
      );

      this.currentAssessmentStats.scoreProgressionData = {
        labels: scoreHistory.map((entry: any) =>
          new Date(entry.timestamp).toLocaleDateString(),
        ),
        datasets: [
          {
            label: 'History',
            data: scoreHistory.map((entry: any) => entry.score),
            fill: false,
            borderColor: '#4bc0c0',
            pointBackgroundColor: scoreColors,
            pointBorderColor: scoreColors,
          },
        ],
      };

      // Force change detection
      this.cdRef.detectChanges();
    }
  }

  toggleGraphs() {
    this.showGraphs.set(!this.showGraphs())
  }
  protected readonly convertUserRoleName = convertUserRoleName;

  protected readonly convertWeight = convertWeight;
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.categoryChange$.complete();
  }
}

Chart.register(ChartDataLabels);

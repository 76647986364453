import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

export interface ConfirmModalState {
  title: string;
  message: string;
}

@Injectable()
export class ConfirmModalStore extends ComponentStore<ConfirmModalState> {
  constructor() {
    super({ title: '', message: '' });
  }

  readonly title$ = this.select((state) => state.title);
  readonly message$ = this.select((state) => state.message);

  readonly setTitle = this.updater((state, title: string) => ({
    ...state,
    title,
  }));

  readonly setMessage = this.updater((state, message: string) => ({
    ...state,
    message,
  }));
}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { ProfilesService } from '../../core/services/profiles.service';
import { ProfilesApiActions, ProfilesAppActions } from './profiles.actions';
import {
  catchError,
  debounce,
  debounceTime,
  exhaustMap,
  map,
  of,
  share,
  take,
} from 'rxjs';
import { handleError } from '../../utils/error-handler.util';
import { Action } from 'rxjs/internal/scheduler/Action';

export const getProfiles$ = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService)) => {
    return actions$.pipe(
      ofType(ProfilesAppActions.getProfiles),
      exhaustMap(({}) =>
        profilesService.getProfiles().pipe(
          take(1),
          map(({ profiles }) => {
            return ProfilesApiActions.getProfilesSuccess({ profiles });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ProfilesApiActions.getProfilesFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const searchProfiles$ = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService)) => {
    return actions$.pipe(
      ofType(ProfilesAppActions.searchProfiles),
      debounceTime(300),
      exhaustMap(({ search, profileType }) =>
        profilesService.searchProfiles(search, profileType).pipe(
          take(1),
          map(({ profiles }) => {
            return ProfilesApiActions.searchProfilesSuccess({ profiles });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ProfilesApiActions.searchProfilesFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const getIndividualProfiles$ = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService)) => {
    return actions$.pipe(
      ofType(ProfilesAppActions.getIndividualProfiles),
      exhaustMap(({}) =>
        profilesService.getIndividualProfiles().pipe(
          take(1),
          map(({ profiles }) => {
            return ProfilesApiActions.getProfilesSuccess({ profiles });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ProfilesApiActions.getProfilesFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const getOrganizationalProfiles$ = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService)) => {
    return actions$.pipe(
      ofType(ProfilesAppActions.getOrganizationalProfiles),
      exhaustMap(({}) =>
        profilesService.getOrganizationalProfiles().pipe(
          take(1),
          map(({ profiles }) => {
            return ProfilesApiActions.getProfilesSuccess({ profiles });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ProfilesApiActions.getProfilesFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const createProfile$ = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService)) => {
    return actions$.pipe(
      ofType(ProfilesAppActions.createProfile),
      exhaustMap(({ profile }) =>
        profilesService.createProfile(profile).pipe(
          take(1),
          map(({ profile }) => {
            return ProfilesApiActions.createProfileSuccess({ profile });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ProfilesApiActions.createProfileFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const updateProfile$ = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService)) => {
    return actions$.pipe(
      ofType(ProfilesAppActions.updateProfile),
      exhaustMap(({ profile, id }) =>
        profilesService.updateProfile(profile, id).pipe(
          take(1),
          map(({ profile }) => {
            return ProfilesApiActions.updateProfileSuccess({ profile });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ProfilesApiActions.updateProfileFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const deleteProfile$ = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService)) => {
    return actions$.pipe(
      ofType(ProfilesAppActions.deleteProfile),
      exhaustMap(({ id }) =>
        profilesService.deleteProfile(id).pipe(
          take(1),
          map(() => {
            return ProfilesApiActions.deleteProfileSuccess({ id });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ProfilesApiActions.deleteProfileFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const enableProfile$ = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService)) => {
    return actions$.pipe(
      ofType(ProfilesAppActions.enableProfile),
      exhaustMap(({ id, isEnabled }) =>
        profilesService.enableProfile(id, isEnabled).pipe(
          take(1),
          map(() => {
            return ProfilesApiActions.enableProfileSuccess({ id, isEnabled });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ProfilesApiActions.enableProfileFailure({ message }));
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

export const toggleProfilePrivate = createEffect(
  (actions$ = inject(Actions), profilesService = inject(ProfilesService))=>{
    return actions$.pipe(
      ofType(ProfilesAppActions.toggleProfilePrivate),
      exhaustMap(({id})=> 
      profilesService.toggleProfilePrivate(id).pipe(
        take(1),
        map(({profile})=>{
          console.log(profile)
          return ProfilesApiActions.toggleProfilePrivateSuccess({profile})
        }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ProfilesApiActions.toggleProfilePrivateFailure({ message }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  { functional: true },
);

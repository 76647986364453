import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil, tap } from 'rxjs';
import {
  ActionItemsAPIActions,
  ActionItemsAppActions,
} from 'src/app/state/action-items/action-items.actions';

interface DownloadActionItemsDocsModalState {
  isLoading: boolean;
  errorMessage: string | null;
}

@Injectable()
export class DownloadActionItemsDocsModalStore
  extends ComponentStore<DownloadActionItemsDocsModalState>
  implements OnDestroy
{
  subDestroy$: Subject<void> = new Subject<void>();

  constructor(private readonly actions$: Actions) {
    super({
      isLoading: false,
      errorMessage: null,
    });
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessaage$ = this.select((state) => state.errorMessage);

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(ActionItemsAppActions.getActionItemById),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(ActionItemsAPIActions.getActionItemByIdSuccess),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(ActionItemsAPIActions.getActionItemByIdFailure),
      tap(({ message }) =>
        this.patchState({ errorMessage: message, isLoading: false }),
      ),
      takeUntil(this.subDestroy$),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subDestroy$.next();
    this.subDestroy$.complete();
  }
}

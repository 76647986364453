import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { RolesAPIActions } from './roles.actions';
import { IRole } from 'src/app/core/models/user.model';

export interface RolesState extends EntityState<IRole> {
  errorMessage: string | null;
}

export const adapter: EntityAdapter<IRole> = createEntityAdapter<IRole>({
  selectId: (role: IRole) => role._id,
});

export const initialState: RolesState = adapter.getInitialState({
  errorMessage: null,
});

const rolesReducer = createReducer(
  initialState,
  on(RolesAPIActions.getRolesSuccess, (state, { roles }) => {
    return adapter.setAll(roles, { ...state, errorMessage: null });
  }),
  on(RolesAPIActions.getRolesFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  })
);

export const rolesFeature = createFeature({
  name: 'roles',
  reducer: rolesReducer,
  extraSelectors: ({ selectRolesState, selectEntities }) => ({
    ...adapter.getSelectors(selectRolesState),
    selectRole: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
  }),
});

export const {
  name,
  reducer,
  selectRolesState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectRole,
} = rolesFeature;

<cap-modal-container
  [modalWidthClass]="(config$ | async)?.modalWidth ?? ''"
  [modalID]="(config$ | async)?.modalID ?? ''"
>
  <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
    {{ (config$ | async)?.title }}
  </p>
  <div class="w-full">
    @if (errorMessage$ | async) {
      <cap-feedback
        type="error"
        [message]="errorMessage$ | async"
      ></cap-feedback>
    }
    @if (loading$ | async) {
      <div
        class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
      >
        <cap-spinner class="z-50" [size]="16"></cap-spinner>
      </div>
    }
    <div
      class="mx-auto max-w-7xl grid grid-cols-1 grid-rows-6 lg:grid-cols-6 lg:gap-x-8 px-0 lg:px-8 py-8 lg:py-12"
    >
      <cap-vertical-step-navigator
        class="row-span-6 col-span-2"
        [steps]="steps"
        [eventEmitter]="navigateStepEmitter"
      ></cap-vertical-step-navigator>
      <div class="row-span-6 col-span-4 flex flex-col justify-between gap-y-5">
        <ng-container class="w-full" #DynamicComponentContainer></ng-container>
        <div class="flex justify-between flex-row-reverse mr-5">
          <button
            [disabled]="
              (currentStepIndex$ | async) === 0 &&
              !(organizationFormValidity$ | async)
            "
            type="button"
            (click)="moveNextStep()"
            class="rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            {{
              (currentStepIndex$ | async) ===
              (config$ | async)!.steps.length - 1
                ? "Finish"
                : "Next"
            }}
          </button>

          @if ((currentStepIndex$ | async) !== 0) {
            <button
              type="button"
              (click)="handlePreviousStep()"
              class="rounded-md bg-gray-100 hover:bg-gray-50 dark:bg-white/10 dark:hover:bg-white/20 px-3 py-2 text-sm font-semibold text-gray-800 dark:text-gray-50 shadow-sm"
            >
              Go back
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</cap-modal-container>

import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { CommonModule} from '@angular/common';
import {
  combineLatestWith,
  Observable,
  of,
  Subject,
  Subscription,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';

import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { VerticalStepNavigatorComponent } from '../vertical-step-navigator/vertical-step-navigator.component';
import { ProfileFormComponent } from '../profile-form/profile-form.component';
import { ChangeAvatarComponent } from '../change-avatar/change-avatar.component';
import { VerticalStepNavigationModalStore } from './vertical-step-navigation-modal.store';
import {
  IVStep,
  IVerticalModalConfig,
  IVerticalStep,
} from 'src/app/core/models/vertical-step.model';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { DynamicComponentLoaderService } from 'src/app/core/dynamic-component-loader.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { IAvatar, IRole, UserRole } from 'src/app/core/models/user.model';
import { IOrganization } from 'src/app/core/models/organization.model';
import {
  IOrganizationProfileFormInput,
  ProfileContext,
} from 'src/app/core/models/profile-form-context.model';
import { AddEntitiyComponent } from '../add-entitiy/add-entitiy.component';
import {
  OrganizationsAPIActions,
  OrganizationsAppActions,
} from 'src/app/state/organizations/organizations.actions';
import { GridListComponent } from '../grid-list/grid-list.component';
import { OrganizationProfileFromComponent } from '../organization-profile-from/organization-profile-from.component';
import { selectOrganizationById } from 'src/app/state/organizations/organizations.state';
import { FeedbackComponent } from '../feedback/feedback.component';
import { PaymentFormComponent } from '../payment-form/payment-form.component';
import { IDiscount } from 'src/app/core/models/discount.model';
import { selectUserRole } from 'src/app/state/auth/auth.selectors';

@Component({
  selector: 'cap-vertical-step-navigation-modal',
  standalone: true,
  providers: [VerticalStepNavigationModalStore],
  templateUrl: './vertical-step-navigation-modal.component.html',
  styleUrl: './vertical-step-navigation-modal.component.scss',
  imports: [
    CommonModule,
    ModalContainerComponent,
    VerticalStepNavigatorComponent,
    SpinnerComponent,
    FeedbackComponent,
  ],
})
export class VerticalStepNavigationModalComponent {
  private dynamicComponentLoader = inject(DynamicComponentLoaderService);
  private store = inject(Store);

  @ViewChild('DynamicComponentContainer', {
    read: ViewContainerRef,
    static: true,
  })
  dynamicComponentContainerRef!: ViewContainerRef;

  destroy$ = new Subject<void>();

  @Input() config$!: Observable<IVerticalModalConfig>;

  readonly currentStepIndex$ = this.componentStore.currentStepIndex$;
  readonly loading$ = this.componentStore.isLoading$;
  readonly errorMessage$ = this.componentStore.errorMessage$;
  readonly currentOrganization$ = this.componentStore.organization$;
  readonly organizationFormValidity$ =
    this.componentStore.organizationFormValidity$;
  readonly selectedAvatar$ = this.componentStore.selectedAvatar$;
  readonly organizationOldAvatars$ =
    this.componentStore.selectOrganizationOldAvatars$;
  readonly organizationCurrentAvatar$ =
    this.componentStore.selectCurrentOrganizationAvatar$;
  readonly paymentCompleted$ = this.componentStore.paymentCompleted$;
  readonly paymentIntentId$ = this.componentStore.paymentIntentId$;
  readonly discountCode$ = this.componentStore.discountCode$;
  subs: Subscription[] = [];

  navigateStepEmitter = new EventEmitter<'next' | 'previous'>();

  public steps: Omit<IVStep, 'id' | 'status'>[] = [];
  currentComponentRef: ComponentRef<
    // TODO: Remove [ProfileFormComponent] later
    | ProfileFormComponent
    | OrganizationProfileFromComponent
    | ChangeAvatarComponent
    | AddEntitiyComponent
    | GridListComponent
    | PaymentFormComponent 
  > | null = null;

  constructor(
    public componentStore: VerticalStepNavigationModalStore,
    private actions$: Actions,
  ) {}

  loadComponentForStep(step: IVerticalStep): void {
    this.dynamicComponentContainerRef.clear();

    const componentRef = this.dynamicComponentLoader.loadComponent(
      this.dynamicComponentContainerRef,
      step.component,
      step.inputs,
      step.outputs,
    );
    this.currentComponentRef = componentRef;
  }

  ngOnInit(): void {
    this.config$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ context, steps }) => {
        this.steps = steps.map(({ title, description }) => ({
          title,
          description,
        }));
        this.loadComponentForStep(steps[0]);

        if (context === ProfileContext.OrgUpdate) {
          steps[0].inputs.formInput$
            .pipe(
              tap((formInput: IOrganizationProfileFormInput) =>
                this.componentStore.setOrganization(
                  formInput['data'] as IOrganization,
                ),
              ),
              takeUntil(this.destroy$),
            )
            .subscribe();
          this.componentStore.setOrganizationFormValidity(true);
        }
      });

      this.store.select(selectUserRole).pipe(
        takeUntil(this.destroy$),
        tap(role => this.componentStore.setIsAdminUser((role as IRole)?.name === UserRole.SysAdmin || (role as IRole)?.name === UserRole.SuperAdmin)),
      ).subscribe();
  }

  moveNextStep() {
    const sub = this.currentStepIndex$
      .pipe(
        withLatestFrom(
          this.currentOrganization$,
          this.selectedAvatar$,
          this.organizationCurrentAvatar$,
          this.organizationOldAvatars$,
          this.config$,
          this.paymentCompleted$,
          this.componentStore.fullyDiscounted$,
          this.componentStore.isAdminUser$
        ),
        take(1),
        tap(
          ([
            currentIndex,
            currentOrg,
            orgSelectedAvatar,
            orgCurrentAvatar,
            orgOldAvatars,
            { steps, context },
            paymentCompleted,
            fullyDiscounted,
            isAdminUser
          ]) => {
            if (currentIndex < this.steps.length - 1) {
              const nextIndex = currentIndex + 1;
              this.navigateStepEmitter.emit('next');
              this.componentStore.setCurrentStepIndex(nextIndex);

              const nextStep = steps[nextIndex];
              let parentOrg: IOrganization | null = null;

              if (currentOrg?.parentOrg) {
                const sub = this.store
                  .select(selectOrganizationById(currentOrg?.parentOrg))
                  .pipe(
                    tap((org) => {
                      parentOrg = org ?? null;
                    }),
                  )
                  .subscribe();
                this.subs.push(sub);
              }

              switch (nextStep.component) {
                case ChangeAvatarComponent:
                  nextStep.inputs = {
                    ...nextStep.inputs,
                    oldAvatars: orgOldAvatars,
                    currentAvatar: orgCurrentAvatar,
                    selectedAvatar: orgSelectedAvatar,
                  };
                  break;
                case GridListComponent:
                  nextStep.inputs = {
                    ...nextStep.inputs,
                    selectedItem: parentOrg ?? null,
                  };
                  break;
                case PaymentFormComponent:
                  nextStep.inputs = {
                    ...nextStep.inputs,
                    featureType: 'organization',
                  };
                  break;
              }

              if (nextStep) {
                this.loadComponentForStep(nextStep);
              }
            } else {
              if (context === ProfileContext.OrgCreation && !isAdminUser && (!paymentCompleted && !fullyDiscounted)) {
                this.componentStore.setErrorMessage('Payment is required to create an organization');
              } else {
                this.finalizeOrganization();
              }
            }
          },
        ),
      )
      .subscribe();
    this.subs.push(sub);
  }

  handlePreviousStep() {
    const sub = this.componentStore.currentStepIndex$
      .pipe(
        withLatestFrom(
          this.currentOrganization$,
          this.selectedAvatar$,
          this.organizationCurrentAvatar$,
          this.organizationOldAvatars$,
          this.config$,
        ),
        take(1),
        tap(
          ([
            currentIndex,
            organization,
            selectedAvatar,
            currentAvatar,
            oldAvatars,
            { context, steps },
          ]) => {
            if (currentIndex > 0) {
              const previousIndex = currentIndex - 1;
              this.navigateStepEmitter.emit('previous');
              this.componentStore.setCurrentStepIndex(previousIndex);

              const previousStep = steps[previousIndex];

              switch (previousStep.component) {
                case OrganizationProfileFromComponent:
                  previousStep.inputs = {
                    ...previousStep.inputs,
                    formInput$: of({
                      context: context,
                      data: organization,
                    }),
                  };
                  break;
                case ChangeAvatarComponent:
                  previousStep.inputs = {
                    ...previousStep.inputs,
                    oldAvatars,
                    currentAvatar,
                    selectedAvatar,
                  };
                  break;
                default:
                  break;
              }
              if (previousStep) {
                this.loadComponentForStep(previousStep);
              }
            }
          },
        ),
      )
      .subscribe();
    this.subs.push(sub);
  }

  finalizeOrganization() {
    this.currentOrganization$
      .pipe(
        combineLatestWith(this.config$, this.paymentIntentId$, this.discountCode$),
        tap(([organization, { context }, paymentIntentId, discountCode]) => {
          if (organization) {
            if (context === ProfileContext.OrgCreation) {
              const organizationData = {
                ...organization,
                paymentIntentId: paymentIntentId || null,
                discountCode: discountCode || null
              };
              this.store.dispatch(
                OrganizationsAppActions.createOrganization({ 
                  organization: organizationData
                }),
              );
            } else if (context === ProfileContext.OrgUpdate) {
              this.store.dispatch(
                OrganizationsAppActions.updateOrganization({
                  organization: <IOrganization>organization,
                }),
              );
            }
            this.waitForResponse();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private waitForResponse() {
    this.actions$
      .pipe(
        ofType(
          OrganizationsAPIActions.createOrganizationSuccess,
          OrganizationsAPIActions.updateOrganizationSuccess,
        ),
        withLatestFrom(this.config$),
        tap(([action, { modalID }]) =>
          this.store.dispatch(ModalActions.unregister({ id: modalID })),
        ),
        //TODO: Maybe change takeUntil() to take(1)
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  handleProfileChange(profile: FormGroup) {
    this.componentStore.setOrganization(profile.value);
    this.componentStore.setOrganizationFormValidity(profile.valid);
  }

  removeAvatar(avatar: IAvatar) {
    this.componentStore.removeOrganizationAvatar(avatar);
  }

  selectAvatar(avatar: IAvatar) {
    this.componentStore.setSelectedAvatar(avatar);
  }

  updateAvatar(avatar: IAvatar) {
    this.componentStore.setCurrentAvatar(avatar);
  }

  onParentOrgSelected(organization: IOrganization) {
    this.componentStore.setParentOrganization(organization._id);
  }


  handlePaymentComplete(result: { success: boolean; paymentIntentId: string | null, discountCode: string | null, fullyDiscounted: boolean }) {  
    if (result.success) {
      if (result.fullyDiscounted) {
        this.componentStore.setFullyDiscounted(true);
        this.componentStore.setPaymentCompleted(true); // Auto-complete payment for full discount
      }

      // Update other payment-related states
      if (result.paymentIntentId) {
        this.componentStore.setPaymentIntentId(result.paymentIntentId);
      }
      if (result.discountCode) {
        this.componentStore.setDiscountCode(result.discountCode);
      }

      // Only set payment completed if not already set by full discount
      if (!result.fullyDiscounted) {
        this.componentStore.setPaymentCompleted(true);
      }

      this.moveNextStep();
    } else {
      this.componentStore.patchState({
        paymentCompleted: false,
        paymentIntentId: null,
        discountCode: null,
        fullyDiscounted: false,
        errorMessage: 'Payment failed. Please try again.'
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { BadgeCellStore } from './badge-cell.store';
import { tap } from 'rxjs';

@Component({
  selector: 'cap-badge-cell',
  standalone: true,
  imports: [CommonModule],
  providers: [BadgeCellStore],
  templateUrl: './badge-cell.component.html',
  styleUrl: './badge-cell.component.scss',
})
export default class BadgeCellComponent {
  private readonly componentStore = inject(BadgeCellStore);

  @Input() set badges(value: string[]) {
    this.componentStore.setBadges(value);
  }

  readonly badges$ = this.componentStore.badges$;
}

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createSelector, on } from "@ngrx/store";
import { createReducer } from "@ngrx/store";
import { PricingConfigAPIActions } from "./pricing-config.actions";
import { IPricingConfig } from "src/app/core/models/pricing-config.model";

export interface PricingConfigState extends EntityState<IPricingConfig> {
    errorMessage: string | null;
}

export const adapter: EntityAdapter<IPricingConfig> = createEntityAdapter<IPricingConfig>({
    selectId: (pricingConfig: IPricingConfig) => pricingConfig._id,
});

export const initialState: PricingConfigState = adapter.getInitialState({
    errorMessage: null,
});

const pricingConfigReducer = createReducer(
    initialState,
    on(PricingConfigAPIActions.getAllPricingConfigsSuccess, (state, { pricingConfigs }) => adapter.setAll(pricingConfigs, state)),
    on(PricingConfigAPIActions.getAllPricingConfigsFailure, (state, { message }) => ({ ...state, errorMessage: message })),
    on(PricingConfigAPIActions.updatePricingConfigPriceSuccess, (state, { pricingConfigs }) => adapter.setAll(pricingConfigs, state)),
    on(PricingConfigAPIActions.updatePricingConfigPriceFailure, (state, { message }) => ({ ...state, errorMessage: message })),
)

export const pricingConfigFeature = createFeature({
    name: 'pricingConfig',
    reducer: pricingConfigReducer,
    extraSelectors: ({ selectPricingConfigState, selectIds, selectEntities }) => ({
        ...adapter.getSelectors(selectPricingConfigState),
        selectPricingConfigById: (id: string) => createSelector(selectEntities, (entities) => entities[id]),
    }),
})

export const {
    name,
    reducer,
    selectPricingConfigState,
    selectAll,
    selectPricingConfigById,
} = pricingConfigFeature;
import { Component, Input } from '@angular/core';

@Component({
  selector: 'cap-cost-cell',
  standalone: true,
  imports: [],
  templateUrl: './cost-cell.component.html',
  styleUrl: './cost-cell.component.scss',
})
export class CostCellComponent {
  @Input() value: boolean = false;
}

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, tap } from 'rxjs';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';

@Injectable()
export class TwoFactorAuthVerificationStore
  extends ComponentStore<{ error: boolean }>
  implements OnDestroy
{
  private subs: Subscription[] = [];
  constructor(private readonly actions$: Actions) {
    super({
      error: false,
    });

    this.subs.push(this.errorActionSub, this.errorRefreshActionSub);
  }

  readonly error$ = this.select((state) => state.error);
  private readonly errorActionSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.validate2FAOTPFailure),
      tap(() => this.patchState({ error: true })),
    )
    .subscribe();

  private readonly errorRefreshActionSub = this.actions$
    .pipe(
      ofType(
        AuthAPIActions.validate2FAOTPSuccess,
        AuthAppActions.validate2FAOTP,
      ),
      tap(() => this.patchState({ error: false })),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

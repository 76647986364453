import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, tap } from 'rxjs';
import { IUser, IUserBase } from 'src/app/core/models/user.model';
import {
  UsersAPIActions,
  UsersAppActions,
} from 'src/app/state/users/users.actions';

interface UserProfilModalState {
  isLoading: boolean;
  errorMessage: string | null;
  user: IUserBase | IUser | null;
  userFormValidity: boolean;
}

@Injectable()
export class UserProfileModalStore
  extends ComponentStore<UserProfilModalState>
  implements OnDestroy
{
  private subs: Subscription[] = [];

  constructor(private readonly actions$: Actions) {
    super({
      isLoading: false,
      errorMessage: null,
      user: null,
      userFormValidity: false,
    });

    this.subs.push(
      this.appActionsSub,
      this.apiSuccessActionsSub,
      this.apiFailureActionsSub
    );
  }

  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly user$ = this.select((state) => state.user);
  readonly userFormValidity$ = this.select((state) => state.userFormValidity);

  readonly setErrorMessage = this.updater(
    (state, newErrorMessage: string | null) => ({
      ...state,
      errorMessage: newErrorMessage,
    })
  );

  readonly setUser = this.updater((state, newUser: IUserBase | IUser) => ({
    ...state,
    user: {
      ...state.user,
      ...newUser,
    },
  }));

  readonly setUserFormValidity = this.updater(
    (state, newUserFormValidity: boolean) => ({
      ...state,
      userFormValidity: newUserFormValidity,
    })
  );

  private readonly appActionsSub = this.actions$
    .pipe(
      ofType(
        UsersAppActions.registerNewUser,
        UsersAppActions.updateUserAccount
      ),
      tap(() => this.patchState({ isLoading: true, errorMessage: null }))
    )
    .subscribe();

  private readonly apiSuccessActionsSub = this.actions$
    .pipe(
      ofType(
        UsersAPIActions.registerNewUserSuccess,
        UsersAPIActions.updateUserAccountSuccess,
        UsersAPIActions.updateUserOrganizationSuccess
      ),
      tap(() => this.patchState({ isLoading: false, errorMessage: null }))
    )
    .subscribe();

  private readonly apiFailureActionsSub = this.actions$
    .pipe(
      ofType(
        UsersAPIActions.registerNewUserFailure,
        UsersAPIActions.updateUserAccountFailure,
        UsersAPIActions.updateUserOrganizationFailure
      ),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message })
      )
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IProfile } from '../../core/models/profiles.model';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ProfilesApiActions } from './profiles.actions';

export interface ProfilesState extends EntityState<IProfile> {
  errorMessage: string | null;
}

export const adapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>({
  selectId: (profile: IProfile) => profile._id,
});

export const initialState: ProfilesState = adapter.getInitialState({
  errorMessage: null,
});

export const profilesReducer = createReducer(
  initialState,
  on(ProfilesApiActions.getProfilesSuccess, (state, { profiles }) => {
    return adapter.setAll(profiles, { ...state, errorMessage: null });
  }),
  on(ProfilesApiActions.getProfilesFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(ProfilesApiActions.searchProfilesSuccess, (state, { profiles }) => {
    return adapter.setAll(profiles, { ...state, errorMessage: null });
  }),
  on(ProfilesApiActions.searchProfilesFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(ProfilesApiActions.createProfileSuccess, (state, { profile }) => {
    return adapter.addOne(profile, state);
  }),
  on(ProfilesApiActions.createProfileFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(ProfilesApiActions.updateProfileSuccess, (state, { profile }) => {
    return adapter.updateOne({ id: profile._id, changes: profile }, state);
  }),
  on(ProfilesApiActions.updateProfileFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(ProfilesApiActions.deleteProfileSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(ProfilesApiActions.deleteProfileFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(ProfilesApiActions.enableProfileSuccess, (state, { id, isEnabled }) => {
    return adapter.updateOne({ id, changes: { isEnabled } }, state);
  }),
  on(ProfilesApiActions.enableProfileFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(ProfilesApiActions.toggleProfilePrivateSuccess, (state, { profile }) => {
    return adapter.updateOne({ id: profile._id, changes: profile }, state);
  }),
  on(ProfilesApiActions.toggleProfilePrivateFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
);

export const profilesFeature = createFeature({
  name: 'profiles',
  reducer: profilesReducer,
  extraSelectors: ({ selectProfilesState, selectEntities, selectIds }) => ({
    ...adapter.getSelectors(selectProfilesState),
    selectProfileById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
  }),
});

export const {
  name,
  reducer,
  selectProfilesState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectProfileById,
} = profilesFeature;

// angular imports
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
//third party imports
import { SpinnerModule } from 'primeng/spinner';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Subject, takeUntil, tap } from 'rxjs';
//app imports
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { AssessmentFormComponent } from './assessment-form/assessment-form.component';
import { SharedHeaderDataService } from '../core/services/shared-header-data.service';
import { SharedAssessmentHeaderComponent } from '../shared/shared-assessment-header/shared-assessment-header.component';
import { SharedAssessmentScrollerComponent } from '../shared/shared-assessment-scroller/shared-assessment-scroller.component';
@Component({
  selector: 'cap-assessment-page',
  standalone: true,
  imports: [
    SidebarComponent,
    AssessmentFormComponent,
    AsyncPipe,
    SharedAssessmentHeaderComponent,
    SharedAssessmentScrollerComponent,
  ],
  templateUrl: './assessment-page.component.html',
  styleUrl: './assessment-page.component.scss',
})
export class AssessmentPageComponent implements OnInit, OnDestroy {
  private sharedHeaderDataService = inject(SharedHeaderDataService);
  private destroy$ = new Subject<void>();
  entity = this.sharedHeaderDataService.selectedEntity$;
  showCategoryScroller: boolean = false;
  showAssessmentForm: boolean = false;
  selectedAssessmentCategory = this.sharedHeaderDataService.selectedCategory$;
  constructor() {}

  ngOnInit(): void {
    this.entity.pipe(takeUntil(this.destroy$)).subscribe((entity) => {
      if (entity) {
        this.showCategoryScroller = true;
      }
    });
    this.selectedAssessmentCategory
      .pipe(
        takeUntil(this.destroy$),
        tap((category) => {
          if (category) this.showAssessmentForm = true;
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UsersAppActions } from '../../state/users/users.actions';
import { OrganizationsAppActions } from '../../state/organizations/organizations.actions';
import {
  combineLatest,
  concatMap,
  debounceTime,
  forkJoin,
  of,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { selectAllUsers } from '../../state/users/users.selectors';
import { selectOrganizations } from '../../state/organizations/organizations.selectors';
import { AssessmentsAppActions } from '../../state/assessments/assessments.actions';
import { selectAssessments } from '../../state/assessments/assessments.selectors';
import { catchError, filter, map, take } from 'rxjs/operators';
import { IAssessment } from '../../core/models/assessments.model';
import { ChartModule } from 'primeng/chart';
import { IAvatar, IUser, UserRole } from '../../core/models/user.model';
import { IOrganization } from '../../core/models/organization.model';
import { selectUser } from '../../state/auth/auth.state';
import { CarouselModule, CarouselResponsiveOptions } from 'primeng/carousel';
import { KnobModule } from 'primeng/knob';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { selectCategoryById } from '../../state/categories/categories.state';
import { ICategory } from '../../core/models/categories.model';
import { CategoriesAppActions } from '../../state/categories/categories.actions';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ProfilesAppActions } from '../../state/profiles/profiles.actions';
import { selectUserRole } from '../../state/auth/auth.selectors';
import { DashboardPageStore } from '../dashboard-page.store';

@Component({
  selector: 'cap-dashbaord-page-admin',
  standalone: true,
  imports: [
    ChartModule,
    CarouselModule,
    KnobModule,
    NgIf,
    PrimeTemplate,
    FormsModule,
    TooltipModule,
    ProgressSpinnerModule,
    NgOptimizedImage,
    TableModule,
    TagModule,
  ],
  templateUrl: './dashbaord-page-admin.component.html',
  styleUrl: './dashbaord-page-admin.component.scss',
})
export class DashbaordPageAdminComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private destroy$ = new Subject<void>();
  private initialUsers: IUser[] = [];
  private initialOrganizations: IOrganization[] = [];
  private initialAssessments: IAssessment[] = [];
  private users$ = this.store.select(selectAllUsers);
  private dashbaordPageStore = inject(DashboardPageStore);
  private organizations$ = this.store.select(selectOrganizations);
  private assessments$ = this.store.select(selectAssessments);
  private currentUser$ = this.store.select(selectUser);
  protected isSysAdmin = false;
  users: IUser[] = [];
  organizations: IOrganization[] = [];
  isLoadingStats = true;
  isLoadingCarousel = true;
  tableData: IEntityStats[] = [];
  currentAssessmentStats: IAssessmentStats = {
    totalUsers: 0,
    totalSubOrganizations: 0,
    categoryScores: {},
    organizations: [],
  };
  carouselItems: ICategoryScore[] = [];
  filteredTableData: IEntityStats[] = [];
  isLoadingAdminData = this.dashbaordPageStore.isLoadingAdminData$;
  carouselResponsiveOptions: CarouselResponsiveOptions[] | undefined = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 1,
    },
  ];

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.store
      .select(selectUserRole)
      .pipe(takeUntil(this.destroy$))
      .subscribe((role) => {
        if (role?.name === UserRole.SysAdmin) {
          this.isSysAdmin = true;
          this.loadInitialDataForSysAdmin();
        } else {
          this.loadInitialData();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(AssessmentsAppActions.restAssessmentsState());
  }

  loadInitialDataForSysAdmin() {
    this.store.dispatch(AssessmentsAppActions.restAssessmentsState());
    this.store.dispatch(UsersAppActions.getAllUsersAccounts());
    this.store.dispatch(OrganizationsAppActions.getOrganizations({}));
    this.store.dispatch(ProfilesAppActions.getProfiles());
    this.store.dispatch(
      CategoriesAppActions.getCategories({ page: 1, pageSize: 1000000 }),
    );
    this.store.dispatch(AssessmentsAppActions.getAssessments());
    this.loadSysAdminDashboardData();
    this.loadTableData();
  }

  loadSysAdminDashboardData() {
    combineLatest([this.users$, this.organizations$])
      .pipe(
        filter(
          ([users, organizations]) =>
            users.length > 0 && organizations.length > 0,
        ),
        take(1),
        map(([users, organizations]) => {
          this.initialUsers = users;
          this.initialOrganizations = organizations;

          const currentAssessmentStats = this.calculateAssessmentStats(
            users,
            organizations,
          );

          return { currentAssessmentStats, users, organizations };
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.currentAssessmentStats = result.currentAssessmentStats;
        this.users = result.users;
        this.organizations = result.organizations;
        this.cdRef.detectChanges();
        this.isLoadingStats = false;
      });
  }

  loadInitialData() {
    this.store.dispatch(OrganizationsAppActions.getOrganizations({}));
    this.store.dispatch(ProfilesAppActions.getProfiles());
    this.store.dispatch(
      CategoriesAppActions.getCategories({ page: 1, pageSize: 1000000 }),
    );
    this.store.dispatch(AssessmentsAppActions.getAssessments());
    this.loadAdminDashboardData();
    this.loadTableData();
  }

  loadAdminDashboardData() {
    combineLatest([this.users$, this.organizations$, this.currentUser$])
      .pipe(
        filter(
          ([users, organizations, user]) =>
            users.length > 0 && organizations.length > 0 && !!user,
        ),
        take(1),
        map(([users, organizations, user]) => {
          if (user) {
            this.initialUsers = users;
            this.initialOrganizations = organizations;

            const currentAssessmentStats = this.calculateAssessmentStats(
              users,
              organizations,
              user.organization as string,
            );

            return { currentAssessmentStats, users, organizations };
          }
          return;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.currentAssessmentStats = result.currentAssessmentStats;
        this.users = result.users;
        this.organizations = result.organizations;
        this.cdRef.detectChanges();
        this.isLoadingStats = false;
      });
  }

  loadTableData() {
    combineLatest([this.users$, this.organizations$, this.assessments$])
      .pipe(
        filter(
          ([users, organizations, assessments]) =>
            users.length > 0 &&
            organizations.length > 0 &&
            assessments.length > 0,
        ),
        debounceTime(1000),
        concatMap(([users, organizations, assessments]) => {
          this.initialAssessments = assessments;

          return forkJoin(
            assessments.map((assessment) =>
              this.store.select(selectCategoryById(assessment.categoryId)).pipe(
                take(1),
                map((category) => ({
                  assessment,
                  category: category || null,
                })),
                catchError((error) => {
                  console.error(
                    `Error loading category for assessment ${assessment._id}:`,
                    error,
                  );
                  return of({ assessment, category: null });
                }),
              ),
            ),
          ).pipe(
            tap((assessmentCategories) => {
              this.populateEntitiesTable(assessmentCategories);
              this.buildCouraselItems(assessmentCategories);
            }),
          );
        }),
        catchError((error) => {
          console.error('Error fetching and populating table data:', error);
          return of([]);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  populateEntitiesTable(
    assessmentCategories: {
      assessment: IAssessment;
      category: ICategory | null;
    }[],
  ): IEntityStats[] {
    const entities: IEntityStats[] = [];

    assessmentCategories.forEach(({ assessment, category }) => {
      const entity: IEntityStats = {
        entityName: '',
        entityType: 'Unknown',
        score: assessment.score,
        categoryName: category?.name || '',
        categoryAvatar: category?.avatars?.[category.avatars.length - 1]?.url || '/assets/images/empty-avatar.jpg',
      };

      if (assessment.organizationId) {
        const org = this.organizations.find(
          (org) => org._id === assessment.organizationId,
        );
        if (org) {
          entity.entityName = org.name;
          entity.entityType = 'Organization';
          entity.avatar = org.avatars[org.avatars.length - 1];
        }
      } else if (assessment.userId) {
        const user = this.users.find((user) => user._id === assessment.userId);
        if (user) {
          entity.entityName = user.firstName + ' ' + user.lastName;
          entity.entityType = 'Individual';
          entity.avatar = user.avatars[user.avatars.length - 1];
        }
      }
      entities.push(entity);
    });

    this.tableData = entities;
    this.filteredTableData = entities;

    return entities;
  }

  calculateAssessmentStats(
    users: IUser[],
    organizations: IOrganization[],
    adminOrganizationId?: string | null,
  ): IAssessmentStats {
    if (this.isSysAdmin) {
      const totalUsers = users.filter(
        (user) => user.role.name === UserRole.Individual,
      ).length;
      const totalAdmins =
        users.filter((user) => user.role.name === UserRole.OrgAdmin).length ??
        0;
      const totalStaff =
        users.filter((user) => user.role.name === UserRole.OrgStaff).length ??
        0;
      const totalMangers =
        users.filter((user) => user.role.name === UserRole.OrgManager).length ??
        0;
      const totalSubOrganizations = organizations.length;

      const categoryScores: { [categoryId: string]: ICategoryScore } = {};
      this.initialAssessments.forEach((assessment) => {
        const categoryId = assessment.categoryId;
        if (!categoryScores[categoryId]) {
          categoryScores[categoryId] = {
            low: 0,
            medium: 0,
            high: 0,
            unanswered: 0,
            category: { _id: categoryId } as ICategory,
            lowEntities: [],
            mediumEntities: [],
            highEntities: [],
            unansweredEntities: [],
          };
        }

        const score = assessment.score;
        const entity: IEntityStats = {
          entityName: '',
          entityType: 'Unknown',
          score,
          categoryName: '',
        };

        if (score < 33) {
          categoryScores[categoryId].low += 1;
          categoryScores[categoryId].lowEntities.push(entity);
        } else if (score < 66) {
          categoryScores[categoryId].medium += 1;
          categoryScores[categoryId].mediumEntities.push(entity);
        } else if (score <= 100) {
          categoryScores[categoryId].high += 1;
          categoryScores[categoryId].highEntities.push(entity);
        } else {
          categoryScores[categoryId].unanswered += 1;
          categoryScores[categoryId].unansweredEntities.push(entity);
        }
      });

      return {
        totalUsers,
        totalAdmins,
        totalStaff,
        totalMangers,
        totalSubOrganizations,
        categoryScores,
        organizations,
      };
    } else {
      if (!adminOrganizationId) {
        return {
          totalUsers: 0,
          totalSubOrganizations: 0,
          categoryScores: {},
          organizations: [] as IOrganization[],
        };
      }

      const adminOrganization = organizations.find(
        (org) => org._id === adminOrganizationId,
      );

      if (!adminOrganization) {
        return {
          totalUsers: 0,
          totalSubOrganizations: 0,
          categoryScores: {},
          organizations: [] as IOrganization[],
        };
      }

      const subOrganizations = this.getSubOrganizations(
        adminOrganization,
        organizations,
      );
      const usersInOrg = this.getUsersInOrganizations(users, [
        adminOrganization,
        ...subOrganizations,
      ]);

      const totalSubOrganizations = subOrganizations.length + 1;

      const categoryScores: { [categoryId: string]: ICategoryScore } = {};
      this.initialAssessments
        .filter((assessment) =>
          [
            adminOrganization._id,
            ...subOrganizations.map((org) => org._id),
          ].includes(assessment.organizationId ?? ''),
        )
        .forEach((assessment) => {
          const categoryId = assessment.categoryId;
          if (!categoryScores[categoryId]) {
            categoryScores[categoryId] = {
              low: 0,
              medium: 0,
              high: 0,
              unanswered: 0,
              category: { _id: categoryId } as ICategory,
              lowEntities: [],
              mediumEntities: [],
              highEntities: [],
              unansweredEntities: [],
            };
          }

          const score = assessment.score;
          const entity: IEntityStats = {
            entityName: '',
            entityType: 'Unknown',
            score,
            categoryName: '',
          };

          if (score < 33) {
            categoryScores[categoryId].low += 1;
            categoryScores[categoryId].lowEntities.push(entity);
          } else if (score < 66) {
            categoryScores[categoryId].medium += 1;
            categoryScores[categoryId].mediumEntities.push(entity);
          } else if (score <= 100) {
            categoryScores[categoryId].high += 1;
            categoryScores[categoryId].highEntities.push(entity);
          } else {
            categoryScores[categoryId].unanswered += 1;
            categoryScores[categoryId].unansweredEntities.push(entity);
          }
        });

      return {
        totalUsers: usersInOrg.filter(
          (user) => user.role.name === UserRole.Individual,
        ).length,
        totalSubOrganizations,
        categoryScores,
        organizations: [adminOrganization, ...subOrganizations],
      };
    }
  }

  getSubOrganizations(
    adminOrg: IOrganization,
    allOrganizations: IOrganization[],
  ) {
    const subOrgs: IOrganization[] = [];
    const orgQueue: IOrganization[] = [adminOrg];

    while (orgQueue.length > 0) {
      const currentOrg = orgQueue.pop();
      const children = allOrganizations.filter(
        (org) => org.parentOrg === currentOrg?._id,
      );
      subOrgs.push(...children);
      orgQueue.push(...children);
    }

    return subOrgs;
  }

  getUsersInOrganizations(users: IUser[], organizations: IOrganization[]) {
    const orgIds = organizations.map((org) => org._id);
    return users.filter((user) => orgIds.includes(user.organization as string));
  }

  onClickCategory(event: any) {
    this.filteredTableData = event;
  }

  getScoreColor(score: number): string {
    if (score < 33) return 'red';
    if (score < 66) return 'yellow';
    if (score <= 100) return 'green';
    return 'gray';
  }

  buildCouraselItems = (
    assessmentCategories: {
      assessment: IAssessment;
      category: ICategory | null;
    }[],
  ) => {
    this.isLoadingCarousel = true;
    const categoryScores: { [categoryId: string]: ICategoryScore } = {};

    assessmentCategories.forEach(({ assessment, category }) => {
      if (!category) return;

      if (!categoryScores[category._id]) {
        categoryScores[category._id] = {
          low: 0,
          medium: 0,
          high: 0,
          unanswered: 0,
          category: category,
          lowEntities: [],
          mediumEntities: [],
          highEntities: [],
          unansweredEntities: [],
        };
      }

      const entity: IEntityStats = {
        entityName: '',
        entityType: 'Unknown',
        score: assessment.score,
        categoryName: category.name,
      };

      if (assessment.organizationId) {
        const org = this.organizations.find(
          (org: { _id: string | null }) =>
            org._id === assessment.organizationId,
        );
        if (org) {
          entity.entityName = org.name;
          entity.entityType = 'Organization';
          entity.avatar = org.avatars[org.avatars.length - 1] ?? { url: '/assets/images/default-avatar.jpeg' };
        }
      } else if (assessment.userId) {
        const user = this.users.find((user) => user._id === assessment.userId);
        if (user) {
          entity.entityName = user.firstName + ' ' + user.lastName;
          entity.entityType = 'Individual';
          entity.avatar = user.avatars[user.avatars.length - 1];
        }
      }

      const hasOptionId = assessment.questionsResponses.some(
        (response) => response.optionId,
      );
      if (!hasOptionId) {
        categoryScores[category._id].unanswered += 1;
        categoryScores[category._id].unansweredEntities.push(entity);
      }

      const score = assessment.score;
      if (score < 33) {
        categoryScores[category._id].low += 1;
        categoryScores[category._id].lowEntities.push(entity);
      } else if (score < 66) {
        categoryScores[category._id].medium += 1;
        categoryScores[category._id].mediumEntities.push(entity);
      } else if (score <= 100) {
        categoryScores[category._id].high += 1;
        categoryScores[category._id].highEntities.push(entity);
      }
    });

    this.carouselItems = Object.values(categoryScores);
    this.isLoadingCarousel = false;

    this.cdRef.detectChanges();
  };
}
// Modify ICategoryScore to include IEntityStats
interface ICategoryScore {
  low: number;
  medium: number;
  high: number;
  unanswered: number;
  category: ICategory;
  lowEntities: IEntityStats[];
  mediumEntities: IEntityStats[];
  highEntities: IEntityStats[];
  unansweredEntities: IEntityStats[];
}

interface IAssessmentStats {
  totalUsers: number;
  totalAdmins?: number;
  totalStaff?: number;
  totalMangers?: number;
  totalSubOrganizations: number;
  categoryScores: {
    [categoryName: string]: ICategoryScore;
  };
  organizations: IOrganization[];
}

interface IEntityStats {
  avatar?: IAvatar;
  entityName: string;
  entityType: 'Organization' | 'Individual' | 'Unknown';
  score: number;
  categoryName: string;
  categoryAvatar?: string;
}


import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { filter, of, switchMap } from 'rxjs';

import { selectUserRole } from '../../state/auth/auth.selectors';
import { UserRole } from '../models/user.model';
import { selectIsAuthenticated } from '../../state/auth/auth.state';

export const roleRedirectGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const store = inject(Store);

  return store.select(selectIsAuthenticated).pipe(
    filter((isAuthenticated) => isAuthenticated !== null),
    take(1),
    switchMap((isAuthenticated) => {
      if (!isAuthenticated) {
        router.navigate(['/auth/login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      }

      return store.select(selectUserRole).pipe(
        map((role) => {
          if (role) {
            const userRole = role.name;
            const availableRoutes = getAvailableRoutes(userRole);
            if (availableRoutes.length > 0) {
              router.navigate([availableRoutes[0]]);
              return false;
            }
          }
          router.navigate(['/not-found']);
          return false;
        }),
      );
    }),
  );
};
function getAvailableRoutes(role: UserRole): string[] {
  const availableRoutes = [];

  if (
    [
      UserRole.OrgAdmin,
      UserRole.OrgStaff,
      UserRole.Individual,
      UserRole.SysAdmin,
      UserRole.SuperAdmin,
    ].includes(role)
  ) {
    availableRoutes.push('/dashboard');
  }
  if ([UserRole.SuperAdmin, UserRole.SysAdmin].includes(role)) {
    availableRoutes.push('/administration');
  }
  if (
    [UserRole.OrgManager, UserRole.SysAdmin, UserRole.SuperAdmin].includes(role)
  ) {
    availableRoutes.push('/management');
  }
  if (
    [
      UserRole.OrgAdmin,
      UserRole.SysAdmin,
      UserRole.OrgStaff,
      UserRole.Individual,
    ].includes(role)
  ) {
    availableRoutes.push('/reports');
  }
  if (
    [
      UserRole.OrgStaff,
      UserRole.OrgAdmin,
      UserRole.Individual,
      UserRole.SysAdmin,
      UserRole.SuperAdmin,
    ].includes(role)
  ) {
    availableRoutes.push('/assessments');
  }

  return availableRoutes;
}

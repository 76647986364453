export const environment = {
  production: false,
  stripePublicKey:
    'pk_test_51QKKRjGyOOe8Xh5ZqoTx64IszFXGgl1DK4ggZMtdMrvYh6cIVwHikHByZF82fnGDZGvemL3S5OxZAd6DrO9H1tBq00X4JZXZuc',
  // apiUrl: 'https://devcdtapi.cyberwa.com/api/v1',
  // appUrl: 'https://devcdt.cyberwa.com',
  // apiUrl: 'http://localhost:3000/api/v1',
  // appUrl: 'http://localhost:4200',
  apiUrl: 'https://devcapapi.cyberwa.com/api/v1',
  appUrl: 'https://devcap.cyberwa.com',
};

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
  CdkDragStart,
  CdkDragRelease,
} from '@angular/cdk/drag-drop';
import { map, Subject, take, takeUntil } from 'rxjs';

import { TableStore } from './table.store';
import { IColumn } from 'src/app/core/models/column.model';
import { AvatarCellComponent } from './avatar-cell/avatar-cell.component';
import { DateCellComponent } from './date-cell/date-cell.component';
import { InfoCellComponent } from './info-cell/info-cell.component';
import { StatusCellComponent } from './status-cell/status-cell.component';
import { TextCellComponent } from './text-cell/text-cell.component';
import { IUser } from 'src/app/core/models/user.model';
import { IOrganization } from 'src/app/core/models/organization.model';
import { ICategory } from 'src/app/core/models/categories.model';
import { Pagination } from 'src/app/state/categories/categories.state';
import { ButtonCellComponent } from './button-cell/button-cell.component';
import { IDiscount } from 'src/app/core/models/discount.model';

@Component({
  selector: 'cap-table',
  standalone: true,
  imports: [
    CommonModule,
    AvatarCellComponent,
    DateCellComponent,
    InfoCellComponent,
    StatusCellComponent,
    TextCellComponent,
    CdkDropList,
    CdkDrag,
    ButtonCellComponent,
  ],
  providers: [TableStore],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent implements OnDestroy, OnInit {
  @Input() isDraggable = false;
  @Input() displayActions = true;
  private destroy$ = new Subject<void>();
  @Output() onEntityDeleted: EventEmitter<IUser | IOrganization | ICategory | IDiscount> =
    new EventEmitter<IUser | IOrganization | ICategory | IDiscount>();

  @Output() onEntityUpdated: EventEmitter<string> = new EventEmitter<string>();

  @Output() onSortChanged = new EventEmitter<{
    entityId: string;
    previousIndex: number;
    currentIndex: number;
  }>();
  @Output() OnToggleClicked = new EventEmitter();

  private readonly componentStore = inject(TableStore);
  @Input() set columns(value: IColumn[]) {
    this.componentStore.setColumns(value);
  }

  @Input() set data(value: any[]) {
    this.componentStore.setData$(value);
  }
  @Input() set totalItems(value: number) {
    this.componentStore.setTotalItems$(value);
  }

  onDeleteClicked(entity: IUser | IOrganization) {
    this.onEntityDeleted.emit(entity);
  }

  onUpdateClicked(entity: IUser | IOrganization) {
    this.onEntityUpdated.emit(entity._id);
  }
  drop(event: CdkDragDrop<any[]>) {
    this.data$.pipe(take(1)).subscribe((data) => {
      if (event.previousIndex !== event.currentIndex) {
        this.onSortChanged.emit({
          entityId: data[event.previousIndex]._id,
          previousIndex: event.previousIndex,
          currentIndex: event.currentIndex,
        });
      }
      moveItemInArray(data, event.previousIndex, event.currentIndex);
    });
  }

  isDragging = false;

  onDragStarted(event: CdkDragStart) {
    this.isDragging = true;
  }

  onDragReleased(event: CdkDragRelease) {
    this.isDragging = false;
  }

  readonly columns$ = this.componentStore.columns$;
  readonly data$ = this.componentStore.data$;
  readonly totalItems$ = this.componentStore.totalItems$;
  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleEnabled($event: any) {
    this.OnToggleClicked.emit($event);
  }

  sortConfig: { key: string; direction: 'asc' | 'desc' | null } = { 
    key: '', 
    direction: null 
  };

  sortColumn(key: string): void {
    if (this.sortConfig.key === key) {
      this.sortConfig.direction = this.sortConfig.direction === 'asc' ? 'desc' : 
                                 (this.sortConfig.direction === 'desc' ? null : 'asc');
    } else {
      this.sortConfig.key = key;
      this.sortConfig.direction = 'asc';
    }

    if (this.sortConfig.direction === null) {
      this.sortConfig.key = '';
    }

    this.applySorting();
  }

  private applySorting(): void {
    this.data$.pipe(take(1)).subscribe(currentData => {
      if (!this.sortConfig.key || !this.sortConfig.direction || !currentData.length) {
        return;
      }
      
      const sortedData = [...currentData].sort((a, b) => {
        const valueA = this.getSortValue(a, this.sortConfig.key);
        const valueB = this.getSortValue(b, this.sortConfig.key);
        
        // Handle string comparison properly
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return this.sortConfig.direction === 'asc' 
            ? valueA.localeCompare(valueB) 
            : valueB.localeCompare(valueA);
        }
        
        // Handle numeric and other types
        if (this.sortConfig.direction === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      });
      
      this.componentStore.setData$(sortedData);
    });
  }

  private getSortValue(item: any, key: string): any {
    // Handle null/undefined items
    if (!item) return '';
    
    // Handle nested properties (e.g., 'info.name')
    if (key.includes('.')) {
      const keys = key.split('.');
      let value = item;
      for (const k of keys) {
        value = value?.[k];
        if (value === undefined || value === null) {
          return '';
        }
      }
      return typeof value === 'string' ? value.toLowerCase() : value;
    }
    
    // Special case for 'info' column (user name)
    if (key === 'info' && item.info?.fullName) {
      return item.info.fullName.toLowerCase();
    } else if (key === 'info') {
      return '';
    }
    
    // Special case for name column
    if (key === 'name') {
      return typeof item.name === 'string' ? item.name.toLowerCase() : '';
    }
    
    // Special case for phone number - extract only the digits for sorting
    if (key === 'phoneNumber') {
      return typeof item.phoneNumber === 'string' ? item.phoneNumber.replace(/\D/g, '') : '';
    }
    
    // For date columns, convert to timestamp for proper sorting
    const columnType = this.getColumnType(key);
    if (columnType === 'date') {
      return item[key] ? new Date(item[key]).getTime() : 0;
    }
    
    // Default case - return the value or empty string/zero if undefined
    if (item[key] === undefined || item[key] === null) {
      return typeof item[key] === 'number' ? 0 : '';
    }
    
    return item[key];
  }

  // Helper method to get column type synchronously
  private getColumnType(key: string): string | undefined {
    let columnType: string | undefined;
    this.columns$.pipe(take(1)).subscribe(columns => {
      const column = columns.find((col: IColumn) => col.key === key);
      columnType = column?.type;
    });
    return columnType;
  }
}

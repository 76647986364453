import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordMatchValidator(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (!control || !matchingControl) {
      return { controlNotFound: true };
    }

    // Return null if controls haven't been touched yet
    if (!control.value || !matchingControl.value) {
      return null;
    }

    return control.value === matchingControl.value
      ? null
      : { passwordMismatch: true };
  };
}

import { Component, inject, signal } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { CategoryUpsertModalComponent } from '../category-upsert-modal/category-upsert-modal.component';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { TableComponent } from '../../../shared/table/table.component';
import { ICategory } from '../../../core/models/categories.model';
import { map, Subject, takeUntil, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  selectCategoriesWithCurrentAvatar,
  selectPagination,
} from '../../../state/categories/categories.selectors';
import { convertCategoryToCategoryTable } from '../../../utils/entity-formatting.util';
import {
  Pagination,
  selectCategoryById,
} from '../../../state/categories/categories.state';
import { EntityName } from '../../../core/models/entity-name.model';
import { IColumn } from '../../../core/models/column.model';
import {
  CategoriesAPIActions,
  CategoriesAppActions,
} from '../../../state/categories/categories.actions';
import { ModalActions } from '../../../state/modal/modal.actions';
import { selectModalVisibility } from '../../../state/modal/modal.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export interface IModalMetadata {
  title: string;
  modalWidth: string;
  modalID: string;
  isEditMode: boolean;
  category: ICategory | null;
}

@Component({
  selector: 'cap-individual-categories-list',
  standalone: true,
  imports: [
    AsyncPipe,
    CategoryUpsertModalComponent,
    ConfirmModalComponent,
    PaginationComponent,
    SpinnerComponent,
    TableComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './individual-categories-list.component.html',
  styleUrl: './individual-categories-list.component.scss',
})
export class IndividualCategoriesListComponent {
  private destroy$ = new Subject<void>();

  private categoryDropped: ICategory | null = null;

  searchQuery: string = '';
  modalMetadata!: IModalMetadata;

  private readonly store = inject(Store);
  private readonly actions = inject(Actions);

  readonly categories$ = this.store.select(selectCategoriesWithCurrentAvatar);
  readonly categoriesData$ = this.categories$.pipe(
    map((cats) => cats.map((cat) => convertCategoryToCategoryTable(cat))),
  );
  current_page: number = 1;
  total_pages: number = 0;
  totalItems: number = 0;
  pageSize: number = 10;
  readonly pagination$ = this.store
    .select(selectPagination)
    .subscribe((pagination: Pagination) => {
      this.current_page = pagination.page;
      this.total_pages = pagination.totalPages;
      this.totalItems = pagination.totalCount;
      this.pageSize = pagination.limit;
    });

  readonly confirmModalData = {
    title: 'Delete Category',
    message: 'Are you sure you want to delete this category?',
  };

  isLoadingCategoryList = signal(true);

  readonly entityName = EntityName.Category;

  readonly columns: IColumn[] = [
    {
      key: 'avatar',
      label: 'Logo',
      type: 'avatar',
      sortable: false,
    },
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: true,
    },
    {
      key: 'weight',
      label: 'Weight',
      type: 'text',
      sortable: true,
    },
    {
      key: 'isActive',
      label: 'Enabled',
      type: 'button',
      sortable: true,
    },
    {
      key: 'updatedAt',
      label: 'Last Update',
      type: 'date',
      sortable: true,
    },
  ];

  ngOnInit(): void {
    this.store.dispatch(
      CategoriesAppActions.getCategoriesByType({
        categoryType: 'individual',
        page: this.current_page,
        limit: this.pageSize,
      }),
    );

    this.actions
      .pipe(
        ofType(
          CategoriesAppActions.getCategoriesByType,
          CategoriesAPIActions.getCategoriesByTypeSuccess,
          CategoriesAPIActions.getCategoriesByTypeFailure,
        ),
        tap((action) =>
          this.isLoadingCategoryList.set(
            action.type === CategoriesAppActions.getCategoriesByType.type,
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
  openCreateCategoryModal() {
    this.modalMetadata = {
      modalID: 'add-category',
      modalWidth: 'sm:max-w-7xl',
      title: 'Add new Category',
      isEditMode: false,
      category: null,
    };

    this.store.dispatch(ModalActions.open({ id: 'add-category' }));
  }

  openUpdateCategorynModal(id: string) {
    this.store
      .select(selectCategoryById(id))
      .pipe(
        map(
          (category) =>
            (this.modalMetadata = {
              modalID: 'edit-category',
              modalWidth: 'sm:max-w-7xl',
              title: 'Edit Category',
              isEditMode: true,
              category,
            }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.store.dispatch(ModalActions.open({ id: 'edit-category' }));
  }
  sortCategories(data: {
    entityId: string;
    previousIndex: number;
    currentIndex: number;
  }) {
    this.store.dispatch(
      CategoriesAppActions.updateCategorySortOrder({
        categoryId: data.entityId,
        previousIndex: data.previousIndex,
        currentIndex: data.currentIndex,
      }),
    );
  }

  openConfirmationModal(category: any) {
    this.categoryDropped = category;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-category' }));
  }

  selectModalVisibilityById(id: string) {
    return this.store.select(selectModalVisibility(id));
  }

  removeCategory() {
    if (this.categoryDropped) {
      this.store.dispatch(
        CategoriesAppActions.removeCategory({ id: this.categoryDropped._id }),
      );
    }
  }
  onPageChange(page: number) {
    this.store.dispatch(
      CategoriesAppActions.getCategoriesByType({
        categoryType: 'individual',
        page,
        limit: this.pageSize,
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  searchProfiles() {
    this.store.dispatch(
      CategoriesAppActions.searchCategories({
        page: this.current_page,
        pageSize: this.pageSize,
        categoryType: 'individual',
        search: this.searchQuery,
      }),
    );
  }

  onIndividualCategoryStatusChange(obj: {
    effectedEntity: string;
    value: boolean;
  }) {
    this.store.dispatch(
      CategoriesAppActions.toggleCategoryStatus({
        categoryId: obj.effectedEntity,
        isActive: obj.value,
      }),
    );
  }
}

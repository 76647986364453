import { Injectable, OnDestroy, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { filter, Subscription, tap } from 'rxjs';
import { StepNavigationCommunicationService } from 'src/app/core/services/step-navigation-communication.service';
import {
  AuthAPIActions,
  AuthAppActions,
} from 'src/app/state/auth/auth.actions';

interface ValidateOtpModalState {
  token: string;
  isFormValid: boolean;
  isLoading: boolean;
  errorMessage: string | null;
}

@Injectable()
export class ValidateOtpModalStore
  extends ComponentStore<ValidateOtpModalState>
  implements OnDestroy
{
  private actionSubs: Subscription[] = [];
  private readonly actions$ = inject(Actions);
  private readonly stepCommunicationService = inject(
    StepNavigationCommunicationService,
  );
  constructor() {
    super({
      token: '',
      isFormValid: false,
      isLoading: false,
      errorMessage: null,
    });

    this.actionSubs.push(
      this.appActionSub,
      this.apiSuccessActionSub,
      this.apiFailureActionSub,
    );
  }

  readonly token$ = this.select((state) => state.token);
  readonly isFormValid$ = this.select((state) => state.isFormValid);
  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);

  readonly setTwoAuthFormToken = this.updater((state, token: string) => ({
    ...state,
    token,
  }));

  private readonly appActionSub = this.actions$
    .pipe(
      ofType(AuthAppActions.validate2FAOTP),
      tap(() => this.patchState({ isLoading: true, errorMessage: null })),
    )
    .subscribe();

  private readonly apiSuccessActionSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.validate2FAOTPSuccess),
      tap(() => this.patchState({ isLoading: false, errorMessage: null })),
    )
    .subscribe();
  private readonly apiFailureActionSub = this.actions$
    .pipe(
      ofType(AuthAPIActions.validate2FAOTPFailure),
      tap(({ message }) =>
        this.patchState({ isLoading: false, errorMessage: message }),
      ),
    )
    .subscribe();

  override ngOnDestroy(): void {
    this.actionSubs.forEach((sub) => sub.unsubscribe());
  }
}

import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { IAvatar } from 'src/app/core/models/user.model';
import { AvatarService } from 'src/app/core/services/avatar.service';
import { AlertActions } from 'src/app/state/alert/alert.actions';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { ChangeAvatarStore } from './change-avatar.store';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { DndDirective } from '../directives/dnd.directive';

@Component({
  selector: 'cap-change-avatar',
  standalone: true,
  providers: [ChangeAvatarStore, ConfirmModalComponent, SpinnerComponent],
  templateUrl: './change-avatar.component.html',
  styleUrl: './change-avatar.component.scss',
  imports: [
    CommonModule,
    SpinnerComponent,
    ConfirmModalComponent,
    DndDirective,
  ],
})
export class ChangeAvatarComponent {
  readonly confirmModalData = {
    title: 'Delete Avatar',
    message: 'Are you sure you want to delete this avatar?',
  };

  avatarDropped: IAvatar | null = null;

  @Input() set currentAvatar(avatar: IAvatar | null) {
    if (avatar) {
      this.componentStore.setCurrentAvatar(avatar);
    }
  }

  @Input() set oldAvatars(avatars: IAvatar[] | null) {
    if (avatars) {
      this.componentStore.setOldAvatars(avatars);
    }
  }

  @Input() set selectedAvatar(avatar: IAvatar | null) {
    if (avatar) {
      this.componentStore.setSelectedAvatar(avatar);
    }
  }

  @Output() avatarRemoved = new EventEmitter<IAvatar>();
  @Output() avatarUpdated = new EventEmitter<IAvatar>(); // this is not used ??
  @Output() avatarSelected = new EventEmitter<IAvatar>();

  private readonly store = inject(Store);
  private readonly componentStore = inject(ChangeAvatarStore);
  private avatarService = inject(AvatarService);

  maxDimensions = this.avatarService.maxDimensions;

  readonly selectedAvatar$ = this.componentStore.selectedAvatar$;
  readonly oldAvatars$ = this.componentStore.oldAvatars$;
  readonly currentAvatar$ = this.componentStore.currentAvatar$;

  onFileDropped(files: FileList) {
    this.onFileSelected(files);
  }

  async onFileSelected(files: FileList | null): Promise<void> {
    if (files && files.length) {
      const file = files[0];

      const validTypes = [
        'image/svg+xml',
        'image/png',
        'image/jpeg',
        'image/gif',
      ];

      if (!validTypes.includes(file.type)) {
        this.store.dispatch(
          AlertActions.addAlert({
            alert: {
              type: 'error',
              message: 'Invalid file type',
            },
          }),
        );
        return;
      }

      try {
        const isValidDimensions =
          await this.avatarService.validateImageDimensions(
            file,
            this.maxDimensions,
          );

        if (!isValidDimensions) {
          this.store.dispatch(
            AlertActions.addAlert({
              alert: {
                type: 'error',
                message: 'Image dimensions exceed the maximum allowed size',
              },
            }),
          );
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const av = {
            data: e.target!.result as string,
            contentType: file.type,
            isCurrent: true,
            url: '',
          };
          this.componentStore.setSelectedAvatar(av);

          // emit a value for the selected avatar to the parent
          this.avatarSelected.emit(av);
        };
        reader.readAsDataURL(file);
      } catch (error: any) {
        this.store.dispatch(
          AlertActions.addAlert({
            alert: {
              type: 'error',
              message: error.message,
            },
          }),
        );
        return;
      }
    }
  }

  selectOldAvatar(avatar: IAvatar): void {
    this.componentStore.setSelectedAvatar(avatar);
    // emit a value for the selected avatar
    this.avatarSelected.emit(avatar);
  }

  removeAvatar() {
    if (this.avatarDropped) this.avatarRemoved.emit(this.avatarDropped);
  }

  callConfirmationModal(avatar: IAvatar) {
    this.avatarDropped = avatar;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-avatar' }));
  }
}
